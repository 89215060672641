import {
  BET_CONTROL_MODE,
  GAME_CONTROL_LAYOUT,
} from 'components/_Games/game-control-v2/constant';
import { BetRoomStatus, GameQuery } from 'graph';
import lodash from 'lodash';

import {
  MINIGAME_ID_ON_TOP_KEY,
  MINIGAME_ZINDEX_TOP_KEY,
} from 'constants/index';
import { commonBetStateBtn } from './bet-control/components/bet-state-btn/index';

const approximatePercent = 3; // in %
export const roundBetAmountToThreePercent = (
  amount: number,
  minimumBetAmount: number,
) => {
  const isAmountInApproximateMinRange = lodash.inRange(
    amount,
    minimumBetAmount * (1 - approximatePercent / 100),
    minimumBetAmount * (1 + 0.01),
  );

  if (isAmountInApproximateMinRange) {
    return amount + (minimumBetAmount * approximatePercent) / 100;
  }

  return amount;
};

export const getBetRoom = (houseGame: GameQuery) => {
  const currency = houseGame?.game?.gameFund?.gameBank?.currency;

  const readyBetRoom = houseGame?.game?.gameFund?.betRooms.find(
    (betRoom) => betRoom.status === BetRoomStatus.Ready,
  );

  if (!readyBetRoom) return null;

  if (currency.id === readyBetRoom.betRoomSetting.currency.id) {
    return readyBetRoom;
  }

  if (currency.id !== readyBetRoom.betRoomSetting.id) {
    return {
      ...readyBetRoom,
      betRoomSetting: {
        ...readyBetRoom.betRoomSetting,
        currency,
        defaultBetAmount:
          (readyBetRoom.betRoomSetting.defaultBetAmount *
            readyBetRoom.betRoomSetting.currency.equivalentUsdRate) /
          currency.equivalentUsdRate,
        maximumBetAmount:
          (readyBetRoom.betRoomSetting.maximumBetAmount *
            readyBetRoom.betRoomSetting.currency.equivalentUsdRate) /
          currency.equivalentUsdRate,
        minimumBetAmount:
          (readyBetRoom.betRoomSetting.minimumBetAmount *
            readyBetRoom.betRoomSetting.currency.equivalentUsdRate) /
          currency.equivalentUsdRate,
      },
    };
  }
};

export const getDefaultGameFormValues = (
  houseGame: GameQuery,
): GameControlFormType<any> => {
  const betRoom = getBetRoom(houseGame);

  return {
    currency: null,
    layoutDirection: GAME_CONTROL_LAYOUT.HORIZONTAL,
    lastBet: null,
    isShowPopupWin: false,
    gameInfo: {
      id: houseGame?.game?.game.id,
      slug: houseGame?.game?.game.slug,
      gameBase: houseGame?.game?.game?.gameBase,
      gameBank: {
        maxProfit: houseGame?.game?.gameFund?.gameBank.maxProfit,
        currency: houseGame?.game?.gameFund?.gameBank.currency,
        amount: houseGame?.game?.gameFund?.gameBank.amount,
      },
      betRoom,
    },
    betControl: {
      mode: BET_CONTROL_MODE.MANUAL,
      disableAuto: false,
      disableManual: false,
      onBetModeChange: () => {},
      isBetProcessing: false,
      manualElements: [],
      autoElements: [],
      betStateBtn: { ...commonBetStateBtn.bet },
      betAmount: {
        defaultBetAmount: betRoom?.betRoomSetting?.maximumBetAmount || 0,
        value:
          betRoom?.betRoomSetting.currency.equivalentUsdRate !== 0
            ? roundBetAmountToThreePercent(
                betRoom?.betRoomSetting.defaultBetAmount,
                betRoom?.betRoomSetting.minimumBetAmount,
              )
            : betRoom?.betRoomSetting.defaultBetAmount,
        maximumBetAmount: betRoom?.betRoomSetting.maximumBetAmount,
        minimumBetAmount: betRoom?.betRoomSetting.minimumBetAmount,
        labelRight: '',
        disabled: false,
        hideSlider: false,
        maxProfit: houseGame?.game?.gameFund?.gameBank?.maxProfit,
      },
      numberOfBets: {
        value: -1,
        disabled: false,
      },
      increaseOnLoss: {
        type: 'reset',
        increaseBy: 0,
        disabled: false,
      },
      increaseOnWin: {
        type: 'reset',
        increaseBy: 0,
        disabled: false,
      },
      stopOnLoss: {
        value: 0,
        labelRight: '',
        disabled: false,
      },
      stopOnWin: {
        value: 0,
        labelRight: '',
        disabled: false,
      },
      cashoutAt: {
        value: 0,
        labelRight: '',
        disabled: false,
      },
      totalProfit: 0,
    },
    header: {
      hide: true,
      gameBank: {
        show: false,
        onClick: () => {},
      },
      trends: {
        show: false,
        onClick: () => {},
      },
    },
    footer: {
      hide: false,
      left: [],
      right: [],
      sound: {
        active: true,
        onClick: () => {},
      },
      turbo: {
        active: false,
        onClick: () => {},
      },
      fairness: {
        active: false,
        onClick: () => {},
      },
      help: {
        active: false,
        onClick: () => {},
      },
      like: {
        active: houseGame?.game?.game?.liked,
        likes: houseGame?.game?.game?.likes,
        onClick: () => {},
      },
      favorite: {
        active: houseGame?.game?.game?.favored,
        favorites: houseGame?.game?.game?.favorites,
        onClick: () => {},
      },
    },
    methods: {
      updateFields: () => {},
      showPopupWin: () => {},
      updateBalance: () => {},
      updateLiveStats: () => {},
      handleBetException: () => {},
      checkAndUpdateGameCurrency: () => {},
      handleUpdateStateAfterBet: () => {},
      addIncreasedProfitOnHeader: () => {},
      getNewStateAfterAutoBetRound: () => null,
    },
  };
};

export function setMiniGameOnTop(element: HTMLElement) {
  if (!element) return;

  let zIndex: number = JSON.parse(
    localStorage.getItem(MINIGAME_ZINDEX_TOP_KEY),
  );
  if (zIndex === undefined || zIndex === null) {
    zIndex = 1000;
  } else {
    zIndex++;
  }
  const id = localStorage.getItem(MINIGAME_ID_ON_TOP_KEY);
  if (id === undefined || id === null || element.id !== id) {
    localStorage.setItem(MINIGAME_ID_ON_TOP_KEY, element.id);
    localStorage.setItem(MINIGAME_ZINDEX_TOP_KEY, String(zIndex));
    element.style.zIndex = zIndex.toString();
  }
}
