import { ReactComponent as Wallet } from 'assets/icons/wallet_ohplay2.svg';
import Button from 'common/Button';
import WalletDialog from 'components/WalletDialog';
import useResponsive from 'hooks/useResponsive';
import { MyWalletBox } from 'layouts/dashboard/header/AuthenticatedUser/styles';
import { useLocales } from 'locales';
import React, { memo, SetStateAction } from 'react';

import { Box, Typography } from '@mui/material';

// ------------------------------------------------------------------

interface IUserWalletProps {
  isWalletDialogOpen: boolean;
  setIsWalletDialogOpen: React.Dispatch<SetStateAction<boolean>>;
}

const UserWallet = ({
  isWalletDialogOpen,
  setIsWalletDialogOpen,
}: IUserWalletProps) => {
  const { translate } = useLocales();
  const isTablet = useResponsive('up', 'md');

  const handleCloseWalletDialog = () => {
    setIsWalletDialogOpen(false);
  };

  return (
    <MyWalletBox className="nav-deposit-btn">
      <Box className="nav-bonus">
        <Typography component="span">300%</Typography>
      </Box>

      <Button
        onClick={() => {
          setIsWalletDialogOpen(true);
        }}
      >
        {!isTablet && <Wallet width="24px" height="24px" />}
        {isTablet && translate('deposit')}
      </Button>

      {isWalletDialogOpen && (
        <WalletDialog
          isOpen={isWalletDialogOpen}
          handleClose={handleCloseWalletDialog}
        />
      )}
    </MyWalletBox>
  );
};

export default memo(UserWallet);
