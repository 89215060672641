// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY

// ----------------------------------------------------------------------

const es = {
  demo: {
    title: `Spanish`,
  },
  blog: 'Blog',
  chat: 'charlar',
  only_admin_can_see_this_item: `Sólo el administrador puede ver este elemento`,
  fairness: 'Justicia',
  blog_academy: 'Blog / Academia',
  live_support: 'Soporte vital',
  search_your_game: 'Busca tu juego',
  random: 'Aleatorio',
  sign_in: 'Iniciar sesión',
  sign_up: 'Inscribirse',
  wallet: 'Billetera',
  deposit: 'Depósito',
  withdraw: 'Retirar',
  swap: 'Intercambio',
  crypto: 'Cripto',
  fiat: 'Fíat',
  nft: 'NFT',
  search_by_name: 'Buscar por nombre',
  transactions: 'Actas',
  // Deposit
  overview: 'Visión general',
  deposit_currency: 'Moneda de depósito',
  choose_network: 'Elige red',
  deposit_address: 'Dirección de depósito',
  notice: 'Aviso:',
  notice_content:
    'Envíe únicamente /currency/ a esta dirección de depósito. No se admiten direcciones de contratos inteligentes (Contáctenos)',
  balance: 'Balance',
  copy: 'Copiar',
  withdraw_amount: 'Retirar cantidad',
  withdraw_address: 'Retirar dirección',
  deposit_address_placeholder:
    'Complete cuidadosamente según la moneda específica',
  fee: 'Tarifa',
  receive_amount: 'Recibir cantidad',
  confirm: 'Confirmar',
  balance_in_wallet: 'Saldo en billetera',
  withdraw_fee: 'Tarifa de retiro',
  from: 'De',
  to: 'A',
  bill: 'Factura',
  view_history_in_etherscan:
    'Ver el historial de transacciones en Pi.game blockexplorer',
  time: 'Tiempo',
  amount: 'Cantidad',
  status: 'Estado',
  detail: 'Detalle',
  view_in_fiat: 'Ver en USD',
  common_assets: 'Activos comunes',
  order_id: 'Solicitar ID',
  currency: 'Divisa',
  quantity: 'Cantidad',
  type: 'Tipo',
  empty_data: 'Datos vacíos',
  currency_not_available: 'Moneda no disponible',
  all_feature: 'Todas las características',
  success: 'Éxito',
  audit: 'Auditoría',
  insufficient: 'Insuficiente',
  processing: 'Procesando',
  new: 'Nuevo',
  profile_settings: 'Configuración de perfil',
  general: 'General',
  security: 'Seguridad',
  verify: 'Verificar',
  verified: 'Verificado',
  id: 'Identificación',
  nickname: 'Apodo',
  email: 'Correo electrónico',
  phone_number: 'Número de teléfono',
  change: 'Cambiar',
  not_use_symbol:
    'No utilice símbolos especiales; de lo contrario, es posible que su cuenta no sea compatible.',
  hide_profile: 'Ocultar perfil',
  registered_mail_cannot_changed:
    'Según la política de seguridad de Pi.game, el correo electrónico registrado no se puede cambiar.',
  phone_second_login:
    'Agregue el número de teléfono como segundo método de inicio de sesión.',
  hide_profile_title: 'Oculta tus datos de juego en el perfil',
  hide_profile_content:
    'Incluso si están ocultos, tu avatar y nombre de usuario siempre están visibles en las salas de chat.',
  copied: 'Copiado',
  enter_nickname: 'Introduzca el apodo aquí',
  enter_email: 'Introduzca el correo electrónico aquí',
  enter_phone_number_: 'Ingrese el número de teléfono aquí',
  change_password: 'Cambiar la contraseña',
  current_password: 'Contraseña actual',
  new_password: 'Nueva contraseña',
  confirm_password: 'Confirmar nueva contraseña',
  existing_password_not_match: 'La contraseña existente no coincide',
  password_not_match: 'La contraseña no coincide',
  new_password_saved: 'Nueva contraseña guardada',
  relogin_after_change_pw:
    'Será necesario volver a iniciar sesión después de cambiar la contraseña.',
  setup_google_auth: 'Configurar el autenticador de Google',
  download_google_auth:
    'Descargue la aplicación Google Authenticator para iPhone o Android',
  save_account_token:
    'Guarde el siguiente token de cuenta (clave) en un lugar seguro',
  account_token: 'Token de cuenta (clave)',
  //
  need_account_token:
    'Necesitará su token de cuenta (clave) arriba para acceder a su cuenta en caso de que pierda su teléfono',
  scan_qr_title:
    'Escanee el siguiente código QR con la aplicación Google Authenticator',
  enter_token_below: 'Ingrese el token a continuación',
  two_FA_code: 'Código 2FA *',
  appear_on_google_auth: 'Aparecer en su aplicación Google Authenticator',
  verify_code_incorrect: 'Código de verificación incorrecto',
  enable_2fa: 'Habilitar 2FA',
  enter_current_pw: 'Introducir la contraseña actual',
  enter_new_password: 'Ingrese nueva clave',
  enter_repeat_password: 'repita la nueva contraseña',
  enter_2fa_code_to_disable:
    'Ingrese su código 2FA para desactivar la autenticación de dos factores.',
  update_nickname_failed: 'Error al actualizar el apodo, inténtelo de nuevo',
  disable_2fa: 'Deshabilitar 2FA',
  verify_email: 'Verificar correo electrónico',
  send_information_to_email:
    'Le enviaremos información a su dirección de correo electrónico registrada.',
  cancel: 'Cancelar',
  verify_phone_number: 'Verificar número de teléfono',
  resend_code: 'Reenviar codigo',
  did_not_receive_code: '¿No recibiste el código?',
  validate_nickname_length: 'El apodo debe tener entre 4 y 13 caracteres..',
  nickname_existing: 'El apodo ya existe.',
  new_nick_name_saved: 'Nuevo apodo guardado',
  new_email_saved: 'Nuevo correo electrónico guardado',
  new_phone_number_save: 'Nuevo número de teléfono guardado',
  invalid_phone_number: 'Numero de telefono invalido',
  validate_invalid_email_address:
    'Por favor, introduce una dirección de correo electrónico válidas',
  view: 'Vista',
  choose_asset: 'Elija activos',
  min: 'mín.',
  max: 'MÁX',
  max2: 'máx.',
  all_currency: 'Todas las monedas',
  to_secure_assets: 'Para proteger sus bienes, por favor',
  bet_id: 'Identificador de reproducción',
  bet_amount: 'Cantidad jugada',
  payout: 'Pagar',
  profit_amount: 'Ganancia',
  hash: 'Picadillo',
  show_more: 'Mostrar más',
  show_less: 'Muestra menos',
  welcome_aboard: 'Bienvenido a bordo',
  first_deposit_bonus: 'Bono por primer depósito',
  rewards: 'Recompensas',
  deposit_now: 'Depositar ahora',
  just_for_you: 'Solo para ti',
  top_games: 'Los mejores juegos',
  recently_played: 'Recientemente jugado',
  recommended: 'Recomendado',
  favourites: 'Favoritos',
  or_log_in_with: 'O inicia sesión con',
  dont_have_account: '¿No tienes una cuenta?',
  forgot_your_password: '¿Olvidaste tu contraseña?',
  password: 'Contraseña',
  terms_of_service: 'Términos de servicio',
  confirm_accessing_site:
    'Al acceder al sitio, confirmo que tengo 18 años y he leído el',
  include_lower_uppercase: 'Incluir mayúsculas y minúsculas',
  at_least_1_number: 'Al menos 1 número',
  minimum_6_char: 'Mínimo 6 caracteres',
  agree_terms_service: 'Estoy de acuerdo y entiendo el',
  terms_conditions: 'Términos y condiciones.',
  already_have_account: '¿Ya tienes una cuenta?',
  enter_password_here: 'Contraseña de inicio de sesión...',
  reset_password: 'Restablecer la contraseña',
  assets_portfolio: 'Portafolio de Activos',
  hide_small: 'Ocultar pequeño',
  menu: 'Menú',
  game_hub: 'Centro de juego',
  notification: 'Notificación',
  chat_room: 'Sala de chat',
  login_fail: 'Acceso fallido. Por favor intente nuevamente',
  error_message: {
    server_error: 'Error del Servidor. Inténtalo de nuevo en unos minutos.',
  },
  dark: 'Oscuro',
  light: 'Luz',
  casino_home: 'Inicio del casino',
  promotions: 'Promociones',
  refer_friend: 'Recomendar un amigod',
  support_legal: 'Soporte / Legal',
  help_center: 'Centro de ayuda',
  faq: 'FAQ',
  privacy_policy: 'política de privacidad',
  aml: 'AML',
  res_gambling: 'Juego responsable',
  provably_fair: 'Demostrablemente justo',
  registration_login: 'Registro e inicio de sesión',
  about_us: 'Sobre nosotros',
  news: 'Noticias',
  business_contact: 'Contacto de negocio',
  work_with_us: 'Trabaja con nosotros',
  coin_accuracy_limit: 'Límite de precisión de la moneda',
  support: 'Apoyo',
  verify_representative: 'Verificar representante',
  crypto_quest: 'Búsqueda criptográfica',
  video_poker: 'Vídeo póquer',
  classic_dice: 'Dados clásicos',
  join_our_community: 'Únete a nuestra comunidad',
  go_to_top: 'ir arriba',
  game: 'Juego',
  game_info: 'Información del juego',
  description: 'Descripción',
  reviews: 'Reseñas',
  latest_bet_race: 'Últimas apuestas y carreras',
  all_bets: 'Todo compromiso',
  my_bets: 'mis compromisos',
  high_bets: 'Altos compromisos',
  player: 'Jugador',
  hidden: 'Oculto',
  about_this_game: 'Acerca de este juego',
  share_this_game: 'Comparte este juegoe',
  share_on_fb: 'SCompartir en Facebook',
  share_on_whatsapp: 'Compartir en WhatsApp',
  share_on_twitter: 'Compartir en X (Twitter)',
  share_on_discord: 'Compartir en discordia',
  share_on_linkedin: 'Compartir en LinkedIn',
  share_on_skype: 'Compartir en Skype',
  by: 'Por',
  release: 'Liberar',
  return_to_player: 'RTP (Volver al jugador)',
  provider: 'Abastecedor',
  max_win: 'victoria máxima',
  mobile: 'Móvil',
  stakes_range: 'Rango de apuestas',
  active_devices: 'Dispositivos activos',
  device: 'Dispositivo',
  location: 'Ubicación',
  ip_address: 'Dirección IPs',
  last_used: 'Utilizado por última vez',
  action: 'Acción',
  play_now: 'Jugar ahora',
  on: 'En',
  activities: 'Actividades',
  high_rollers: 'Apostadores altos',
  jackpot_winners: 'Ganadores del premio mayor',
  follow_us_on: 'Siga con nosotros',
  send_recover_link_to_email:
    'Se enviará un correo electrónico con un enlace de recuperación de contraseña a su correo electrónico.',
  request_reset_email:
    'La solicitud fue enviada. Por favor revise su correo electrónico',
  something_wrong: 'Algo salió mal. Inténtalo de nuevo',
  vip_club: 'club vip',
  welcome_vip_club_text:
    'Los jugadores que jueguen más tiempo y apuesten más alto recibirán invitaciones exclusivas al club VIP. Brindamos una experiencia premium y bonificaciones a nuestros miembros.',
  jackpot: 'Bote',
  responsible_gambling: 'Juego responsable',
  next: 'Próximo',
  step: 'Paso',
  verification: 'Verificación',
  verify_setup: 'Verificar configuración',
  verify_setup_description1:
    'Para brindarle un mejor servicio, le pedimos que proporcione documentos de identificación originales. Esto protegerá su cuenta en casos de recuperación. También ayuda a garantizar que los obsequios o recompensas reales que reciba se envíen a la ubicación correcta.',
  verify_setup_description2:
    'Una prueba de identificación aceptable incluye una fotografía de su gobierno, un documento de identidad aprobado, una licencia de conducir y un pasaporte. Nos comunicaremos con usted por correo electrónico una vez que se haya completado este proceso.',
  first_name: 'Nombre de pila',
  last_name: 'Apellido',
  gender: 'Género',
  date_of_birth: 'Fecha de nacimiento',
  address: 'DIRECCIÓN',
  city: 'Ciudad',
  postal_code: 'Código Postal',
  country: 'País',
  enter_your_first_name: 'Ponga su primer nombre...',
  enter_your_last_name: 'Introduce tu apellido...',
  verify_government_id_description:
    'Cargue un documento de identificación o licencia de conducir válido emitido por el gobierno. Debe contener tu nombre completo, fecha de nacimiento, tu foto y la fecha de vencimiento del documento.',
  id_card: 'Tarjeta de identificación',
  passport: 'Pasaporte',
  front_side: 'Lado delantero',
  back_side: 'Lado trasero',
  take_a_photo: 'Toma una foto',
  upload_picture: 'Subir foto',
  information_page: 'Página de información',
  verify_portrait_description:
    'Por favor sube una foto tuya. En la foto necesitas tener tu pasaporte o DNI, y un papel con "Pi.game", tu nombre de usuario y la fecha. Asegúrese de que su rostro y la información que tiene sean claros.',
  your_photo: 'Tu fotoo',
  references: 'Referencias',
  all_information_submitted: 'Toda la información se envía',
  thank_you_for_cooperation:
    'Gracias por su cooperación. Le informaremos cuando finalice la revisión.',
  done: 'Hecho',
  this_field_is_required: 'Este campo es obligatorio',
  male: 'Masculino',
  female: 'Femenino',
  unspecified: 'No especificado',
  select_gender: 'Seleccione género',
  back: 'Atrás',
  step1_update_identification_success:
    'Paso 1: actualice la identificación con éxito',
  step23_update_identification_photo_success:
    'Paso 2, 3: actualice las fotografías de identificación con éxito',
  invalid_date_format: 'Formato de fecha no válido',
  update_avatar_success: 'Actualizar avatar exitosamente',
  update_avatar_failed: 'Error al actualizar el avatar',
  game_bank_overview: 'Descripción general',
  game_bank_history: 'Historia',
  game_bank_list: 'Lista',
  game_bank_withdraw: 'Retirar',
  confirm_password_must_match: 'Confirmar que la contraseña debe coincidir',
  recover_password: 'Recuperar contraseña',
  logout_failed: 'Error al cerrar sesión, inténtelo de nuevo!',
  at_least_18_years_old: 'Necesitas tener al menos 18 años.',
  no_support: 'sin apoyot',
  gems: 'Gemas',
  sound: 'Sonido',
  invalid_wallet_address: 'Dirección de billetera no válida',
  target_multiplier: 'Multiplicador objetivo',
  multiplier: 'Multiplicadora',
  unexpected_error:
    'Ha ocurrido un error inesperado. Por favor, inténtelo de nuevo más tarde',
  validate_nickname_message:
    'El apodo debe tener entre 4 y 13 caracteres, sin firmar y no contener espacios, símbolo.',
  swap_fee: 'Tarifa de intercambio',
  verify_account: 'Verificar Cuenta',
  history: 'Historia',
  big_winner: 'Gran ganador',
  jackpot_winner: 'Ganador del premio mayor',
  my_bet: 'Mi juego',
  all_bet: 'Todo compromiso',
  winner: 'Ganador',
  insufficient_ballance: 'Saldo insuficiente',
  gamebank: 'Banco de juegos',
  maximumFileSize: 'El tamaño máximo del archivo es 4 MB',
  verify_email_was_send:
    'Verifique que se envió el correo electrónico, verifique su correo electrónico',
  error_code: {
    EMAIL_IN_USED:
      'Este correo electrónico ha sido utilizado por otro usuario.',
  },
  account_verified: 'Tu cuenta fue verificada',
  check_information_carefully:
    'Verifique cuidadosamente la información de su inversión antes de confirmar.',
  invest_now: 'Invierta ahora',
  more_information: 'Más información',
  host: 'Anfitrión',
  loading: 'Cargando...',
  you_must_specify_a_number: 'Debes especificar un número',
  must_be_a_positive_value: 'Debe ser un valor positivo.',
  withdraw_success: 'Retirar el éxito',
  withdraw_failed: 'Retiro fallido',
  your_total_invest: 'Su inversión total',
  your_current_shares: 'Tus acciones actuales',
  your_balance: 'Tu balance',
  your_profit: 'Tu beneficio',
  leverage: 'Aprovechar',
  game_bank: 'banco de juegos',
  game_bank_max: 'GameBank máximo',
  your_invest: 'Tu inversión',
  available_invest_amount: 'Monto de inversión disponible',
  your_share: 'Tu parte',
  host_share: 'Compartir anfitrión',
  invest_success: 'Invierta el éxito',
  invest_failed: 'Invertir fallidod',
  investor: 'Inversor',
  share: 'Compartir',
  error_message_server_error: 'error_message.server_error',
  successful_balance_change: 'Cambio de equilibrio exitoso:',
  permission_denied: 'Permiso denegado',
  you_not_permission_page: 'Usted no tiene permiso para acceder a esta página',
  not_bad: 'No bad',
  nice: 'Lindo',
  awesome: 'Impresionante',
  wicked: 'Malvado',
  godlike: 'Divino',
  to_the_moon: 'A la Luna',
  you_win: '¡TU GANAS!',
  bum: '¡BUMMM!',
  some_thing_went_wrong: 'Algo salió mal',
  maximum_number: 'El número máximo de celdas seleccionadas es',
  auto_select: 'SELECCIÓN AUTOMÁTICA',
  number_of_spin: 'Número de giro',
  advanced_auto: 'automático avanzado',
  stop_if_anyone_win_jackpot: 'Detente si alguien gana Jackpot',
  stop_decrease_by: 'Detener si el saldo disminuye en',
  stop_increase_by: 'Detener si el saldo aumenta en',
  stop_exceeds_by: 'Deténgase si la ganancia individual excede por',
  start_auto_spin: 'Iniciar giro automático',
  not_enough_balance: 'No hay suficiente equilibrio',
  set_auto_bet: 'Establecer apuesta automática',
  cancel_auto_bet: 'Cancelar apuesta automática',
  cashout_at: 'Retiro en',
  win_amount: 'ganar cantidadt',
  increase: 'aumentar',
  decrease: 'disminuir',
  bet: 'Jugar',
  waiting: 'Espera...',
  bet_next_round: 'APUESTA (próxima ronda)',
  cashout: 'Retiro',
  win: 'GANAR',
  lose: 'PERDER',
  increase_by: 'Aumentado por',
  reset: 'Reiniciar',
  manual: 'Manual',
  auto: 'Auto',
  max_profit: 'Max profit',
  max_bet: 'apuesta máxima',
  stop_on_profit: 'Detener las ganancias',
  number_of_bet: 'Número de juego',
  on_loss: 'En pérdida',
  on_lose: 'Al perder',
  on_win: 'Al ganar',
  stop_on_loss: 'Detener la pérdida',
  stop_on_win: 'Detenerse al ganar',
  live_stats: 'Estadísticas en vivo',
  total_bet_amount: 'Monto total de la apuesta',
  total_profit: 'Recompensa total',
  game_bank2: 'BANCO DE JUEGOS',
  can_not_get_game_bank: 'No puedo obtener el banco del juego',
  trends: 'Tendencias',
  play_real: 'Juega de verdad',
  play_with_fun: 'Juega con DIVERSIÓN',
  online: 'En línea',
  welcome_bonus: 'BONO DE BIENVENIDA',
  up_to: 'HASTA',
  join_now: 'Únete ahora',
  total: 'Total',
  invest: 'Invertir',
  insufficient_balance: 'Saldo insuficiente',
  please_switch_to_another_asset_to_continue_playing:
    'Cambia a otro activo para seguir jugando.',
  switch: 'Cambiar',
  not_found: 'Extraviado',
  no_results_found_for: 'No se encontraron resultados para',
  checking_for_typos:
    'Intente comprobar si hay errores tipográficos o utilizar palabras completas.',
  please_enter_keywords: 'Por favor ingrese palabras clave',
  exit_fullscreen: 'Salir de pantalla completa',
  fullscreen: 'Pantalla completa',
  settings: 'Ajustes',
  currency_do_not_supported: 'Esta moneda no es compatible.',
  update_photo: 'Actualizar foto',
  upload_photo: 'Subir foto',
  remove_all: 'Eliminar todo',
  upload_files: 'Subir archivos',
  drop_or_select_file: 'Soltar o seleccionar archivo',
  drop_files_here_or_click: 'Suelte los archivos aquí o haga clic',
  thorough_your_machine: 'A través de tu máquina',
  no_found_any_currency_can_swap:
    'No se ha encontrado ninguna moneda que pueda intercambiarse.',
  account_not_verified: 'La cuenta no ha sido verificada.',
  account_not_conditions: 'Apostar más para retirar',
  drawing_your_balance:
    'Primero debe verificar su cuenta antes de retirar su saldo.',
  withdraw_request_was_sent:
    'Se envió la solicitud de retiro, esperando confirmación',
  available_amount_insufficient: 'Cantidad disponible insuficiente',
  need_help: '¿Necesitas ayuda?',
  coming_soon: 'muy pronto',
  introduction_to_coco:
    'Un criptocasino ganador de múltiples premios. Con un enfoque centrado en el jugador, PI.GAME puede satisfacer a millones de jugadores en todo el mundo. PI.GAME tiene como prioridad su comunidad, garantizando una experiencia de juego duradera e infinitamente entretenida.',
  coming_soon2: 'Coming soon...',
  under_development: 'The function is under development',
  page_404: '404 Página no encontrada | Pi.juego',
  page_not_found: 'Sorry, page not found!',
  check_your_spelling:
    'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.',
  go_to_home: 'Ir a casa',
  remove_session_success: 'Eliminar el éxito de la sesión',
  remove_session_failed: 'Error al eliminar sesión',
  in_use: 'En uso',
  remove_device: 'Retire el dispositivo',
  your_profile: 'Tu perfil',
  is_hidden: 'Estaba escondido',
  is_public_now: 'es publico ahora',
  hide_profile_failed: 'Error al ocultar el perfil, inténtelo de nuevo',
  change_password_success:
    'Cambiar contraseña exitosamente. Por favor inicie sesión nuevamente',
  upload_image_failed: 'Error al subir la imagen',
  file_size_limit_10MB: 'jpg, png; Límite de tamaño de archivo 10 MB',
  roll_over: 'Dese la vuelta',
  win_chance: 'Oportunidad de ganar',
  profit_on_next_tile: 'Ganancia en el siguiente mosaico',
  pick_a_tile_randomly: 'Elige un mosaico al azar',
  pay_table: 'tabla de pagos',
  game_rule: 'regla del juego',
  number_of_bets: 'Número de juego',
  bet_failed: 'El juego falló',
  stop_auto_bet: 'Otomatik oynatmayı durdur',
  currency_not_support: 'Moneda no compatible',
  login_to_play: 'Iniciar sesión para jugar',
  yes: 'Sí',
  how_to_play: 'Cómo jugar',
  help: 'Ayuda',
  start_bet_round_failed: 'Oyun turu başlatılamadı',
  cashout_failed: 'Retiro fallido',
  auto_bet_round: 'Comprometer el éxito',
  play_now2: 'Jugar ahora',
  under_min_bet: 'Por debajo de la apuesta mínima',
  invalid_bet_params: 'parámetros de juego no válidos',
  game_not_available_now: 'Juego no disponible ahora',
  insufficient_amount: 'Cantidad insuficiente',
  invalid_bet_state: 'estado de juego no válido',
  win_rate: 'Ratio de victorias',
  welcome_to_Coco_game: 'Bienvenida a pi.game',
  welcome_to_only: 'Bienvenida a',
  welcome_only: 'Bienvenida',
  to_join_club: 'para unirte a nuestro club vip',
  sign_in_or_sign_up: 'Sign In/ Sign Up',
  invalid_email_format: 'Formato de correo inválido',
  resend_after: 'Reenviar después',
  second: 'segundo',
  only_available_at_large_resolutions:
    'Solo disponible en resoluciones grandes > 1600 px (xl)',
  provably_fairs: 'ferias demostrables',
  Fairness: 'Justicia',

  club_member: 'Miembro del Club',
  vip_club_subtitle1:
    'Los jugadores más veteranos y con mayor compromiso recibirán invitaciones exclusivas al',
  vip_club_subtitle2:
    'Eso proporciona una experiencia premium y bonificaciones para sus miembros.',
  your_vip_progress: 'Tu progreso VIP',
  your_vip_progress_tooltip:
    'Inicie sesión primero y luego vea su progreso VIP',
  total_wager: 'Compromiso total',
  your_vip_progress_description:
    'Todos los compromisos se convierten a USD al tipo de cambio actual.',
  how_does_it_work: '¿Como funciona?',
  how_vip_club_work: '¿Cómo funciona el Club VIP?',
  the_rewards: 'las recompensas',
  frequently_asked_questions: 'Preguntas frecuentes',
  vip_frequent_questions_subtitle:
    'Le recomendamos que se comunique con el soporte o con su administrador VIP si tiene alguna pregunta.',
  login: 'Acceso',
  to_see_your_vip_progress: 'para ver tu progreso VIP',
  wager_rate: 'Juega $1.00 = 1XP',
  all_wagers_converted_to_usd:
    'Todas las apuestas se convierten a USD al tipo de cambio actual.',
  vip_club_benefit: 'Beneficio del club VIP',
  vip_club_details: 'Detalles del club VIP',
  benefit_table: 'Tabla de beneficios',
  wager_contest: 'Concurso de apuestas',
  game_of_the_day: 'Juego del día',
  play_to_earn: 'Juega para ganar apuestas',
  time_remaining: 'Tiempo restante',
  rank: 'Rango',
  wagered: 'Apostado',
  prize: 'Premio',
  wager_contest_information: 'Información del desafío',
  rule: 'Regla',
  gamebank_chart: 'Gráfico del banco de juegos',
  next_round: 'Proxima ronda',
  start_auto_bet: 'Iniciar apuesta automática',
  understand_trend_chart: 'Comprender el gráfico de tendencias',
  round_has_been_bet:
    'Esta ronda ha sido apostada, cancela desde la siguiente ronda.',
  crash_server_disconnect: 'Fallo en la desconexión del servidor...',
  coco_info:
    'Este sitio web es propiedad y está operado por Orisun N.V., una empresa registrada y establecida bajo las leyes de Curazao, con número de registro 163631, con dirección registrada Zuikertuintjeweg Z/N (Zuikertuin Tower), Curazao.',
  crash_result: 'Resultado del accidente',
  wagers: 'Juega',
  reset_after: 'Reiniciar después',
  daily_wagering_contest_rules: 'Reglas del concurso de juego diario',
  the_daily_race:
    'El concurso Daily Race se realiza diariamente desde las 00:00 UTC hasta las 23:59 UTC, a partir de',
  daily_contest_prize_pool: 'Premio acumulado del concurso diario',
  straight_flush_with_jack: 'Escalera de color con Jack',
  straight_flush: 'Escalera de color',
  four_of_a_kind: 'Cuatro de un tipo',
  full_house: 'Casa llena',
  flush: 'Enjuagar',
  straight: 'Derecho',
  three_of_a_kind: 'Tres de un tipo',
  tow_pair: 'Dos pares',
  pair_of_jacks_or_better: 'Par de jotas o mejor',
  you_won: 'Ganaste',
  show_all: 'Mostrar todo',
  personal: 'Personal',
  no_notifications_at_the_moment:
    'No hay notificaciones por el momento. Por favor, vuelva más tarde.',
  mode: 'Modo',
  contrast: 'Contraste',
  direction: 'Dirección',
  layout: 'Disposición',
  stretch: 'Estirar',
  presets: 'Preajustes',
  status_success: 'éxito de estado',
  status_pending: 'Estado: Pendiente',
  swap_successfully: '¡Intercambia con éxito!',
  swap_failed: 'El intercambio falló, inténtelo tarder',
  play_your_favorite_games: 'Juega tus juegos favoritos',
  instantly_sign_up:
    'Regístrese instantáneamente y comience a jugar en el juego o en la casa de apuestas deportivas.',
  unlock_further_benefits:
    'Disfruta jugando, alcanza nuevos niveles y desbloquea más beneficios',
  rank_claim_bonus: 'Aumenta tu rango y reclama bonificación',
  go_up_to_the_next_rank:
    '¡Sube al siguiente rango! ¡Cada nivel tiene su bonificación y aún más oportunidades!',
  ranking_system_title: 'Pi.game VIP Ranking System',
  ranking_system_text:
    '¡Desbloquee un mundo de oportunidades y reconocimiento uniéndose a nuestro sistema de clasificación de plataformas! Únase a nosotros hoy para mejorar su perfil, jugar nuestros juegos y disfrutar de los beneficios de ser un participante destacado. No pierdas la oportunidad de ser reconocido: ¡sé parte de la historia de éxito ahora!',
  you_start_your_gaming:
    'Comienzas tu camino de juego desde el primer rango: RECIÉN LLEGADO.',
  the_more_you_play:
    'Cuanto más juegues, mayor será tu rango. Aumenta según la cantidad de juego mientras se juega en Pi.game. Todos los compromisos se convierten a USD al tipo de cambio actual.',
  mark_all_as_read: 'marcar todo como leido',
  eligible_VIP_member: '¿Soy elegible para convertirme en miembro VIP?',
  what_does_affect_the_weekly_monthly: '¿Qué afecta el bono semanal/mensual?',
  information_to_become_a_VIP_member:
    '¿Tendré que proporcionar alguna información adicional para convertirme en miembro VIP?',
  one_min: '1 minuto',
  five_min: '5 minutos',
  one_hour: '1 hora',
  for_hours: '4 horas',
  one_day: '1 día',
  investment: 'Inversión',
  my_investment: 'Mi inversión',
  what_upp: '¿Qué pasa???',
  hide: 'Esconder',
  play: 'Jugar',
  stake_and_earn: 'Juega y gana',
  //
  vip_level_comparison: 'Comparación de niveles VIP',
  bronze: 'Bronce',
  silver: 'Plata',
  gold: 'Oro',
  platinum: 'Platino',
  diamond: 'Diamante',
  level_up_bonus: 'Bono por subir de nivel',
  surprise_box: 'Caja Sorpresa',
  free_spin_wheel_enable: 'Habilitación de rueda de giro libre',
  surprise_box_upgraded: 'Caja sorpresa mejorada',
  weekly_bonus: 'Bono Semanal',
  chat_enable: 'Habilitar chat',
  upload_avatar_enable: 'Subir avatar Habilitar',
  vip_support_enable: 'Habilitación de soporte VIP',
  surprise_box_upgraded_2:
    'Caja sorpresa mejorada (hasta 1,8 millones de USDT)',
  we_have_given_over_1_billion:
    'Hemos otorgado más de mil millones de dólares en bonificaciones. Esta es principalmente la razón por la que tenemos el mejor programa VIP en línea. Realmente creemos en recompensar a nuestros jugadores por su juego y lealtad.',
  me: 'a mí',
  result: 'Resultado',
  crush_of_crash: 'Aplastamiento de choque',
  // Banner new
  earn_real_money: 'Gana dinero real',
  the_daily_game: 'el juego diario',
  earn_everyday_with_original_games:
    'Gana todos los días con juegos originales',
  daily_race: 'CARRERA DIARIA',
  wager: 'Jugar',
  and: 'Y',
  everyday: 'CADA DÍA',
  crush: 'APLASTAR',
  the_crash: 'EL CHOQUE',
  wager_and_win_everyday: 'APUESTA Y GANA TODOS LOS DÍAS',
  show_off_your_prediction_skills: 'Demuestra tus habilidades de predicción',
  deposit_now2: 'DEPOSITAR AHORA',
  on_1st_deposit: 'En el 1er depósito',
  // Just for you
  free_play: 'JUEGO GRATIS',
  with_fun_coin: 'Con moneda DIVERTIDA',
  show_me: 'MUÉSTRAME',
  low_risk_high_rewards: 'Bajo riesgo, Altas recompensas',
  prize_100000: 'PREMIO $100,000',
  win_1000x_jackpot: 'Gana un bote de 1000x',
  // Notification
  balance_change: 'cambio de saldo',
  your_casino: 'Tu centro de juegos',
  // Referral
  spread_the_word_and: 'difundir el mundo y',
  earn_reward: 'Gana recompensa',
  campaign_name: 'Nombre de campaña',
  start_your_referrals: 'Inicia tus referidos',
  id_number: 'Número ID',
  registered: 'Registrado',
  total_deposits: 'Depósitos totales',
  last_deposit: 'Último depósito',
  vip_point: 'Punto VIP',
  commission: 'Comisión',
  referral: 'Remisión',
  register_date: 'Fecha de registro',
  display_name: 'Nombre para mostrar',
  agents: 'Agentes',
  transaction_date: 'Fecha de Transacción',
  transaction_type: 'tipo de transacción',
  export: 'Exportar',
  campaigns: 'campana',
  create_a_new_campaign: 'Crear nueva campaña',
  claim_income: 'Reclamar ingresos',
  per_players: 'Por jugadores',
  per_agents: 'Por agentes',
  per_day: 'Por día',
  per_week: 'Por semana',
  per_month: 'Por mes',
  per_country: 'Por país',
  date: 'Fecha',
  total_income: 'Recompensa total',
  total_user_register: 'Registro total de usuarios',
  total_user_bet: 'Juego total del usuario',
  total_transaction: 'Transacción total',
  refer_description:
    '¡Recomiende nuestra marca y gane dinero participando en el programa de afiliados de Pi.game! Recibirás una comisión de afiliado por cada usuario que se registre y juegue en Pi.com a través de tus enlaces. Ser un afiliado de Pi.game significa que ganarás una comisión por todos los compromisos realizados tanto en nuestra plataforma como en Sportsbook, lo que, a diferencia de los programas de afiliados tradicionales, significa que, independientemente de si ganan o pierden, ¡tú seguirás ganando la misma comisión!',
  download_banner: 'Descargar banner',
  search: 'Búsqueda',
  clicks: 'Clics',
  referrals: 'Referencias',
  deposit_users: 'Usuarios de depósito',
  total_commission: 'Comisión total',
  commission_rate: 'Porcentaje de comision',
  features: 'Características',
  link: 'Enlace',
  code: 'Código',
  today: 'Hoy',
  this_week: 'Esta semana',
  last_week: 'La semana pasada',
  overall: 'En general',
  commission_available: 'Comisión disponible',
  claim: 'Afirmar',
  log_in: 'Acceso',
  to_see_your_rank: 'para ver tu rango',
  start_in: 'Empezar en ...',
  reconnect: 'Reconectar',
  controls: 'controles',
  leaderboard: 'tabla de clasificación',
  bet_next: 'Reproducir siguiente',
  betting: 'jugar',
  // Tip
  message_is_required: 'Se requiere mensaje',
  receiver_id: 'ID del receptor',
  enter_user_id: 'Introduzca el ID de usuario',
  transaction_fee: 'Tarifa de transacción',
  message: 'Mensaje',
  give_your_friends_some_messages_here:
    'Dale a tus amigos algunos mensajes aquí...',
  continue: 'Continuar',
  successfully_tipped: 'propina exitosa',
  you_are_tipping_to: 'te estás inclinando hacia',
  your_tip_request_has_been_confirmed:
    'Su solicitud de propina ha sido confirmada.',
  you_can_track_the_progress_on_the_wallet_transaction_history:
    'Puede realizar un seguimiento del progreso en el Historial de transacciones de Wallet.',
  view_history: 'Ver historial',
  tip_failed: 'Consejo fallido',
  your_tip_request_was_unsuccessful_due_to_an_abnormality:
    'Su solicitud de propina no tuvo éxito debido a una anomalía.',
  please_try_again_later: 'Por favor, inténtelo de nuevo más tarde.',
  ok_i_got_it: 'Ok, lo tengo',
  tip_information: 'Información de propinas',
  password_is_required: 'se requiere contraseña',
  tip_verification: 'Verificación de propinas',
  authentication_secure: 'Autenticación segura',
  to_process_tipping_please: 'Para procesar las propinas, por favor',
  first_to_secure_your_assets: 'primero en asegurar sus bienes',
  step_verification: 'Verificación de 2 pasos',
  enter_your_login_password: 'Ingrese su contraseña de inicio de sesión',
  enter_the_2fa_code_appear_on_your_google_authenticator_app:
    'Ingrese el código 2FA que aparece en su aplicación Google Authenticator',
  authorize: 'Autorizar',
  // 30/09
  sign_up_in_and_earn: 'Regístrese / Ingrese y gane',
  learn_more_about_our_affiliate_program:
    'Obtenga más información sobre nuestro programa de afiliados',
  referral_link: 'Enlace de referencia',
  create: 'Crear',
  referral_users: 'Usuarios de referencia',
  registered_date: 'La fecha registrada',
  send_tip: 'Envía propina',
  delete: 'Borrar',
  accept: 'Aceptar',
  close: 'Cerca',
  member_downline: 'Línea descendente de miembros',
  new_player: 'Nuevo jugador',
  claim_commission_failed: 'La comisión de reclamo falló',
  claim_commission_successfully: 'Comisión de Reclamación con éxito',
  create_campaign_successfully: 'Crear campaña exitosamente',
  create_campaign_failed: 'Error al crear campaña',
  any_date: 'Cualquier fecha',
  // Mini CoCo
  stop_if_single_win_exceeds_by:
    'Deténgase si la ganancia individual excede por',
  // Host
  show_filter: 'Mostrar filtro',
  filter: 'Filtrar',
  default_campaign: 'Campaña predeterminada',
  select_all: 'Seleccionar todo',
  role: 'role',
  hello_world: 'Hola Mundo',
  // GameBank => Overview
  gb_capacity: 'GB de capacidad',
  game_bank_capacity: 'Capacidad del banco de juegos',
  the_maximum_amount_that_you_can_invest:
    'La cantidad máxima que puedes invertir.',
  // Crash
  finish_bet: 'Finalizar apuestat',
  finishing_bet: 'Apuesta final',
  // Referral
  delete_invitation: 'Eliminar invitación',
  you_had_been_invited_to_become_an_agent_by:
    'Usted había sido invitado a convertirse en agente por',
  confirming_to_delete_this_invitation:
    'Al confirmar la eliminación de esta invitación, esta acción no se deshará. ¿Aún quieres decidirte?',
  successfully_accepted_invitation: 'Has aceptado con éxito la invitación.',
  the_house: 'La casa',
  invited_you_to_become_an_agent: 'te invitó a convertirte en agente',
  campaign_name_1: 'Nombre de campaña 1',
  available_commission: 'Comisión disponible',
  there_are_no_commission: 'no hay comision',
  please: 'Por favor',
  login2: 'login',
  to_see_your_rewards: 'Para ver tus recompensas.',
  you_have_successfully_accepted_the_invitation:
    'Has aceptado con éxito la invitación',
  //
  downline_report: 'Informe de línea descendente',
  referral_faq_subscription:
    'Para obtener más detalles sobre el programa de afiliados, comuníquese con ',
  internet_connection_restored: 'Conexión a Internet restaurada',
  lost_connect_to_network: 'Se perdió la conexión a la Red',
  // 10/10
  user_profile: 'Perfil del usuario',
  leave_a_tip: 'Deja una propina',
  your_commission_rate: 'Su tasa de comisión',
  affiliate_terms_conditions: 'Términos y condiciones de afiliados',
  REFERRAL_CODE_OR_NAME_EXISTS:
    'El nombre de la campaña o el código de referencia ya existen.',
  swap_unavailable:
    'Actualmente no se admite el intercambio de pares. Vuelve a intentarlo más tarde.',
  please_enter_swap_amount: 'Por favor ingrese el monto del intercambio',
  become_our_agent_Contact_business:
    '¿Convertirse en nuestro agente? Contacto (comercial:',
  earn_commission_rewards:
    'Gana comisiones cada vez que tus amigos hagan apuestas en nuestros juegos',
  turn_your_friends_and_followers_into_weekly_commissions:
    'Convierte a tus amigos y seguidores en comisiones semanales con el programa de afiliados de Pi. Es simple, rentable y completamente transparente.',
  // FAQ
  adding_our_link_and_or_banner_to_your_website:
    'Si tienes un sitio web o canales sociales con cierto tráfico, ¿por qué no maximizar su potencial? Al agregar nuestro enlace y/o banner a su sitio web, puede crear una forma nueva y efectiva de obtener ingresos adicionales al instante.',
  how_do_I_get_paid_for_being_an_affiliate: '¿Cómo me pagan por ser afiliado?',
  the_banner_or_link_you_create_contains_your_personal_tracking_code:
    'El banner o enlace que cree contiene su código de seguimiento personal. Cuando los jugadores visitan Pi.game, este código se almacena en una cookie en su computadora. Cuando el usuario se registra, lo identificamos como referido por usted. Este jugador queda asociado permanentemente contigo y tú continúas generando comisiones por su actividad durante toda la vida.',
  how_a_new_player_linked_to_me: '¿Cómo se vinculó un nuevo jugador conmigo?',
  how_exactly_is_my_affiliate_commission_calculated:
    '¿Cómo se calcula exactamente mi comisión de afiliado?',
  the_commission_for_each_bet: `La comisión por cada apuesta se calcula según la siguiente fórmula: Comisión = Apuesta * ventaja de la casa en el juego * tasa de comisión Donde:
      - Apuesta total: el monto total de las apuestas del juego generado por todos los usuarios recomendados por el afiliado.
      - Ventaja de la casa: la relación entre la pérdida promedio y la apuesta inicial. La ventaja de la casa de cada juego se define como 1 menos el RTP (Retorno al jugador).
      - Tasa de comisión: la tasa depende del rol de afiliado de los usuarios y se muestra en el sitio del afiliado.`,
  how_to_join_the_affiliate_program: '¿Cómo unirse al programa de afiliados?',
  how_can_i_keep_track_of_my_performance:
    '¿Cómo puedo realizar un seguimiento de mi rendimiento?',
  once_your_affiliate_link_is_generated:
    'Una vez que se genera su enlace de afiliado, puede iniciar sesión en el panel de afiliados. Allí, tiene acceso para ver todas sus estadísticas y puede filtrar la información en los informes en función de varios factores.',
  i_have_big_audience_how_i_can_get_special_deals:
    'Tengo una gran audiencia, ¿cómo puedo obtener ofertas especiales?',
  if_you_have_a_website_with_substantial_traffic:
    'Si tiene un sitio web con mucho tráfico o una cuenta de redes sociales con una gran audiencia, puede comunicarse con nosotros en business@pi.game para obtener ofertas exclusivas.',
  // Crash
  maximum_is_9900: `El máximo es "9900"`,
  minium_is_101: `El mínimo es "1,01"`,
  insufficient_currency: 'Dinero insuficiente',
  this_game_does_not_support_this_currency:
    'Este juego no admite esta moneda; cambia a otro activo para seguir jugando.',
  unknown: 'Desconocido',
  you_should_verify_account_first_before_tipping_your_friends:
    'Deberías verificar la cuenta primero \n antes de darle propina a tus amigos',
  must_be_only_digits: 'Debe tener solo números',
  min_length_ID_at_least_14_number:
    'El documento de identidad tiene una longitud mínima de al menos 14 números.',
  receiver_is_required: 'Requiere receptor',
  tip_amount_must_specify_a_number:
    'El monto de la propina debe especificar un número.',
  tip_amount_must_greater_than_0: 'El monto de la propina debe ser mayor que 0',
  tip_amount_is_required: 'El monto de la propina es obligatorio.',
  how_to_see_other_ID: '¿Cómo ver otras identificaciones?',
  get_your_friends_ID_from_their_profiles:
    'Obtenga la identificación de sus amigos desde su perfil.',
  tips_amount: 'Monto de propina',
  total_spending_amount: 'Monto total gastado',
  withdrawing_your_balance:
    'Debes verificar tu cuenta antes de retirar tu saldo.',
  play_increase_wager:
    'Ao receber o dinheiro do bônus, você precisará atingir um valor de aposta de $ 120.000 para sacar',
  pls_enter_code_send_to_tel:
    'Por favor ingresa el código enviado a tu teléfono',
  please_enter_withdraw_address: 'Por favor ingrese la dirección de retiro',
  your_profile_had_been_verified: 'Tu perfil ha sido verificado.',
  SWAP_UNAVAILABLE: 'Saldo insuficiente',
  // Maintain
  this_page_is_under_maintenance:
    '¡LO SIENTO! Esta pagina esta bajo mantenimiento',
  please_give_us_some_minutes_to_fix_the_issue:
    'Por favor danos unos minutos para solucionar el problema.',
  // 404
  we_cant_find_that_page: `¡Vaya! No podemos encontrar esa página.`,
  you_can_still_enjoy_the_best_casino_experience_at_Coco: `Pero no te preocupes, aún puedes disfrutar de la MEJOR experiencia de casino en Pi.game.`,
  //
  region_prohibited: 'Región prohibida',
  coco_game_is_not_available_in_your_region:
    'Lo sentimos, pi.game no está disponible en tu región.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country:
    'Debido a nuestra licencia de juego, no podemos aceptar jugadores de su país. Contáctanos a través de support@Pi.game si necesitas más ayuda.',
  congrats: 'Felicitaciones',
  won_the_most: '¡ganó más!',
  page: 'Paje',
  refresh: 'Actualizar',
  // Popup
  register_to_get: 'Regístrate para conseguir',
  player_to_earn_the: 'Jugador para ganar el',
  $10_prize: 'premio de $10',
  sign_up_now: 'Regístrate ahora',
  // Popup 2
  you_receive: 'RECIBES',
  weekly: 'semanalmente',
  see_detail: 'ver detalle',
  swap_amount_need_to_larger_than_min:
    'El monto del swap debe ser mayor que el mínimo',
  crypto_play: 'Criptojuego',
  fun_play: 'DIVERSIÓN Jugar',
  // Popup 3
  play_with: 'JUGAR CON',
  earn: 'GANARSE',
  real: 'REAL',
  dollars: 'DÓLARES',
  big_wins: 'Grandes victorias',
  what_is_gift_code: '¿Qué es el código de regalo?',
  gift_code_info:
    'Un código de regalo es como un código especial formado por letras y números. Cuando canjeas este código, obtienes recompensas especiales de',
  gift_code_cod:
    'Cada código de regalo tiene su propio valor y duración. Todas las recompensas se pagan en forma de tokens COD.',
  how_to_get_Coco_gift_code: '¿Cómo obtener el código de regalo de Pi.game?',
  how_to_use_Coco_gift_code: '¿Cómo utilizar el código de regalo de Pi.game?',
  simply_enter_the_gift_code:
    'Simplemente ingrese el código de regalo y las recompensas se agregarán automáticamente a su saldo.',
  giftcode_required: 'Se requiere código de regalo',
  bonus_code_claimed_successfully: 'Código de bonificación reclamado con éxito',
  claim_your_free_reward: 'Reclama tu recompensa gratis',
  enter_your_giftcode: 'Introduce tu código de regalo',
  claim_bonus: 'Bono de reclamación',
  what_is_giftcode: '¿Qué es el código de regalo?',
  got_it: 'Entiendo',
  won: 'ganó',

  // 8/12
  use_your_COD_gift_to_bet_and_win_at_Cocogame:
    'Usa tu regalo Coco para apostar y ganar en Coco.game',
  gift_code: 'Código de regalo',
  // 15/12
  rule_content: `Reglas: los símbolos comienzan desde la primera columna, consecutivamente de izquierda a derecha. El pago sólo calcula el valor más alto en una línea.`,
  jackpot2: 'PREMIO MAYOR',
  explode_if_you_collect_3_WILD: 'Explota si recoges 3 SALVAJES.',
  wild_can_subsitute_for_all_symbols:
    'El comodín puede sustituir a todos los símbolos.',

  // 12/01
  deposit_bonus: 'Bono de depósito',
  rakeback: 'rakeback',
  spin: 'Girar',
  get_extra_300_bonus_on_minimum_of_10_USDT_deposit:
    'Obtenga un bono adicional del 300% con un depósito mínimo de 10,00 USDT',
  min_deposit: 'Depósito mínimo:',
  max_claim: 'Reclamo máximo:',
  withdraw_successful: 'Retiro exitoso',
  your_withdrawal_request_has_been_confirmed:
    'Su solicitud de retiro ha sido confirmada. Puede realizar un seguimiento del progreso en el Historial de transacciones de Wallet.',
  pi_balance_in_wallet: 'Saldo Pi en billetera',
  your_Pi_address: 'Su dirección Pi',
  fill_in_carefully_according_to_the_specific_currency:
    'Complete cuidadosamente según la moneda específica',
  you_will_receive_Pi_to_your_address_within_1_working_day:
    'Recibirá Pi en su dirección dentro de 1 día hábil.',
  please_make_sure_you_enter_the_correct_Pi_address_otherwise_your_funds_will_not_be_reversed:
    'Asegúrese de ingresar la dirección Pi correcta; de lo contrario, sus fondos no se revertirán.',
  current_capacity: 'Capacidad actual',
  what_is_CODG_capacity: '¿Qué es la capacidad CODG?',
  rng_certificated_by: 'RNG certificado por',

  // 08/03
  buy_crypto: 'Comprar criptomonedas',
  buy_fiat: 'Seleccione Dinero Fiat',
  refund: 'Reembolso',
  bet_histories: 'Historiales de apuestas',
  round_histories: 'Historias redondas',
  round_id: 'ID redondo',
  bet_place: 'Lugar de apuesta',
  profit: 'Ganancia',
  top: 'Arriba',
  top_profit: 'Mayor beneficio',
  leader_board: 'Tabla de líderes',
  how_to_join: 'Como unirse',
  see_more: 'Ver más',
  top_winning_streak: 'Máxima racha ganadora',
  top_losing_streak: 'Mayor racha de derrotas',
  place: 'Lugar',
  streak: 'Racha',
  total_prized: 'Total premiado',
  top_reward: 'Recompensa superior',
  top_10_winners: 'Los 10 mejores ganadores',
  monthly_bonus: 'Bono Mensual',
  available_rakeback: 'Rakeback disponible',
  no_rakeback_yet: 'Aún no hay rakeback',
  claim_my_rakeback: 'Reclama mi rakeback',
  wager_required: 'Apuesta requerida',
  receive_every: 'Recibe cada',
  claim_bonus_successfully: 'Reclama el bono con éxito',
  claim_bonus_failed: 'No se ha podido reclamar el bono. ¡Inténtelo de nuevo!',
  no_bonus_available: 'No hay bono disponible',
  play_to_earn_bonus: 'Juega y comienza a apostar para ganar bonos',
  play_and_wager: 'Jugar y apostar',
  claim_all: 'Reclamar todo',
  duration: 'Duración',
  your_total_bonus: 'Tu bono total',
  roll_under: 'Rodar hacia abajo',
  game_result_will_be_displayed_here:
    'El resultado del juego se mostrará aquí.',
  level_up_bonus_upgrade: 'Mejora de bonificación por subir de nivel',
  _24h_live_support: 'Soporte en vivo las 24 horas',
  bonus_growth: 'Crecimiento de bonificación',
  xp_required: 'XP requerido',
  slots_wager_rate: 'Apuesta en tragamonedas $1,00 = 2XP',
  platinum_1_to_6: 'Platino I - VI',
  diamond_1_to_5: 'Diamante I-V',

  // CODG
  total_received_CODG: 'CODG total recibido',
  unlocked_amount: 'Cantidad desbloqueada',
  how_to_unlock_CODG: '¿Cómo desbloquear CODG?',
  swap_and_play: 'Intercambia y juega',
  unlocked_CODG:
    'Puedes cambiar tu CODG desbloqueado por otras monedas para jugar y ganar',
  available_to_swap: 'Disponible para intercambiar',
  there_are_no_redeemable_currencies: 'No hay monedas canjeables',
  first_deposit: 'Primer depósito',
  _300_bonus: 'Bonificación del 300%',
  get_up_to: 'Llegar a',
  second_deposit: 'Segundo depósito',
  _200_bonus: 'Bonificación del 200%',
  third_deposit: 'Tercer depósito',
  _150_bonus: 'Bonificación del 150%',
  fourth_deposit: 'Cuarto Depósito',
  _100_bonus: 'Bonificación del 100%',
  deposit_promotion: 'Promoción de depósito',
  unlock_codg_description:
    'Presenta un excepcional bono de depósito de 4 niveles, que brinda una excelente oportunidad para ganar recompensas adicionales en nuestro token exclusivo, CODG. Aprovecha esta ventaja, amplifica tus fondos y embárcate en un emocionante viaje con nuestra plataforma.',

  // Buy Crypto
  our_excited_bonus: 'Nuestro emocionante viaje extra para usted',
  something_is_wrong: 'Algo está mal.',
  disclaimer: 'Descargo de responsabilidad',
  the_above_third_party:
    'Los servicios de terceros anteriores se pueden utilizar para comprar criptomonedas que se pueden utilizar para jugar en Coco. Al registrarse en su plataforma, también acepta sus términos de servicio y se le pedirá que pase su proceso KYC, que se ejecuta de forma independiente al nuestro.',
  buy_with_transFI: 'Comprar con TransFI',
  order_processing: 'procesando orden',
  your: 'Su',
  buying_order_is_currently:
    'La orden de compra se está procesando actualmente. Espere unos minutos.',
  // Deposit
  transfer_pi: 'Transferir Pi',
  transfer_Pi_to_the_address_above_which_is_our_agent_address:
    'Transfiera Pi a la dirección anterior, que es la dirección de nuestro agente.',
  meet_our_live_support: 'Conozca nuestro soporte en vivo',
  click_the_i_transferred_Pi:
    'Haga clic en el botón "Transferí Pi" para conocer nuestro soporte en vivo.',
  confirm_transaction: 'Confirmar transacción',
  send_your_transaction_and_screenshot_to_the_chat_box_as_confirmation:
    'Envíe su transacción y captura de pantalla al cuadro de chat como confirmación.',
  transfer_Pi_to_this_address: 'Transfiera Pi a esta dirección',
  how_to_deposit_Pi: '¿Cómo depositar Pi??',
  i_transferred_Pi: 'Transferí Pi',
  earn_an_extra_300_bonus_with_a_minimum_deposit_of_1000_USD:
    'Gana un bono extra del 300% con un depósito mínimo de 10,00 USD.',
  // Tip
  max_length_ID_not_more_than_20_number:
    'ID de longitud máxima no más de 20 númerosr',
  receiver: 'receptor',
  transaction_fee_rate: 'Tasa de tarifa de transacción',
  transaction_fee_amount: 'Monto de la tarifa de transacción',
  you_need_to_wager: 'necesitas apostar',
  // Withdraw
  more_to_withdraw_due_to_the_bonus_money_policies:
    'más para retirar debido a las políticas de dinero de bonificación',
  pi_address_is_required: 'Se requiere dirección Pi',
  pi_Amount_must_specify_a_number:
    'La cantidad de Pi debe especificar un número',
  pi_amount_must_greater_than_10_Pi:
    'La cantidad de Pi debe ser mayor que 10 Pi',
  pi_amount_must_less_than_10000_Pi:
    'La cantidad de Pi debe ser inferior a 10.000 Pi',
  pi_amount_is_required: 'Se requiere cantidad de Pi',
  withdraw_successfully_We_will_contact_you_later:
    '¡Retiro exitoso! Nos comunicaremos contigo más tarde',
  hey_there: 'Hola',
  please_process_my_Pi_coin_withdrawal_as_follows:
    'Procese mi retiro de monedas Pi de la siguiente manera',
  fees: 'Honorarios',
  input_your_Pi_address: 'Ingrese su dirección Pi',
  input_number_of_Pi: 'Número de entrada de Pi',
  lucky_win: 'Victoria afortunada',
  can_not_get_bet_detail: '¡No puedo obtener los detalles de la apuesta!',

  // Popup currency
  cannot_be_used_in_this_game:
    'no se puede utilizar en este juego para el modo Real Play. Cambie a otros activos.',
  the_selected_currency_will_be_displayed_in:
    'La moneda seleccionada se mostrará en',
  and_if_you_change_currency_while_playing:
    'y si cambias de moneda mientras juegas, el juego se actualizará y reiniciará.',

  // CODG
  about_CODG: 'Acerca de CODG',
  CODG_is_the_reward_token_of_Cocogame:
    'CODG es el token de recompensa de Coco.game. Cuanto más interactúes con la plataforma, más CODG ganarás para enriquecer tus activos.',
  can_CODG_be_swapped_for_other_currencies:
    '¿Se puede cambiar CODG por otras monedas?',
  you_can_swap_your_unlocked_CODG:
    'You can swap your unlocked CODG for other currencies based on the current exchange rate. However, locked CODG cannot be swapped; it can only be used to play in various games within the platform.Puede cambiar su CODG desbloqueado por otras monedas según el tipo de cambio actual. Sin embargo, el CODG bloqueado no se puede intercambiar; sólo se puede utilizar para jugar en varios juegos dentro de la plataforma.',
  // GameBank => Overview
  the_maximum_share_of_all_users: 'La proporción máxima de todos los usuarios.',
  your_maximum_share: 'Tu participación máxima',
  total_available_investing_amount: 'Monto total de inversión disponible',
  your_available_investing_amount: 'Su monto de inversión disponible',
  // Game bank => InvestInformation
  anyone_can_add_money_to_the_gamebank:
    'Cualquiera puede agregar dinero al Gamebank y unirse a las apuestas de la casa. Tus ganancias se determinan multiplicando las ganancias totales de Gamebank por tu participación.',
  why_should_i_invest: '¿Por qué debería invertir?',
  profitable: 'Rentable',
  do_you_believe_in_the_bad_luck_of_other_gamblers:
    '¿Crees en la mala suerte de otros jugadores? Si es así, convertirse en la casa es una excelente manera de obtener ingresos pasivos.',
  real_yield: 'Rendimiento real',
  you_will_receive_profit_in_the_same_token_that_you_invest:
    'Recibirá ganancias en la misma medida en que invierta.',
  analysis_tool: 'Herramienta de análisis',
  access_gamebank_value:
    'Acceda al valor, las acciones, el historial y las tendencias de Gamebank para tomar las mejores decisiones.',
  understand_the_risk_involved: 'Comprender el riesgo involucrado',
  keep_in_mind_that_the_gamebank_value_may_temporarily_decrease_due_to_gamblers_wins:
    'Tenga en cuenta que el valor del Gamebank puede disminuir temporalmente debido a las ganancias de los jugadores, por lo que es posible que no vea ganancias inmediatas. Invierta con cautela.',
  invest_information: 'Información sobre inversiones',
  details: 'Detalles',
  // Gift code
  gift_received_successfully: 'Código de regalo reclamado',
  congratulations_you_have_just_received: '¡Felicidades! Acabas de recibir:',
  cod_successfully: 'DQO con éxito',
  // Transaction
  receiver_nickname: 'Apodo del receptor',
  sender_nickname: 'Apodo del remitente',
  tip_successful: 'Consejo exitoso',
  // JFU
  bet_win: 'APUESTAR Y GANAR',
  challenge: 'DESAFÍO',
  cash_it_out: 'COBRARLO',
  tournament: 'TORNEO',
  november_exclusive: 'EXCLUSIVO DE NOVIEMBRE',
  new_game: 'NUEVO JUEGO',
  you_need_to_reach_the_rank_to_unlock_this_feature:
    'Necesitas llegar al {{val}} rango para desbloquear esta función. Comprueba tu rango en',
  play_with_balance_in: 'Juega con equilibrio en',
  welcome_to_Ohplay: 'Bienvenido a Ohplay.club',
  welcome_to_Pi: 'Bienvenido a Pi.game',
  you_can_still_enjoy_the_best_casino_experience_at_Pi: `Pero no te preocupes, aún puedes disfrutar de la MEJOR experiencia de casino en Pi.game`,
  you_can_still_enjoy_the_best_casino_experience_at_Ohplay: `Pero no te preocupes, aún puedes disfrutar de la MEJOR experiencia de casino en Ohplay.club`,
  pi_game_is_not_available_in_your_region:
    'Lo sentimos, Pi.game no está disponible en tu región.',
  ohplay_game_is_not_available_in_your_region:
    'Lo sentimos, Ohplay.club no está disponible en su región.',
  how_to_get_Pi_gift_code: '¿Cómo obtener el código de regalo de Coco.game?',
  how_to_get_Ohplay_gift_code:
    '¿Cómo obtener el código de regalo de Coco.game?',
  gift_code_pi: 'Pi.juego',
  gift_code_ohplay: 'Ohplay.club',
  how_to_use_pi_gift_code: '¿Cómo utilizar el código de regalo de Pi.game?',
  how_to_use_ohplay_gift_code:
    '¿Cómo utilizar el código de regalo de Ohplay.club?',
  use_your_COD_gift_to_bet_and_win_at_pi:
    'Usa tu regalo Pi para jugar y ganar en Pi.game',
  use_your_COD_gift_to_bet_and_win_at_ohplay:
    'Usa tu regalo Ohplay para apostar y ganar en Ohplay.club',
  CODG_is_the_reward_token_of_Pigame:
    'CODG es el token de recompensa de Pi.game. Cuanto más interactúes con la plataforma, más CODG ganarás para enriquecer tus activos.',
  CODG_is_the_reward_token_of_Ohplay:
    'CODG es el token de recompensa de Ohplay.club. Cuanto más interactúes con la plataforma, más CODG ganarás para enriquecer tus activos.',
  if_you_have_a_website_with_substantial_traffic_pi:
    'Si tiene un sitio web con mucho tráfico o una cuenta de redes sociales con una gran audiencia, puede comunicarse con nosotros en business@Pi.game para obtener ofertas exclusivas.',
  if_you_have_a_website_with_substantial_traffic_ohplay:
    'Si tiene un sitio web con mucho tráfico o una cuenta de redes sociales con una gran audiencia, puede comunicarse con nosotros en business@Ohplay.club para obtener ofertas exclusivas.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_pi:
    'Debido a nuestra licencia de juego, no podemos aceptar jugadores de su país. Contáctenos a través de support@Pi.game si necesita más ayuda.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_ohplay:
    'Debido a nuestra licencia de juego, no podemos aceptar jugadores de su país. Contáctanos a través de support@Ohplay.club si necesitas más ayuda.',
  all_plays: 'Todos juegan',
  my_plays: 'Mi juego',
  high_play: 'juego alto',
  play_id: 'ID de juego',
  play_amount: 'Cantidad de juego',
  set_auto_play: 'Establecer apuesta automática',
  number_of_play: 'Número de juego',
  stop_auto_play: 'Detener la reproducción automática',
  start_play_round_failed: 'Error al iniciar la ronda de juego',
  wager_contest_pi: 'Concurso Pi.game',
  max_play: 'Juego máximo',
  total_play_amount: 'Cantidad total de juego',
  under_min_play: 'Juego mínimo',
  invalid_play_params: 'parámetros de juego no válidos',
  over_max_play: 'Juego por encima del máximo',
  invalid_play_state: 'estado de juego no válido',
  play_next: 'Jugar a continuación',
  playing: 'Jugando',
  play_head: 'jugar head',
  play_tail: 'jugar cola',
  auto_play_setup: 'Configuración de reproducción automática',
  click_play_to_choose_coin_side:
    'Haz clic en reproducir para elegir el lado de la moneda.',
  play_histories: 'Historias de juego',
  play_place: 'Lugar de juego',
  can_not_get_play_detail: '¡No puedo obtener detalles del juego!',
  play_win: 'JUGAR Y GANAR',

  // CODG 05/04
  locked_CODG_is_earned:
    'El CODG bloqueado se obtiene a través de promociones o bonos específicos, como bonos de depósito.',
  unlocking_CODG_is_simple: `Desbloquear CODG es simple; Es similar al rakeback y se desbloquea proporcionalmente con tus apuestas de la siguiente manera:`,
  unlocking_formula:
    'Monto de desbloqueo = Monto de la apuesta * Ventaja de la casa como decimal * X',
  conversion_formula: `Por ejemplo, si apuestas $100, desbloquearás 0,2 CODG bloqueados.`,
  note_unlock_codg:
    'Nota: Para desbloquear CODG, debes alcanzar al menos la clasificación Bronce, que requiere 10,000 XP.',
  how_to_claim_COD: 'Cómo reclamar COD',
  as_you_place_wagers:
    'A medida que realizas apuestas, tu Piggy acumulará CODG desbloqueado, que luego se convertirá en COD.',
  check_to_claim: 'Verificar para reclamar',
  about_CODG_token: 'Acerca del token CODG',
  play_our_games_to_earn_CODG_for_free:
    '¡Juega nuestros juegos para ganar CODG gratis!',
  how_to_unlock: 'Como desbloquear',
  how_to_claim: 'Cómo reclamar',
  today_biggest_winners: 'Hoy Los Mayores Ganadores',
  and_get_up_to: 'y levántate hasta',
  to_get: 'Llegar',
  claim_now: 'Reclama ahora',
  learn_more: 'Aprende más',

  // Host 10/04
  welcome_to_satoshity: 'Bienvenido a satoshity.io',
  welcome_to_betgecko: 'Bienvenido a betgecko.io',
  welcome_to_bitwin: 'Bienvenido a bitwin.club',
  host_vip_club: `${process.env.REACT_APP_SITE} club VIP`,
  to_host_vip_club: `to ${process.env.REACT_APP_SITE} club VIP`,
  learn_vip: `Aprender más acerca de ${process.env.REACT_APP_SITE} sistema VIP`,
  become_a_host: `Convertirse en un ${process.env.REACT_APP_SITE}`,
  host_member: `Convertirse en un ${process.env.REACT_APP_SITE} miembro`,
  how_much_has_host_given_out_in_bonuses: `cuanto tiene ${process.env.REACT_APP_SITE} entregado en bonos?`,
  why_is_HOST_VIP_program_the_best: `Por que es ${process.env.REACT_APP_SITE} ¿El programa VIP es el mejor?`,
  HOST_VIP_telegram_channel: `¿Dónde puedo encontrar el ${process.env.REACT_APP_SITE} ¿Canal VIP de Telegram?`,
  tip_with_any_currency_in_HOST: `Propina con cualquier moneda en ${process.env.REACT_APP_SITE}`,
  turn_your_friends_and_followers_into_weekly_commissions_HOST: `Convierte a tus amigos y seguidores en comisiones semanales con ${process.env.REACT_APP_SITE}'s programa de afiliación. Es simple, rentable y completamente transparente.`,
  why_should_i_be_a_HOST_affiliate: `¿Por qué debería ser un ${process.env.REACT_APP_SITE} Afiliado?`,
  if_you_already_have_a_HOST_account: `Si ya tienes un ${process.env.REACT_APP_SITE} cuenta, simplemente siga estos pasos:
      1. Visite la página de afiliados.
       2. Genere una nueva campaña utilizando su código de referencia único.
       3. Comparta su enlace de referencia en su sitio web, redes sociales o cualquier plataforma de alto tráfico.
       4. Reclama tu comisión semanal de los usuarios que has recomendado.`,
  HOST_took_it_all: `${process.env.REACT_APP_SITE} ¡se lo llevó todo!`,
  there_are_various_ways_to_get_HOST_gift_codes: `Hay varias maneras de conseguir ${process.env.REACT_APP_SITE} códigos de regalo, como participar en eventos exclusivos de redes sociales, actividades comunitarias, bonificaciones o misiones especiales.`,
  what_is_HOST_giftcode: `'What is ${process.env.REACT_APP_SITE} Giftcode?`,
  mini_HOST_is_Allways_3x3_Slot_machine_game: `Mini ${process.env.REACT_APP_SITE} es un juego de máquina tragamonedas 3x3 de 27 líneas.`,
  the_above_third_party_HOST: `Los servicios de terceros anteriores se pueden utilizar para comprar criptomonedas que se pueden utilizar para jugar. ${process.env.REACT_APP_SITE}. Al registrarse en su plataforma, también acepta sus términos de servicio y se le pedirá que pase su proceso KYC, que se ejecuta de forma independiente al nuestro.`,
  what_is_investing_in_HOST_gamebank: `¿En qué se invierte? ${process.env.REACT_APP_SITE} banco de juegos`,

  you_can_still_enjoy_the_best_casino_experience_at_satoshity: `Pero no te preocupes, aún puedes disfrutar de la MEJOR experiencia de casino en satoshity.io`,
  you_can_still_enjoy_the_best_casino_experience_at_betgecko: `Pero no te preocupes, aún puedes disfrutar de la MEJOR experiencia de casino en betgecko.io.`,
  you_can_still_enjoy_the_best_casino_experience_at_bitwin: `Pero no te preocupes, aún puedes disfrutar de la MEJOR experiencia de casino en bitwin.club`,
  satoshity_game_is_not_available_in_your_region:
    'Lo sentimos, satoshity.io no está disponible en su región.',
  betgecko_game_is_not_available_in_your_region:
    'Lo sentimos, betgecko.io no está disponible en su región.',
  bitwin_game_is_not_available_in_your_region:
    'Lo sentimos, bitwin.club no está disponible en su región.',
  how_to_get_satoshity_gift_code:
    '¿Cómo obtener el código de regalo de satoshity.io?',
  how_to_get_betgecko_gift_code:
    '¿Cómo obtener el código de regalo de betgecko.io?',
  how_to_get_bitwin_gift_code:
    '¿Cómo obtener el código de regalo de bitwin.club?',
  how_to_use_satoshity_gift_code:
    '¿Cómo utilizar el código de regalo de satoshity.io?',
  how_to_use_betgecko_gift_code:
    '¿Cómo utilizar el código de regalo de betgecko.io?',
  how_to_use_bitwin_gift_code:
    '¿Cómo utilizar el código de regalo de bitwin.club?',
  use_your_COD_gift_to_bet_and_win_at_satoshity:
    'Usa tu regalo de Satoshity para apostar y ganar en satoshity.io',
  use_your_COD_gift_to_bet_and_win_at_betgecko:
    'Usa tu regalo Betgecko para apostar y ganar en betgecko.io',
  use_your_COD_gift_to_bet_and_win_at_bitwin:
    'Usa tu regalo Bitwin para apostar y ganar en bitwin.club',
  CODG_is_the_reward_token_of_satoshity:
    'CODG es el token de recompensa de satoshity.io. Cuanto más interactúes con la plataforma, más CODG ganarás para enriquecer tus activos.',
  CODG_is_the_reward_token_of_bitwin:
    'CODG es el token de recompensa de bitwin.club. Cuanto más interactúes con la plataforma, más CODG ganarás para enriquecer tus activos.',
  CODG_is_the_reward_token_of_betgecko:
    'CODG es el token de recompensa de betgecko.io. Cuanto más interactúes con la plataforma, más CODG ganarás para enriquecer tus activos.',
  if_you_have_a_website_with_substantial_traffic_satoshity:
    'Si tiene un sitio web con mucho tráfico o una cuenta de redes sociales con una gran audiencia, puede comunicarse con nosotros en business@satoshity.io para obtener ofertas exclusivas.',
  if_you_have_a_website_with_substantial_traffic_betgecko:
    'Si tiene un sitio web con mucho tráfico o una cuenta de redes sociales con una gran audiencia, puede comunicarse con nosotros en business@betgecko.io para obtener ofertas exclusivas..',
  if_you_have_a_website_with_substantial_traffic_bitwin:
    'Si tiene un sitio web con mucho tráfico o una cuenta de redes sociales con una gran audiencia, puede comunicarse con nosotros en business@bitwin.club para obtener ofertas exclusivas.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_betgecko:
    'Debido a nuestra licencia de juego, no podemos aceptar jugadores de su país. Contáctenos a través de support@Betgecko.io si necesita más ayuda.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_satoshity:
    'Debido a nuestra licencia de juego, no podemos aceptar jugadores de su país. Contáctenos a través de support@Satoshity.io si necesita más ayuda.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_bitwin:
    'Debido a nuestra licencia de juego, no podemos aceptar jugadores de su país. Contáctenos a través de support@Bitwin.club si necesita más ayuda.',

  refer_description_coco:
    'Recomiende a sus amigos el programa de afiliados de coco.game y gane comisiones por cada usuario que se registre y juegue a través de sus enlaces, independientemente de sus resultados.',
  refer_description_pi:
    'Recomiende a sus amigos el programa de afiliados de pi.game y gane comisiones por cada usuario que se registre y juegue a través de sus enlaces, independientemente de sus resultados.',
  refer_description_ohplay:
    'Recomiende a sus amigos el programa de afiliados de ohplay.club y gane comisiones por cada usuario que se registre y juegue a través de sus enlaces, independientemente de sus resultados.',
  refer_description_satoshity:
    'Recomiende a sus amigos el programa de afiliados de satoshity.io y gane comisiones por cada usuario que se registre y juegue a través de sus enlaces, independientemente de sus resultados.',
  refer_description_betgecko:
    'Recomiende a sus amigos el programa de afiliados de betgecko.io y gane comisiones por cada usuario que se registre y juegue a través de sus enlaces, independientemente de sus resultados.',
  refer_description_bitwin:
    'Recomiende a sus amigos el programa de afiliados de bitwin.club y gane comisiones por cada usuario que se registre y juegue a través de sus enlaces, independientemente de sus resultados.',
  your_email_verified_coco:
    'Su correo electrónico ha sido verificado. Exploremos coco.game.',
  your_email_verified_pi:
    'Su correo electrónico ha sido verificado. Exploremos pi.game.',
  your_email_verified_ohplay:
    'Su correo electrónico ha sido verificado. Exploremos ohplay.club.',
  your_email_verified_satoshity:
    'Su correo electrónico ha sido verificado. Exploremos satoshity.io.',
  your_email_verified_betgecko:
    'Su correo electrónico ha sido verificado. Exploremos betgecko.io.',
  your_email_verified_bitwin:
    'Su correo electrónico ha sido verificado. Exploremos bitwin.club.',
  GAME: 'Juego',
  AFFILIATE: 'Afiliado',
  ACCOUNT: 'Cuenta',
  BONUS: 'Prima',
  INVEST: 'Invertir',
  SWAP: 'Intercambio',
};

export default es;
