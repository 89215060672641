import { ReactComponent as WithdrawIcon } from 'assets/icons/withdraw_ohplay2.svg';
import Button from 'common/Button';
import V2Notifications from 'components/Notifications';
import { WALLET_TAB_KEY } from 'constants/index';
import useResponsive from 'hooks/useResponsive';
import UserBalance from 'layouts/dashboard/header/AuthenticatedUser/UserBalance';
import UserInfo from 'layouts/dashboard/header/AuthenticatedUser/UserInfo';
import UserWallet from 'layouts/dashboard/header/AuthenticatedUser/UserWallet';
import { useLocales } from 'locales';
import { memo, useState } from 'react';

import { Box } from '@mui/material';

import { AuthenticatedUserWrapper } from './styles';

/* -------------------------------------------------------------------------- */

interface AuthenticatedUserProps {
  isDrawerNotiOpen: boolean;
  setIsDrawerNoti: (e) => void;
  isDrawerChatOpen: boolean;
  setIsDrawerChat: (e) => void;
}

const AuthenticatedUser = (props: AuthenticatedUserProps) => {
  const {
    isDrawerNotiOpen,
    setIsDrawerNoti,
    isDrawerChatOpen,
    setIsDrawerChat,
  } = props;

  const isTablet = useResponsive('up', 'md');
  const isDesktop = useResponsive('up', 'sm');

  const { translate } = useLocales();
  const [isWalletDialogOpen, setIsWalletDialogOpen] = useState(false);
  const handleClickWithdraw = () => {
    setIsWalletDialogOpen(true);
    window.history.replaceState(
      {
        walletTab: WALLET_TAB_KEY.WITHDRAW,
      },
      undefined,
    );
  };

  return (
    <AuthenticatedUserWrapper
      className="authenticated-box"
      open={isDrawerNotiOpen || isDrawerChatOpen}
    >
      <Box className="wrapper">
        <Box className="balance-wallet">
          <UserBalance setIsWalletDialogOpen={setIsWalletDialogOpen} />

          <Box className="nav-wallet-btn">
            <Button className="nav-withdraw" onClick={handleClickWithdraw}>
              {!isTablet && <WithdrawIcon />}
              {isTablet && translate('withdraw')}
            </Button>

            <UserWallet
              isWalletDialogOpen={isWalletDialogOpen}
              setIsWalletDialogOpen={setIsWalletDialogOpen}
            />
          </Box>
        </Box>
        <UserInfo />
      </Box>

      {isDesktop && (
        <V2Notifications
          drawer={isDrawerNotiOpen}
          setDrawer={(e) => {
            setIsDrawerChat(false);
            setIsDrawerNoti(e);
          }}
          isShow
        />
      )}
    </AuthenticatedUserWrapper>
  );
};

export default memo(AuthenticatedUser);
