import {
  bronzeVip,
  diamondVip,
  goldVip,
  noVip,
  platinumVip,
  silverVip,
} from 'assets/images/vip_clubs';
import i18n from 'locales/i18n';

export type RANK_VALUE =
  | ''
  | 'bronze'
  | 'silver'
  | 'gold'
  | 'platinum'
  | 'diamond';

export const getVipRankByVipPoint = (
  vipPoint: number,
): { rankImg: string; title: string; value: RANK_VALUE | string } => {
  const rank = { rankImg: noVip, title: '', value: '' };

  if (vipPoint >= 10000) {
    rank.rankImg = bronzeVip;
    rank.title = i18n.t('bronze');
    rank.value = 'bronze';
  }

  if (vipPoint >= 50000) {
    rank.rankImg = silverVip;
    rank.title = i18n.t('silver');
    rank.value = 'silver';
  }
  if (vipPoint >= 100000) {
    rank.rankImg = goldVip;
    rank.title = i18n.t('gold');
    rank.value = 'gold';
  }

  if (vipPoint >= 250000) {
    rank.rankImg = platinumVip;
    rank.title = `${i18n.t('platinum')} I`;
    rank.value = 'platinum';
  }

  if (vipPoint >= 500000) {
    rank.title = `${i18n.t('platinum')} II`;
  }

  if (vipPoint >= 1000000) {
    rank.title = `${i18n.t('platinum')} III`;
  }

  if (vipPoint >= 2500000) {
    rank.title = `${i18n.t('platinum')} IV`;
  }

  if (vipPoint >= 5000000) {
    rank.title = `${i18n.t('platinum')} V`;
  }

  if (vipPoint >= 10000000) {
    rank.title = `${i18n.t('platinum')} VI`;
  }

  if (vipPoint >= 25000000) {
    rank.rankImg = diamondVip;
    rank.title = `${i18n.t('diamond')} I`;
    rank.value = 'diamond';
  }

  if (vipPoint >= 50000000) {
    rank.title = `${i18n.t('diamond')} II`;
  }

  if (vipPoint >= 100000000) {
    rank.title = `${i18n.t('diamond')} III`;
  }

  if (vipPoint >= 250000000) {
    rank.title = `${i18n.t('diamond')} IV`;
  }

  if (vipPoint >= 500000000) {
    rank.title = `${i18n.t('diamond')} V`;
  }

  return rank;
};
