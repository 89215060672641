import { useReactiveVar } from '@apollo/client';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import currencyImages from 'assets/images/coins';
import V2AssetPopover from 'components/AssetsPopover';
import { LockCurrencyDialog } from 'components/AssetsPopover/LockCurrencyDialog';
import OverBalancePopover from 'components/OverBalancePopover';
import OverGamebankPopover from 'components/OverGamebankPopover';
import { WALLET_TAB_KEY } from 'constants/index';
import { Currency, useCurrenciesQuery, useUserWalletsQuery } from 'graph';
import useGetCurrencyAmount from 'hooks/useGetCurrencyAmount';
import { BalanceBox } from 'layouts/dashboard/header/AuthenticatedUser/styles';
import {
  memo,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  showInsufficientBalancePopoverVar,
  showOverGameBankPopoverVar,
  storageCurrencyVar,
} from 'store';
import { gamesLocalStateOperations } from 'store/operations';
import { currencyLocalStateOperations } from 'store/operations/currency-opts';

import { Box, Typography } from '@mui/material';

interface IUserBalanceProps {
  setIsWalletDialogOpen: React.Dispatch<SetStateAction<boolean>>;
}

const HeaderUserBalance = ({ setIsWalletDialogOpen }: IUserBalanceProps) => {
  const boxRef: any = useRef();
  const { storageCurrency } = useReactiveVar(storageCurrencyVar);
  const { isShowInsufficientBalancePopOver } = useReactiveVar(
    showInsufficientBalancePopoverVar,
  );
  const { isShowOverGameBankPopOver } = useReactiveVar(
    showOverGameBankPopoverVar,
  );
  const { getCurrencyAmount } = useGetCurrencyAmount();
  const { showInsufficientBalancePopOver, showOverGameBankPopover } =
    gamesLocalStateOperations;
  const { setCurrencies, setStorageCurrency } = currencyLocalStateOperations;

  const [openLockCurrencyDialog, setOpenLockCurrencyDialog] = useState(false);
  const [anchorElBalanceOver, setAnchorElBalanceOver] = useState<
    (EventTarget & HTMLDivElement) | null
  >(null);
  const [anchorElGamebankOver, setAnchorElGamebankOver] = useState<
    (EventTarget & HTMLDivElement) | null
  >(null);
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLDivElement) | null
  >(null);

  const { data } = useCurrenciesQuery();
  const { data: userWallet } = useUserWalletsQuery({ pollInterval: 300000 }); // polling in 5 minutes

  const currencyBallance = useMemo(
    () =>
      userWallet?.me?.userWallets.find(
        (userWallet: { currencyId: any }) =>
          userWallet.currencyId === storageCurrency?.id,
      )?.amount || 0,
    [storageCurrency, userWallet],
  );

  const handleOpenAssetPopover = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectCurrency = (currency: Currency) => {
    setStorageCurrency(currency);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (data?.currencies) {
      setCurrencies(data.currencies);
    }
  }, [data?.currencies]);

  useEffect(() => {
    setAnchorElGamebankOver(boxRef.current);
    setAnchorElBalanceOver(boxRef.current);
  }, [boxRef]);

  return (
    <>
      <BalanceBox onClick={(e) => handleOpenAssetPopover(e)} ref={boxRef}>
        {storageCurrency?.shortName && (
          <Box className="current-currency">
            <img
              className="coin_img"
              src={
                storageCurrency && currencyImages[storageCurrency?.shortName]
              }
              alt={storageCurrency?.name}
            />
          </Box>
        )}

        <Box
          position="relative"
          id="header-user-balance"
          sx={{ alignItems: 'center' }}
        >
          <Typography component="span">
            {storageCurrency &&
              getCurrencyAmount(currencyBallance, storageCurrency)}
          </Typography>

          <ArrowDown width="24px" height="24px" />
        </Box>
      </BalanceBox>

      {Boolean(anchorEl) && (
        <V2AssetPopover
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
          handleSelectCurrency={handleSelectCurrency}
          selectedCurrency={storageCurrency}
          handleOpenLockCurrency={() => setOpenLockCurrencyDialog(true)}
          handleOpenWalletCODG={() => {
            setIsWalletDialogOpen(true);
            window.history.replaceState(
              {
                walletTab: WALLET_TAB_KEY.CODG,
              },
              undefined,
            );
          }}
        />
      )}
      {openLockCurrencyDialog && (
        <LockCurrencyDialog
          open={openLockCurrencyDialog}
          handleClose={() => setOpenLockCurrencyDialog(false)}
          handleOpenDeposit={() => {
            setOpenLockCurrencyDialog(false);
            setIsWalletDialogOpen(true);
          }}
        />
      )}
      {isShowInsufficientBalancePopOver && (
        <OverBalancePopover
          open={isShowInsufficientBalancePopOver}
          anchorEl={anchorElBalanceOver}
          handleClose={() => {
            showInsufficientBalancePopOver(false);
          }}
          openWalletDialog={() => {
            setIsWalletDialogOpen(true);
            showInsufficientBalancePopOver(false);
          }}
          openSwitchCrypto={() => {
            setAnchorEl(anchorElBalanceOver);
            showInsufficientBalancePopOver(false);
          }}
        />
      )}
      {isShowOverGameBankPopOver && (
        <OverGamebankPopover
          open={isShowOverGameBankPopOver}
          anchorEl={anchorElGamebankOver}
          handleClose={() => {
            showOverGameBankPopover(false);
          }}
          openWalletDialog={() => {
            setIsWalletDialogOpen(true);
            showOverGameBankPopover(false);
          }}
          openSwitchCrypto={() => {
            showOverGameBankPopover(false);
            setAnchorEl(anchorElGamebankOver);
          }}
        />
      )}
    </>
  );
};

export default memo(HeaderUserBalance);
