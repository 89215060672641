// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ja = {
  demo: {
    title: `Japan`,
  },
  blog: `ブログ`,
  chat: `チャット`,
  only_admin_can_see_this_item: `この項目は管理者のみが閲覧できます`,
  fairness: '公平性',
  blog_academy: 'ブログ/アカデミー',
  live_support: 'ライブサポート',
  search_your_game: 'ゲームを検索して',
  random: 'ランダム',
  sign_in: 'サインイン',
  sign_up: 'サインアップ',
  wallet: '財布',
  deposit: 'デポジット',
  withdraw: '撤回する',
  swap: 'スワップ',
  crypto: '暗号',
  fiat: 'フィアット',
  nft: 'NFT',
  search_by_name: '名前で検索すると',
  transactions: '取引',
  // Deposit
  overview: '概要',
  deposit_currency: '預金通貨',
  choose_network: 'ネットワークを選択し',
  deposit_address: '入金アドレス',
  notice: '知らせ:',
  notice_content: '通知内容',
  balance: 'バランス',
  copy: 'コピー',
  withdraw_amount: '金額を撤回',
  withdraw_address: '引き出しアドレス',
  deposit_address_placeholder: '入金アドレスのプレースホルダー',
  fee: '手数料',
  receive_amount: '受け取り金額',
  confirm: '確認する',
  balance_in_wallet: '財布の残高',
  withdraw_fee: '出金手数料',
  from: 'から',
  to: 'に',
  bill: '請求書',
  view_history_in_etherscan: 'Etherscan で履歴を表示',
  time: '時間',
  amount: '額',
  status: '状態',
  detail: '詳細',
  view_in_fiat: '米ドルで見ると',
  common_assets: '共有資産',
  order_id: '注文ID',
  currency: '通貨',
  quantity: '量',
  type: 'タイプ',
  empty_data: '空のデータ',
  currency_not_available: '通貨が利用できません',
  all_feature: 'すべての機能',
  success: '成功',
  audit: '監査',
  insufficient: '不十分',
  processing: '処理',
  new: '新しい',
  profile_settings: 'プロファイル設定',
  general: '一般的な',
  security: '安全',
  verify: '確認する',
  verified: '検証され',
  id: 'ID',
  nickname: 'ニックネーム',
  email: 'Eメー',
  phone_number: '電話番号',
  change: '変化',
  not_use_symbol:
    '特殊な記号は使用しないでください。使用しないと、アカウントがサポートされない可能性があります。',
  hide_profile: 'プロフィールを隠す',
  registered_mail_cannot_changed:
    'COCO.GAMEのセキュリティポリシーにより、登録メールアドレスは変更できません。',
  phone_second_login: '2 番目のログイン方法として電話番号を追加します。',
  hide_profile_title: 'プロフィール上のゲームデータを非表示にする',
  hide_profile_content:
    '非表示であっても、あなたのアバターとユーザー名はチャット ルームに常に表示されます。',
  copied: 'コピーされました',
  enter_nickname: 'ここにニックネームを入力してください',
  enter_email: 'ここにメールアドレスを入力してください',
  enter_phone_number_: 'ここに電話番号を入力してください',
  change_password: 'パスワードを変更する',
  current_password: '現在のパスワード',
  new_password: '新しいパスワード',
  confirm_password: '新しいパスワードを確認',
  existing_password_not_match: '既存のパスワードが一致しません',
  password_not_match: 'パスワードが一致しません',
  new_password_saved: '新しいパスワードが保存されました',
  relogin_after_change_pw: 'パスワード変更後は再ログインが必要となります。',
  setup_google_auth: 'SGoogle認証システムを設定する',
  download_google_auth:
    'iPhone または Android 用の Google 認証アプリをダウンロードします',
  save_account_token:
    '次のアカウント トークン (キー) を安全な場所に保存します。',
  account_token: 'アカウントトークン（キー）',
  need_account_token:
    '携帯電話を紛失した場合に備えてアカウントにアクセスするには、上記のアカウント トークン (キー) が必要になります。',
  scan_qr_title:
    'Google Authenticator アプリで以下の QR コードをスキャンしてください',
  enter_token_below: '以下にトークンを入力してください',
  two_FA_code: '2FA コード *',
  appear_on_google_auth: 'Google Authenticator アプリに表示されます',
  verify_code_incorrect: '認証コードが間違っています',
  enable_2fa: '2FA を有効にする',
  enter_current_pw: '現在のパスワードを入力してください',
  enter_new_password: '新しいパスワードを入力してください',
  enter_repeat_password: '新しいパスワードを繰り返します',
  enter_2fa_code_to_disable: '2FA コードを入力して 2 要素認証を無効にします。',
  update_nickname_failed:
    'ニックネームの更新に失敗しました。もう一度お試しください。',
  disable_2fa: '2FA を無効にする',
  verify_email: 'Eメールを確認します',
  send_information_to_email:
    'ご登録いただいたメールアドレスに情報をお送りいたします。',
  cancel: 'Cancel',
  verify_phone_number: '電話番号を確認する',
  resend_code: 'コードを再送信する',
  did_not_receive_code: 'コードを受け取っていませんか?',
  validate_nickname_length: 'ニックネームは 4 ～ 13 文字にする必要があります。',
  nickname_existing: 'ニックネームはすでに存在します。',
  new_nick_name_saved: '新しいニックネームが保存されました',
  new_email_saved: '新しいメールが保存されました',
  new_phone_number_save: '新しい電話番号が保存されました',
  invalid_phone_number: '無効な電話番号',
  validate_invalid_email_address: '有効なメールアドレスを入力してください',
  view: 'ビュー',
  choose_asset: 'アセットの選択',
  min: '分',
  max: 'マックス',
  max2: '最大',
  all_currency: 'すべての通貨',
  to_secure_assets: 'あなたの資産を安全に保つために、',
  bet_id: 'ベットID',
  bet_amount: 'ベット額',
  payout: '支払う',
  profit_amount: '利益',
  hash: 'ハッシュ',
  show_more: 'もっと見せる',
  show_less: '表示を少なくする',
  welcome_aboard: 'ようこそ',
  first_deposit_bonus: '初回入金ボーナス',
  rewards: '報酬',
  deposit_now: '今すぐ入金',
  just_for_you: '貴方のために',
  top_games: 'トップゲーム',
  recently_played: '最近再生しました',
  recommended: '推奨',
  favourites: 'お気に入り',
  or_log_in_with: 'または次の方法でログインします',
  dont_have_account: 'アカウントをお持ちではありませんか?',
  forgot_your_password: 'パスワードをお忘れですか？',
  password: 'パスワード',
  terms_of_service: '利用規約',
  confirm_accessing_site:
    'サイトにアクセスすることにより、私は 18 歳であり、以下の文書を読んだことを確認します。',
  include_lower_uppercase: '小文字と大文字を含める',
  at_least_1_number: '少なくとも 1 つの数字',
  minimum_6_char: '最低6文字',
  agree_terms_service: '同意し、理解します',
  terms_conditions: '利用規約',
  already_have_account: 'すでにアカウントをお持ちですか？',
  enter_password_here: 'ログインパスワード...',
  reset_password: 'パスワードを再設定する',
  assets_portfolio: '資産ポートフォリオ',
  hide_small: '小さく隠す',
  menu: 'メニュー',
  game_hub: 'ゲームハブ',
  notification: '通知',
  chat_room: 'チャットルーム',
  login_fail: 'ログインに失敗しました。もう一度お試しください',
  error_message: {
    server_error: 'サーバーエラー。 数分後にもう一度お試しください',
  },
  dark: '暗い',
  light: 'ライト',
  casino_home: 'カジノホーム',
  promotions: 'プロモーション',
  refer_friend: '友達を紹介する',
  support_legal: 'サポート/法務',
  help_center: 'ヘルプセンター',
  faq: 'よくある質問',
  privacy_policy: 'プライバシーポリシー',
  aml: 'AML',
  res_gambling: '責任あるギャンブル',
  provably_fair: '証明的に公正',
  registration_login: '登録とログイン',
  about_us: '私たちについて',
  news: 'ニュース',
  business_contact: '業務連絡',
  work_with_us: '俺たちと一緒に仕事しようよ',
  coin_accuracy_limit: 'コイン精度の限界',
  support: 'サポート',
  verify_representative: '代表者の確認',
  crypto_quest: 'クリプトクエスト',
  video_poker: 'ビデオポーカー',
  classic_dice: 'クラシックダイス',
  join_our_community: '私たちのコミュニティに参加する',
  go_to_top: 'ボールに行く',
  game: 'ゲーム',
  game_info: 'ゲーム情報',
  description: '説明',
  reviews: 'レビュー',
  latest_bet_race: '最新の賭けとレース',
  all_bets: 'すべてのベット',
  my_bets: '私の賭け',
  high_bets: '高額な賭け',
  player: 'プレーヤー',
  hidden: '隠れた',
  about_this_game: 'このゲームについて',
  share_this_game: 'このゲームをシェアする',
  share_on_fb: 'Facebookでシェア',
  share_on_whatsapp: 'WhatsAppで共有する',
  share_on_twitter: 'X（Twitter）でシェア',
  share_on_discord: 'Discordで共有する',
  share_on_linkedin: 'LinkedIn で共有する',
  share_on_skype: 'Skype で共有する',
  by: 'による: ',
  release: 'リリース',
  return_to_player: 'RTP（プレイヤーに戻る）',
  provider: 'プロバイダー',
  max_win: '最大の勝利',
  mobile: '携帯',
  stakes_range: '賭け金の範囲',
  active_devices: 'アクティブな装置',
  device: 'デバイス',
  location: '位置',
  ip_address: 'IPアドレス',
  last_used: '最後に使用した',
  action: 'アクション',
  play_now: '今すぐプレイ',
  on: 'フロント',
  activities: '活動内容',
  high_rollers: 'ハイローラー',
  jackpot_winners: 'ジャックポット勝者',
  follow_us_on: 'フォローしてください',
  INTERNAL_TRANSFER: '傾いた',
  send_recover_link_to_email:
    '回復パスワードのリンクが記載された電子メールが電子メールに送信されます。',
  request_reset_email: 'リクエストが送信されました。 メールを確認してください',
  something_wrong: '何か問題が発生しました。 もう一度試してください',
  vip_club: 'VIPクラブ',
  jackpot: 'ジャックポット',
  responsible_gambling: '責任あるギャンブル',
  next: '次',
  step: 'ステップ',
  verification: '検証',
  verify_setup: 'セットアップの確認',
  verify_setup_description1:
    'より良いサービスを提供するために、本人確認書類の原本をご提供いただくようお願いいたします。 これにより、アカウントが回復された場合にアカウントが保護されます。 また、受け取ったギフトや実際の報酬が正しい場所に確実に送られるようにするのにも役立ちます.',
  verify_setup_description2:
    '許容される身分証明書には、政府の写真、承認された ID カード、運転免許証、パスポートが含まれます。 このプロセスが完了したら、電子メールでご連絡いたします.',
  first_name: 'ファーストネーム',
  last_name: '苗字',
  gender: '性別',
  date_of_birth: '生年月日',
  address: '住所',
  city: '市',
  postal_code: '郵便番号',
  country: '国',
  enter_your_first_name: 'あなたの名前を入力してください...',
  enter_your_last_name: '姓を入力してください...',
  verify_government_id_description:
    '政府発行の有効な身分証明書または運転免許証をアップロードしてください。 氏名、生年月日、写真、書類の有効期限が含まれている必要があります。',
  id_card: 'ID Card',
  passport: 'Passport',
  front_side: 'Front Side',
  back_side: 'Back Side',
  take_a_photo: 'Take a Photo',
  upload_picture: 'Upload Picture',
  information_page: 'Information Page',
  verify_portrait_description:
    'あなた自身の写真をアップロードしてください。 写真にはパスポートまたは身分証明書と、「COCO.GAME」、ユーザー名、日付が書かれた紙が必要です。 あなたの顔とあなたが保持する情報が明確であることを確認してください。',
  your_photo: 'あなたの写真',
  references: '参考文献',
  all_information_submitted: 'すべての情報が送信されます',
  thank_you_for_cooperation:
    'ご協力ありがとうございます。 審査が完了しましたのでお知らせいたします。',
  done: '終わり',
  this_field_is_required: 'この項目は必須です',
  male: '男',
  female: '女性',
  unspecified: '不特定',
  select_gender: '性別を選択して下さい',
  back: '戻る',
  step1_update_identification_success: 'ステップ 1: ID を正常に更新する',
  step23_update_identification_photo_success:
    'ステップ 2、3: 証明写真を正常に更新する',
  invalid_date_format: '無効な日付形式です',
  update_avatar_success: 'アバターが正常に更新されました',
  update_avatar_failed: 'アバターの更新に失敗しました',
  game_bank_overview: '概要',
  game_bank_history: '歴史',
  game_bank_list: 'リスト',
  game_bank_withdraw: '撤回する',
  confirm_password_must_match: 'パスワードが一致する必要があることを確認する',
  recover_password: 'パスワードを回復',
  logout_failed: 'ログアウトに失敗しました。もう一度お試しください。',
  at_least_18_years_old: '18 歳以上である必要があります',
  no_support: 'サポートなし',
  gems: '宝石',
  sound: '音',
  invalid_wallet_address: '無効なウォレットアドレス',
  target_multiplier: 'ターゲット乗数',
  unexpected_error:
    '予期しないエラーが発生しました。 後でもう一度試してください',
  validate_nickname_message:
    'ニックネームは 4 ～ 13 文字で、署名がなく、スペースや記号を含まない必要があります。',
  swap_fee: 'スワップ手数料e',
  verify_account: '今すぐKYC',
  history: '歴史',
  big_winner: '大勝者',
  jackpot_winner: 'ジャックポットの勝者',
  my_bet: '私の賭け',
  all_bet: '全部賭ける',
  winner: '勝者',
  insufficient_ballance: '残高不足',
  gamebank: 'ゲームバンク',
  maximumFileSize: '最大ファイルサイズは4MBです',
  verify_email_was_send:
    'メールが送信されたことを確認してください。メールを確認してください',
  error_code: {
    EMAIL_IN_USED: 'このメールは別のユーザーによって使用されています。',
  },
  account_verified: 'アカウントが認証されました',
  check_information_carefully: '確認する前に、投資情報をよくご確認ください。',
  invest_now: '今すぐ投資する',
  more_information: '詳しくは',
  host: 'シュー',
  loading: '読み込み中...',
  you_must_specify_a_number: '数値を指定する必要があります',
  must_be_a_positive_value: '正の値である必要があります',
  withdraw_success: '撤退成功',
  withdraw_failed: '出金に失敗しました',
  your_total_invest: '投資総額',
  your_current_shares: '現在の株式数',
  your_balance: 'あなたのバランス',
  your_profit: 'あなたの利益',
  leverage: 'てこの作用',
  game_bank: 'ゲームバンク',
  game_bank_max: 'ゲームバンクマックス',
  your_invest: 'あなたの投資',
  available_invest_amount: '投資可能額',
  your_share: 'あなたのシェア',
  host_share: 'ホストシェア',
  invest_success: '投資の成功',
  invest_failed: '投資は失敗しました',
  investor: '投資家',
  share: '共有',
  error_message_server_error: 'error_message.server_error',
  successful_balance_change: '成功、バランス変更',
  permission_denied: '許可が拒否されました',
  you_not_permission_page: 'このページにアクセスする権限がありません',
  not_bad: '悪くない',
  nice: 'ニース',
  awesome: '素晴らしい',
  wicked: '邪悪な',
  godlike: '神のような',
  to_the_moon: '月へ',
  you_win: 'あなたはウィンです！',
  bum: 'ブーム！',
  some_thing_went_wrong: '何か問題が発生しました',
  maximum_number: '選択されるセルの最大数は次のとおりです。',
  auto_select: '自動選択',
  number_of_spin: 'スピン数',
  // Mini Coco
  advanced_auto: 'アドバンスオート',
  stop_if_anyone_win_jackpot: '誰かがジャックポットを獲得したらストップ',
  stop_decrease_by: '残高が 減少した場合は停止します',
  stop_increase_by: '残高が増えたら停止',
  stop_exceeds_by: '単一の勝利が次の値を超えた場合に停止します',
  start_auto_spin: 'オートスピンの開始',
  not_enough_balance: '残高が足りない',
  set_auto_bet: '自動ベットを設定する',
  cancel_auto_bet: 'オートベットをキャンセルする',
  cashout_at: 'キャッシュアウト時',
  win_amount: '「勝ち金額」',
  increase: '増加',
  decrease: '減少',
  bet: 'ベット',
  waiting: '待っている...',
  bet_next_round: 'BET (次のラウンド)',
  cashout: 'キャッシュアウト',
  win: '勝つ',
  lose: '失う',
  increase_by: '増加分',
  reset: 'リセット',
  manual: 'マニュアル',
  auto: '自動',
  max_profit: '最大利益',
  max_bet: 'マックスベット',
  stop_on_profit: '利益でストップ',
  number_of_bet: 'ベット数',
  on_loss: '損失時',
  on_lose: '負けたとき',
  on_win: '勝利時',
  stop_on_lose: '負けたらストップ',
  stop_on_win: 'ストップオンウィン',
  live_stats: 'ライブ統計',
  total_bet_amount: '合計ベット額',
  total_profit: '利益総額',
  game_bank2: 'ゲームバンク',
  can_not_get_game_bank: 'ゲームバンクを取得できません',
  trends: 'トレンド',
  play_real: 'リアルで遊ぶ',
  play_with_fun: '楽しく遊ぶ',
  online: 'オンライン',
  welcome_bonus: 'ウェルカムボーナス',
  up_to: 'まで',
  join_now: '今すぐ参加',
  total: '合計',
  invest: '投資する',
  insufficient_balance: '残高不足',
  please_switch_to_another_asset_to_continue_playing:
    'プレイを続けるには、別のアセットに切り替えてください。',
  insufficient_gamebank: '残高不足',
  switch: 'スイッチ',
  not_found: '見つかりません',
  no_results_found_for: '結果が見つかりませんでした',
  checking_for_typos:
    'タイプミスがないか確認するか、完全な単語を使用してみてください。',
  please_enter_keywords: 'キーワードを入力してください',
  exit_fullscreen: '全画面表示を終了する',
  fullscreen: '全画面表示',
  settings: '設定',
  currency_do_not_supported: 'この通貨はサポートされていません。',
  update_photo: '写真を更新',
  upload_photo: '写真をアップする',
  remove_all: 'すべて削除する',
  upload_files: 'ファイルをアップロードする',
  drop_or_select_file: 'ファイルをドロップまたは選択します',
  drop_files_here_or_click: 'ここにファイルをドロップするか、',
  thorough_your_machine: 'あなたのマシンを通して',
  no_found_any_currency_can_swap: 'スワップできる通貨は見つかりませんでした',
  account_not_verified: 'アカウントはKYC認証されていません',
  account_not_conditions: 'Wagering more to withdraw',
  withdraw_request_was_sent: '出金リクエストが送信されました。確認待ちです',
  available_amount_insufficient: '利用可能量が不足しています',
  need_help: '助けが必要？',
  coming_soon: '近日公開',
  introduction_to_coco:
    '数々の賞を受賞した暗号カジノ。 プレイヤー中心のアプローチにより、COCO.GAME は世界中の何百万ものギャンブラーを満足させることができます。 COCO.GAME はコミュニティを優先し、永遠に続く、終わりのない楽しいギャンブル体験を保証します。',
  coming_soon2: '近日公開...',
  under_development: '機能は開発中です',
  page_404: '404 ページが見つかりません | ココゲーム',
  page_not_found: '申し訳ありませんが、ページが見つかりません。',
  check_your_spelling:
    '申し訳ありませんが、お探しのページは見つかりませんでした。 もしかしてURLを打ち間違えたのでしょうか？ スペルを必ず確認してください。',
  go_to_home: 'ホームへ行く',
  remove_session_success: 'セッションの削除が成功しました',
  remove_session_failed: 'セッションの削除に失敗しました',
  in_use: '使用中で',
  remove_device: 'デバイスを削除',
  your_profile: 'あなたのプロフィール',
  is_hidden: '隠されています',
  is_public_now: '現在公開されています',
  hide_profile_failed:
    'プロフィールを非表示にできませんでした。もう一度お試しください。',
  change_password_success:
    'パスワードの変更が成功しました。 もう一度ログインしてください',
  upload_image_failed: '画像のアップロードに失敗しました',
  file_size_limit_10MB: 'jpg、png; ファイルサイズ制限 10MB',
  roll_over: '転がる',
  win_chance: '勝利のチャンス',
  profit_on_next_tile: '次のタイルで利益を得る',
  pick_a_tile_randomly: 'タイルをランダムに選択する',
  pay_table: '給与表',
  game_rule: 'ゲームルール',
  number_of_bets: 'ベット数',
  bet_failed: '賭けは失敗しました',
  stop_auto_bet: '自動ベットを停止する',
  currency_not_support: 'サポートされていない通貨',
  login_to_play: 'PLAYにログイン',
  yes: 'はい',
  how_to_play: '遊び方',
  help: 'ヘルプ',
  start_bet_round_failed: 'ベットラウンドの開始に失敗しました',
  cashout_failed: 'キャッシュアウトに失敗しました',
  auto_bet_round: '自動ベットラウンド',
  play_now2: '今すぐプレイ',
  under_min_bet: '最小ベット未満',
  invalid_bet_params: '無効なベットパラメータです',
  game_not_available_now: 'ゲームは現在利用できません',
  insufficient_amount: '量が足りない',
  invalid_bet_state: '無効なベット状態',
  over_max_bet: '最大ベットを超える',
  gamebank_not_available: 'ゲームバンクは利用できません',
  win_rate: '勝率',
  welcome_to_Coco_game: 'Coco.game へようこそ',
  invalid_email_format: '無効な電子メール形式',
  resend_after: '後に再送信してください',
  second: '2番',
  only_available_at_large_resolutions:
    '1600px (xl) を超える高解像度でのみ利用可能',
  provably_fairs: '明らかに見本市',
  Fairness: '公平性',

  become_a_coco: 'ココになる',
  club_member: 'クラブ会員',
  vip_club_subtitle1:
    '最も長くプレイし、最も高額な賭けをしたプレイヤーには、特別な招待状が届きます。',
  vip_club_subtitle2:
    'これにより、メンバーにプレミアムな体験とボーナスが提供されます。',
  your_vip_progress: 'VIP の進捗状況',
  your_vip_progress_tooltip:
    'まずサインインしてから、VIP の進行状況を確認してください',
  total_wager: '賭け金総額',
  your_vip_progress_description:
    'すべての賭け金は現在のレートで米ドルに換算されます',
  how_does_it_work: 'どのように機能するのでしょうか?',
  the_rewards: '報酬',
  frequently_asked_questions: 'よくある質問',
  vip_frequent_questions_subtitle:
    'ご質問がある場合は、サポートまたは VIP マネージャーにお問い合わせいただくことをお勧めします。',
  login: 'ログイン',
  to_see_your_vip_progress: 'VIP の進行状況を確認するには',
  wager_rate: '賭け金 $1.00 = 1XP',
  all_wagers_converted_to_usd:
    'すべての賭け金は現在のレートで米ドルに換算されます',
  vip_club_benefit: 'VIPクラブ特典',
  vip_club_details: 'VIPクラブの詳細',
  benefit_table: '特典表',
  wager_contest: '賭けコンテスト',
  game_of_the_day: '今日のゲーム',
  play_to_earn_wagers: '賭け金を稼ぐためにプレイする',
  time_remaining: '残り時間',
  rank: 'ランク',
  wagered: '賭けた',
  prize: '賞',
  wager_contest_information: '賭けコンテスト情報',
  rule: 'ルール',
  gamebank_chart: 'ゲームバンクチャート',
  next_round: '次のラウンド',
  start_auto_bet: 'オートベットを開始する',
  understand_trend_chart: 'トレンドチャートを理解する',
  round_has_been_bet:
    'このラウンドはベットされました、次のラウンドからキャンセルします',
  crash_server_disconnect: 'サービスの中断',
  crash_server_disconnect_alerts:
    'サーバーを再接続して、Coco.game の探索を続けてください。 お待ちいただいてありがとうございます。',
  coco_info:
    'このウェブサイトは、キュラソー島の法律に基づいて登録および設立された会社、Orisun N.V. によって所有および運営されています。登録番号は 163631、登録住所は Zuikertuintjeweg Z/N (Zuikertuin Tower), Curaçao です。',
  crash_result: 'クラッシュ結果',
  wagers: '賭け金',
  reset_after: '後にリセット',
  daily_wagering_contest_rules: 'デイリー賭博コンテストのルール',
  the_daily_race:
    'デイリー レース コンテストは、毎日 00:00 UTC から 23:59 UTC まで開催されます。',
  daily_contest_prize_pool: 'デイリーコンテストの賞金プール',
  straight_flush_with_jack: 'ジャック付きストレートフラッシュ',
  straight_flush: 'ストレートフラッシュ',
  four_of_a_kind: 'フォー・オブ・ア・カインド',
  full_house: 'フルハウス',
  flush: '流す',
  straight: '真っ直ぐ',
  three_of_a_kind: 'スリー・オブ・ア・カインド',
  tow_pair: 'ツーペア',
  pair_of_jacks_or_better: 'ジャックのペア以上',
  you_won: 'あなたは勝ちました',
  show_all: 'すべて表示する',
  personal: '個人的',
  no_notifications_at_the_moment:
    '現時点では通知はありません。 後でもう一度確認してください。',
  mode: 'モード',
  contrast: '対比',
  direction: '方向',
  layout: 'レイアウト',
  stretch: 'ストレッチ',
  presets: 'プリセット',
  status_success: 'ステータス成功',
  status_pending: 'ステータス保留中',
  swap_successfully: '無事交換完了！',
  swap_failed: 'スワップに失敗しました。後で試してください',
  coco_member: 'ココ会員になる',
  play_your_favorite_games: 'お気に入りのゲームをプレイする',
  instantly_sign_up:
    'すぐにサインアップして、カジノまたはスポーツ ブックに賭けを始めてください。',
  unlock_further_benefits:
    '楽しくプレイし、新しいレベルに到達し、さらなる特典をアンロックしましょう',
  rank_claim_bonus: 'ランクを上げてボーナスを獲得しましょう',
  go_up_to_the_next_rank:
    '次のランクに上がりましょう！ 各レベルにはボーナスがあり、さらに多くのチャンスがあります!',
  you_start_your_gaming:
    'ゲームのスタートはランク 1 の NEWCOMER から始まります。',
  the_more_you_play:
    'プレイすればするほど、ランクが上がります。 COCO.gameでプレイ中の賭け金に応じて上がります。 すべての賭け金は現在のレートで米ドルに換算されます',
  mark_all_as_read: 'すべて既読としてマークする',
  eligible_VIP_member: 'VIP 会員になる資格はありますか?',
  how_much_has_coco_given_out_in_bonuses:
    'ココはボーナスでいくら出しましたか？',
  why_is_Coco_VIP_program_the_best:
    'なぜココの VIP プログラムが最高なのでしょうか?',
  what_does_affect_the_weekly_monthly:
    '毎週/毎月のボーナスには何が影響しますか?',
  information_to_become_a_VIP_member:
    'VIP メンバーになるために追加情報を提供する必要がありますか?',
  coco_VIP_telegram_channel:
    'Coco VIP Telegram チャンネルはどこで見つけられますか?',
  one_min: '1分',
  five_min: '5分',
  one_hour: '1時間',
  for_hours: '4時間',
  one_day: '1日',
  investment: '投資',
  my_investment: '私の投資',
  what_upp: 'どうしたの？？？',
  hide: '隠れる',
  play: '遊ぶ',
  stake_and_earn: '賭けて稼ぐ',
  //
  vip_level_comparison: 'VIPレベルの比較',
  bronze: 'ブロンズ',
  silver: '銀',
  gold: '金',
  platinum: '白金',
  diamond: 'ダイヤモンド',
  level_up_bonus: 'レベルアップボーナス',
  surprise_box: 'サプライズボックス',
  free_spin_wheel_enable: 'フリースピンホイールの有効化',
  surprise_box_upgraded: 'サプライズボックスがアップグレードされました',
  weekly_bonus: 'ウィークリーボーナス',
  chat_enable: 'チャットを有効にする',
  upload_avatar_enable: 'アバターのアップロードを有効にする',
  vip_support_enable: 'VIP サポートの有効化',
  surprise_box_upgraded_2:
    'サプライズボックスのアップグレード（最大180万USDT）',
  where_coco_vip_telegram_channel:
    'Coco VIP Telegram チャンネルはどこで見つけられますか?',
  we_have_given_over_1_billion:
    '私たちはこれまでに 10 億ドル以上のボーナスを支給してきました。 これが、当社がオンラインで最高の VIP プログラムを提供している主な理由です。 私たちはプレイヤーのゲームプレイと忠誠心に報いることを心から信じています。',
  me: '自分',
  result: '結果',
  crush_of_crash: 'クラッシュ・オブ・クラッシュ',
  // Banner new
  earn_real_money: 'リアルマネーを稼ぐ',
  the_daily_game: '毎日のゲーム',
  earn_everyday_with_original_games: 'オリジナルゲームで毎日稼ごう',
  daily_race: 'デイリーレース',
  wager: '賭け金',
  and: 'そして',
  everyday: '毎日',
  crush: '打ち砕く',
  the_crash: 'クラッシュ',
  show_off_your_prediction_skills: 'あなたの予測スキルを披露してください',
  deposit_now2: '今すぐ入金',
  on_1st_deposit: '初回入金時',
  // Just for you
  free_play: '無料プレイ',
  with_fun_coin: 'FUNコイン付き',
  show_me: '見せて',
  low_risk_high_rewards: '低リスク、高報酬',
  prize_100000: '賞金 100,000 ドル',
  win_1000x_jackpot: '1000x ジャックポットを獲得',
  // Notification
  balance_change: 'バランス変更',
  your_casino: 'あなたのカジノ',
  // Referral
  spread_the_word_and: '言葉を広めて、',
  earn_reward: '報酬を獲得する',
  campaign_name: 'キャンペーン名',
  id_number: 'ID番号',
  registered: '登録済み',
  total_deposits: '総入金額',
  last_deposit: '最後の入金',
  vip_point: 'VIPポイント',
  commission: '手数料',
  referral: '照会',
  register_date: '登録日',
  display_name: '表示名',
  agents: 'エージェント',
  transaction_date: '取引日',
  transaction_type: '取引タイプ',
  export: '輸出',
  campaigns: 'キャンペーン',
  create_a_new_campaign: '新しいキャンペーンを作成する',
  claim_income: '保険金収入',
  per_players: 'プレイヤーごと',
  per_agents: 'エージェントごと',
  per_day: '1日あたり',
  per_week: '週あたり',
  per_month: '月あたり',
  per_country: '国ごと',
  date: '日付',
  total_income: '総収入',
  total_user_register: '総ユーザー登録数',
  total_user_bet: 'ユーザーの合計ベット額',
  total_transaction: '合計トランザクション',
  refer_description:
    "Refer friends to Coco.game's Affiliate Program and earn commissions for every user who registers and plays through your links, regardless of their outcomes",
  download_banner: 'バナーをダウンロード',
  search: '検索',
  clicks: 'クリック数',
  referrals: '紹介',
  deposit_users: '入金ユーザー',
  total_commission: '合計手数料',
  commission_rate: '手数料率',
  features: '特徴',
  link: 'リンク',
  code: 'コード',
  today: '今日',
  this_week: '今週',
  last_week: '先週',
  overall: '全体',
  commission_available: 'コミッションあり',
  claim: '請求',
  log_in: 'ログイン',
  to_see_your_rank: '自分のランクを見るには',
  // Crash
  start_in: 'で開始 ...',
  process: 'プロセス...',
  reconnect: '再接続',
  controls: 'コントロール',
  leaderboard: 'リーダーボード',
  bet_next: '次に賭けます',
  betting: '賭け',
  // Tip
  message_is_required: 'メッセージは必須です',
  tip_with_any_currency_in_CoCo: 'CoCo の任意の通貨によるチップ',
  receiver_id: '受信者ID',
  enter_user_id: 'ユーザーIDを入力してください',
  transaction_fee: '取引手数料',
  message: 'メッセージ',
  give_your_friends_some_messages_here:
    'ここでお友達にメッセージを送りましょう...',
  continue: '続く',
  successfully_tipped: 'チップアップに成功しました',
  you_are_tipping_to: 'あなたはチップを渡しています',
  your_tip_request_has_been_confirmed: 'チップのリクエストが確認されました。',
  you_can_track_the_progress_on_the_wallet_transaction_history:
    'ウォレットの取引履歴で進行状況を追跡できます。',
  view_history: '履歴を見る',
  tip_failed: 'チップが失敗しました',
  your_tip_request_was_unsuccessful_due_to_an_abnormality:
    '異常のため、チップのリクエストは失敗しました。',
  please_try_again_later: '後でもう一度試してください。',
  ok_i_got_it: 'はい、分かりました',
  tip_information: 'ヒント情報',
  password_is_required: 'パスワードが必要',
  tip_verification: 'チップの検証',
  authentication_secure: '安全な認証',
  to_process_tipping_please: 'チップを処理するには、',
  first_to_secure_your_assets: 'まず資産を保護するために',
  step_verification: '2段階認証',
  enter_your_login_password: 'ログインパスワードを入力してください',
  enter_the_2fa_code_appear_on_your_google_authenticator_app:
    'Google Authenticator アプリに表示される 2FA コードを入力します',
  authorize: '承認する',
  // 30/09
  sign_up_in_and_earn: 'サインアップ/ログインして獲得',
  learn_more_about_our_affiliate_program:
    'アフィリエイト プログラムについて詳しくはこちら',
  referral_link: '紹介リンク',
  create: '作成する',
  referral_users: '紹介ユーザー',
  registered_date: '登録日',
  tip: 'ヒント',
  send_tip: 'チップを送信する',
  delete: '消去',
  accept: '受け入れる',
  close: '近い',
  member_downline: 'メンバーのダウンライン',
  new_player: '新しいプレイヤー',
  claim_commission_failed: '請求手数料が失敗しました',
  claim_commission_successfully: 'コミッションの請求が成功しました',
  create_campaign_successfully: 'キャンペーンが正常に作成されました',
  create_campaign_failed: 'キャンペーンの作成に失敗しました',
  any_date: '任意の日付',
  // Mini CoCo
  stop_if_single_win_exceeds_by: '単一の勝利が次の値を超えた場合に停止します',
  // Host
  show_filter: 'フィルターを表示',
  filter: 'フィルター',
  default_campaign: 'デフォルトのキャンペーン',
  select_all: 'すべて選択',
  role: '役割',
  hello_world: 'こんにちは世界',
  // GameBank => Overview
  gb_capacity: 'GB容量',
  game_bank_capacity: 'ゲームバンクの容量',
  the_maximum_amount_that_you_can_invest: '投資できる最大金額。',
  // Crash
  finish_bet: 'ベットを終了する',
  finishing_bet: 'フィニッシュベット',
  // Referral
  delete_invitation: '招待状の削除',
  you_had_been_invited_to_become_an_agent_by:
    'あなたは、次からエージェントになるよう招待されました。',
  confirming_to_delete_this_invitation:
    'この招待状の削除を確認すると、この操作は元に戻されなくなります。 まだ決めたいですか？',
  successfully_accepted_invitation: '招待を承諾しました',
  the_house: '家 ',
  invited_you_to_become_an_agent: 'あなたをエージェントになるよう招待しました',
  campaign_name_1: 'キャンペーン名1',
  available_commission: '利用可能な手数料',
  there_are_no_commission: '手数料はかかりません',
  please: 'お願いします',
  login2: 'ログイン',
  to_see_your_rewards: '報酬を見るには',
  you_have_successfully_accepted_the_invitation: '招待を承諾しました',
  //
  downline_report: 'ダウンラインレポート',
  referral_faq_subscription:
    'アフィリエイトプログラムの詳細については、お問い合わせください。 ',
  internet_connection_restored: 'インターネット接続が回復しました',
  lost_connect_to_network: 'ネットワークへの接続が失われた',
  // 10/10
  user_profile: 'ユーザープロフィール',
  leave_a_tip: 'チップを残す',
  your_commission_rate: '手数料率',
  affiliate_terms_conditions: 'アフィリエイト利用規約',
  REFERRAL_CODE_OR_NAME_EXISTS:
    'キャンペーン名または紹介コードがすでに存在します',
  swap_unavailable:
    'スワップ ペアは現在サポートされていません。後でもう一度試してください。',
  please_enter_swap_amount: 'スワップ金額を入力してください',
  become_our_agent_Contact_business:
    '私たちの代理店になりませんか？ ビジネスへの連絡先:',
  earn_commission_rewards:
    '友達がゲームに賭けるたびにコミッション報酬を獲得できます',
  turn_your_friends_and_followers_into_weekly_commissions:
    'Coco のアフィリエイト プログラムを使用して、友達やフォロワーを毎週のコミッションに変えましょう。 シンプルで収益性が高く、完全に透明性があります',
  // FAQ
  why_should_i_be_a_Coco_affiliate:
    'なぜココ アフィリエイトになる必要があるのですか?',
  adding_our_link_and_or_banner_to_your_website:
    'ある程度のトラフィックがある Web サイトやソーシャル チャネルをお持ちの場合は、その可能性を最大限に活用してみてはいかがでしょうか? 私たちのリンクやバナーをあなたのウェブサイトに追加することで、即座に追加収入を得る新しい効果的な方法を作成できます。',
  how_do_I_get_paid_for_being_an_affiliate:
    'アフィリエイトとして報酬を受け取るにはどうすればよいですか?',
  the_banner_or_link_you_create_contains_your_personal_tracking_code:
    '作成したバナーまたはリンクには、個人のトラッキング コードが含まれています。 プレーヤーが Coco にアクセスすると、このコードはコンピューター上の Cookie に保存されます。 ユーザーがサインアップすると、当社はそのユーザーをあなたからの紹介として識別します。 このプレイヤーはあなたと永続的に関連付けられ、あなたは彼らの活動から生涯にわたってコミッションを生み出し続けます。',
  how_a_new_player_linked_to_me:
    '新しいプレイヤーはどのように私にリンクしましたか?',
  how_exactly_is_my_affiliate_commission_calculated:
    'アフィリエイト手数料は正確にどのように計算されますか?',
  the_commission_for_each_bet: `各ベットのコミッションは、以下の式に基づいて計算されます: コミッション = 賭け金 * ゲームのハウスエッジ * コミッション率 ここで:
       - 賭け金総額: アフィリエイトによって紹介されたすべてのユーザーによって生成されたゲーム賭け金の総額。
       - ハウスエッジ: 初期ベットに対する平均損失の比率。 各ゲームのハウスエッジは、1 から RTP (Return to Player) を引いたものとして定義されます。
       - コミッション率: 手数料率はユーザーのアフィリエイト役割に応じて異なり、アフィリエイト サイトに表示されます。`,
  how_to_join_the_affiliate_program:
    'アフィリエイト プログラムに参加するにはどうすればよいですか?',
  if_you_already_have_a_Coco_account: `すでに Coco アカウントをお持ちの場合は、次の手順に従ってください:
       1. アフィリエイト ページにアクセスします。
       2. 独自の紹介コードを使用して新しいキャンペーンを生成します。
       3. Web サイト、ソーシャル メディア、またはトラフィックの多いプラットフォームで紹介リンクを共有します。
       4. 紹介したユーザーから毎週のコミッションを請求します。`,
  how_can_i_keep_track_of_my_performance:
    '自分のパフォーマンスをどのように追跡できますか?',
  once_your_affiliate_link_is_generated:
    'アフィリエイト リンクが生成されると、アフィリエイト ダッシュボードにログインできるようになります。 そこでは、すべての統計を表示するアクセス権があり、さまざまな要素に基づいてレポート内の情報をフィルタリングできます。',
  at_the_start_of_each_new_week:
    '新しい週の初めに手数料が計算され、COD トークンの形式で請求できるようになります。 収益はアカウントウォレットに簡単に出金できます。',
  i_have_big_audience_how_i_can_get_special_deals:
    '大勢の視聴者がいるのですが、どうすれば特別取引を入手できますか?',
  if_you_have_a_website_with_substantial_traffic:
    '大量のトラフィックがある Web サイト、または多数の視聴者が集まるソーシャル メディア アカウントをお持ちの場合は、business@coco.game までお問い合わせください。限定セールが受けられます。',
  // Crash
  maximum_is_9900: `最大値は「9900」"`,
  minium_is_101: `最小値は「1.01」です`,
  insufficient_currency: '通貨が不足しています',
  this_game_does_not_support_this_currency:
    'このゲームはこの通貨をサポートしていません。プレイを続けるには別のアセットに切り替えてください。',
  unknown: '未知',
  you_should_verify_account_first_before_tipping_your_friends:
    '他の人にチップを渡すには、KYC 検証に合格する必要があります',
  must_be_only_digits: '数字のみである必要があります',
  min_length_ID_at_least_14_number: 'ID の最小長は 14 個以上の数字',
  receiver_is_required: '受信機が必要です',
  tip_amount_must_specify_a_number:
    'ヒント 金額には数値を指定する必要があります',
  tip_amount_must_greater_than_0:
    'チップの金額は 0 より大きくなければなりません',
  tip_amount_is_required: 'チップ金額は必須です',
  how_to_see_other_ID: '他のIDを見るにはどうすればいいですか？',
  get_your_friends_ID_from_their_profiles:
    'プロフィールから友達 ID を取得します。',
  tips_amount: 'チップの金額',
  total_spending_amount: '総支出額',
  withdrawing_your_balance:
    '残高を引き出す前に、まずアカウントを確認する必要があります',
  pls_enter_code_send_to_tel: '電話に送信するコードを入力してください',
  please_enter_withdraw_address: '出金アドレスを入力してください',
  your_profile_had_been_verified: 'あなたのプロフィールは確認されました',
  SWAP_UNAVAILABLE: '残高不足',
  // Maintain
  this_page_is_under_maintenance: 'ごめん！ このページはメンテナンス中です」',
  please_give_us_some_minutes_to_fix_the_issue:
    '問題を解決するまで少しお待ちください',
  // 404
  we_cant_find_that_page: `おっと！ そのページが見つかりません。`,
  you_can_still_enjoy_the_best_casino_experience_at_Coco: `ただし、心配しないでください。Coco.game では最高のカジノ体験をお楽しみいただけます。`,
  //
  region_prohibited: '禁止地域',
  coco_game_is_not_available_in_your_region:
    '申し訳ございませんが、Coco.game はお住まいの地域ではご利用いただけません。',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country:
    'ゲームライセンスにより、あなたの国のプレイヤーを受け入れることができません。 さらにサポートが必要な場合は、support@coco.game までお問い合わせください。',
  congrats: 'おめでとうございます',
  won_the_most: '一番勝ちました！',
  coco_took_it_all: 'ココが全部持って行った！',
  page: 'ページ',
  refresh: 'リフレッシュ',
  // Popup
  register_to_get: '登録して入手',
  player_to_earn_the: 'を獲得するプレイヤー',
  $10_prize: '賞金10ドル',
  sign_up_now: '今すぐサインアップ',
  // Popup 2
  you_receive: 'あなたは受け取ります',
  weekly: '毎週',
  see_detail: '詳細を見る',
  swap_amount_need_to_larger_than_min:
    'スワップ額は最小値より大きくする必要があります',
  crypto_play: 'クリプトプレイ',
  fun_play: '楽しい遊び',
  // Popup 3
  play_with: 'と遊ぶ',
  earn: '得る',
  real: '本物',
  dollars: 'ドル',
  big_wins: '大勝利',
  what_is_gift_code: 'ギフトコードとは何ですか？',
  gift_code_info:
    'ギフト コードは、文字と数字で構成される特別なコードのようなものです。 このコードを引き換えると、特別な報酬が得られます。',
  gift_code_cod:
    '各ギフト コードには独自の価値と期間があります。 すべての報酬は COD トークンの形式で支払われます',
  there_are_various_ways_to_get_Coco_gift_codes:
    'There are various ways to get Coco gift codes, such as participating in exclusive social media events, community activities, bonuses, or special quests.',
  how_to_get_Coco_gift_code: 'ココギフトコードの入手方法は？',
  how_to_use_Coco_gift_code: 'ココギフトコードの使い方は？',
  simply_enter_the_gift_code:
    'ギフトコードを入力するだけで、特典が自動的に残高に追加されます。',
  giftcode_required: 'ギフトコードが必要です',
  bonus_code_claimed_successfully: 'ボーナスコードが正常に請求されました',
  claim_your_free_reward: '無料の特典を受け取りましょう',
  enter_your_giftcode: 'ギフトコードを入力してください',
  claim_bonus: 'ボーナスを請求する',
  what_is_giftcode: 'ギフトコードとは',
  what_is_COCO_giftcode: 'COCOギフトコードとは何ですか？',
  got_it: 'わかった',
  won: '勝利した',

  // 8/12
  use_your_COD_gift_to_bet_and_win_at_Cocogame:
    'Coco ギフトを使用して、Coco.game に賭けて勝ちましょう。」',
  gift_code: 'ギフトコード',
  // 15/12
  mini_Coco_is_Allways_3x3_Slot_machine_game:
    'Mini Coco は、27 行の 3x3 スロット マシン ゲームです。',
  rule_content: `規則: シンボルは最初の列から左から右に連続して始まります。 支払いは 1 行の最高値のみを計算します。`,
  jackpot2: 'ジャックポット',
  explode_if_you_collect_3_WILD: 'WILDを3つ集めると爆発します。',
  wild_can_subsitute_for_all_symbols:
    'ワイルドはすべてのシンボルの代わりに使用できます。',

  // 3/1
  series: 'シリーズ',
  multiple: '複数',
  result_will_be_display_here: '結果はここに表示されます',
  bet_head: 'ベットヘッド',
  bet_tail: 'ベットテール',
  instant: 'インスタント',
  multiply: 'かける',
  coin_flip_instant_mode_desc:
    '単純に表か裏を賭けるか、自動的に賭けを設定します',
  auto_bet_setup: '自動ベットの設定',
  you_win_title: 'あなたが勝ちます',
  select_network_to_continue: '続行するには、ネットワークを選択してください',
  ensure_withdraw_address:
    '必ず正しい住所を入力してください。 そうしないと、資金が失われ、回復できなくなります。',
  click_bet_to_choose_coin_side: 'ベットをクリックしてコイン面を選択します',

  copied_share_in_chat: 'コピーしましたのでチャットで共有してください!',
  copied_share_online: 'コピーしてオンラインで共有しましょう!',
  game_id: 'ゲームID',
  server_seed_hash: 'サーバーシード(ハッシュ)',
  client_seed: 'クライアントシード',
  nonce: 'ノンス',
  about_COD: '代金引換について',
  coco_dollar_info:
    'COD（ココドル）はCOCO.GAMEが立ち上げた特別通貨です。 COD を使用すると、ゲームに参加したり、他のプレイヤーにヒントを与えることができます。',
  can_COD_be_exchanged_to_any_other_currency: 'CODを他の通貨に交換できますか?',
  you_can_swap_cod:
    '現在の為替レートに基づいて、Coco Swap を使用していつでも COD を他の通貨に交換できます。',
  get_daily_bonus_up_to_5000_COD: '毎日最大 5,000 COD のボーナスを獲得',
  by_playing_games_tips_coin_dropping_and_rain_bonus:
    'ゲームをプレイすることで、チップ、コインドロップ、レインボーナスが得られます。',
  see_how: '方法を見てください',
  how_to_unlock_COD: 'CODのロックを解除する方法',
  locked_COD_is_earned_through_specific_bonuses_like_deposit_bonuses:
    'ロックされた COD は、入金ボーナスなどの特定のボーナスを通じて獲得されます。',
  unlocking_COD_is_simple:
    'COD のロックを解除するのは簡単です。 これはレーキバックに似ており、賭け金に比例してロックが解除されます。',

  // Chat
  please_slow_down: '減速してください。',
  links_must_include_your_website_domain:
    'リンクには Web サイトのドメインを含める必要があります。',
  chat_is_hidden: 'チャットは非表示になっています',
  show_chat: 'チャットを表示する',
  pinned_message: '固定メッセージ',

  // 12/01
  deposit_bonus: '入金ボーナス',
  rakeback: 'レイクバック',
  spin: 'スピン',
  get_extra_300_bonus_on_minimum_of_10_USDT_deposit:
    '最低 10.00 USDT の入金で追加の 300% ボーナスを獲得',
  min_deposit: '最低入金額:',
  max_claim: '最大の主張:',
  withdraw_successful: '出金成功',
  your_withdrawal_request_has_been_confirmed:
    '出金リクエストが確認されました。 ウォレットの取引履歴で進行状況を追跡できます。',
  pi_balance_in_wallet: 'ウォレット内のPi残高',
  your_Pi_address: 'あなたのPiアドレス',
  fill_in_carefully_according_to_the_specific_currency:
    '特定の通貨に従って慎重に入力してください',
  you_will_receive_Pi_to_your_address_within_1_working_day:
    'Pi は 1 営業日以内にあなたの住所に届きます。',
  please_make_sure_you_enter_the_correct_Pi_address_otherwise_your_funds_will_not_be_reversed:
    '正しい Pi アドレスを入力していることを確認してください。 そうしないと、資金は取り消されません。',
  current_capacity: '電流容量',
  what_is_CODG_capacity: 'CODGの容量とは何ですか?',

  // 08/03
  buy_crypto: '仮想通貨を購入する',
  refund: '返金',
  bet_histories: 'ベット履歴',
  round_histories: 'ラウンド履歴',
  round_id: 'ラウンドID',
  bet_place: 'ベットプレイス',
  profit: '利益',
  top: '上',
  top_profit: 'トップ利益',
  leader_board: 'リーダーボード',
  how_to_join: '参加方法',
  see_more: '続きを見る',
  top_winning_streak: '連勝記録トップ',
  top_losing_streak: '連敗トップ',
  place: '場所',
  streak: 'ストリーク',
  total_prized: '獲得総額',
  top_reward: '最高の報酬',
  top_10_winners: '上位10名の受賞者',
  monthly_bonus: '毎月のボーナス',
  available_rakeback: '利用可能なレイクバック',
  no_rakeback_yet: 'レーキバックはまだありません',
  claim_my_rakeback: '私のレーキバックを請求してください',
  wager_required: '賭け金が必要です',
  receive_every: '毎回受信',
  claim_bonus_successfully: 'ボーナスを無事に受け取りましょう',
  claim_bonus_failed:
    'ボーナスの受け取りに失敗しました。もう一度お試しください。',
  no_bonus_available: 'ボーナスは利用できません',
  play_to_earn_bonus: 'ゲームをプレイして賭けを始めてボーナスを獲得しましょう',
  play_and_wager: '遊びと賃金r',
  claim_all: 'すべてを請求する',
  duration: '間隔',
  your_total_bonus: '合計ボーナス',
  roll_under: 'ロールアンダー',
  game_result_will_be_displayed_here: 'ここに試合結果が表示されます。',
  level_up_bonus_upgrade: 'レベルアップボーナスアップグレード',
  _24h_live_support: '24時間ライブサポート',
  bonus_growth: 'ボーナスの成長h',
  xp_required: 'XPが必要です',
  buy_fiat: '法定通貨を選択してください',
  play_increase_wager:
    'ボーナスを受け取ったら、出金するには賭け金が 120,000 ドルに達する必要があります',
  slots_wager_rate: 'スロットの賭け金 $1.00 = 2XP',
  platinum_1_to_6: 'プラチナI～VI',
  diamond_1_to_5: 'ダイヤモンド I ～ V',

  // CODG
  total_received_CODG: '受け取ったCODGの合計',
  unlocked_amount: 'ロック解除された金額',
  how_to_unlock_CODG: 'CODGのロックを解除するにはどうすればよいですか？',
  swap_and_play: '交換してプレイ',
  unlocked_CODG:
    'ロック解除された CODG を他の通貨に交換してプレイして獲得することもできます',
  available_to_swap: '交換可能',
  there_are_no_redeemable_currencies: '引き換え可能な通貨はありません',
  first_deposit: '初回入金',
  _300_bonus: '300% ボーナス',
  get_up_to: 'までに到達',
  second_deposit: '2回目の入金',
  _200_bonus: '200% ボーナス',
  third_deposit: '3回目の入金',
  _150_bonus: '150% ボーナス',
  fourth_deposit: '4回目の入金',
  _100_bonus: '100% ボーナス',
  deposit_promotion: '入金プロモーション',
  unlock_codg_description:
    '例外的な 4 段階のデポジット マッチ ボーナスを提供し、当社独自のトークンである CODG で追加の報酬を獲得する絶好の機会を提供します。 この利点を活用して資金を増やし、当社のプラットフォームで爽快な旅に乗り出しましょう。',

  // Buy Crypto
  our_excited_bonus: '私たちの興奮したボーナスの旅をあなたに',
  something_is_wrong: '何かが間違っている。',
  disclaimer: '免責事項',
  the_above_third_party:
    '上記のサードパーティ サービスは、Coco でのプレイに使用できる暗号通貨の購入に使用できます。 プラットフォームに登録すると、サービス利用規約にも同意したことになり、独自に実行される KYC プロセスを当社のプロセスに渡す必要があります。',
  buy_with_transFI: 'TransFI で購入する',
  order_processing: '注文処理',
  your: 'あなたの',
  buying_order_is_currently: '買い注文は現在処理中です。 数分お待ちください',
  // Deposit
  transfer_pi: '転送Pi',
  transfer_Pi_to_the_address_above_which_is_our_agent_address:
    'Pi を上記の当社の代理店の住所に転送します。',
  meet_our_live_support: 'ライブサポートをご紹介します',
  click_the_i_transferred_Pi:
    'ライブサポートを受けるには、「Pi を転送しました」ボタンをクリックしてください。',
  confirm_transaction: '取引の確認',
  send_your_transaction_and_screenshot_to_the_chat_box_as_confirmation:
    '確認としてトランザクションとスクリーンショットをチャット ボックスに送信します。',
  transfer_Pi_to_this_address: 'Piをこのアドレスに転送します',
  how_to_deposit_Pi: 'Piを入金するにはどうすればよいですか?',
  i_transferred_Pi: '円周率を転送しました',
  earn_an_extra_300_bonus_with_a_minimum_deposit_of_1000_USD:
    '最低 10.00 USD の入金で追加の 300% ボーナスを獲得できます。',
  // Tip
  max_length_ID_not_more_than_20_number: 'ID の最大長は 20 個以下です',
  receiver: '受信機',
  transaction_fee_rate: '取引手数料率',
  transaction_fee_amount: '取引手数料額',
  you_need_to_wager: '賭ける必要があります',
  // Withdraw
  more_to_withdraw_due_to_the_bonus_money_policies:
    'ボーナスマネーポリシーにより引き出しが増える',
  pi_address_is_required: 'Pi アドレスは必須です',
  pi_Amount_must_specify_a_number: 'Pi Amount には数値を指定する必要があります',
  pi_amount_must_greater_than_10_Pi:
    'Pi の量は 10 Pi より大きくなければなりません',
  pi_amount_must_less_than_10000_Pi:
    'Pi の金額は 10.000 Pi 未満である必要があります',
  pi_amount_is_required: '円周率の量は必須です',
  withdraw_successfully_We_will_contact_you_later:
    '無事引き出し完了！ 後ほどご連絡させていただきます',
  hey_there: 'ちょっと、そこ',
  please_process_my_Pi_coin_withdrawal_as_follows:
    'Pi コインの出金は次のように処理してください。',
  fees: '料金',
  input_your_Pi_address: 'Pi アドレスを入力してください',
  input_number_of_Pi: '円周率の数値を入力',
  lucky_win: '幸運な勝利',
  can_not_get_bet_detail: 'ベットの詳細を取得できません!',

  // Popup currency
  cannot_be_used_in_this_game:
    'このゲームのリアル プレイ モードでは使用できません。 他のアセットに切り替えてください。',
  the_selected_currency_will_be_displayed_in: '選択した通貨が表示されます',
  and_if_you_change_currency_while_playing:
    'プレイ中に通貨を変更すると、ゲームが更新されて再起動されます。',

  // CODG
  about_CODG: 'CODGについて',
  CODG_is_the_reward_token_of_Cocogame:
    'CODG は Coco.game の報酬トークンです。 プラットフォームに関与すればするほど、より多くの CODG を獲得して資産を豊かにすることができます。',
  can_CODG_be_swapped_for_other_currencies: 'CODGを他の通貨に交換できますか?',
  you_can_swap_your_unlocked_CODG:
    '現在の為替レートに基づいて、ロック解除された CODG を他の通貨に交換できます。 ただし、ロックされた CODG は交換できません。 プラットフォーム内のさまざまなゲームでプレイするためにのみ使用できます。',
  // GameBank => Overview
  the_maximum_share_of_all_users: '全ユーザーの最大シェア',
  your_maximum_share: 'あなたの最大シェア',
  total_available_investing_amount: '投資可能総額',
  your_available_investing_amount: '投資可能額',
  // Game bank => InvestInformation
  what_is_investing_in_coco_gamebank: 'Coco ゲームバンクへの投資とは',
  anyone_can_add_money_to_the_gamebank:
    '誰でもゲームバンクにお金を追加して、ハウス側のベットに参加できます。 あなたの収益は、Game Bank の総利益にあなたの取り分を乗算して決定されます。',
  why_should_i_invest: 'なぜ投資する必要があるのでしょうか?',
  profitable: '儲かる',
  do_you_believe_in_the_bad_luck_of_other_gamblers:
    '他のギャンブラーの不運を信じますか? もしそうなら、家になることは不労所得を得る素晴らしい方法です。',
  real_yield: '実質利回り',
  you_will_receive_profit_in_the_same_token_that_you_invest:
    '投資したのと同じトークンで利益を受け取ります。',
  analysis_tool: '分析ツール',
  access_gamebank_value:
    'ゲームバンクの価値、シェア、履歴、トレンドにアクセスして、最良の決定を下します。',
  understand_the_risk_involved: '伴うリスクを理解する',
  keep_in_mind_that_the_gamebank_value_may_temporarily_decrease_due_to_gamblers_wins:
    'ゲームバンクの価値はギャンブラーの勝利によって一時的に減少する可能性があるため、すぐに利益が得られない可能性があることに注意してください。 慎重に投資してください。',
  invest_information: '投資情報',
  details: '詳細',
  // Gift code
  gift_received_successfully: 'ギフトコードを請求しました',
  congratulations_you_have_just_received:
    'おめでとう！ あなたはたった今受け取りました:',
  cod_successfully: 'CODが成功しました',
  // Transaction
  receiver_nickname: '受信者のニックネーム',
  sender_nickname: '送信者のニックネーム',
  tip_successful: 'チップ成功',
  // JFU
  bet_win: 'ベット&ウィン',
  challenge: 'チャレンジ',
  cash_it_out: '現金化しましょう',
  tournament: 'トーナメント',
  november_exclusive: '11月限定',
  new_game: '新しいゲーム',
  you_need_to_reach_the_rank_to_unlock_this_feature:
    'に到達する必要があります{{val}}この機能のロックを解除するにはランクを上げてください。 自分のランクインを確認する',
  play_with_balance_in: 'バランスを考えて遊ぶ',

  // Host
  ohplay_vip_club: 'オープレイ VIP クラブ',
  pi_vip_club: 'パイVIPクラブ',
  to_pi_vip_club: 'パイVIPクラブへ',
  to_ohplay_vip_club: 'オープレイVIPクラブへ',
  welcome_to_Ohplay: 'Ohplay.clubへようこそ',
  welcome_to_Pi: 'Pi.game へようこそ',
  become_a_pi: '円周率になる',
  become_a_ohplay: 'オープレイになる',
  pi_member: 'Piのメンバーになる',
  ohplay_member: 'オープレイ会員になる',
  why_is_Pi_VIP_program_the_best: 'Pi VIP プログラムが最適な理由は何ですか?',
  why_is_Ohplay_VIP_program_the_best:
    'Ohplay VIP プログラムが最適な理由は何ですか?',
  how_much_has_pi_given_out_in_bonuses: 'パイはボーナスでいくら出しましたか?',
  how_much_has_ohplay_given_out_in_bonuses:
    'Ohplay はボーナスをいくら支給しましたか?',
  pi_VIP_telegram_channel:
    'Pi VIP Telegram チャンネルはどこで見つけられますか?',
  ohplay_VIP_telegram_channel:
    'Ohplay VIP Telegram チャンネルはどこで見つけられますか?',
  tip_with_any_currency_in_Pi: '円周率の任意の通貨によるチップ',
  tip_with_any_currency_in_Ohplay: 'Ohplay の任意の通貨でチップ',
  why_should_i_be_a_Pi_affiliate:
    'Pi アフィリエイトになる必要があるのはなぜですか?',
  why_should_i_be_a_Ohplay_affiliate:
    'Ohplay アフィリエイトになる必要があるのはなぜですか?',
  if_you_already_have_a_Pi_account: `すでに Pi アカウントをお持ちの場合は、次の手順に従ってください。
       1. アフィリエイト ページにアクセスします。
       2. 独自の紹介コードを使用して新しいキャンペーンを生成します。
       3. Web サイト、ソーシャル メディア、またはトラフィックの多いプラットフォームで紹介リンクを共有します。
       4. 紹介したユーザーから毎週のコミッションを請求します。`,
  if_you_already_have_a_Ohplay_account: `すでに Ohplay アカウントをお持ちの場合は、次の手順に従ってください。
       1. アフィリエイト ページにアクセスします。
       2. 独自の紹介コードを使用して新しいキャンペーンを生成します。
       3. Web サイト、ソーシャル メディア、またはトラフィックの多いプラットフォームで紹介リンクを共有します。
       4. 紹介したユーザーから毎週のコミッションを請求します。`,
  you_can_still_enjoy_the_best_casino_experience_at_Pi: `ただし、心配しないでください。Pi.game では引き続き最高のカジノ体験をお楽しみいただけます。`,
  you_can_still_enjoy_the_best_casino_experience_at_Ohplay: `ただし、心配しないでください。それでも Ohplay.club で最高のカジノ体験をお楽しみいただけます。`,
  pi_game_is_not_available_in_your_region:
    '申し訳ございませんが、Pi.game はお住まいの地域ではご利用いただけません。',
  ohplay_game_is_not_available_in_your_region:
    '申し訳ございませんが、お住まいの地域では Ohplay.club をご利用いただけません。',
  pi_took_it_all: 'パイはすべてを手に入れました！',
  ohplay_took_it_all: 'オープレイがすべてを手に入れた！',
  there_are_various_ways_to_get_pi_gift_codes:
    'Pi ギフト コードを入手するには、限定のソーシャル メディア イベント、コミュニティ活動、ボーナス、特別クエストへの参加など、さまざまな方法があります。',
  there_are_various_ways_to_get_ohplay_gift_codes:
    'Ohplay ギフト コードを入手するには、限定ソーシャル メディア イベント、コミュニティ活動、ボーナス、特別クエストへの参加など、さまざまな方法があります。',
  how_to_get_Pi_gift_code: 'Coco.game ギフトコードを入手するには?',
  how_to_get_Ohplay_gift_code: 'Coco.game ギフトコードを入手するには?',
  gift_code_pi: 'Pi.game',
  gift_code_ohplay: 'Ohplay.club',
  how_to_use_pi_gift_code: 'Pi.gameギフトコードの使い方は？',
  how_to_use_ohplay_gift_code: 'Ohplay.club ギフトコードの使用方法?',
  what_is_pi_giftcode: 'Piギフトコードとは何ですか?',
  what_is_ohplay_giftcode: 'Ohplay ギフトコードとは?',
  use_your_COD_gift_to_bet_and_win_at_pi:
    'Pi ギフトを使って Pi.game でプレイして勝ちましょう',
  use_your_COD_gift_to_bet_and_win_at_ohplay:
    'Ohplay ギフトを使用して、Ohplay.club で賭けて勝ちましょう',
  the_above_third_party_pi:
    '上記のサードパーティ サービスは、Pi でのプレイに使用できる暗号通貨の購入に使用できます。 プラットフォームに登録すると、サービス利用規約にも同意したことになり、独自に実行される KYC プロセスを当社のプロセスに渡す必要があります。',
  the_above_third_party_ohplay:
    '上記のサードパーティ サービスは、Ohplay でのプレイに使用できる暗号通貨の購入に使用できます。 プラットフォームに登録すると、サービス利用規約にも同意したことになり、独自に実行される KYC プロセスを当社のプロセスに渡す必要があります。',
  CODG_is_the_reward_token_of_Pigame:
    'CODG は Pi.game の報酬トークンです。 プラットフォームに関与すればするほど、より多くの CODG を獲得して資産を豊かにすることができます。',
  CODG_is_the_reward_token_of_Ohplay:
    'CODG は Ohplay.club の報酬トークンです。 プラットフォームに関与すればするほど、より多くの CODG を獲得して資産を豊かにすることができます。',
  what_is_investing_in_pi_gamebank: 'Pi ゲームバンクへの投資とは',
  what_is_investing_in_ohplay_gamebank: 'Ohplay ゲームバンクへの投資とは',
  turn_your_friends_and_followers_into_weekly_commissions_pi:
    'Pi のアフィリエイト プログラムを使用して、友達やフォロワーを毎週のコミッションに変えましょう。 シンプルで収益性が高く、完全に透明ですt',
  turn_your_friends_and_followers_into_weekly_commissions_ohplay:
    'Ohplay のアフィリエイト プログラムを利用して、友達やフォロワーを毎週のコミッションに変えましょう。 シンプルで収益性が高く、完全に透明性があります',
  if_you_have_a_website_with_substantial_traffic_pi:
    '大量のトラフィックがある Web サイト、または多数の視聴者が集まるソーシャル メディア アカウントをお持ちの場合は、business@Pi.game までお問い合わせください。限定セールが受けられます。',
  if_you_have_a_website_with_substantial_traffic_ohplay:
    '大量のトラフィックがある Web サイト、または多数の視聴者が集まるソーシャル メディア アカウントをお持ちの場合は、business@Ohplay.club までお問い合わせください。限定セールが受けられます。',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_pi:
    'ゲームライセンスにより、あなたの国のプレイヤーを受け入れることができません。 さらにサポートが必要な場合は、support@Pi.game までお問い合わせください。',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_ohplay:
    'ゲームライセンスにより、あなたの国のプレイヤーを受け入れることができません。 さらにサポートが必要な場合は、support@Ohplay.club までお問い合わせください。',
  all_plays: 'オールプラy',
  my_plays: '私の遊び',
  high_play: 'ハイプレイ',
  play_id: 'プレイID',
  play_amount: 'プレイ量',
  set_auto_play: '自動ベットを設定する',
  number_of_play: 'プレイ数',
  stop_auto_play: '自動再生を停止する',
  start_play_round_failed: 'プレイラウンドの開始に失敗しました',
  wager_contest_pi: 'パイゲームコンテスト',
  max_play: 'マックスプレイ',
  total_play_amount: '総プレイ額',
  under_min_play: '最小プレイ未満',
  invalid_play_params: '無効なプレイパラメータ',
  over_max_play: '最大プレイを超えて',
  invalid_play_state: '無効なプレイ状態',
  play_next: '次にプレイする',
  playing: '遊ぶ',
  play_head: 'プレイヘッド',
  play_tail: 'プレイテール',
  auto_play_setup: '自動再生の設定',
  click_play_to_choose_coin_side: '「再生」をクリックしてコイン面を選択します',
  play_histories: 'プレイ履歴',
  play_place: 'プレイプレイス',
  can_not_get_play_detail: 'プレイ詳細を取得できません!',
  play_win: 'プレイ&ウィン',

  // CODG 05/04
  locked_CODG_is_earned:
    'ロックされた CODG は、入金ボーナスなどの特定のボーナスまたはプロモーションを通じて獲得されます。',
  unlocking_CODG_is_simple: `CODG のロックを解除するのは簡単です。 これはレイクバックに似ており、次のように賭け金に比例してロックが解除されます。`,
  unlocking_formula: 'ロック解除額 = 賭け金 * 小数としてのハウスエッジ * X',
  conversion_formula: `たとえば、100 ドルを賭けると、0.2 個のロックされた CODG がロック解除されます。`,
  note_unlock_codg:
    '注: CODG のロックを解除するには、少なくともブロンズ ランクを達成する必要があり、それには 10,000 XP が必要です。',
  how_to_claim_COD: 'CODの請求方法',
  as_you_place_wagers:
    '賭けをすると、ピギーにはロックが解除された CODG が蓄積され、COD に変換されます。',
  check_to_claim: '請求するにはチェックを入れてください',
  about_CODG_token: 'CODGトークンについて',
  play_our_games_to_earn_CODG_for_free:
    'ゲームをプレイして CODG を無料で獲得しましょう!',
  how_to_unlock: '解除方法',
  how_to_claim: '請求方法',
  today_biggest_winners: '今日の最大の勝者',
  and_get_up_to: 'そして立ち上がる',
  to_get: '取得するため',
  claim_now: '今すぐ請求する',
  learn_more: 'もっと詳しく知る',

  // Host 10/04
  welcome_to_satoshity: 'satoshiity.io へようこそ',
  welcome_to_betgecko: 'betgecko.io へようこそ',
  welcome_to_bitwin: 'bitwin.club へようこそ',
  host_vip_club: `${process.env.REACT_APP_SITE} VIPクラブ`,
  to_host_vip_club: `に ${process.env.REACT_APP_SITE} VIPクラブ`,
  learn_vip: `詳しくはこちら ${process.env.REACT_APP_SITE} VIPのシステム`,
  become_a_host: `になる ${process.env.REACT_APP_SITE}`,
  host_member: `になる ${process.env.REACT_APP_SITE} メンバー`,
  how_much_has_host_given_out_in_bonuses: `どれくらいありますか ${process.env.REACT_APP_SITE} ボーナスで配られる？`,
  why_is_HOST_VIP_program_the_best: `なぜですか ${process.env.REACT_APP_SITE} VIPプログラムは最高ですか？`,
  HOST_VIP_telegram_channel: `どこで見つけられますか ${process.env.REACT_APP_SITE} VIP電報チャンネル？`,
  tip_with_any_currency_in_HOST: `任意の通貨でのチップ ${process.env.REACT_APP_SITE}`,
  turn_your_friends_and_followers_into_weekly_commissions_HOST: `友達やフォロワーを毎週のコミッションに変えましょう ${process.env.REACT_APP_SITE}'s アフィリエイトプログラム。 シンプルで収益性が高く、完全に透明性があります`,
  why_should_i_be_a_HOST_affiliate: `なぜ私がなる必要があるのですか ${process.env.REACT_APP_SITE} アフィリエイト？`,
  if_you_already_have_a_HOST_account: `すでにお持ちの場合は、 ${process.env.REACT_APP_SITE} アカウントを作成するには、次の手順に従ってください。
      1. アフィリエイト ページにアクセスします。
      2. 独自の紹介コードを使用して新しいキャンペーンを生成します。
      3. Web サイト、ソーシャル メディア、またはトラフィックの多いプラットフォームで紹介リンクを共有します。
      4. 紹介したユーザーから毎週のコミッションを請求します。`,
  HOST_took_it_all: `${process.env.REACT_APP_SITE} 全部取った！`,
  there_are_various_ways_to_get_HOST_gift_codes: `色々な入手方法がありますが、 ${process.env.REACT_APP_SITE} ギフト コード (限定的なソーシャル メディア イベント、コミュニティ活動、ボーナス、特別クエストへの参加など)。`,
  what_is_HOST_giftcode: `'とは ${process.env.REACT_APP_SITE} ギフトコード?`,
  mini_HOST_is_Allways_3x3_Slot_machine_game: `ミニ ${process.env.REACT_APP_SITE} 27 ラインの 3x3 スロット マシン ゲームです。`,
  the_above_third_party_HOST: `上記のサードパーティ サービスは、上でプレイするために使用できる暗号通貨を購入するために使用できます。 ${process.env.REACT_APP_SITE}. プラットフォームに登録すると、サービス利用規約にも同意したことになり、独自に実行される KYC プロセスを当社のプロセスに渡す必要があります。`,
  what_is_investing_in_HOST_gamebank: `投資とは何ですか ${process.env.REACT_APP_SITE} ゲームバンク`,

  you_can_still_enjoy_the_best_casino_experience_at_satoshity: `ただし、心配しないでください。さとしシティ.io では最高のカジノ体験をお楽しみいただけます。`,
  you_can_still_enjoy_the_best_casino_experience_at_betgecko: `ただし、ご心配なく、betgecko.io で最高のカジノ体験をお楽しみいただけます。`,
  you_can_still_enjoy_the_best_casino_experience_at_bitwin: `ただし、心配しないでください。bitwin.club では引き続き最高のカジノ体験をお楽しみいただけます。`,
  satoshity_game_is_not_available_in_your_region:
    '申し訳ございませんが、お住まいの地域では satoshiity.io をご利用いただけません。',
  betgecko_game_is_not_available_in_your_region:
    '申し訳ございませんが、お住まいの地域では betgecko.io をご利用いただけません。',
  bitwin_game_is_not_available_in_your_region:
    '申し訳ございませんが、お住まいの地域では bitwin.club をご利用いただけません。',
  how_to_get_satoshity_gift_code:
    'satoshiity.io ギフトコードを取得するにはどうすればよいですか?',
  how_to_get_betgecko_gift_code: 'betgecko.io ギフトコードを入手するには?',
  how_to_get_bitwin_gift_code:
    'bitwin.club ギフトコードを取得するにはどうすればよいですか?',
  how_to_use_satoshity_gift_code: 'SATOSHITY.IOギフトコードの使い方は？',
  how_to_use_betgecko_gift_code: 'betgecko.io ギフトコードの使用方法?',
  how_to_use_bitwin_gift_code: 'bitwin.clubギフトコードの使い方は？',
  use_your_COD_gift_to_bet_and_win_at_satoshity:
    'Satoshity ギフトを使って satoshiity.io で賭けて勝ちましょう',
  use_your_COD_gift_to_bet_and_win_at_betgecko:
    'Betgecko ギフトを使用して betgecko.io で賭けて勝ちましょう',
  use_your_COD_gift_to_bet_and_win_at_bitwin:
    'Bitwin ギフトを使用して、bitwin.club で賭けて勝ちましょう',
  CODG_is_the_reward_token_of_satoshity:
    'CODG は satoshiity.io の報酬トークンです。 プラットフォームに関与すればするほど、より多くの CODG を獲得して資産を豊かにすることができます。',
  CODG_is_the_reward_token_of_bitwin:
    'CODG は bitwin.club の報酬トークンです。 プラットフォームに関与すればするほど、より多くの CODG を獲得して資産を豊かにすることができます。',
  CODG_is_the_reward_token_of_betgecko:
    'CODG は betgecko.io の報酬トークンです。 プラットフォームに関与すればするほど、より多くの CODG を獲得して資産を豊かにすることができます。',
  if_you_have_a_website_with_substantial_traffic_satoshity:
    '大量のトラフィックがある Web サイトや、多数の視聴者が集まるソーシャル メディア アカウントをお持ちの場合は、business@satoshity.io までお問い合わせください。限定セールが受けられます。',
  if_you_have_a_website_with_substantial_traffic_betgecko:
    '大量のトラフィックがある Web サイトや、多数の視聴者が集まるソーシャル メディア アカウントをお持ちの場合は、business@betgecko.io までお問い合わせください。限定セールが受けられます。',
  if_you_have_a_website_with_substantial_traffic_bitwin:
    '大量のトラフィックがある Web サイトや、多数の視聴者が集まるソーシャル メディア アカウントをお持ちの場合は、business@bitwin.club までお問い合わせください。限定セールが受けられます。.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_betgecko:
    'ゲームライセンスにより、あなたの国のプレイヤーを受け入れることができません。 さらにサポートが必要な場合は、support@Betgecko.io までお問い合わせください。',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_satoshity:
    'ゲームライセンスにより、あなたの国のプレイヤーを受け入れることができません。 さらにサポートが必要な場合は、support@Satocity.io までお問い合わせください。',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_bitwin:
    'ゲームライセンスにより、あなたの国のプレイヤーを受け入れることができません。 さらにサポートが必要な場合は、support@Bitwin.club までお問い合わせください。',

  refer_description_coco:
    '友達を coco.game のアフィリエイト プログラムに紹介し、結果に関係なく、リンクを通じて登録してプレイするすべてのユーザーに対してコミッションを獲得します',
  refer_description_pi:
    '友達を pi.game のアフィリエイト プログラムに紹介し、結果に関係なく、リンクを通じて登録してプレイするすべてのユーザーに対してコミッションを獲得します',
  refer_description_ohplay:
    '友達を ohplay.club のアフィリエイト プログラムに紹介し、結果に関係なく、リンクを通じて登録してプレイするすべてのユーザーに対してコミッションを獲得します',
  refer_description_satoshity:
    '友達を satoshity.io のアフィリエイト プログラムに紹介し、結果に関係なく、リンクを通じて登録してプレイするすべてのユーザーに対してコミッションを獲得します',
  refer_description_betgecko:
    '友達を betgecko.io のアフィリエイト プログラムに紹介し、結果に関係なく、リンクを通じて登録してプレイするすべてのユーザーに対してコミッションを獲得します',
  refer_description_bitwin:
    '友達を bitwin.club のアフィリエイト プログラムに紹介し、結果に関係なく、リンクを通じて登録してプレイするすべてのユーザーに対してコミッションを獲得します',
  your_email_verified_coco:
    'あなたのメールアドレスが確認されました。 coco.game を探索してみましょう。',
  your_email_verified_pi:
    'あなたのメールアドレスが確認されました。 pi.game を探索してみましょう。',
  your_email_verified_ohplay:
    'あなたのメールアドレスが確認されました。 ohplay.club を探索してみましょう。',
  your_email_verified_satoshity:
    'あなたのメールアドレスが確認されました。 satoshity.io を探索してみましょう。',
  your_email_verified_betgecko:
    'あなたのメールアドレスが確認されました。 betgecko.io を探索してみましょう。',
  your_email_verified_bitwin:
    'あなたのメールアドレスが確認されました。 bitwin.club を探索してみましょう。',
  GAME: 'ゲーム',
  AFFILIATE: 'アフィリエイト',
  ACCOUNT: 'アカウント',
  BONUS: 'ボーナス',
  INVEST: '投資する',
  SWAP: 'スワップ',
};

export default ja;
