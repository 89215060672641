// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const tr = {
  demo: {
    title: `Turkey`,
  },
  blog: `Blog`,
  chat: `sohbet`,
  only_admin_can_see_this_item: `Bu öğeyi yalnızca yönetici görebilir`,
  fairness: 'Adalet',
  blog_academy: 'Blog / Akademi',
  live_support: 'Canlı destek',
  search_your_game: 'Oyununu ara',
  random: 'Rastgele',
  sign_in: 'Kayıt olmak',
  sign_up: 'Üye olmak',
  wallet: 'Cüzdan',
  deposit: 'Depozito',
  withdraw: 'Geri çekilmek',
  swap: 'Takas',
  crypto: 'Crypto',
  fiat: 'Fiat',
  nft: 'NFT',
  search_by_name: 'İsme göre ara',
  transactions: 'İşlemler',
  // Deposit
  overview: 'Genel Bakış',
  deposit_currency: 'Mevduat Para Birimi',
  choose_network: 'Ağ seçin',
  deposit_address: 'Para Yatırma Adresi',
  notice: 'Fark etme:',
  notice_content:
    'Bu para yatırma adresine yalnızca /para birimi/ gönderin. Akıllı sözleşme adresleri desteklenmiyor (Bize ulaşın)',
  balance: 'Denge',
  copy: 'Kopyala',
  withdraw_amount: 'Para Çekme Tutarı',
  withdraw_address: 'Adresi geri çek',
  deposit_address_placeholder: 'Belirli para birimine göre dikkatlice doldurun',
  fee: 'Ücret',
  receive_amount: 'Tutarı al',
  confirm: 'onaylamak',
  balance_in_wallet: 'Cüzdandaki bakiye',
  withdraw_fee: 'Para Çekme Ücreti',
  from: 'İtibaren',
  to: 'İle',
  bill: 'Fatura',
  view_history_in_etherscan: `Pi.game Blockexplorer'da işlem geçmişini görüntüleyin`,
  time: 'Zaman',
  amount: 'Miktar',
  status: 'Durum',
  detail: 'Detay',
  view_in_fiat: 'USD cinsinden görüntüle',
  common_assets: 'Ortak Varlıklar',
  order_id: 'Sipariş Kimliği',
  currency: 'Para birimi',
  quantity: 'Miktar',
  type: 'Tip',
  empty_data: 'Boş veriler',
  currency_not_available: 'Para birimi mevcut değil',
  all_feature: 'Tüm özellikler',
  success: 'Başarı',
  audit: 'Denetim',
  insufficient: 'Yetersiz',
  processing: 'İşleme',
  new: 'Yeni',
  profile_settings: 'Profil ayarları',
  general: 'genel',
  security: 'güvenlik',
  verify: 'doğrulamak',
  verified: 'doğrulandı',
  id: 'id',
  nickname: 'nickname',
  email: 'Email',
  phone_number: 'telefon numarası',
  change: 'Değiştirmek',
  not_use_symbol:
    'Özel simgeler kullanmayın, aksi takdirde hesabınız desteklenmeyebilir.',
  hide_profile: 'Profili gizle',
  registered_mail_cannot_changed: `Ohplay.club'in güvenlik politikası gereği kayıtlı e-posta adresi değiştirilemez.`,
  phone_second_login:
    'İkinci giriş yönteminiz olarak Telefon Numarasını ekleyin.',
  hide_profile_title: 'Oyun verilerinizi profilde gizleyin',
  hide_profile_content:
    'Gizli olsa bile avatarınız ve kullanıcı adınız sohbet odalarında her zaman görünür.',
  copied: 'Kopyalandı',
  enter_nickname: 'Takma adınızı buraya girin',
  enter_email: 'Buraya e-posta girin',
  enter_phone_number_: 'Telefon numarasını buraya girin',
  change_password: 'Şifre değiştir',
  current_password: 'Mevcut Şifre',
  new_password: 'Yeni Şifre',
  confirm_password: 'Yeni şifreyi onayla',
  existing_password_not_match: 'Mevcut şifre eşleşmiyor',
  password_not_match: 'Parola eşleşmedi',
  new_password_saved: 'Yeni şifre kaydedildi',
  relogin_after_change_pw:
    'Şifre değiştirildikten sonra tekrar giriş yapılması gerekecektir.',
  setup_google_auth: `Google Authenticator'ı kurun`,
  download_google_auth:
    'iPhone veya Android için Google Authenticator uygulamasını indirin',
  save_account_token:
    'Aşağıdaki Hesap Simgesini (Anahtar) güvenli bir yere kaydedin',
  account_token: 'Hesap Jetonu (Anahtar)',
  need_account_token:
    'Telefonunuzu kaybetmeniz durumunda hesabınıza erişebilmek için yukarıdaki Hesap Simgenize (Anahtar) ihtiyacınız olacaktır.',
  scan_qr_title:
    'Google Authenticator uygulamasıyla aşağıdaki QR Kodunu tarayın',
  enter_token_below: `Jeton'u aşağıya girin`,
  two_FA_code: '2FA Kodu *',
  appear_on_google_auth: 'Google Authenticator uygulamanızda görünün',
  verify_code_incorrect: 'Doğrulama kodu hatalı',
  enable_2fa: `2FA'yı etkinleştir`,
  enter_current_pw: 'Güncel şifrenizi giriniz',
  enter_new_password: 'Yeni şifreyi girin',
  enter_repeat_password: 'Yeni şifreyi tekrar girin',
  enter_2fa_code_to_disable:
    'İki faktörlü kimlik doğrulamayı devre dışı bırakmak için 2FA kodunuzu girin.',
  update_nickname_failed: 'Takma ad güncellenemedi, lütfen tekrar deneyin',
  disable_2fa: `2FA'yı devre dışı bırak`,
  verify_email: `E-mail'i doğrula`,
  send_information_to_email: 'Kayıtlı e-posta adresinize bilgi göndereceğiz.',
  cancel: 'İptal etmek',
  verify_phone_number: 'Telefon Numarasını Doğrulayın',
  resend_code: 'Yeniden gönderme kodu',
  did_not_receive_code: 'Kodu almadınız mı?',
  validate_nickname_length: 'Takma ad 4 ile 13 karakter arasında olmalıdır.',
  nickname_existing: 'Takma ad zaten var.',
  new_nick_name_saved: 'Yeni takma ad kaydedildi',
  new_email_saved: 'Yeni e-posta kaydedildi',
  new_phone_number_save: 'Yeni telefon numarası kaydedildi',
  invalid_phone_number: 'Geçersiz telefon numarası',
  validate_invalid_email_address: 'Geçerli bir e',
  view: 'Görüş',
  choose_asset: 'Varlıkları seçin',
  min: 'Min.',
  max: 'MAKS',
  max2: 'Maksimum',
  all_currency: 'Tüm Para Birimleri',
  to_secure_assets: 'Varlıklarınızı güvence altına almak için lütfen',
  bet_id: 'Oynatma Kimliği',
  bet_amount: 'Oynatma miktarı',
  payout: 'Ödeme',
  profit_amount: 'Kâr',
  hash: 'Doğramak',
  show_more: 'Daha fazla göster',
  show_less: 'Daha az göster',
  welcome_aboard: 'Gemiye Hoşgeldiniz',
  first_deposit_bonus: 'İlk para yatırma bonusu',
  rewards: 'Ödüller',
  deposit_now: 'Şimdi yatır',
  just_for_you: 'Sadece senin için',
  top_games: 'En iyi oyunlar',
  recently_played: 'Son oynatılan',
  recommended: 'Tavsiye edilen',
  favourites: 'Favoriler',
  or_log_in_with: 'Veya şununla giriş yapın:',
  dont_have_account: 'Hesabınız yok mu?',
  forgot_your_password: 'Parolanızı mı unuttunuz?',
  password: 'Şifre',
  terms_of_service: 'kullanım Şartları',
  confirm_accessing_site:
    'Siteye erişerek 18 yaşında olduğumu ve bu metni okuduğumu onaylıyorum.',
  include_lower_uppercase: 'Küçük ve büyük harf ekle',
  at_least_1_number: 'En az 1 sayı',
  minimum_6_char: 'en az 6 karakter',
  agree_terms_service: 'katılıyorum ve anlıyorum',
  terms_conditions: 'şartlar ve koşullar',
  already_have_account: 'Zaten hesabınız var mı?',
  enter_password_here: 'Giriş şifresi...',
  reset_password: 'Şifreyi yenile',
  assets_portfolio: 'Varlık Portföyü',
  hide_small: 'Küçükleri gizle',
  menu: 'Menü',
  game_hub: 'Oyun Merkezi',
  notification: 'Bildiri',
  chat_room: 'Sohbet odası',
  login_fail: 'Giriş başarısız. Lütfen tekrar deneyin',
  error_message: {
    server_error: 'Server hatası. Lütfen birkaç dakika içinde tekrar deneyin',
  },
  dark: 'Karanlık',
  light: 'Işık',
  casino_home: 'Kumarhane Ana Sayfası',
  promotions: 'Promosyonlar',
  refer_friend: 'Bir arkadaşınıza tavsiye edin',
  support_legal: 'Destek / Yasal',
  help_center: 'Yardım Merkezi',
  faq: 'FAQ',
  privacy_policy: 'Gizlilik Politikası',
  aml: 'AML',
  res_gambling: 'Sorumlu Kumar',
  provably_fair: 'Kesinlikle Adil',
  registration_login: 'Kayıt ve Giriş',
  about_us: 'Hakkımızda',
  news: 'Haberler',
  business_contact: 'İş bağlantısı',
  work_with_us: 'Bizimle çalış',
  coin_accuracy_limit: 'Madeni Para Doğruluk Sınırı',
  support: 'Destek',
  verify_representative: 'Temsilciyi Doğrula',
  crypto_quest: 'Kripto Görevi',
  video_poker: 'Video Poker',
  classic_dice: 'Klasik Zar',
  join_our_community: 'Topluluğumuza Katılın',
  go_to_top: 'Başa gitmek',
  game: 'Oyun',
  game_info: 'Oyun bilgisi',
  description: 'Tanım',
  reviews: 'Yorumlar',
  latest_bet_race: 'En son bahisler ve Yarışlar',
  all_bets: 'Tüm taahhüt',
  my_bets: 'taahhütlerim',
  high_bets: 'Yüksek taahhütler',
  player: 'oyuncu',
  hidden: 'Gizlenmiş',
  about_this_game: 'Bu Oyun Hakkında',
  share_this_game: 'Bu Oyunu Paylaş',
  share_on_fb: `Facebook'ta Paylaş`,
  share_on_whatsapp: `WhatsApp'ta paylaş`,
  share_on_twitter: `X'te Paylaş (Twitter)`,
  share_on_discord: `Discord'da Paylaş`,
  share_on_linkedin: `LinkedIn'de payla`,
  share_on_skype: `Skype'ta paylaşın`,
  by: 'İle: ',
  release: 'Serbest bırakmak',
  return_to_player: 'RTP (Oyuncuya dönüş)',
  provider: 'Sağlayıcı',
  max_win: 'Maksimum kazanç',
  mobile: 'Mobil',
  stakes_range: 'Bahis aralığı',
  active_devices: 'Aktif cihazlar',
  device: 'Cihaz',
  location: 'Konum',
  ip_address: 'IP adresi',
  last_used: 'Son kullanılan',
  action: 'Aksiyon',
  play_now: 'Şimdi oyna',
  on: 'Açık',
  activities: 'Faaliyetler',
  high_rollers: 'Yüksek makaralar',
  jackpot_winners: 'İkramiye Kazananlar',
  follow_us_on: 'Bizi takip edin',
  INTERNAL_TRANSFER: 'Uçlu',
  send_recover_link_to_email:
    'Kurtarma şifresi bağlantısını içeren bir e-posta e-postanıza gönderilecektir.',
  request_reset_email: 'Talep gönderildi. Lütfen emailinizi kontrol edin',
  something_wrong: 'Bir şeyler yanlış gitti. Lütfen tekrar deneyin',
  vip_club: 'VIP kulübü',
  coco_vip_club: 'Ohplay VIP kulübü',
  jackpot: 'İkramiye',
  responsible_gambling: 'Sorumlu kumar',
  next: 'Sonraki',
  step: 'Adım',
  verification: 'Doğrulama',
  verify_setup: 'Kurulumu Doğrulayın',
  verify_setup_description1:
    'Size daha iyi hizmet verebilmek için kimlik bilgilerinizin orijinallerini sunmanızı rica ediyoruz. Bu, hesap kurtarma durumlarında hesabınızın güvenliğini sağlayacaktır. Ayrıca aldığınız hediyelerin veya gerçek ödüllerin doğru yere gönderilmesini sağlamaya da yardımcı olur.',
  verify_setup_description2:
    'Kabul edilebilir bir kimlik belgesi, resmi belgenizin bir fotoğrafını, onaylı kimlik kartınızı, ehliyetinizi ve pasaportunuzu içerir. Bu süreç tamamlandıktan sonra sizinle e-posta yoluyla iletişime geçeceğiz.',
  first_name: 'İlk adı',
  last_name: 'Soy isim',
  gender: 'Cinsiyet',
  date_of_birth: 'Doğum tarihi',
  address: 'Adres',
  city: 'Şehir',
  postal_code: 'Posta Kodu',
  country: 'Ülke',
  enter_your_first_name: 'İlk adınızı girin...',
  enter_your_last_name: 'Soyadınızı giriniz...',
  verify_government_id_description:
    'Lütfen devlet tarafından verilmiş geçerli bir kimlik belgesi veya ehliyet yükleyin. Tam adınızı, doğum tarihinizi, fotoğrafınızı ve belgenin son kullanma tarihini içermelidir.',
  id_card: 'Kimlik kartı',
  passport: 'Pasaport',
  front_side: 'Ön taraf',
  back_side: 'Arka Taraf',
  take_a_photo: 'Fotoğraf çekmek',
  upload_picture: 'Resmi yükle',
  information_page: 'Bilgi Sayfası',
  verify_portrait_description:
    'Lütfen kendi fotoğrafınızı yükleyin. Fotoğrafta pasaportunuzu veya kimliğinizi ve üzerinde "Ohplay.club", kullanıcı adınızı ve tarihi yazan bir kağıt parçası bulundurmanız gerekmektedir. Lütfen yüzünüzün ve tuttuğunuz bilgilerin net olduğundan emin olun.',
  your_photo: 'Senin resmin',
  references: 'Referanslar',
  all_information_submitted: 'Tüm bilgiler gönderildi',
  thank_you_for_cooperation:
    'İş birliğin için teşekkürler. İnceleme bittiğinde sizi bilgilendireceğiz.',
  done: 'Tamamlamak',
  this_field_is_required: 'Bu alan gereklidir',
  male: 'Erkek',
  female: 'Dişi',
  unspecified: 'belirtilmemiş',
  select_gender: 'Cinsiyet seç',
  back: 'Geri',
  step1_update_identification_success: '1. Adım: Kimliği başarıyla güncelleyin',
  step23_update_identification_photo_success:
    'Adım 2, 3: Kimlik fotoğraflarını başarıyla güncelleyin',
  invalid_date_format: 'Geçersiz tarih biçimi',
  update_avatar_success: 'Avatarı başarıyla güncelle',
  update_avatar_failed: 'Avatar güncellenemedi',
  game_bank_overview: 'Genel Bakış',
  game_bank_history: 'Tarih',
  game_bank_list: 'Liste',
  game_bank_withdraw: 'Geri çekilmek',
  confirm_password_must_match: 'Şifrenin eşleşmesi gerektiğini onaylayın',
  recover_password: 'Şifre kurtarma',
  logout_failed: 'Çıkış başarısız oldu, lütfen tekrar deneyin!',
  at_least_18_years_old: 'En az 18 yaşında olmanız gerekiyor',
  no_support: 'Destek yok',
  gems: 'Taşlar',
  sound: 'Ses',
  invalid_wallet_address: 'Geçersiz cüzdan adresi',
  target_multiplier: 'Target Multiplier',
  multiplier: 'Çarpanı',
  unexpected_error:
    'Beklenmeyen bir hata oluştu. Lütfen daha sonra tekrar deneyiniz',
  validate_nickname_message:
    'Takma ad 4 ile 13 karakter arasında olmalı, imzasız olmalı ve boşluk, sembol içermemelidir.',
  swap_fee: 'Takas ücreti',
  verify_account: 'Hesabı Doğrula',
  history: 'Tarih',
  big_winner: 'Büyük kazanan',
  jackpot_winner: 'Büyük ikramiye kazananı',
  my_bet: 'Benim oyunum',
  all_bet: 'Tüm taahhüt',
  winner: 'kazanan',
  insufficient_ballance: 'Yetersiz bakiye',
  gamebank: 'Gamebank',
  maximumFileSize: 'Maksimum dosya boyutu 4MB',
  verify_email_was_send:
    'E-postanın gönderildiğini doğrulayın, lütfen e-postanızı kontrol edin',
  error_code: {
    EMAIL_IN_USED: 'Bu e-posta başka bir kullanıcı tarafından kullanıldı.',
  },
  account_verified: 'Hesabınız doğrulandı',
  check_information_carefully:
    'Lütfen onaylamadan önce yatırım bilgilerinizi dikkatlice kontrol edin.',
  invest_now: 'Şimdi Yatırım Yapın',
  more_information: 'Daha fazla bilgi',
  host: 'Ev sahibi',
  loading: 'Yükleniyor...',
  you_must_specify_a_number: 'Bir sayı belirtmelisiniz',
  must_be_a_positive_value: 'Pozitif bir değer olmalı',
  withdraw_success: 'Başarıyı geri çek',
  withdraw_failed: 'Para çekme işlemi başarısız oldu',
  your_total_invest: 'Toplam Yatırımınız',
  your_current_shares: 'Mevcut paylaşımlarınız',
  your_balance: 'Bakiyeniz',
  your_profit: 'Senin kazancın',
  leverage: 'Kaldıraç',
  game_bank: 'Oyun bankası',
  game_bank_max: 'Oyun Bankası Maksimum',
  your_invest: 'Yatırımınız',
  available_invest_amount: 'Mevcut Yatırım Tutarı',
  your_share: 'Senin payın',
  host_share: 'Ana Bilgisayar Paylaşımı',
  invest_success: 'Başarıya yatırım yapın',
  invest_failed: 'Yatırım başarısız oldu',
  investor: 'Yatırımcı',
  share: 'Paylaşmak',
  error_message_server_error: 'error_message.server_error',
  successful_balance_change: 'Başarılı, denge değişikliği:',
  permission_denied: 'İzin reddedildi',
  you_not_permission_page: 'Bu sayfaya erişim izniniz yok',
  not_bad: 'FENA DEĞİL',
  nice: 'GÜZEL',
  awesome: 'MÜKEMMEL',
  wicked: 'KÖTÜ',
  godlike: 'TANRI GİBİ',
  to_the_moon: `AY'A`,
  you_win: 'SİZ KAZANDINIZ!',
  bum: 'BUMMM!',
  some_thing_went_wrong: 'Bir şeyler yanlış gitti',
  maximum_number: 'Seçilen maksimum hücre sayısı:',
  auto_select: 'OTOMATİK SEÇİM',
  number_of_spin: 'Dönüş sayısı',
  // Mini Coco
  advanced_auto: 'Gelişmiş otomatik',
  stop_if_anyone_win_jackpot: 'Birisi Jackpot kazanırsa dursun',
  stop_decrease_by: 'Bakiye azalırsa dur',
  stop_increase_by: 'Bakiye artarsa dur',
  stop_exceeds_by: 'Tek galibiyet şunu aşarsa dur',
  start_auto_spin: 'Otomatik Döndürmeyi Başlat',
  not_enough_balance: 'Yeterli bakiye yok',
  set_auto_bet: 'Otomatik Bahsi Ayarla',
  cancel_auto_bet: 'Otomatik Bahsi İptal Et',
  cashout_at: 'Nakit Çıkış Tarihi',
  win_amount: 'Kazanma Tutarı',
  increase: 'arttırmak',
  decrease: 'azaltmak',
  bet: 'Oynamak',
  waiting: 'Beklemek...',
  bet_next_round: 'BAHİS (sonraki tur)',
  cashout: 'Param bitti',
  win: 'KAZANÇ',
  lose: 'KAYBETMEK',
  increase_by: 'Artış oranı',
  reset: 'Sıfırla',
  manual: 'Manuel',
  auto: 'Oto',
  max_profit: 'Maksimum kar',
  max_bet: 'Maksimum bahis',
  stop_on_profit: 'Kârda Dur',
  number_of_bet: 'Oynatma sayısı',
  on_loss: 'Kayıp durumunda',
  on_lose: 'Kaybedildiğinde',
  on_win: 'Kazanıldığında',
  stop_on_lose: 'Kaybedince Dur',
  stop_on_win: 'Kazanınca Dur',
  live_stats: 'Canlı İstatistikler',
  total_bet_amount: 'Toplam Bahis Tutarı',
  total_profit: 'Toplam ödül',
  game_bank2: 'OYUN BANKASI',
  can_not_get_game_bank: 'Oyun bankası alamıyorum',
  trends: 'Trendler',
  play_real: 'Gerçek Oyna',
  play_with_fun: 'EĞLENCE ile oynayın',
  online: 'Çevrimiçi',
  welcome_bonus: 'HOŞGELDİNİZ BONUSU',
  up_to: 'KADAR',
  join_now: 'Şimdi Katıl',
  total: 'Toplam',
  invest: 'Yatırım',
  insufficient_balance: 'Yetersiz bakiye',
  please_switch_to_another_asset_to_continue_playing:
    'Oynamaya devam etmek için lütfen başka bir varlığa geçin.',
  insufficient_gamebank: 'Yetersiz bakiye',
  switch: 'Anahtar',
  not_found: 'Bulunamadı',
  no_results_found_for: 'için sonuç bulunamadı',
  checking_for_typos:
    'Yazım hatalarını kontrol etmeyi veya sözcüklerin tamamını kullanmayı deneyin.',
  please_enter_keywords: 'Lütfen anahtar kelimeleri girin',
  exit_fullscreen: 'Tam ekrandan çık',
  fullscreen: 'Tam ekran',
  settings: 'Ayarlar',
  currency_do_not_supported: 'Bu para birimi desteklenmiyor.',
  update_photo: 'Fotoğrafı güncelle',
  upload_photo: 'Fotoğraf yükle',
  remove_all: 'Hepsini kaldır',
  upload_files: 'Dosyaları yükle',
  drop_or_select_file: 'Dosyayı Bırakın veya Seçin',
  drop_files_here_or_click: 'Dosyaları buraya bırakın veya tıklayın',
  thorough_your_machine: 'Makineniz aracılığıyla',
  no_found_any_currency_can_swap:
    'Takas yapılabilecek herhangi bir para birimi bulunamadı',
  account_not_verified: 'Hesap doğrulanmadı',
  withdraw_request_was_sent: 'Para çekme talebi gönderildi, onay bekleniyor',
  available_amount_insufficient: 'Mevcut miktar yetersiz',
  need_help: 'Yardıma mı ihtiyacınız var?',
  coming_soon: 'coming-soon',
  introduction_to_coco: `Çok ödüllü bir kripto kumarhanesi. Oyuncu merkezli bir yaklaşımla Ohplay.club, dünya çapında milyonlarca kumarbazı memnun edebilmektedir. Ohplay.club'in önceliği topluluğuna yönelik olup, sonsuz ve sonsuz derecede eğlenceli bir kumar deneyimi sağlar.`,
  coming_soon2: 'Yakında gelecek...',
  under_development: 'Fonksiyon geliştirilme aşamasındadır',
  page_404: '404 Sayfa Bulunamadı | Ohplay.Oyun',
  page_not_found: 'Üzgünüz, sayfa bulunamadı!',
  check_your_spelling: `Üzgünüz aradığınız sayfayı bulamadık. Belki URL'yi yanlış yazdınız? Doğru yazıp yazmadığınızı kontrol ettiğinizden emin olun.`,
  go_to_home: 'Eve gitmek',
  remove_session_success: 'Oturum başarısını kaldır',
  remove_session_failed: 'Oturumu kaldırma işlemi başarısız oldu',
  in_use: 'Kullanımda',
  remove_device: 'Aygıtı kaldır',
  your_profile: 'Senin profil',
  is_hidden: 'gizli',
  is_public_now: 'şu anda halka açık',
  hide_profile_failed: 'Profili gizle başarısız oldu, lütfen tekrar deneyin',
  change_password_success:
    'Şifre başarısını değiştirin. Lütfen tekrar giriş yapın',
  upload_image_failed: 'Resim yüklenemedi',
  file_size_limit_10MB: 'jpg, png; Dosya boyutu sınırı 10MB',
  roll_over: 'Yuvarlanmak',
  win_chance: 'Kazanma Şansı',
  profit_on_next_tile: 'Sonraki Döşemeden Kar Edin',
  pick_a_tile_randomly: 'Rastgele Bir Döşeme Seçin',
  pay_table: 'Ödeme masası',
  game_rule: 'Oyun kuralı',
  number_of_bets: 'Oynatma sayısı',
  bet_failed: 'Oynatma başarısız oldu',
  stop_auto_bet: 'Otomatik oynatmayı durdur',
  currency_not_support: 'Para birimi desteklenmiyor',
  login_to_play: 'Oynamak için giriş yapın',
  yes: 'Evet',
  how_to_play: 'Nasıl oynanır',
  help: 'Yardım',
  start_bet_round_failed: 'Oyun turu başlatılamadı',
  cashout_failed: 'Para çekme işlemi başarısız oldu',
  auto_bet_round: 'Başarıyı taahhüt edin',
  play_now2: 'ŞİMDİ OYNA',
  under_min_bet: 'Minimum bahis altında',
  invalid_bet_params: 'geçersiz oyun parametreleri',
  game_not_available_now: 'Oyun şu anda kullanılamıyor',
  insufficient_amount: 'Yetersiz miktar',
  invalid_bet_state: 'geçersiz oynatma durumu',
  over_max_bet: 'Maksimum bahisin üzerinde',
  gamebank_not_available: 'Oyun bankası mevcut değil',
  win_rate: 'Kazanma oranı',
  welcome_to_Coco_game: `Ohplay.club'e hoş geldiniz`,
  invalid_email_format: 'Geçersiz e-posta formatı',
  resend_after: 'Şu tarihten sonra yeniden gönder:',
  second: 'ikinci',
  only_available_at_large_resolutions:
    'Yalnızca 1600 pikselden (xl) büyük çözünürlüklerde kullanılabilir',
  provably_fairs: 'Muhtemelen fuarlar',
  Fairness: 'Adalet',

  become_a_coco: 'Ohplay ol',
  club_member: 'Klüp üyesi',
  vip_club_subtitle1:
    'En uzun süre oynayan ve en yüksek bağlılığa sahip oyuncular, turnuvaya özel davetler alacak.',
  vip_club_subtitle2:
    'Bu, üyelerine birinci sınıf bir deneyim ve bonuslar sağlar.',
  your_vip_progress: 'VIP ilerlemeniz',
  your_vip_progress_tooltip:
    'Lütfen önce oturum açın, ardından VIP ilerlemenizi görün',
  total_wager: 'Toplam taahhüt',
  your_vip_progress_description: `Tüm taahhütler geçerli kur üzerinden USD'ye dönüştürülür`,
  how_does_it_work: 'O nasıl çalışır?',
  the_rewards: 'Ödüller',
  frequently_asked_questions: 'Sıkça Sorulan Sorular',
  vip_frequent_questions_subtitle:
    'Sorularınız varsa destek ekibiyle veya VIP yöneticinizle iletişime geçmenizi öneririz.',
  login: 'Giriş yapmak',
  to_see_your_vip_progress: 'VIP ilerlemenizi görmek için',
  wager_rate: '1,00$ oyna = 1XP',
  all_wagers_converted_to_usd: `Tüm bahisler güncel kur üzerinden USD'ye çevrilir`,
  vip_club_benefit: 'VIP Kulüp Avantajı',
  vip_club_details: 'VIP Kulüp Detayları',
  benefit_table: 'Fayda Tablosu',
  wager_contest: 'Bahis Yarışması',
  game_of_the_day: 'Günün Oyunu',
  play_to_earn: 'Bahis kazanmak için oynayın',
  time_remaining: 'Kalan süre',
  rank: 'Rütbe',
  wagered: 'Bahis yapıldı',
  prize: 'Ödül',
  wager_contest_information: 'Mücadele Bilgileri',
  rule: 'Kural',
  gamebank_chart: 'Oyun Bankası Tablosu',
  next_round: 'Sonraki tur',
  start_auto_bet: 'Otomatik Bahsi Başlat',
  understand_trend_chart: 'Trend Grafiğini Anlayın',
  round_has_been_bet: 'Bu turda bahis oynandı, sonraki turdan iptal edin',
  crash_server_disconnect: 'Sunucu bağlantısının kesilmesi...',
  coco_info: `Bu web sitesi, Curaçao yasalarına göre kayıtlı ve kurulmuş, 163631 kayıt numarasıyla, Zuikertuintjeweg Z/N (Zuikertuin Tower), Curaçao kayıtlı adresiyle bir şirket olan Orisun N.V.'nin sahibi ve işletmecisidir.`,
  crash_result: 'Kilitlenme Sonucu',
  wagers: 'Oynatmalar',
  reset_after: 'Şu tarihten sonra sıfırla:',
  daily_wagering_contest_rules: 'Günlük Oyun Yarışması Kuralları',
  the_daily_race: `Günlük Yarış yarışması her gün 00:00 UTC'den 23:59 UTC'ye kadar gerçekleştirilir.`,
  daily_contest_prize_pool: 'Günlük Yarışma ödül havuzu',
  straight_flush_with_jack: 'Jack ile aynı hizada',
  straight_flush: 'Düz Gömme',
  four_of_a_kind: 'Dörtlü',
  full_house: 'Dolu ev',
  flush: 'Yıkama',
  straight: 'Dümdüz',
  three_of_a_kind: 'Üç çeşit',
  tow_pair: 'İki çift',
  pair_of_jacks_or_better: 'Bir Çift Vale veya Daha İyisi',
  you_won: 'Kazandın',
  show_all: 'Hepsini Göster ↓',
  personal: 'Kişisel',
  no_notifications_at_the_moment:
    'Şu anda bildirim yok. Lütfen daha sonra tekrar kontrol edin.',
  mode: 'Mod',
  contrast: 'Zıtlık',
  direction: 'Yön',
  layout: 'Düzen',
  stretch: 'Uzatmak',
  presets: 'Ön ayarlar',
  status_success: 'durum başarısı',
  status_pending: 'Durum: Beklemede',
  swap_successfully: 'Başarıyla değiştir!',
  swap_failed: 'Değiştirme başarısız oldu, lütfen daha sonra deneyin',
  coco_member: `Ohplay'ya üye olun`,
  play_your_favorite_games: 'En sevdiğiniz oyunları oynayın',
  instantly_sign_up:
    'Hemen üye olun ve ister oyundan ister spor bahis sitesinden oynamaya başlayın.',
  unlock_further_benefits:
    'Oynamanın keyfini çıkarın, yeni seviyelere ulaşın ve diğer avantajların kilidini açın',
  rank_claim_bonus: 'Sıralamanızı artırın ve bonus talep edin',
  go_up_to_the_next_rank:
    'Bir sonraki Sıralamaya geçin! Her seviyenin kendine has bonusu ve daha fazla fırsatı var!',
  you_start_your_gaming: 'Oyununuza 1. Sıradan - YENİ GELENLER - başlarsınız.',
  the_more_you_play: `Ne kadar çok oynarsanız Rütbeniz o kadar yüksek olur. Ohplay.club'de oynarken oynanan bahis miktarına göre artar. Tüm bahisler güncel kur üzerinden USD'ye çevrilir`,
  mark_all_as_read: 'Tümünü okundu olarak işaretle',
  eligible_VIP_member: 'VIP üye olmaya uygun muyum?',
  how_much_has_coco_given_out_in_bonuses: 'Ohplay ne kadar ikramiye dağıttı?',
  why_is_Coco_VIP_program_the_best: `Ohplay'nun VIP programı neden en iyisi?`,
  what_does_affect_the_weekly_monthly: 'Haftalık/aylık bonusu neler etkiler?',
  information_to_become_a_VIP_member:
    'VIP üye olmak için herhangi bir ek bilgi vermem gerekecek mi?',
  coco_VIP_telegram_channel: 'Ohplay VIP Telegram kanalını nerede bulabilirim?',
  one_min: '1 dakika',
  five_min: '5 dakika',
  one_hour: '1 saat',
  for_hours: '4 saat',
  one_day: '1 gün',
  investment: 'Yatırım',
  my_investment: 'Yatırımım',
  what_upp: 'Naber???',
  hide: 'Saklamak',
  play: 'Oynamak',
  stake_and_earn: 'Oynayın ve kazanın',
  //
  vip_level_comparison: 'VIP Seviyesi Karşılaştırması',
  bronze: 'Bronz',
  silver: 'Gümüş',
  gold: 'Altın',
  platinum: 'Platin',
  diamond: 'Elmas',
  level_up_bonus: 'Seviye Atlama Bonusu',
  surprise_box: 'Sürpriz Kutusu',
  free_spin_wheel_enable: 'Serbest Döndürme Tekerleğini Etkinleştir',
  surprise_box_upgraded: 'Sürpriz Kutusu Yükseltildi',
  weekly_bonus: 'Haftalık Bonus',
  chat_enable: 'Sohbeti Etkinleştir',
  upload_avatar_enable: 'Avatar Yükle Etkinleştir',
  vip_support_enable: 'VIP Desteği Etkinleştir',
  surprise_box_upgraded_2: `Sürpriz Kutu Yükseltildi (1,8 milyon USDT'ye kadar)`,
  where_coco_vip_telegram_channel:
    'Ohplay VIP Telegram kanalını nerede bulabilirim?',
  we_have_given_over_1_billion:
    '1 milyar doların üzerinde ikramiye verdik. Bu öncelikle çevrimiçi olarak en iyi VIP programına sahip olmamızın nedenidir. Oyuncularımızı oynanışları ve sadakatleri için ödüllendirmeye gerçekten inanıyoruz.',
  me: 'Ben',
  result: 'Sonuç',
  crush_of_crash: `Crash'in Ezilmesi`,
  // Banner new
  earn_real_money: 'Gerçek para kazanın',
  the_daily_game: 'Günlük oyun',
  earn_everyday_with_original_games: 'Orijinal oyunlarla her gün kazanın',
  daily_race: 'GÜNLÜK YARIŞ',
  wager: 'Oyna',
  and: 'VE',
  everyday: 'HER GÜN',
  crush: 'EZMEK',
  the_crash: 'KAZA',
  show_off_your_prediction_skills: 'Tahmin becerilerinizi gösterin',
  deposit_now2: 'ŞİMDİ YATIR',
  on_1st_deposit: '1. Para Yatırmada',
  // Just for you
  free_play: 'ÜCRETSİZ OYNA',
  with_fun_coin: 'EĞLENCELİ Coin ile',
  show_me: 'BANA GÖSTER',
  low_risk_high_rewards: 'Düşük Risk, Yüksek Ödüller',
  prize_100000: 'ÖDÜL 100.000$',
  win_1000x_jackpot: `1000x Jackpot'u kazanın`,
  // Notification
  balance_change: 'Bakiye değişikliği',
  your_casino: 'Oyun merkeziniz',
  // Referral
  spread_the_word_and: 'Haberi yayalım ve',
  earn_reward: 'Ödül kazanın',
  campaign_name: 'Kampanya ismi',
  id_number: 'Kimlik Numarası',
  registered: 'Kayıtlı',
  total_deposits: 'Toplam Mevduat',
  last_deposit: 'Son para yatırma',
  vip_point: 'VIP noktası',
  commission: 'komisyon',
  referral: 'Referans',
  register_date: 'kayıt Tarihi',
  display_name: 'Ekran adı',
  agents: 'Temsilciler',
  transaction_date: 'Transaction Date',
  transaction_type: 'işlem tipi',
  export: 'İhracat',
  campaigns: 'Kampanyalar',
  create_a_new_campaign: 'Yeni bir kampanya oluştur',
  claim_income: 'Gelir talebinde bulunun',
  per_players: 'Oyuncu Başına',
  per_agents: 'Temsilci Başına',
  per_day: 'Günlük',
  per_week: 'Haftada',
  per_month: 'Her ay',
  per_country: 'Ülke Başına',
  date: 'Tarih',
  total_income: 'Toplam ödül',
  total_user_register: 'Toplam Kullanıcı Kaydı',
  total_user_bet: 'Toplam Kullanıcı Oynatması',
  total_transaction: 'Toplam İşlem',
  refer_description:
    "Arkadaşlarınızı Ohplay.club'in Ortaklık Programına yönlendirin ve sonuçları ne olursa olsun, bağlantılarınız aracılığıyla kaydolan ve oynayan her kullanıcı için komisyon kazanın",
  download_banner: `Banner'ı İndir`,
  search: 'Aramak',
  clicks: 'Tıklamalar',
  referrals: 'Tavsiyeler',
  deposit_users: 'Mevduat Kullanıcıları',
  total_commission: 'Toplam Komisyon',
  commission_rate: 'Komisyon oranı',
  features: 'Özellikler',
  link: 'Bağlantı',
  code: 'Kod',
  today: 'Bugün',
  this_week: 'Bu hafta',
  last_week: 'Geçen hafta',
  overall: 'Etraflı',
  commission_available: 'Komisyon mevcut',
  claim: 'İddia',
  log_in: 'Giriş yapmak',
  to_see_your_rank: 'rütbenizi görmek için',
  // Crash
  start_in: 'Başla ...',
  reconnect: 'Yeniden bağlan',
  controls: 'kontroller',
  leaderboard: 'Liderler Sıralaması',
  bet_next: 'Sonrakini çal',
  betting: 'oynamak',
  // Tip
  message_is_required: 'Mesaj gerekli',
  tip_with_any_currency_in_CoCo: `Ohplay'da herhangi bir para birimiyle bahşiş`,
  receiver_id: 'Alıcı Kimliği',
  enter_user_id: 'Kullanıcı kimliğini girin',
  transaction_fee: 'İşlem ücreti',
  message: 'İleti',
  give_your_friends_some_messages_here:
    'Arkadaşlarınıza buradan bazı mesajlar verin...',
  continue: 'Devam etmek',
  successfully_tipped: 'Başarıyla bahşiş verildi',
  you_are_tipping_to: 'Bahşiş veriyorsun',
  your_tip_request_has_been_confirmed: 'Bahşiş talebiniz onaylandı.',
  you_can_track_the_progress_on_the_wallet_transaction_history: `İlerlemeyi Cüzdan İşlem Geçmişi'nden takip edebilirsiniz.`,
  view_history: 'Geçmişi Görüntüle',
  tip_failed: 'İpucu Başarısız',
  your_tip_request_was_unsuccessful_due_to_an_abnormality:
    'Bir anormallik nedeniyle bahşiş talebiniz başarısız oldu.',
  please_try_again_later: 'Lütfen daha sonra tekrar deneyiniz.',
  ok_i_got_it: 'Tamam anladım',
  tip_information: 'İpucu Bilgileri',
  password_is_required: 'Şifre gereklidir',
  tip_verification: 'İpucu Doğrulaması',
  authentication_secure: 'Kimlik Doğrulama Güvenliği',
  to_process_tipping_please: 'Bahşiş işlemini gerçekleştirmek için lütfen',
  first_to_secure_your_assets:
    'varlıklarınızı güvence altına almak için ilk önce',
  step_verification: '2 Adımlı Doğrulama',
  enter_your_login_password: 'Giriş Şifrenizi girin',
  enter_the_2fa_code_appear_on_your_google_authenticator_app:
    'Google Authenticator Uygulamanızda görünen 2FA Kodunu girin',
  authorize: 'Yetki vermek',
  // 30/09
  sign_up_in_and_earn: 'Kaydol/Giriş Yap ve Kazan',
  learn_more_about_our_affiliate_program:
    'Ortaklık programımız hakkında daha fazla bilgi edinin',
  referral_link: 'Yönlendirme Bağlantısı',
  create: 'Yaratmak',
  referral_users: 'Yönlendiren Kullanıcılar',
  registered_date: 'Kayıtlı Tarih',
  tip: 'Tip',
  send_tip: 'Bahşiş gönder',
  delete: 'Silmek',
  accept: 'Kabul etmek',
  close: 'Kapalı',
  member_downline: 'Üye alt ekibi',
  new_player: 'Yeni oyuncu',
  claim_commission_failed: 'Talep Komisyonu Başarısız Oldu',
  claim_commission_successfully: 'Talep Komisyonu başarıyla',
  create_campaign_successfully: 'Kampanyayı başarıyla oluştur',
  create_campaign_failed: 'Kampanya Oluşturulamadı',
  any_date: 'Herhangi bir tarih',
  // Mini CoCo
  stop_if_single_win_exceeds_by: 'Tek galibiyet şunu aşarsa dur',
  // Host
  show_filter: 'Filtreyi göster',
  filter: 'Filtre',
  default_campaign: 'Varsayılan Kampanyan',
  select_all: 'Hepsini seç',
  role: 'rol',
  hello_world: 'Selam Dünya',
  // GameBank => Overview
  gb_capacity: 'GB Kapasitesi',
  game_bank_capacity: 'Oyun Bankası Kapasitesi',
  the_maximum_amount_that_you_can_invest:
    'Yatırım yapabileceğiniz maksimum tutar.',
  // Crash
  finish_bet: 'Bahsi Bitir',
  finishing_bet: 'Bitirme Bahsi',
  // Referral
  delete_invitation: 'Daveti Sil',
  you_had_been_invited_to_become_an_agent_by:
    'tarafından temsilci olmaya davet edildiniz.',
  confirming_to_delete_this_invitation:
    'Bu davetin silinmesini onayladığınızda bu işlem geri alınmayacak. Hala karar vermek istiyor musun?',
  successfully_accepted_invitation: 'Daveti başarıyla kabul ettiniz',
  the_house: 'Ev',
  invited_you_to_become_an_agent: 'seni temsilci olmaya davet ettim',
  campaign_name_1: 'Kampanya Adı 1',
  available_commission: 'Mevcut Komisyon',
  there_are_no_commission: 'Komisyon yok',
  please: 'Lütfen',
  login2: 'giriş yapmak',
  to_see_your_rewards: 'ödüllerinizi görmek için',
  you_have_successfully_accepted_the_invitation:
    'Daveti başarıyla kabul ettiniz',
  //
  downline_report: 'Alt Ekip Raporu',
  referral_faq_subscription:
    'Ortaklık programı hakkında daha fazla bilgi için lütfen iletişime geçin ',
  internet_connection_restored: 'İnternet bağlantısı yeniden sağlandı',
  lost_connect_to_network: 'Ağ bağlantısı kesildi',
  // 10/10
  user_profile: 'Kullanıcı profili',
  leave_a_tip: 'Bir ipucu bırakın',
  your_commission_rate: 'Komisyon oranınız',
  affiliate_terms_conditions: 'Ortaklık Hüküm ve Koşulları',
  REFERRAL_CODE_OR_NAME_EXISTS:
    'Kampanya adı veya yönlendirme kodu zaten mevcut',
  swap_unavailable:
    'Takas çifti şu anda desteklenmiyor, lütfen daha sonra tekrar deneyin',
  please_enter_swap_amount: 'Lütfen takas tutarını giriniz',
  become_our_agent_Contact_business:
    'Temsilcimiz olur musunuz? İşletmeyle iletişime geçin:',
  earn_commission_rewards:
    'Arkadaşlarınız oyunlarımıza bahis oynadığında komisyon ödülleri kazanın',
  turn_your_friends_and_followers_into_weekly_commissions:
    "Ohplay'nun ortaklık programıyla arkadaşlarınızı ve takipçilerinizi haftalık komisyonlara dönüştürün. Basit, karlı ve tamamen şeffaftır",
  // FAQ
  why_should_i_be_a_Coco_affiliate: `Neden Ohplay Affiliate'i olmalıyım?`,
  adding_our_link_and_or_banner_to_your_website: `Biraz trafiğe sahip bir web siteniz veya sosyal kanallarınız varsa neden potansiyelini en üst düzeye çıkarmayasınız? Bağlantımızı ve/veya banner'ımızı web sitenize ekleyerek anında ek gelir elde etmenin yeni ve etkili bir yolunu yaratabilirsiniz.`,
  how_do_I_get_paid_for_being_an_affiliate:
    'Satış ortağı olduğum için nasıl ödeme alacağım?',
  the_banner_or_link_you_create_contains_your_personal_tracking_code: `Oluşturduğunuz banner veya bağlantı kişisel takip kodunuzu içerir. Oyuncular Ohplay'yu ziyaret ettiğinde bu kod bilgisayarlarındaki bir çerezde saklanır. Kullanıcı kaydolduğunda, onları sizin tarafınızdan yönlendirilen kişiler olarak tanımlarız. Bu oyuncu sizinle kalıcı olarak ilişkilendirilir ve siz de onun faaliyetlerinden ömür boyu komisyon kazanmaya devam edersiniz.`,
  how_a_new_player_linked_to_me:
    'Yeni bir oyuncu benimle nasıl bağlantı kurabilir?',
  how_exactly_is_my_affiliate_commission_calculated:
    'Satış ortaklığı komisyonum tam olarak nasıl hesaplanıyor?',
  the_commission_for_each_bet: `Her bahis için komisyon aşağıdaki formüle göre hesaplanır: Komisyon = Bahis * oyunun kasa avantajı * komisyon oranı Burada:
      - Toplam bahis: Ortak tarafından yönlendirilen tüm kullanıcılar tarafından oluşturulan toplam oyun bahis tutarı.
      - Kasa avantajı: Ortalama kaybın ilk bahise oranı. Her oyunun kasa avantajı 1 eksi RTP (Oyuncuya Dönüş) olarak tanımlanır.
      - Komisyon oranı: Oran, kullanıcıların ortaklık rolüne bağlıdır ve Ortaklık sitesinde görüntülenir.`,
  how_to_join_the_affiliate_program: 'Ortaklık programına nasıl katılabilirim?',
  if_you_already_have_a_Coco_account: `Zaten bir Ohplay hesabınız varsa aşağıdaki adımları uygulamanız yeterlidir:
      1. Ortaklık sayfasını ziyaret edin.
      2. Benzersiz yönlendirme kodunuzu kullanarak yeni bir kampanya oluşturun.
      3. Yönlendirme bağlantınızı web sitenizde, sosyal medyada veya yüksek trafikli platformlarda paylaşın.
      4. Yönlendirdiğiniz kullanıcılardan haftalık komisyonunuzu alın.`,
  how_can_i_keep_track_of_my_performance:
    'Performansımı nasıl takip edebilirim?',
  once_your_affiliate_link_is_generated:
    'Satış ortaklığı bağlantınız oluşturulduktan sonra satış ortaklığı kontrol paneline giriş yapabilirsiniz. Burada tüm istatistiklerinizi görüntüleyebilir ve raporlardaki bilgileri çeşitli faktörlere göre filtreleyebilirsiniz.',
  at_the_start_of_each_new_week:
    'Her yeni haftanın başında komisyonunuz hesaplanır ve COD tokenleri şeklinde talep edilebilir hale getirilir. Kazançlarınızı kolaylıkla hesap cüzdanınıza çekebilirsiniz.',
  i_have_big_audience_how_i_can_get_special_deals:
    'Büyük bir kitlem var, nasıl özel fırsatlar elde edebilirim?',
  if_you_have_a_website_with_substantial_traffic:
    'Yoğun trafiğe sahip bir web siteniz veya geniş kitleye sahip bir sosyal medya hesabınız varsa özel fırsatlar için bize business@Ohplay.club adresinden ulaşabilirsiniz.',
  // Crash
  maximum_is_9900: `Maksimum "9900"`,
  minium_is_101: `Minimum "1,01"`,
  insufficient_currency: 'Yetersiz Para Birimi',
  this_game_does_not_support_this_currency:
    'Bu oyun bu para birimini desteklemiyor, oynamaya devam etmek için lütfen başka bir varlığa geçin.',
  unknown: 'Bilinmeyen',
  you_should_verify_account_first_before_tipping_your_friends:
    'Önce hesabı doğrulamanız gerekir \n arkadaşlarına bahşiş vermeden önce',
  must_be_only_digits: 'Yalnızca rakam olmalı',
  min_length_ID_at_least_14_number: 'Minimum uzunluk kimliği en az 14 sayı',
  receiver_is_required: 'Alıcı gerekli',
  tip_amount_must_specify_a_number: 'Bahşiş Tutarı bir sayı belirtmelidir',
  tip_amount_must_greater_than_0: `Bahşiş Tutarı 0'dan büyük olmalıdır`,
  tip_amount_is_required: 'Bahşiş Tutarı gerekli',
  how_to_see_other_ID: 'Diğer kimliği nasıl görebilirim?',
  get_your_friends_ID_from_their_profiles:
    'Arkadaşlarınızın kimliğini profillerinden alın.',
  tips_amount: 'Bahşiş Tutarı',
  total_spending_amount: 'Toplam harcama tutarı',
  withdrawing_your_balance:
    'Bakiyenizi çekmeden önce hesabınızı doğrulamalısınız',
  pls_enter_code_send_to_tel: 'lütfen kodu girin telefona gönderin',
  please_enter_withdraw_address: 'Lütfen para çekme adresini girin',
  your_profile_had_been_verified: 'Profiliniz doğrulandı',
  SWAP_UNAVAILABLE: 'Yetersiz bakiye',
  // Maintain
  this_page_is_under_maintenance: 'ÜZGÜNÜM! Bu sayfa bakımdadır',
  please_give_us_some_minutes_to_fix_the_issue:
    'Lütfen sorunu çözmemiz için bize birkaç dakika verin',
  // 404
  we_cant_find_that_page: `Hata! O sayfayı bulamıyoruz.`,
  you_can_still_enjoy_the_best_casino_experience_at_Coco: `Endişelenmeyin, yine de Ohplay.club'de EN İYİ casino deneyiminin keyfini çıkarabilirsiniz.`,
  //
  region_prohibited: 'Bölge Yasak',
  coco_game_is_not_available_in_your_region:
    'Üzgünüz, Ohplay.club bölgenizde mevcut değil.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country:
    'Oyun lisansımız nedeniyle ülkenizden oyuncu kabul edemiyoruz. Daha fazla yardıma ihtiyacınız olursa support@Ohplay.club aracılığıyla bizimle iletişime geçin.',
  congrats: 'tebrikler',
  won_the_most: 'en çok kazandı!',
  coco_took_it_all: 'Ohplay hepsini aldı!',
  page: 'Sayfa',
  refresh: 'Yenile',
  // Popup
  register_to_get: 'Almak için kaydolun',
  player_to_earn_the: 'Kazanmak için oyuncu',
  $10_prize: '10$ ödül',
  sign_up_now: 'Şimdi kayıt ol',
  // Popup 2
  you_receive: 'ALDIĞIN',
  weekly: 'haftalık',
  see_detail: 'ayrıntıları gör',
  swap_amount_need_to_larger_than_min:
    'Takas tutarının minimumdan büyük olması gerekiyor',
  crypto_play: 'Kripto Oyunu',
  fun_play: 'EĞLENCELİ Oyun',
  // Popup 3
  play_with: 'İLE OYNA',
  earn: 'KAZANMAK',
  real: 'GERÇEK',
  dollars: 'DOLAR',
  big_wins: 'Büyük Kazanımlar',
  what_is_gift_code: 'Hediye kodu nedir?',
  gift_code_info:
    'Hediye kodu, harf ve rakamlardan oluşan özel bir kod gibidir. Bu kodu kullandığınızda, şu adresten özel ödüller alırsınız:m',
  gift_code_cod:
    'Her hediye kodunun kendi değeri ve süresi vardır. Tüm ödüller COD jetonları şeklinde ödenir',
  there_are_various_ways_to_get_Coco_gift_codes:
    'Özel sosyal medya etkinliklerine, topluluk etkinliklerine, bonuslara veya özel görevlere katılmak gibi Ohplay hediye kodları almanın çeşitli yolları vardır.',
  how_to_get_Coco_gift_code: 'Ohplay hediye kodu nasıl alınır?',
  how_to_use_Coco_gift_code: 'Ohplay hediye kodu nasıl kullanılır?',
  simply_enter_the_gift_code:
    'Hediye kodunu girmeniz yeterlidir; ödüller otomatik olarak bakiyenize eklenecektir.',
  giftcode_required: 'Hediye kodu gerekli',
  bonus_code_claimed_successfully: 'Bonus kodu başarıyla talep edildi',
  claim_your_free_reward: 'Ücretsiz ödülünüzü alın',
  enter_your_giftcode: 'Hediye kodunuzu girin',
  claim_bonus: 'Bonus Talep Et',
  what_is_giftcode: 'Hediye Kodu Nedir?',
  what_is_COCO_giftcode: 'Ohplay Hediye Kodu nedir?',
  got_it: 'Anladım',
  won: 'kazandı',
  to_coco_vip_club: "Ohplay VIP Club'a",
  welcome_vip_club_text:
    'En uzun süre oynayan ve en yüksek bahis yapan oyuncular, VIP kulübüne özel davetler alacak. Üyelerimize premium bir deneyim ve bonuslar sağlıyoruz.',
  welcome_to_only: 'Bem vindo ao',
  welcome_only: 'Bem vindo ',
  to_join_club: 'VIP Kulübümüze katılmak için',
  sign_in_or_sign_up: 'Giriş yap Kayıt Ol',
  how_vip_club_work: 'VIP Kulübü nasıl çalışır?',
  ranking_system_title: 'Ohplay VIP Ranking System',
  ranking_system_text:
    'Platform sıralama sistemimize katılarak fırsatlar ve tanınma dünyasının kilidini açın! Profilinizi yükseltmek, oyunlarımızı oynamak ve öne çıkan bir katılımcı olmanın avantajlarından yararlanmak için bugün bize katılın. Tanınma şansını kaçırmayın; şimdi başarı hikayesinin bir parçası olun!',
  start_your_referrals: 'Yönlendirmelerinizi başlatın',
  // 8/12
  use_your_COD_gift_to_bet_and_win_at_Cocogame: `Coco.game'de bahis yapmak ve kazanmak için Coco hediyenizi kullanın`,
  gift_code: 'Hediye kodu',
  // 15/12
  mini_Coco_is_Allways_3x3_Slot_machine_game:
    'Mini Ohplay 27 çizgili 3x3 Slot makinesi oyunudur.',
  rule_content: `Kurallar: semboller ilk sütundan başlayarak soldan sağa doğru sırayla başlar. Ödeme yalnızca bir satırdaki en yüksek değeri hesaplar.`,
  jackpot2: 'İKRAMİYE',
  explode_if_you_collect_3_WILD: '3 WILD toplarsanız patlayın.',
  wild_can_subsitute_for_all_symbols: 'Wild tüm sembollerin yerine geçebilir.',

  // 3/1
  series: 'Seri',
  multiple: 'Çoklu',
  result_will_be_display_here: 'Sonuç burada görüntülenecek',
  bet_head: 'Bahis Kafası',
  bet_tail: 'Bahis Kuyruğul',
  instant: 'Ani',
  multiply: 'çarpmak',
  coin_flip_instant_mode_desc:
    'Basitçe Head veya Tail Bahsi Yapın veya Otomatik Olarak Bahis Yapın',
  auto_bet_setup: 'Otomatik Bahis Kurulumu',
  you_win_title: 'Sen kazandın',
  select_network_to_continue: 'Devam etmek için ağı seçin',
  ensure_withdraw_address:
    'Lütfen doğru adresi girdiğinizden emin olun; aksi takdirde paranız kaybolur ve geri alınamaz.',
  click_bet_to_choose_coin_side:
    'Madeni para tarafını seçmek için bahise tıklayın',

  copied_share_in_chat: 'Kopyalandı, sohbette paylaşın!',
  copied_share_online: 'Kopyalandı, internette paylaşın!',
  game_id: 'Oyun Kimliği',
  server_seed_hash: 'Sunucu Tohumu (Hash)',
  client_seed: 'Müşteri Kaynağı',
  nonce: 'bir kez',
  about_COD: 'COD hakkında',
  coco_dollar_info:
    'COD (Coco Dollar), COCO.GAME tarafından başlatılan özel bir para birimidir. COD ile oyunlara katılabilir veya diğer oyunculara bahşiş verebilirsiniz.',
  can_COD_be_exchanged_to_any_other_currency:
    'COD başka bir para birimine dönüştürülebilir mi?',
  you_can_swap_cod: `Mevcut döviz kuruna bağlı olarak Coco Swap'ı kullanarak COD'yi istediğiniz zaman diğer para birimleriyle değiştirebilirsiniz.`,
  get_daily_bonus_up_to_5000_COD: `5.000 COD'a kadar günlük bonus kazanın`,
  by_playing_games_tips_coin_dropping_and_rain_bonus:
    'Oyunlar oynayarak, bahşişler, jeton düşürme ve yağmur bonusu.',
  see_how: 'Nasıl olduğunu gör',
  how_to_unlock_COD: `COD'nin kilidini açma`,
  locked_COD_is_earned_through_specific_bonuses_like_deposit_bonuses:
    'Kilitli COD, para yatırma bonusları gibi belirli bonuslarla kazanılır.',
  unlocking_COD_is_simple: `COD'nin kilidini açmak basittir; geri komisyona benzer ve bahislerinizle orantılı olarak kilidi açılır.`,

  // Chat
  please_slow_down: 'Lütfen yavaşla.',
  links_must_include_your_website_domain:
    'Bağlantılar web sitenizin alan adını içermelidir.',
  chat_is_hidden: 'Sohbet gizlendi',
  show_chat: 'Sohbeti Göster',
  pinned_message: 'Sabitlenmiş mesaj',

  // 12/01
  deposit_bonus: 'Para Yatırma Bonusu',
  rakeback: 'Komisyon iadesi',
  spin: 'Döndürmek',
  get_extra_300_bonus_on_minimum_of_10_USDT_deposit:
    'Minimum 10,00 USDT para yatırma işleminde ekstra %300 bonus kazanın',
  min_deposit: 'Minimum Depozito:',
  max_claim: 'Maksimum Talep:',
  withdraw_successful: 'Başarılı Bir Şekilde Çekildi',
  your_withdrawal_request_has_been_confirmed: `Para çekme talebiniz onaylandı. İlerlemeyi Cüzdan İşlem Geçmişi'nden takip edebilirsiniz.`,
  pi_balance_in_wallet: 'Cüzdandaki Pi Bakiyesi',
  your_Pi_address: 'Pi Adresiniz',
  fill_in_carefully_according_to_the_specific_currency:
    'Belirli para birimine göre dikkatlice doldurun',
  you_will_receive_Pi_to_your_address_within_1_working_day: `Pi'niz 1 iş günü içerisinde adresinize teslim edilecektir.`,
  please_make_sure_you_enter_the_correct_Pi_address_otherwise_your_funds_will_not_be_reversed:
    'Lütfen doğru Pi adresini girdiğinizden emin olun; aksi takdirde paranız geri çevrilmeyecektir.',
  current_capacity: 'Şuanki kapasite',
  what_is_CODG_capacity: 'CODG kapasitesi nedir?',
  rng_certificated_by: 'RNG Sertifikalı',

  // 08/03
  buy_crypto: 'Kripto Satın Al',
  buy_fiat: `Money Fiat'ı seçin`,
  refund: 'Geri ödemek',
  bet_histories: 'Bahis Geçmişleri',
  round_histories: 'Yuvarlak Tarihler',
  round_id: 'Yuvarlak Kimlik',
  bet_place: 'Bahis Yeri',
  profit: 'Kâr',
  top: 'Tepe',
  top_profit: 'En Yüksek Kâr',
  leader_board: 'Liderler Sıralaması',
  how_to_join: 'Nasıl katılınır',
  see_more: 'Daha fazla gör',
  top_winning_streak: 'En iyi galibiyet serisi',
  top_losing_streak: 'En iyi mağlubiyet serisi',
  place: 'Yer',
  streak: 'Rüzgâr gibi geçmek',
  total_prized: 'Toplam Ödüllü',
  top_reward: 'En iyi ödül',
  top_10_winners: 'İlk 10 kazanan',
  monthly_bonus: 'Aylık Bonus',
  available_rakeback: 'Mevcut Komisyon İadesi',
  no_rakeback_yet: 'Henüz komisyon iadesi yok',
  claim_my_rakeback: 'Komisyon İademi Talep Edin',
  wager_required: 'Bahis gerekli',
  receive_every: 'Her birini al',
  claim_bonus_successfully: 'Bonusu başarıyla talep edin',
  claim_bonus_failed: 'Bonus talep edilemedi, lütfen tekrar deneyin!',
  no_bonus_available: 'Bonus mevcut değil',
  play_to_earn_bonus:
    'Bonus kazanmak için oyun oynayın ve bahis oynamaya başlayın',
  play_and_wager: 'Oyna ve Bahis',
  claim_all: 'Tümünü Talep Et',
  duration: 'Süre',
  your_total_bonus: 'Toplam bonusunuz',
  roll_under: 'Altına Dön',
  game_result_will_be_displayed_here: 'Oyun sonucu burada görüntülenecektir.',
  level_up_bonus_upgrade: 'Seviye Atlama Bonus Yükseltmesi',
  _24h_live_support: '24 saat Canlı Destek',
  bonus_growth: 'Bonus artışı',
  xp_required: 'XP gerekli',
  account_not_conditions: 'Geri çekilmek için daha fazla bahis oynamak',
  play_increase_wager:
    'Bonus parayı aldıktan sonra, çekebilmek için 120.000$ bahis tutarına ulaşmanız gerekecek',
  slots_wager_rate: 'Slot Bahisleri 1,00$ = 2XP',
  platinum_1_to_6: 'Platin I - VI',
  diamond_1_to_5: 'Elmas I - V',

  // CODG
  total_received_CODG: 'Toplam alınan CODG',
  unlocked_amount: 'Kilitlenmemiş miktar',
  how_to_unlock_CODG: `CODG'nin kilidi nasıl açılır?`,
  swap_and_play: 'Değiştir ve oyna',
  unlocked_CODG: `Oynamak ve kazanmak için kilidi açılmış CODG'nizi diğer para birimleriyle değiştirebilirsiniz`,
  available_to_swap: 'Takas yapılabilir',
  there_are_no_redeemable_currencies: 'Kullanılabilir para birimi yok',
  first_deposit: 'İlk Para Yatırma',
  _300_bonus: '%300 Bonus',
  get_up_to: 'Kalk',
  second_deposit: 'İkinci Para Yatırma',
  _200_bonus: '%200 Bonus',
  third_deposit: 'Üçüncü Para Yatırma',
  _150_bonus: '%150 Bonus',
  fourth_deposit: 'Dördüncü Para Yatırma',
  _100_bonus: '%100 Bonus',
  deposit_promotion: 'Mevduat Promosyonu',
  unlock_codg_description: `Özel jetonumuz CODG'de ekstra ödüller kazanmak için önemli bir fırsat sağlayan, olağanüstü 4 katmanlı bir para yatırma eşleştirme bonusu sunar. Bu avantajı yakalayın, fonlarınızı artırın ve platformumuzla heyecan verici bir yolculuğa çıkın.`,

  // Buy Crypto
  our_excited_bonus: 'Sizin için heyecanlı bonus yolculuğumuz',
  something_is_wrong: 'Bir şey yanlış.',
  disclaimer: 'Sorumluluk reddi beyanı',
  the_above_third_party: `Yukarıdaki üçüncü taraf hizmetleri, Coco'da oynamak için kullanılabilecek kripto satın almak için kullanılabilir. Platformlarına kaydolarak aynı zamanda hizmet şartlarını da kabul etmiş olursunuz ve bizimkinden bağımsız olarak yürütülen KYC süreçlerini geçmeniz gerekecektir.`,
  buy_with_transFI: 'TransFI ile satın alın',
  order_processing: 'sipariş düzenleniyor',
  your: 'Senin',
  buying_order_is_currently:
    'satın alma siparişi şu anda işleniyor. Lütfen birkaç dakika bekleyin',
  // Deposit
  transfer_pi: `Pi'yi aktar`,
  transfer_Pi_to_the_address_above_which_is_our_agent_address: `Pi'yi acente adresimiz olan yukarıdaki adrese aktarın.`,
  meet_our_live_support: 'Canlı Desteğimizle Tanışın',
  click_the_i_transferred_Pi:
    'Canlı Desteğimizle tanışmak için “Pi Aktardım” butonuna tıklayın.',
  confirm_transaction: 'İşlemi onayla',
  send_your_transaction_and_screenshot_to_the_chat_box_as_confirmation:
    'İşleminizi ve ekran görüntüsünü onay olarak sohbet kutusuna gönderin.',
  transfer_Pi_to_this_address: `Pi'yi bu adrese aktarın`,
  how_to_deposit_Pi: 'Pi nasıl yatırılır?',
  i_transferred_Pi: `Pi'yi Aktardım`,
  earn_an_extra_300_bonus_with_a_minimum_deposit_of_1000_USD:
    'Minimum 10,00 USD tutarındaki para yatırma işlemiyle ekstra %300 bonus kazanın.',
  // Tip
  max_length_ID_not_more_than_20_number: `Maksimum uzunluk ID'si 20 rakamdan fazla değil`,
  receiver: 'alıcı',
  transaction_fee_rate: 'İşlem Ücreti Oranı',
  transaction_fee_amount: 'İşlem Ücreti Tutarı',
  you_need_to_wager: 'Bahse girmen gerekiyor',
  // Withdraw
  more_to_withdraw_due_to_the_bonus_money_policies:
    'Bonus para politikaları nedeniyle daha fazla para çekilecek',
  pi_address_is_required: 'Pi Adresi gerekli',
  pi_Amount_must_specify_a_number: 'Pi Tutarı bir sayı belirtmelidir',
  pi_amount_must_greater_than_10_Pi: `Pi Miktarı 10 Pi'den büyük olmalıdır`,
  pi_amount_must_less_than_10000_Pi: `Pi Miktarı 10.000 Pi'den az olmalıdır`,
  pi_amount_is_required: 'Pi Miktarı gerekli',
  withdraw_successfully_We_will_contact_you_later:
    'Başarılı bir şekilde geri çekilin! Sizinle daha sonra iletişime geçeceğiz',
  hey_there: 'selam',
  please_process_my_Pi_coin_withdrawal_as_follows:
    'Lütfen Pi para çekme işlemimi aşağıdaki şekilde gerçekleştirin',
  fees: 'Ücretler',
  input_your_Pi_address: 'Pi adresinizi girin',
  input_number_of_Pi: `Pi'nin giriş sayısı`,
  lucky_win: 'Şanslı galibiyet',
  can_not_get_bet_detail: 'Bahis detayı alınamıyor!',

  // Popup currency
  cannot_be_used_in_this_game:
    'Bu oyunda Real Play modu için kullanılamaz. Lütfen diğer varlıklara geçin.',
  the_selected_currency_will_be_displayed_in:
    'Seçilen para birimi görüntülenecektir',
  and_if_you_change_currency_while_playing:
    've oynarken para birimini değiştirirseniz oyun yenilenir ve yeniden başlar.',

  // CODG
  about_CODG: 'CODG Hakkında',
  CODG_is_the_reward_token_of_Cocogame: `CODG, Coco.game'in ödül tokenidir. Platformla ne kadar çok etkileşime girerseniz varlıklarınızı zenginleştirmek için o kadar fazla CODG kazanırsınız.`,
  can_CODG_be_swapped_for_other_currencies:
    'CODG diğer para birimleriyle değiştirilebilir mi?',
  you_can_swap_your_unlocked_CODG: `Kilidi açılmış CODG'nizi mevcut döviz kuruna bağlı olarak diğer para birimleriyle değiştirebilirsiniz. Ancak kilitli CODG değiştirilemez; yalnızca platformdaki çeşitli oyunlarda oynamak için kullanılabilir.`,
  // GameBank => Overview
  the_maximum_share_of_all_users: 'Tüm kullanıcıların maksimum payı',
  your_maximum_share: 'Maksimum payınız',
  total_available_investing_amount: 'Mevcut toplam yatırım tutarı',
  your_available_investing_amount: 'Mevcut yatırım tutarınız',
  // Game bank => InvestInformation
  what_is_investing_in_coco_gamebank:
    'Coco oyun bankasına yatırım yapmak nedir',
  anyone_can_add_money_to_the_gamebank:
    'Herkes Oyun bankasına para ekleyebilir ve bahislerin kasa tarafına katılabilir. Kazancınız, toplam Oyun bankası kârının payınızla çarpılmasıyla belirlenir.',
  why_should_i_invest: 'Neden yatırım yapmalıyım?',
  profitable: 'karlı',
  do_you_believe_in_the_bad_luck_of_other_gamblers:
    'Diğer kumarbazların kötü şansına inanır mısınız? Eğer öyleyse, ev sahibi olmak pasif gelir elde etmenin harika bir yoludur.',
  real_yield: 'Gerçek Verim',
  you_will_receive_profit_in_the_same_token_that_you_invest:
    'Yatırım yaptığınız tokenla aynı miktarda kâr elde edeceksiniz.',
  analysis_tool: 'Analiz Aracı',
  access_gamebank_value:
    'En iyi kararları vermek için Oyun bankası değerine, hisselerine, geçmişine ve trendlerine erişin.',
  understand_the_risk_involved: 'İlgili riski anlayın',
  keep_in_mind_that_the_gamebank_value_may_temporarily_decrease_due_to_gamblers_wins:
    'Kumarbazların kazanması nedeniyle Oyun bankasının değerinin geçici olarak düşebileceğini, dolayısıyla anında kar göremeyebileceğinizi unutmayın. Dikkatli yatırım yapın.',
  invest_information: 'Yatırım Bilgileri',
  details: 'Detaylar',
  // Gift code
  gift_received_successfully: 'Hediye kodu talep edildi',
  congratulations_you_have_just_received: 'Tebrikler! Az önce şunu aldınız:',
  cod_successfully: 'COD başarıyla',
  // Transaction
  receiver_nickname: 'Alıcının Takma Adı',
  sender_nickname: 'Gönderenin Takma Adı',
  tip_successful: 'İpucu başarılı',
  // JFU
  bet_win: 'BAHİS & KAZAN',
  challenge: 'MEYDAN OKUMAK',
  cash_it_out: 'NAKİT ÇIKAR',
  tournament: 'TURNUVA',
  november_exclusive: 'KASIM AYINA ÖZEL',
  new_game: 'YENİ OYUN',
  you_need_to_reach_the_rank_to_unlock_this_feature:
    'Şuraya ulaşmanız gerekiyor:{{val}} Bu özelliğin kilidini açmak için sıralamaya girin. Sıralamanızı kontrol edin',
  play_with_balance_in: 'Dengeyle oynayın',

  // Host
  ohplay_vip_club: 'Ohplay VIP Kulübü',
  pi_vip_club: 'Pi VIP Kulübü',
  to_pi_vip_club: `Pi VIP Club'a`,
  to_ohplay_vip_club: `Ohplay VIP Club'a`,
  welcome_to_Ohplay: `Ohplay.club'a hoş geldiniz`,
  welcome_to_Pi: `Pi.game'e hoş geldiniz`,
  become_a_pi: 'Pi ol',
  become_a_ohplay: 'Ohplay ol',
  pi_member: 'Pi üyesi olun',
  ohplay_member: `Ohplay'e üye olun`,
  why_is_Pi_VIP_program_the_best: 'Pi VIP programı neden en iyisidir?',
  why_is_Ohplay_VIP_program_the_best: 'Ohplay VIP programı neden en iyisidir?',
  how_much_has_pi_given_out_in_bonuses: 'Pi ne kadar ikramiye dağıttı?',
  how_much_has_ohplay_given_out_in_bonuses: 'Ohplay ne kadar ikramiye dağıttı?',
  pi_VIP_telegram_channel: 'Pi VIP Telegram kanalını nerede bulabilirim??',
  ohplay_VIP_telegram_channel:
    'Ohplay VIP Telegram kanalını nerede bulabilirim?',
  tip_with_any_currency_in_Pi: `Pi'de herhangi bir para birimiyle bahşiş`,
  tip_with_any_currency_in_Ohplay: `Ohplay'de herhangi bir para birimiyle bahşiş verin`,
  why_should_i_be_a_Pi_affiliate: 'Neden Pi İştiraki olmalıyım?',
  why_should_i_be_a_Ohplay_affiliate: 'Neden Ohplay Üyesi olmalıyım?',
  if_you_already_have_a_Pi_account: `Zaten bir Pi hesabınız varsa aşağıdaki adımları uygulamanız yeterlidir:
       1. Ortaklık sayfasını ziyaret edin.
       2. Benzersiz yönlendirme kodunuzu kullanarak yeni bir kampanya oluşturun.
       3. Yönlendirme bağlantınızı web sitenizde, sosyal medyada veya yüksek trafikli platformlarda paylaşın.
       4. Yönlendirdiğiniz kullanıcılardan haftalık komisyonunuzu alın.`,
  if_you_already_have_a_Ohplay_account: `Zaten bir Ohplay hesabınız varsa aşağıdaki adımları uygulamanız yeterlidir:
       1. Ortaklık sayfasını ziyaret edin.
       2. Benzersiz yönlendirme kodunuzu kullanarak yeni bir kampanya oluşturun.
       3. Yönlendirme bağlantınızı web sitenizde, sosyal medyada veya yüksek trafikli platformlarda paylaşın.
       4. Yönlendirdiğiniz kullanıcılardan haftalık komisyonunuzu alın.`,
  you_can_still_enjoy_the_best_casino_experience_at_Pi: `Endişelenmeyin, Pi.game'de EN İYİ casino deneyiminin keyfini çıkarmaya devam edebilirsiniz.`,
  you_can_still_enjoy_the_best_casino_experience_at_Ohplay: `Yine de endişelenmeyin, Ohplay.club'da EN İYİ casino deneyiminin keyfini çıkarmaya devam edebilirsiniz.`,
  pi_game_is_not_available_in_your_region:
    'Üzgünüz, Pi.game bölgenizde mevcut değil.',
  ohplay_game_is_not_available_in_your_region:
    'Üzgünüz, Ohplay.club bölgenizde mevcut değil..',
  pi_took_it_all: 'Pi hepsini aldı!',
  ohplay_took_it_all: 'Ohplay hepsini aldı!',
  there_are_various_ways_to_get_pi_gift_codes:
    'Özel sosyal medya etkinliklerine, topluluk etkinliklerine, bonuslara veya özel görevlere katılmak gibi Pi hediye kodları almanın çeşitli yolları vardır.',
  there_are_various_ways_to_get_ohplay_gift_codes:
    'Ohplay hediye kodlarını almanın özel sosyal medya etkinliklerine, topluluk etkinliklerine, bonuslara veya özel görevlere katılmak gibi çeşitli yolları vardır.',
  how_to_get_Pi_gift_code: 'Coco.game hediye kodu nasıl alınır?',
  how_to_get_Ohplay_gift_code: 'Coco.game hediye kodu nasıl alınır?',
  gift_code_pi: 'Pi.game',
  gift_code_ohplay: 'Ohplay.club',
  how_to_use_pi_gift_code: 'Pi.game hediye kodu nasıl kullanılır?',
  how_to_use_ohplay_gift_code: 'Ohplay.club hediye kodu nasıl kullanılır?',
  what_is_pi_giftcode: 'Pi Hediye kodu nedir?',
  what_is_ohplay_giftcode: 'Ohplay Hediye kodu nedir',
  use_your_COD_gift_to_bet_and_win_at_pi: `Pi.game'de oynamak ve kazanmak için Pi hediyenizi kullanın`,
  use_your_COD_gift_to_bet_and_win_at_ohplay: `Ohplay.club'da bahis yapmak ve kazanmak için Ohplay hediyenizi kullanın`,
  the_above_third_party_pi: `Yukarıdaki üçüncü taraf hizmetleri, Pi'de oynamak için kullanılabilecek kripto satın almak için kullanılabilir. Platformlarına kaydolarak aynı zamanda hizmet şartlarını da kabul etmiş olursunuz ve bizimkinden bağımsız olarak yürütülen KYC süreçlerini geçmeniz gerekecektir.`,
  the_above_third_party_ohplay: `Yukarıdaki üçüncü taraf hizmetleri, Ohplay'de oynamak için kullanılabilecek kripto satın almak için kullanılabilir. Platformlarına kaydolarak aynı zamanda hizmet şartlarını da kabul etmiş olursunuz ve bizimkinden bağımsız olarak yürütülen KYC süreçlerini geçmeniz gerekecektir.`,
  CODG_is_the_reward_token_of_Pigame: `CODG, Pi.game'in ödül tokenidir. Platformla ne kadar çok etkileşime girerseniz varlıklarınızı zenginleştirmek için o kadar fazla CODG kazanırsınız.`,
  CODG_is_the_reward_token_of_Ohplay: `CODG, Ohplay.club'un ödül tokenidir. Platformla ne kadar çok etkileşime girerseniz varlıklarınızı zenginleştirmek için o kadar fazla CODG kazanırsınız..`,
  what_is_investing_in_pi_gamebank: 'Pi oyun bankasına yatırım yapmak nedir',
  what_is_investing_in_ohplay_gamebank:
    'Ohplay oyun bankasına yatırım yapmak nedir',
  turn_your_friends_and_followers_into_weekly_commissions_pi:
    "Pi'nin ortaklık programıyla arkadaşlarınızı ve takipçilerinizi haftalık komisyonlara dönüştürün. Basit, karlı ve tamamen şeffaftır",
  turn_your_friends_and_followers_into_weekly_commissions_ohplay:
    "Ohplay'in ortaklık programıyla arkadaşlarınızı ve takipçilerinizi haftalık komisyonlara dönüştürün. Basit, karlı ve tamamen şeffaftırt",
  if_you_have_a_website_with_substantial_traffic_pi:
    'Yoğun trafiğe sahip bir web siteniz veya geniş kitleye sahip bir sosyal medya hesabınız varsa özel fırsatlar için bize business@Pi.game adresinden ulaşabilirsiniz.',
  if_you_have_a_website_with_substantial_traffic_ohplay:
    'Yoğun trafiğe sahip bir web siteniz veya geniş bir kitleye sahip bir sosyal medya hesabınız varsa, özel fırsatlar için bize business@Ohplay.club adresinden ulaşabilirsiniz.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_pi:
    'Oyun lisansımız nedeniyle ülkenizden oyuncu kabul edemiyoruz. Daha fazla yardıma ihtiyacınız olursa support@Pi.game aracılığıyla bizimle iletişime geçin.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_ohplay:
    'Oyun lisansımız nedeniyle ülkenizden oyuncu kabul edemiyoruz. Daha fazla yardıma ihtiyacınız olursa support@Ohplay.club aracılığıyla bizimle iletişime geçin.',
  all_plays: 'Tümü',
  my_plays: 'Benim oyunum',
  high_play: 'Yüksek oyun',
  play_id: 'Oynatma Kimliği',
  play_amount: 'Oynatma miktarı',
  set_auto_play: 'Otomatik Bahsi Ayarla',
  number_of_play: 'Oynatma sayısı',
  stop_auto_play: 'Otomatik oynatmayı durdury',
  start_play_round_failed: 'Oyun turu başlatılamadı',
  wager_contest_pi: 'Pi.Game Yarışması',
  max_play: 'Maksimum oynatma',
  total_play_amount: 'Toplam Oynatma Tutarı',
  under_min_play: 'Minimum oyun altında',
  invalid_play_params: 'geçersiz oyun parametreleri',
  over_max_play: 'Maksimum oynatmanın üzerinde',
  invalid_play_state: 'geçersiz oynatma durumue',
  play_next: 'Sonrakini çalt',
  playing: 'Oynanıyor',
  play_head: 'Oyun Kafası',
  play_tail: 'Kuyruk Oyna',
  auto_play_setup: 'Otomatik Oynatma Kurulumu',
  click_play_to_choose_coin_side:
    'Madeni para tarafını seçmek için oynata tıklayın',
  play_histories: 'Çalma Geçmişleri',
  play_place: 'Oyun Yeri',
  can_not_get_play_detail: 'Oyun detayı alınamıyor!',
  play_win: 'OYNA & KAZAN',

  // CODG 05/04
  locked_CODG_is_earned:
    'Kilitli CODG, para yatırma bonusları gibi belirli bonuslar veya promosyonlar aracılığıyla kazanılır.',
  unlocking_CODG_is_simple: `CODG'nin kilidini açmak basittir; komisyon iadesine benzer ve bahislerinizle orantılı olarak aşağıdaki şekilde açılır:`,
  unlocking_formula:
    'Kilit Açma Tutarı = Bahis Tutarı * Ondalık sayı olarak kasa avantajı * X',
  conversion_formula: `Örneğin, 100$ bahis oynarsanız 0,2 kilitli CODG'nin kilidini açarsınız.`,
  note_unlock_codg: `Not: CODG'nin kilidini açmak için en az 10.000 XP gerektiren Bronz sıralamaya ulaşmanız gerekir.`,
  how_to_claim_COD: 'COD nasıl talep edilir',
  as_you_place_wagers: `Bahis yaptığınızda, Piggy'niz kilidi açılmış CODG'yi biriktirecek ve bu daha sonra COD'ye dönüştürülecektir.`,
  check_to_claim: 'Hak talebinde bulunmak için kontrol edin',
  about_CODG_token: 'CODG Token Hakkında',
  play_our_games_to_earn_CODG_for_free:
    'Ücretsiz CODG kazanmak için oyunlarımızı oynayın!',
  how_to_unlock: 'Nasıl açılır',
  how_to_claim: 'Nasıl hak talebinde bulunulur?',
  today_biggest_winners: 'Bugünün En Büyük Kazananları',
  and_get_up_to: 've ayağa kalk',
  to_get: 'almak için',
  claim_now: 'Şimdi Talep Et',
  learn_more: 'Daha fazla bilgi edin',

  // Host 10/04
  welcome_to_satoshity: `satosity.io'ya hoş geldiniz`,
  welcome_to_betgecko: `betgecko.io'ya hoş geldiniz`,
  welcome_to_bitwin: `bitwin.club'a hoş geldiniz`,
  host_vip_club: `${process.env.REACT_APP_SITE} VIP Kulübü`,
  to_host_vip_club: `ile ${process.env.REACT_APP_SITE} VIP Kulübü`,
  learn_vip: `Hakkında daha fazla öğren ${process.env.REACT_APP_SITE} VIP sistemi`,
  become_a_host: `Haline gelmeka ${process.env.REACT_APP_SITE}`,
  host_member: `Olmak ${process.env.REACT_APP_SITE} üye`,
  how_much_has_host_given_out_in_bonuses: `Ne kadarı var ${process.env.REACT_APP_SITE} ikramiye olarak mı dağıtılıyor?`,
  why_is_HOST_VIP_program_the_best: `Neden ki ${process.env.REACT_APP_SITE} VIP programı en iyisi?`,
  HOST_VIP_telegram_channel: `Nerede bulabilirim ${process.env.REACT_APP_SITE} VIP Telegram kanalı mı?`,
  tip_with_any_currency_in_HOST: `Herhangi bir para birimiyle bahşiş ${process.env.REACT_APP_SITE}`,
  turn_your_friends_and_followers_into_weekly_commissions_HOST: `Arkadaşlarınızı ve takipçilerinizi haftalık komisyonlara dönüştürün ${process.env.REACT_APP_SITE}'s Ortaklık Programı. Basit, karlı ve tamamen şeffaftır`,
  why_should_i_be_a_HOST_affiliate: `Neden ben olmalıyım? ${process.env.REACT_APP_SITE} Ortaklık?`,
  if_you_already_have_a_HOST_account: `Zaten bir ${process.env.REACT_APP_SITE} hesabınızda şu adımları uygulamanız yeterlidir:
      1. Ortaklık sayfasını ziyaret edin.
      2. Benzersiz yönlendirme kodunuzu kullanarak yeni bir kampanya oluşturun.
      3. Yönlendirme bağlantınızı web sitenizde, sosyal medyada veya yüksek trafikli platformlarda paylaşın.
      4. Yönlendirdiğiniz kullanıcılardan haftalık komisyonunuzu alın.`,
  HOST_took_it_all: `${process.env.REACT_APP_SITE} hepsini aldı!`,
  there_are_various_ways_to_get_HOST_gift_codes: `almanın çeşitli yolları var ${process.env.REACT_APP_SITE} özel sosyal medya etkinliklerine, topluluk etkinliklerine, bonuslara veya özel görevlere katılmak gibi hediye kodları.`,
  what_is_HOST_giftcode: `'Nedir ${process.env.REACT_APP_SITE} Hediye kodu?`,
  mini_HOST_is_Allways_3x3_Slot_machine_game: `Mini ${process.env.REACT_APP_SITE} 27 çizgili 3x3 Slot makinesi oyunudur.`,
  the_above_third_party_HOST: `Yukarıdaki üçüncü taraf hizmetleri, oynamak için kullanılabilecek kripto satın almak için kullanılabilir. ${process.env.REACT_APP_SITE}. Platformlarına kaydolarak aynı zamanda hizmet şartlarını da kabul etmiş olursunuz ve bizimkinden bağımsız olarak yürütülen KYC süreçlerini geçmeniz gerekecektir..`,
  what_is_investing_in_HOST_gamebank: `Neye Yatırım Yapılıyor? ${process.env.REACT_APP_SITE} oyun bankası`,

  you_can_still_enjoy_the_best_casino_experience_at_satoshity: `Endişelenmeyin, yine de satoshity.io'da EN İYİ casino deneyiminin keyfini çıkarabilirsiniz.`,
  you_can_still_enjoy_the_best_casino_experience_at_betgecko: `Endişelenmeyin, yine de betgecko.io'da EN İYİ casino deneyiminin keyfini çıkarabilirsiniz.`,
  you_can_still_enjoy_the_best_casino_experience_at_bitwin: `Endişelenmeyin, yine de bitwin.club'da EN İYİ casino deneyiminin keyfini çıkarabilirsiniz.`,
  satoshity_game_is_not_available_in_your_region:
    'Üzgünüz satoshity.io bölgenizde kullanılamıyor.',
  betgecko_game_is_not_available_in_your_region:
    'Üzgünüz, betgecko.io bölgenizde kullanılamıyor.',
  bitwin_game_is_not_available_in_your_region:
    'Üzgünüz, bitwin.club bölgenizde mevcut değil.',
  how_to_get_satoshity_gift_code: 'satoshity.io hediye kodu nasıl alınır?',
  how_to_get_betgecko_gift_code: 'Betgecko.io hediye kodu nasıl alınır?',
  how_to_get_bitwin_gift_code: 'bitwin.club hediye kodu nasıl alınır?',
  how_to_use_satoshity_gift_code: 'satoshity.io hediye kodu nasıl kullanılır?',
  how_to_use_betgecko_gift_code: 'Betgecko.io hediye kodu nasıl kullanılır?',
  how_to_use_bitwin_gift_code: 'bitwin.club hediye kodu nasıl kullanılır?',
  use_your_COD_gift_to_bet_and_win_at_satoshity: `Satoshity.io'da bahis yapmak ve kazanmak için Satoshity hediyenizi kullanın`,
  use_your_COD_gift_to_bet_and_win_at_betgecko: `Betgecko.io'da bahis yapmak ve kazanmak için Betgecko hediyenizi kullanın`,
  use_your_COD_gift_to_bet_and_win_at_bitwin: `Bitwin.club'da bahis yapmak ve kazanmak için Bitwin hediyenizi kullanın`,
  CODG_is_the_reward_token_of_satoshity: `CODG, satoshity.io'nun ödül tokenidir. Platformla ne kadar çok etkileşime girerseniz varlıklarınızı zenginleştirmek için o kadar fazla CODG kazanırsınız.`,
  CODG_is_the_reward_token_of_bitwin: `CODG, bitwin.club'un ödül tokenidir. Platformla ne kadar çok etkileşime girerseniz varlıklarınızı zenginleştirmek için o kadar fazla CODG kazanırsınız.`,
  CODG_is_the_reward_token_of_betgecko: `CODG, betgecko.io'nun ödül tokenidir. Platformla ne kadar çok etkileşime girerseniz varlıklarınızı zenginleştirmek için o kadar fazla CODG kazanırsınız.`,
  if_you_have_a_website_with_substantial_traffic_satoshity:
    'Yoğun trafiğe sahip bir web siteniz veya geniş kitleye sahip bir sosyal medya hesabınız varsa özel fırsatlar için bize business@satoshity.io adresinden ulaşabilirsiniz.',
  if_you_have_a_website_with_substantial_traffic_betgecko:
    'Yoğun trafiğe sahip bir web siteniz veya geniş kitleye sahip bir sosyal medya hesabınız varsa, özel fırsatlar için bize business@betgecko.io adresinden ulaşabilirsiniz.',
  if_you_have_a_website_with_substantial_traffic_bitwin:
    'Yoğun trafiğe sahip bir web siteniz veya geniş kitleye sahip bir sosyal medya hesabınız varsa özel fırsatlar için bize business@bitwin.club adresinden ulaşabilirsiniz.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_betgecko:
    'Oyun lisansımız nedeniyle ülkenizden oyuncu kabul edemiyoruz. Daha fazla yardıma ihtiyacınız olursa support@Betgecko.io aracılığıyla bizimle iletişime geçin.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_satoshity:
    'Oyun lisansımız nedeniyle ülkenizden oyuncu kabul edemiyoruz. Daha fazla yardıma ihtiyacınız olursa support@Satoshity.io aracılığıyla bizimle iletişime geçin.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country_bitwin:
    'Oyun lisansımız nedeniyle ülkenizden oyuncu kabul edemiyoruz. Daha fazla yardıma ihtiyacınız olursa support@Bitwin.club aracılığıyla bizimle iletişime geçin.',

  refer_description_coco:
    "Arkadaşlarınızı coco.game'in Ortaklık Programına yönlendirin ve sonuçları ne olursa olsun, bağlantılarınız aracılığıyla kaydolan ve oynayan her kullanıcı için komisyon kazanın",
  refer_description_pi:
    "Arkadaşlarınızı pi.game'in Ortaklık Programına yönlendirin ve sonuçları ne olursa olsun, bağlantılarınız aracılığıyla kaydolan ve oynayan her kullanıcı için komisyon kazanın",
  refer_description_ohplay:
    "Arkadaşlarınızı ohplay.club'in Ortaklık Programına yönlendirin ve sonuçları ne olursa olsun, bağlantılarınız aracılığıyla kaydolan ve oynayan her kullanıcı için komisyon kazanın",
  refer_description_satoshity:
    "Arkadaşlarınızı satoshity.io'in Ortaklık Programına yönlendirin ve sonuçları ne olursa olsun, bağlantılarınız aracılığıyla kaydolan ve oynayan her kullanıcı için komisyon kazanın",
  refer_description_betgecko:
    "Arkadaşlarınızı betgecko.io'in Ortaklık Programına yönlendirin ve sonuçları ne olursa olsun, bağlantılarınız aracılığıyla kaydolan ve oynayan her kullanıcı için komisyon kazanın",
  refer_description_bitwin:
    "Arkadaşlarınızı bitwin.club'in Ortaklık Programına yönlendirin ve sonuçları ne olursa olsun, bağlantılarınız aracılığıyla kaydolan ve oynayan her kullanıcı için komisyon kazanın",
  your_email_verified_coco: `Email adresin onaylandı. Coco.game'i keşfedelim.`,
  your_email_verified_pi: `Email adresin onaylandı. pi.game'i keşfedelim.`,
  your_email_verified_ohplay: `Email adresin onaylandı. ohplay.club'i keşfedelim.`,
  your_email_verified_satoshity: `Email adresin onaylandı. satoshity.io'i keşfedelim.`,
  your_email_verified_betgecko: `Email adresin onaylandı. betgecko.io'i keşfedelim.`,
  your_email_verified_bitwin: `Email adresin onaylandı. bitwin.club'i keşfedelim.`,
  GAME: 'Oyun',
  AFFILIATE: 'Ortaklık',
  ACCOUNT: 'Hesap',
  BONUS: 'Bonus',
  INVEST: 'Yatırım',
  SWAP: 'Takas',
};

export default tr;
