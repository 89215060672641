import isPropValid from '@emotion/is-prop-valid';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const WalletSubTabItemWrapper = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(String(prop)),
})<{ $isActive: boolean | undefined }>(({ theme, $isActive }) => ({
  backgroundColor: $isActive ? '#161432' : 'none',
  color: $isActive ? '#FFF' : 'none',
  borderRadius: theme.shape.borderRadius,
  margin: '4px',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  padding: '8px',
  '.sub_tab': {
    display: 'flex',
  },
  '.subtab_icon_Crypto': {
    path: {
      color: '#FFF',
      fill: '#FFF',
    },
  },
}));

export const WalletTabItemWrapper = styled('div', {
  shouldForwardProp: (prop) => isPropValid(String(prop)),
})<{
  $isActive: boolean | undefined;
  $isDisable: boolean | undefined;
  $isIconColor: boolean | undefined;
}>(({ theme, $isActive, $isDisable, $isIconColor }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: $isActive ? 'flex-start' : 'center',
  borderRadius: theme.shape.borderRadius,
  padding: '0.5rem',
  width: '170px',
  [theme.breakpoints.down('lg')]: {
    width: '131px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.down(400)]: {
    ...(!$isActive && {
      p: {
        width: !$isActive && 0,
      },
    }),
  },
  p: {
    fontWeight: 600,
  },
  background: $isActive
    ? 'linear-gradient(180deg, #313064 0%, #43416C 100%)'
    : theme.palette.background.main,
  '&:hover': {
    backgroundColor: !$isDisable && theme.palette.background.hovering,
  },

  '.wallet_item_icon': {
    height: 24,
    width: 24,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1),
    },
  },

  '.wallet_item_icon--active': {
    marginRight: theme.spacing(1),
  },

  '.wallet_item_label': {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.text.contrast,
    lineHeight: 'normal',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },

  '.wallet_item_arrow_icon': {
    display: 'inline-flex',
    marginLeft: 'auto',
    'p, path': {
      fill: 'none',
    },
  },
  transition: 'ease all 0.3s',
  'p, path': {
    color: $isActive ? theme.palette.text.contrast : theme.palette.text.primary,
    ...(!$isIconColor && {
      fill: $isActive
        ? theme.palette.text.contrast
        : theme.palette.text.primary,
    }),
  },
  cursor: $isDisable ? 'not-allowed' : 'pointer',
}));

export const WalletNavigation = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  backgroundColor: theme.palette.background.main,
  padding: '1.5rem 0.75rem',
  [theme.breakpoints.down('lg')]: {
    gap: 0,
    flexDirection: 'row',
    overflowY: 'hidden',
    overflowX: 'auto',
    '::-webkit-scrollbar': { display: 'none' },
    backgroundImage: 'none',
    backgroundColor: theme.palette.background.main,
    borderRadius: theme.shape.borderRadius,
    margin: '0 12px 12px',
    padding: '2px 0',
    alignItems: 'center',
  },
}));

export const WalletBody = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  minWidth: '480px',
  padding: theme.spacing(3, 1.5),
  backgroundImage:
    'linear-gradient(180deg, rgba(65, 64, 108, 0.5) 0%, rgb(36, 35, 74, 0.5) 7%, rgba(36, 35, 74, 0) 100%), linear-gradient(87deg, rgba(65, 64, 108, 0.5) 0%, rgb(36 35 74 / 70%)  21%, rgba(36, 35, 74, 0) 55%)',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  'p.label, p.amount': {
    fontWeight: 500,
  },
  [theme.breakpoints.down('lg')]: {
    minWidth: 'unset',
    backgroundImage: 'none',
    backgroundColor: theme.palette.background.secondary,
    padding: '0 12px',
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: 'unset',
    backgroundImage: 'none',
    backgroundColor: theme.palette.background.secondary,
    padding: '0 12px',
  },
}));

export const ChooseNetworkBox = styled(Box)(({ theme }) => ({
  width: '100%',
  '.MuiButtonBase-root': {
    transition: 'ease all 0.3s',
  },
  '& button': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.secondary,
    padding: theme.spacing(1.5, 2),
    fontSize: 14,
    [theme.breakpoints.down(400)]: {
      padding: theme.spacing(1),
      fontSize: 12,
    },
    fontWeight: 500,
    margin: theme.spacing(0.125),
    borderRadius: theme.shape.borderRadius,
    '.MuiTabs-indicator': {
      display: 'none',
    },
    '&.MuiButtonBase-root.MuiTab-root:not(:last-of-type)': {
      marginRight: theme.spacing(0.5),
    },
  },
  '& button:hover': {
    backgroundColor: theme.palette.background.hovering,
  },
  '& button[aria-selected=true]': {
    backgroundColor: theme.palette.background.hovering,
    color: theme.palette.text.contrast,
  },
  '.MuiTabs-scrollButtons.Mui-disabled': {
    opacity: 0.3,
    display: 'none',
  },
  '.MuiTabScrollButton-root': {
    backgroundColor: theme.palette.background.overlay,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[24],
  },
}));

export const SelectCoinBox = styled(Box)(() => ({
  justifySelf: 'flex-start',
  width: '100%',
}));

export const QuickWithdrawAmountBox = styled(Box)(() => ({
  display: 'flex',
}));

interface QuickSelectWithdrawItemProps {
  $isActive: boolean;
}

export const QuickSelectWithdrawItem = styled('div', {
  shouldForwardProp: (prop) => isPropValid(String(prop)),
})<QuickSelectWithdrawItemProps>(({ theme, $isActive }) => ({
  padding: theme.spacing(1.25, 1.5),
  background: 'linear-gradient(180deg, #313064 0%, #43416C 100%)',
  [theme.breakpoints.down(400)]: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(0.75)}`,
  },
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  '&& p': {
    color: $isActive ? theme.palette.text.contrast : theme.palette.text.primary,
  },
  backgroundColor: $isActive
    ? theme.palette.background.hovering
    : theme.palette.background.secondary,
  '&:hover': {
    backgroundColor: theme.palette.background.hovering,
  },
  transition: 'ease all 0.15s',
}));

export const WalletFooter = styled(Box)(({ theme }) => ({
  width: '100%',
  div: {
    width: '100%',
    display: 'flex',
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.main,
    borderRadius: theme.shape.borderRadius,
    '.secure_text': {
      color: theme.palette.text.disabled,
      fontWeight: 500,
    },
    button: {
      color: theme.palette.primary.main,
      fontWeight: 500,
      fontSize: 14,
      padding: theme.spacing(1, 0.5),
    },
  },
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
}));

export const SubtabWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: '#161432',
  alignSelf: 'center',
  borderRadius: '12px',
  // marginTop: '5px',
  marginBottom: '10px',

  '.sub_tab_container': {
    minWidth: 120,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1, 3),

    span: {
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.text.contrast,
      lineHeight: 'normal',
      [theme.breakpoints.down(450)]: {
        fontSize: 12,
      },
    },
  },

  '.sub_tab': {
    display: 'flex',
  },

  '.subtab_icon_Crypto': {
    path: {
      color: '#FFF',
      fill: '#FFF',
    },
  },
}));
