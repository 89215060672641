// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const vi = {
  demo: {
    title: `Vietnamese`,
  },
  blog: 'Diễn đàn',
  only_admin_can_see_this_item: `Chỉ quản trị viên mới có thể xem mục này`,
  crypto: 'Tiền điện tử',
  // Home page
  dark: 'Tối',
  light: 'Sáng',
  Fairness: 'Sự công bằng',
  online: 'trực tuyến',
  welcome_bonus: 'ƯU ĐÃI CHÀO MỪNG',
  up_to: 'LÊN TỚI',
  join_now: 'THAM GIA NGAY',
  // BillTransactionsTable
  type: 'Phân loại',
  time: 'Thời gian',
  amount: 'Số lượng',
  sign_in: 'Đăng nhập',
  login: 'Đăng nhập',
  sign_up: 'Đăng ký',
  password: 'Mật khẩu',
  reset_password: 'Đặt lại mật khẩu',
  enter_password_here: 'Mật khẩu đăng nhập...',
  forgot_your_password: 'Quên mật khẩu?',
  confirm_accessing_site:
    'Bằng cách truy cập trang web, tôi xác nhận rằng tôi đủ 18 tuổi và tôi đã đọc các',
  terms_of_service: 'Điều khoản dịch vụ',
  dont_have_account: 'Bạn chưa có tài khoản?',
  or_log_in_with: 'Hoặc đăng nhập bằng',
  validate_invalid_email_address: 'Vui lòng nhập địa chỉ Email hợp lệ',
  request_reset_email: 'Yêu cầu đã được gửi. Vui lòng kiểm tra email của bạn',
  send_recover_link_to_email:
    'Một email có liên kết khôi phục mật khẩu sẽ được gửi đến email của bạn.',
  recover_password: 'Khôi phục mật khẩu',
  new_password: 'Mật khẩu mới',
  enter_new_password: 'Nhập mật khẩu mới',
  include_lower_uppercase: 'Bao gồm chữ hoa và chữ thường',
  at_least_1_number: 'Ít nhất 1 chữ số',
  minimum_6_char: 'Tối thiểu 6 ký tự',
  agree_terms_service: 'Tôi đồng ý và hiểu',
  terms_conditions: 'Điều khoản và điều kiện.',
  confirm_password: 'Xác nhận mật khẩu mới',
  enter_repeat_password: 'Nhập lại mật khẩu mới',
  already_have_account: 'Bạn đã có tài khoản?',
  'password not match': 'Mật khẩu không khớp',
  existing_password_not_match: 'Mật khẩu hiện tại không khớp',
  new_password_saved: 'Mật khẩu mới đã được lưu',
  profile_settings: 'Cài đặt hồ sơ',
  coming_soon2: 'Sắp ra mắt...',
  under_development: 'Chức năng đang được phát triển',
  page_not_found: 'Xin lỗi, không tìm thấy trang!',
  check_your_spelling:
    'Xin lỗi, chúng tôi không thể tìm thấy trang bạn đang tìm kiếm. Có lẽ bạn đã nhập sai URL? Hãy chắc chắn bạn viết chính xác.',
  go_to_home: 'Đi tới Trang chủ',
  active_devices: 'Thiết bị đang hoạt động',
  device: 'Thiết bị',
  ip_address: 'Địa chỉ IP',
  last_used: 'Lần đăng nhập cuối',
  action: 'Hoạt động',
  // General
  general: 'Tổng quan',
  validate_nickname_length: 'Tên đại diện phải có từ 4 đến 13 ký tự',
  validate_nickname_message:
    'Tên đại diện phải có độ dài từ 4 đến 13 ký tự, không được chứa dấu cách, ký tự hoặc số.',
  copied: 'Đã sao chép',
  update_avatar_success: 'Cập nhật ảnh đại diện thành công',
  verify_email_was_send:
    'Email xác minh đã được gửi, vui lòng kiểm tra email của bạn.',
  something_wrong: 'Đã xảy ra sự cố. Vui lòng thử lại',
  new_nick_name_saved: 'Đã lưu tên đại diện mới',
  new_email_saved: 'Đã lưu Email mới',
  new_phone_number_save: 'Đã lưu số điện thoại mới',
  invalid_phone_number: 'Số điện thoại không hợp lệ',
  update_nickname_failed:
    'Cập nhật tên đại diện không thành công, vui lòng thử lại.',
  update_avatar_failed: 'Cập nhật ảnh đại diện không thành công',
  nickname: 'Tên đại diện',
  maximumFileSize: 'Kích thước tệp tối đa là 4MB',
  your_profile: 'Hồ sơ của bạn',
  is_hidden: 'Đã ẩn',
  is_public_now: 'Đã công khai',
  hide_profile_failed: 'Ẩn hồ sơ không thành công, vui lòng thử lại.',
  enter_nickname: 'Nhập tên đại diện ở đây...',
  confirm: 'Xác nhận',
  nickname_existing: 'Tên đại diện đã tồn tại',
  not_use_symbol:
    'Không sử dụng ký tự đặc biệt, nếu không tài khoản của bạn có thể không được hỗ trợ.',
  hide_profile_title: 'Ẩn dữ liệu game trên hồ sơ của bạn',
  hide_profile_content:
    'Ngay cả khi ẩn đi, hình đại diện và tên người dùng của bạn vẫn luôn hiển thị trong các phòng trò chuyện.',
  hide_profile: 'Ẩn hồ sơ',
  verify: 'Xác minh',
  verified: 'Đã xác minh',
  enter_email: 'Nhập Email vào đây...',
  registered_mail_cannot_changed:
    'Theo chính sách bảo mật của Ohplay.club, email đã đăng ký không thể thay đổi.',
  send_information_to_email:
    'Chúng tôi sẽ gửi thông tin đến địa chỉ email đã đăng ký của bạn.',
  cancel: 'Hủy bỏ',
  verify_email: 'Xác nhận Email',
  verify_phone_number: 'Xác minh số điện thoại',
  resend_code: 'Gửi lại mã',
  did_not_receive_code: 'Không nhận được mã?',
  // Security
  security: 'Bảo mật',
  change_password: 'Đổi mật khẩu',
  current_password: 'Mật khẩu hiện tại',
  enter_current_pw: 'Nhập mật khẩu hiện tại',
  password_not_match: 'Mật khẩu không khớp',
  relogin_after_change_pw: 'Bạn sẽ cần đăng nhập lại sau khi thay đổi mật khẩu',
  setup_google_auth: 'Thiết lập Google Authentication',
  download_google_auth:
    'Tải xuống ứng dụng Google Authenticator cho Iphone hoặc Android',
  save_account_token: 'Lưu Mã thông báo tài khoản (Key) ở nơi an toàn',
  account_token: 'Mã thông tin tài khoản (Key)',
  copy: 'Sao chép',
  need_account_token:
    'Bạn sẽ cần Mã thông báo tài khoản (Key) ở trên để truy cập vào tài khoản của mình trong trường hợp bạn bị mất điện thoại',
  scan_qr_title: 'Quét mã QR bên dưới bằng ứng dụng Google Authenticator',
  enter_token_below: 'Vui lòng nhập mã truy cập bên dưới',
  verify_code_incorrect: 'Mã xác thực không chính xác',
  two_FA_code: 'Mã xác thực 2 yếu tố',
  appear_on_google_auth:
    'Xuất hiện trên ứng dụng Google Authenticator của bạn.',
  enable_2fa: 'Bật xác thực 2 yếu tố',
  enter_2fa_code_to_disable: 'Nhập mã 2FA của bạn để tắt xác thực 2 yếu tố',
  disable_2fa: 'Tắt xác thực 2 yếu tố',
  // Verify
  this_field_is_required: 'Trường này là bắt buộc',
  invalid_date_format: 'Định dạng ngày không hợp lệ',
  at_least_18_years_old: 'Bạn cần ít nhất 18 tuổi',
  step1_update_identification_success:
    'Bước 1: Cập nhật thông tin xác nhận thành công',
  step23_update_identification_photo_success:
    'Bước 2, 3: Cập nhật ảnh xác nhận thành công',
  all_information_submitted: 'Tất cả thông tin đã được gửi',
  thank_you_for_cooperation:
    'Cảm ơn bạn đã hợp tác. Chúng tôi sẽ thông báo cho bạn khi quá trình xét duyệt hoàn thành.',
  verification: 'Xác minh',
  done: 'Xong',
  step: 'Bước',
  back: 'Quay lại',
  next: 'Tiếp theo',
  account_verified: 'Tài khoản của bạn đã được xác minh',
  verify_setup: 'Xác minh cài đặt',
  verify_setup_description1:
    'Để phục vụ bạn tốt hơn, vui lòng cung cấp cho chúng tôi các giấy tờ xác minh dạng gốc. Điều này sẽ bảo vê tài khoản của bạn trong trường hợp khôi phục tài khoản. Điều này cũng giúp đảm bảo mọi quà tặng hoặc phần thưởng sẽ được gửi đến đúng địa chỉ.',
  verify_setup_description2:
    'Tài liệu xác minh được chấp nhận bao gồm ảnh, căn cước công dân, giấy phép lái xe và hộ chiếu. Chúng tôi sẽ liên hệ với bạn qua email sau khi quá trình này hoàn tất.',
  // Verefy => GeneralInfoUploading
  male: 'Nam',
  female: 'Nữ',
  unspecified: 'Không xác định',
  first_name: 'Tên',
  enter_your_first_name: 'Nhập tên của bạn...',
  last_name: 'Họ',
  enter_your_last_name: 'Nhập họ của bạn...',
  gender: 'Giới tính',
  select_gender: 'Chọn giới tính',
  date_of_birth: 'Ngày sinh',
  address: 'Địa chỉ',
  city: 'Thành phố',
  postal_code: 'Mã bưu điện',
  country: 'Quốc gia',
  // Verefy => GovernmentIdUploading
  verify_government_id_description:
    'Vui lòng tải lên căn cước công dân hoặc bằng lái xe còn hiệu lực có các thông tin gồm họ tên đầy đủ, ngày tháng năm sinh, ảnh của bạn và ngày hết hạn.',
  front_side: 'Mặt trước',
  id_card: 'Căn cước công dân',
  back_side: 'Mặt sau',
  passport: 'Hộ chiếu',
  // Verefy => PortraitUploading
  verify_portrait_description:
    'Vui lòng tải lên 1 bức ảnh tự chụp, trong đó bạn cầm căn cước công dân hoặc Hộ chiếu, 1 mảnh giấy có ghi Ohplay.club, tên đăng nhập và thời gian hiện tại. Vui lòng đảm bảo khuôn mặt của bạn và thông tin rõ ràng.',
  your_photo: 'Ảnh của bạn',
  game: 'Trò chơi',
  // Footer
  game_hub: 'Trung tâm trò chơi',
  promotions: 'Khuyến mãi',
  vip_club: 'Câu lạc bộ VIP',
  coco_vip_club: 'Câu lạc bộ VIP COCO',
  learn_vip: 'Learn more about Coco VIP’s system',
  coming_soon: 'Sắp ra mắt',
  refer_friend: 'Giới thiệu bạn bè',
  support_legal: 'Hỗ trợ / Pháp lý',
  responsible_gambling: 'Pháp lý',
  res_gambling: 'Pháp lý',
  about_us: 'Về chúng tôi',
  news: 'Tin tức',
  business_contact: 'Liên hệ kinh doanh',
  work_with_us: 'Làm việc với chúng tôi',
  verify_representative: 'Xác minh đại diện',
  follow_us_on: 'Theo dõi chúng tôi trên',
  go_to_top: 'Lên trên cùng',
  wallet: 'Ví',
  transactions: 'Giao dịch',
  logout: 'Đăng xuất',
  search_your_game: 'Tìm trò chơi của bạn',
  random: 'Ngẫu nhiên',
  need_help: 'Bạn cần giúp đỡ?',
  // WalletDialog
  deposit: 'Nạp tiền',
  withdraw: 'Rút tiền',
  swap: 'Trao đổi',
  verify_account: 'Xác minh tài khoản',
  to_secure_assets: 'Để bảo vệ tài khoản của bạn, vui lòng',
  assets_portfolio: 'Danh mục tài sản',
  search_by_name: 'Tìm kiếm theo tên',
  common_assets: 'Tài sản phổ biến',
  currency_not_available: 'Không tìm thấy đơn vị tiền tệ',
  view_in_fiat: 'Xem trong Fiat',
  hide_small: 'Nhỏ đi',
  // Deposit
  overview: 'Tổng quan',
  history: 'Lịch sử',
  sound: 'Âm thanh',
  deposit_currency: 'Gửi tiền',
  choose_network: 'Chọn mạng',
  deposit_address: 'Địa chỉ gửi tiền',
  notice: 'Thông báo',
  notice_content:
    'Chỉ gửi /currency/ đến địa chỉ nạp tiền này. Địa chỉ hợp đồng thông minh không được hỗ trợ (Liên hệ chúng tôi).',
  // SelectCurrency
  balance: 'Số dư',
  // Swap
  from: 'Từ',
  to: 'đến',
  swap_fee: 'Phí hoán đổi',
  insufficient_ballance: 'Số dư không đủ',
  // AccountNotVerified
  account_not_verified: 'Tài khoản chưa được xác minh',
  drawing_your_balance:
    'Bạn cần xác minh tài khoản trước khi rút số dư của bạn',
  // ConfirmWithdrawDialog
  balance_in_wallet: 'Số dư trong ví',
  withdraw_fee: 'Phí rút tiền',
  receive_amount: 'Số tiền nhận',
  // Withdraw
  choose_asset: 'Chọn tài sản',
  withdraw_address: 'Địa chỉ rút tiền',
  deposit_address_placeholder:
    'Hãy điền thông tin cẩn thận theo loại tiền tệ cụ thể.',
  withdraw_amount: 'Số tiền rút',
  fee: 'Phí',
  // UploadAvatar
  update_photo: 'Cập nhật ảnh',
  upload_photo: 'Tải ảnh lên',
  // Upload
  drop_or_select_file: 'Thả hoặc chọn tệp',
  drop_files_here_or_click: 'Thả tập tin ở đây hoặc nhấp vào',
  browse: 'Duyệt',
  thorough_your_machine: 'Duyệt qua máy của bạn',
  upload_files: 'Tải tệp lên',
  remove_all: 'Loại bỏ tất cả',
  empty_data: 'Dữ liệu trống',
  // TransactionsDialog
  success: 'Thành công',
  processing: 'Đang xử lý',
  audit: 'Kiểm tra',
  insufficient: 'Không đủ',
  new: 'Mới',
  status: 'Trạng thái',
  detail: 'Chi tiết',
  view: 'Xem',
  order_id: 'Mã đơn hàng',
  currency: 'Đơn vị tiền tệ',
  quantity: 'Số lượng',
  all_feature: 'Tất cả tính năng',
  all: 'Tất cả',
  all_currency: 'Tất cả đơn vị tiền tệ',
  Settings: 'Cài đặt',
  // SearchNotFound
  not_found: 'Không tìm thấy',
  no_results_found_for: 'Không tìm thấy kết quả cho',
  checking_for_typos:
    'Hãy thử kiểm tra lỗi chính tả hoặc sử dụng các từ hoàn chỉnh.',
  please_enter_keywords: 'Vui lòng nhập từ khóa',
  // LiveStats
  live_stats: 'Thống kê trực tiếp',
  total_bet_amount: 'Tổng số tiền cược',
  total_profit: 'Tổng lợi nhuận',
  // Game Bank
  more_information: 'Thêm thông tin',
  // Overview
  invest: 'Đầu tư',
  Withdraw: 'Rút',
  // Game Mines
  gems: 'Đá quý',
  // Game MiniPoker
  auto_select: 'TỰ ĐỘNG CHỌN',
  number_of_spin: 'Số lần quay',
  advanced_auto: 'Tự động nâng cao',
  stop_if_anyone_win_jackpot: 'Dừng nếu có ai đó trúng giải độc đắc',
  stop_decrease_by: 'Dừng lại nếu số dư giảm',
  stop_increase_by: 'Dừng lại nếu số dư tăng thêm',
  stop_exceeds_by: 'Dừng lại nếu một lần thắng vượt quá',
  // Bet Control
  cashout_at: 'Rút tiền tại',
  cashout: 'Rút tiền',
  win_amount: 'Số tiền thắng',
  // Bet Detail
  bet_id: 'Mã đặt cược',
  by: 'Bởi: ',
  hidden: 'Ẩn',
  on: 'vào',
  bet_amount: 'Số tiền cược',
  multiplier: 'Hệ số nhân',
  payout: 'Thanh toán',
  play_now: 'Chơi ngay',
  loading: 'Đang tải...',
  bet: 'Cược',
  waiting: 'Vui lòng chờ...',
  bet_next_round: 'Cược (vòng tiếp theo)',
  // ContinueAutoControl
  increase_by: 'Tăng bởi',
  reset: 'Đặt lại',
  manual: 'Thủ công',
  auto: 'Tự động',
  max_profit: 'Lợi nhuận tối đa',
  max_bet: 'Cược tối đa',
  on_loss: 'Khi thua',
  on_win: 'Khi thắng',
  stop_on_lose: 'Dừng khi thua',
  stop_on_win: 'Dừng khi thắng',
  trends: 'Xu hướng',
  all_bet: 'Tất cả cược',
  my_bet: 'Cược của tôi',
  my_bets: 'Cược của tôi',
  high_bets: 'Người cược cao',
  winner: 'Người chiến thắng',
  jackpot_winner: 'Người trúng độc đắc',
  big_winner: 'Người thắng lớn',
  play_real: 'Chơi thực tế',
  play_with_fun: 'Chơi với FUN',
  // RoleBasedGuard
  permission_denied: 'Từ chối quyền truy cập',
  you_not_permission_page: 'Bạn không có quyền truy cập vào trang này',
  min: 'Tối thiểu',
  max: 'Tối đa',
  max2: 'Tối đa',
  set_auto_bet: 'Tự động đặt cược',
  cancel_auto_bet: 'Hủy tự động đặt cược',
  number_of_bet: 'Số lần đặt cược',
  check_information_carefully:
    'Hãy kiểm tra kỹ thông tin đầu tư của bạn trước khi xác nhận',
  invest_now: 'Đầu tư ngay',
  host: 'Nhà cái',
  you_must_specify_a_number: 'Bạn phải chỉ định một số',
  must_be_a_positive_value: 'Giá trị phải là một số dương',
  withdraw_success: 'Rút tiền thành công',
  withdraw_failed: 'Rút tiền thất bại',
  your_total_invest: 'Tổng số tiền đầu tư của bạn',
  your_current_shares: 'Số lượng cổ phần hiện tại của bạn',
  your_balance: 'Số dư của bạn',
  your_profit: 'Lợi nhuận của bạn',
  game_bank: 'Quỹ trả thưởng',
  game_bank_max: 'Quỹ trả thưởng tối đa',
  your_invest: 'Đầu tư của bạn',
  your_share: 'Tỷ lệ cổ phần của bạn',
  host_share: 'Tỷ lệ cổ phần của nhà cái',
  invest_success: 'Đầu tư thành công',
  invest_failed: 'Đầu tư thất bại',
  all_bets: 'Tất cả cược',
  high_rollers: 'Người cược cao',
  jackpot_winners: 'Người trúng độc đắc',
  jackpot_winner2: 'Độc đắc',
  player: 'Người chơi',
  investor: 'Nhà đầu tư',
  share: 'Tỷ lệ cổ phần',
  game_bank_overview: 'Tổng quan',
  game_bank_history: 'Lịch sử',
  game_bank_list: 'Danh sách',
  game_bank_withdraw: 'Rút tiền',
  error_message_server_error: 'Lỗi máy chủ, vui lòng thử lại sau!',
  login_fail: 'Đăng nhập thất bại, vui lòng thử lại!',
  successful_balance_change: 'Thay đổi số dư thành công',
  logout_failed: 'Đăng xuất không thành công, vui lòng thử lại!',
  not_bad: 'KHÔNG TỆ',
  nice: 'TỐT',
  awesome: 'TUYỆT VỜI',
  wicked: 'XUẤT SẮC',
  godlike: 'ĐỈNH CAO',
  to_the_moon: 'LÊN MẶT TRĂNG',
  you_win: 'BẠN THẮNG!',
  bum: 'BÙM!',
  some_thing_went_wrong: 'Đã xảy ra sự cố',
  maximum_number: 'Số ô tối đa được chọn là',
  start_auto_spin: 'Tự Động Quay',
  not_enough_balance: 'Số dư không đủ',
  increase: 'Tăng',
  decrease: 'Giảm',
  win: 'THẮNG',
  lose: 'THUA',
  can_not_get_game_bank: 'Không thể truy cập quỹ trả thưởng',
  total: 'Tổng',
  insufficient_balance: 'Số dư không đủ',
  please_switch_to_another_asset_to_continue_playing:
    'Vui lòng chuyển sang tài sản khác để tiếp tục chơi.',
  insufficient_gamebank: 'Tạm thời không hỗ trợ tài sản này',
  insufficient_gamebank_balance:
    'Trò chơi này tạm thời không hỗ trợ tài sản này, vui lòng chuyển sang loại tài sản khác để tiếp tục chơi.',
  switch: 'Chuyển đổi',
  exit_fullscreen: 'Thoát toàn màn hình',
  fullscreen: 'Toàn màn hình',
  settings: 'Cài đặt',
  currency_do_not_supported: 'Không hỗ trợ đơn vị tiền tệ này',
  no_found_any_currency_can_swap:
    'Không tìm thấy đồng tiền nào có thể hoán đổi',
  withdraw_request_was_sent: 'Yêu cầu rút tiền đã được gửi, chờ xác nhận',
  available_amount_insufficient: 'Số dư khả dụng không đủ',
  page_404: 'Không tìm thấy trang | Ohplay.club',
  remove_session_success: 'Xóa phiên làm việc thành công',
  remove_session_failed: 'Xóa phiên làm việc thất bại',
  in_use: 'Đang sử dụng',
  remove_device: 'Gỡ bỏ thiết bị',
  error_code: {
    EMAIL_IN_USED: 'Email này đã được sử dụng bởi một người dùng khác.',
  },
  live_support: 'Hỗ trợ trực tuyến',
  bill: 'Hóa đơn',
  view_history_in_etherscan: 'Xem lịch sử trên Etherscan',
  phone_number: 'Số điện thoại',
  change: 'Thay đổi',
  phone_second_login:
    'Thêm Số điện thoại làm phương thức đăng nhập thứ hai của bạn.',
  enter_phone_number_: 'Nhập số điện thoại ở đây...',
  welcome_aboard: 'Chào mừng bạn đến với chúng tôi',
  first_deposit_bonus: 'Tiền thưởng nạp tiền lần đầu',
  rewards: 'Phần thưởng',
  deposit_now: 'Nạp tiền ngay',
  just_for_you: 'Dành riêng cho bạn',
  top_games: 'Trò chơi hàng đầu',
  recently_played: 'Đã chơi gần đây',
  recommended: 'Được đề xuất',
  favourites: 'Yêu thích',
  chat_room: 'Phòng chat',
  error_message: {
    server_error: 'Lỗi máy chủ. Vui lòng thử lại sau vài phút',
  },
  help_center: 'Trung tâm trợ giúp',
  faq: 'Câu hỏi thường gặp',
  privacy_policy: 'Chính sách bảo mật',
  provably_fair: 'Hợp lý và công bằng',
  registration_login: 'Đăng ký và đăng nhập',
  coin_accuracy_limit: 'Giới hạn độ chính xác của đồng tiền',
  support: 'Hỗ trợ',
  join_our_community: 'Tham gia cộng đồng của chúng tôi',
  game_info: 'Thông tin trò chơi',
  description: 'Miêu tả',
  provably_fairs: 'Sự công bằng',
  reviews: 'Đánh giá',
  latest_bet_race: 'Cược cuối và cuộc đua',
  about_this_game: 'Về trò chơi này',
  share_this_game: 'Chia sẻ trò chơi này',
  share_on_fb: 'Chia sẻ lên Facebook',
  share_on_whatsapp: 'Chia sẻ lên Whats sapp',
  share_on_twitter: 'Chia sẻ lên Twitter',
  share_on_discord: 'Chia sẻ lên Discord',
  share_on_linkedin: 'Chia sẻ lên LinkedIn',
  share_on_skype: 'Chia sẻ lên Skype',
  release: 'Phát hành',
  return_to_player: 'RTP (Tỷ lệ trả lại người chơi)',
  provider: 'Nhà phát hành',
  max_win: 'Thắng tối đa',
  mobile: 'Thiết bị di động',
  stakes_range: 'Phạm vi cược',
  location: 'Vị trí',
  activities: 'Hoạt động',
  jackpot: 'Độc đắc',
  take_a_photo: 'Chụp ảnh',
  upload_picture: 'Tải ảnh lên',
  information_page: 'Trang thông tin',
  references: 'Người giới thiệu',
  confirm_password_must_match: 'Xác nhận mật khẩu phải khớp',
  no_support: 'Không hỗ trợ',
  invalid_wallet_address: 'Địa chỉ ví không hợp lệ',
  target_multiplier: 'Hệ số nhân',
  unexpected_error: 'Đã xảy ra lỗi không mong đợi. Vui lòng thử lại sau.',
  change_password_success: 'Đổi mật khẩu thành công. Vui lòng đăng nhập lại.',
  upload_image_failed: 'Tải ảnh lên thất bại',
  file_size_limit_10MB: 'Giới hạn kích thước tệp là 10MB',
  roll_over: 'Chuyển vùng',
  win_chance: 'Tỷ lệ thắng',
  profit_on_next_tile: 'Lợi nhuận trên ô tiếp theo',
  pick_a_tile_randomly: 'Chọn một ô ngẫu nhiên',
  pay_table: 'Bảng trả thưởng',
  game_rule: 'Luật chơi',
  number_of_bets: 'Số lần đặt cược',
  on_lose: 'Khi thua',
  stop_on_profit: 'Dừng khi có lãi',
  bet_failed: 'Đặt cược thất bại',
  stop_auto_bet: 'Dừng tự động cược',
  start_auto_bet: 'Bắt đầu tự động cược',
  show_more: 'Xem thêm',
  show_less: 'Ẩn bớt',
  profit_amount: 'Lợi nhuận',
  currency_not_support: 'Tiền tệ không được hỗ trợ',
  login_to_play: 'Đăng nhập để CHƠI',
  yes: 'Có',
  how_to_play: 'Cách chơi',
  help: 'Hỗ trợ',
  start_bet_round_failed: 'Cược thất bại',
  cashout_failed: 'Rút tiền thất bại',
  auto_bet_round: 'Cược tự động',
  bet_success: 'Đặt cược thành công',
  play_now2: 'CHƠI NGAY',
  game_not_available_now: 'Trò chơi hiện tại không có sẵn',
  invalid_bet_params: 'Số tiền đặt cược không hợp lệ',
  under_min_bet: 'Dưới mức đặt cược tối thiểu',
  invalid_bet_state: 'Trạng thái đặt cược không hợp lệ',
  over_max_bet: 'Đặt cược vượt quá giới hạn',
  gamebank_not_available: 'Gamebank không hỗ trợ',
  insufficient_amount: 'Số tiền không đủ',
  win_rate: 'Tỷ lệ thắng',
  welcome_to_Coco_game: 'Chào mừng bạn đến với Ohplay.club',
  invalid_email_format: 'Định dạng email không hợp lệ',
  resend_after: 'Gửi lại sau',
  second: 'giây',
  only_available_at_large_resolutions:
    'Chỉ khả dụng ở độ phân giải lớn > 1600px (xl)',
  fairness: 'Minh bạch',
  available_invest_amount: 'Số tiền đầu tư khả dụng',
  gamebank_chart: 'Biểu đồ đầu tư',
  next_round: 'Vòng tiếp theo',
  understand_trend_chart: 'Hiểu biểu đồ xu hướng',
  round_has_been_bet: 'Vòng này đã được đặt cược, hủy từ vòng tiếp theo',
  crash_server_disconnect: 'Mất kết nối máy chủ do sự cố...',
  coco_info:
    'Trang web này thuộc sở hữu và được vận hành bởi Orisun N.V., một công ty được đăng ký và thành lập theo luật pháp của Curaçao, với số đăng ký 163631 và địa chỉ đăng ký tại Zuikertuintjeweg Z/N (Tòa nhà Zuikertuin Tower), Curaçao.',
  leverage: 'Đòn bẩy',
  rank: 'Thứ hạng',
  prize: 'Phần thưởng',
  wagers: 'Đặt cược',
  crash_result: 'Kết quả',
  reset_after: 'Đặt lại sau',
  rule: 'Quy tắc',
  club_member: 'Thành viên câu lạc bộ',
  become_a_coco: 'Trở thành thành viên của coco',
  vip_club_subtitle1:
    'Những người chơi chơi lâu nhất và đặt cược cao nhất sẽ nhận được lời mời độc quyền tham gia:',
  vip_club_subtitle2:
    'Điều đó mang lại trải nghiệm cao cấp và tiền thưởng cho các thành viên.',
  your_vip_progress: 'Tiến trình VIP của bạn',
  your_vip_progress_tooltip:
    'Vui lòng đăng nhập trước sau đó xem tiến trình VIP của bạn',
  total_wager: 'Tổng cược',
  your_vip_progress_description:
    'Tất cả mức cược sẽ được quy đổi sang USD theo tỷ giá hiện tại.',
  how_does_it_work: 'Làm thế nào nó hoạt động?',
  the_rewards: 'Phần thưởng',
  frequently_asked_questions: 'Câu hỏi thường gặp',
  vip_frequent_questions_subtitle:
    'Chúng tôi khuyến khích bạn liên hệ với bộ phận hỗ trợ hoặc người quản lý VIP của bạn nếu bạn có bất kỳ câu hỏi nào',
  to_see_your_vip_progress: 'để xem tiến trình VIP của bạn.',
  wager_rate: 'Cược $1,00 = 1XP',
  all_wagers_converted_to_usd:
    'Tất cả cược được quy đổi sang USD theo tỷ giá hiện tại',
  vip_club_benefit: 'Quyền lợi Câu lạc bộ VIP',
  vip_club_details: 'Chi tiết câu lạc bộ VIP',
  benefit_table: 'Bảng lợi ích',
  wager_contest: 'Giải thưởng đặt cược',
  game_of_the_day: 'Trò chơi trong ngày',
  play_to_earn: 'Chơi để kiếm tiền cược',
  time_remaining: 'Thời gian còn lại',
  wagered: 'Đã đặt cược',
  wager_contest_information: 'Thông tin về Giải thưởng Đặt cược',
  daily_wagering_contest_rules: 'Luật chơi Giải thưởng đặt cược theo ngày',
  the_daily_race:
    'Cuộc thi "Cuộc đua hàng ngày" diễn ra hàng ngày từ 00:00 UTC đến 23:59 UTC, bắt đầu từ',
  daily_contest_prize_pool: 'Tổng quỹ giải thưởng theo ngày',
  straight_flush_with_jack: 'Sảnh chúa J',
  straight_flush: 'Thùng phá sảnh',
  four_of_a_kind: 'Tứ quý',
  full_house: 'Cù lũ',
  flush: 'Thùng',
  straight: 'Sảnh',
  three_of_a_kind: 'Bộ ba',
  tow_pair: 'Hai đôi',
  pair_of_jacks_or_better: 'Một đôi từ J',
  you_won: 'Bạn thắng',
  show_all: 'Xem tất cả',
  notification: 'Thông báo',
  platform: 'Hệ thống',
  personal: 'Cá nhân',
  no_notifications_at_the_moment:
    'Hiện tại không có thông báo nào. Vui lòng kiểm tra lại sau.',
  What_up: 'Có gì mới',
  mode: 'Kiểu',
  contrast: 'Tương phản',
  direction: 'Hướng',
  layout: 'Bố cục',
  stretch: 'Mở rộng',
  presets: 'Thiết lập sẵn',
  status_success: 'Trạng thái: hoàn thành',
  status_pending: 'Trạng thái: đang chờ',
  swap_successfully: 'Hoán đổi thành công!',
  swap_failed: 'Hoán đổi không thành công, vui lòng thử lại sau',
  coco_member: 'Trở thành thành viên Coco',
  play_your_favorite_games: 'Chơi các trò chơi yêu thích của bạn',
  instantly_sign_up:
    'Đăng ký ngay lập tức và bắt đầu đặt cược vào Casino hoặc nhà cái thể thao.',
  unlock_further_benefits:
    'Thỏa thích trải nghiệm trò chơi, đạt đến các cấp độ mới và mở khóa thêm những lợi ích.',
  rank_claim_bonus: 'Tăng thứ hạng của bạn và nhận phần thưởng',
  go_up_to_the_next_rank:
    'Đi lên thứ hạng tiếp theo! Mỗi cấp độ có phần thưởng và nhiều cơ hội hơn!',
  you_start_your_gaming:
    'Bạn bắt đầu con đường chơi game của mình từ Hạng 1 – NGƯỜI MỚI.',
  the_more_you_play:
    'Bạn chơi càng nhiều – Thứ hạng của bạn càng cao. Nó tăng lên theo số tiền đặt cược khi chơi trên Ohplay.club. Tất cả cược được quy đổi sang USD theo tỷ giá hiện tại',
  mark_all_as_read: 'Đánh dấu tất cả là đã đọc',
  eligible_VIP_member: 'Tôi có đủ điều kiện để trở thành thành viên VIP không?',
  how_much_has_coco_given_out_in_bonuses:
    'Coco đã trao đi bao nhiêu tiền thưởng?',
  why_is_Coco_VIP_program_the_best:
    'Tại sao chương trình VIP của Coco lại là tốt nhất?',
  what_does_affect_the_weekly_monthly:
    'Những yếu tố nào ảnh hưởng đến tiền thưởng hàng tuần/hàng tháng?',
  information_to_become_a_VIP_member:
    'Tôi có phải cung cấp thêm thông tin gì để trở thành thành viên VIP không?',
  coco_VIP_telegram_channel: 'Tôi có thể tìm kênh Telegram VIP của Coco ở đâu?',
  one_min: '1 phút',
  five_min: '5 phút',
  one_hour: '1 giờ',
  for_hours: '4 giờ',
  one_day: '1 ngày',
  investment: 'Đầu tư',
  my_investment: 'Đầu tư của tôi',
  what_upp: 'Có gì mới ???',
  hide: 'Ẩn',
  play: 'chơi',
  stake_and_earn: 'Đặt cược và kiếm tiền',
  //
  vip_level_comparison: 'Bảng so sánh cấp độ VIP',
  bronze: 'Đồng',
  silver: 'Bạc',
  gold: 'Vàng',
  platinum: 'Bạch kim',
  diamond: 'Kim cương',
  level_up_bonus: 'Tiền thưởng tăng cấp',
  surprise_box: 'Hộp bất ngờ',
  free_spin_wheel_enable: 'Kích hoạt bánh xe quay miễn phí',
  surprise_box_upgraded: 'Hộp bất ngờ được nâng cấp',
  weekly_bonus: 'Tiền thưởng hàng tuần',
  chat_enable: 'Bật trò chuyện',
  upload_avatar_enable: 'Cho phép tải lên hình đại diện',
  vip_support_enable: 'Hỗ trợ VIP Được Kích Hoạt',
  surprise_box_upgraded_2: 'Hộp bất ngờ được nâng cấp (lên tới 1,8 triệu USDT)',
  where_coco_vip_telegram_channel:
    'Tôi có thể tìm kênh Telegram VIP của Coco ở đâu?',
  we_have_given_over_1_billion:
    'Chúng tôi đã trao hơn 1 tỷ USD tiền thưởng. Đây chính là lý do chúng tôi có chương trình VIP trực tuyến tốt nhất. Chúng tôi thực sự tin tưởng vào việc thưởng cho người chơi vì lối chơi và lòng trung thành của họ.',
  me: 'tôi',
  result: 'Kết quả',
  crush_of_crash: 'Crush of Crash',
  // Banner new
  earn_real_money: 'Kiếm tiền thật',
  the_daily_game: 'Trò chơi hàng ngày',
  earn_everyday_with_original_games: 'Kiếm tiền hàng ngày với các trò chơi gốc',
  daily_race: 'Cược hằng ngày',
  wager: 'CƯỢC',
  and: 'VÀ',
  everyday: 'MỖI NGÀY',
  crush_: 'ĐÈ BẸP',
  the_crash: 'SỰ CỐ',
  show_off_your_prediction_skills: 'Thể hiện khả năng dự đoán của bạn',
  deposit_now2: 'NẠP TIỀN NGAY',
  on_1st_deposit: 'Vào lần nạp tiền đầu tiên',
  // Just for you
  free_play: 'CHƠI MIỄN PHÍ',
  with_fun_coin: 'Với đồng xu FUN',
  show_me: 'CHO TÔI XEM',
  low_risk_high_rewards: 'Rủi ro thấp, Phần thưởng cao',
  prize_100000: 'GIẢI THƯỞNG 100.000 USD',
  win_1000x_jackpot: 'Giành độc đắc 1000x',
  // Notification
  balance_change: 'Thay đổi số dư',
  your_casino: 'Casino của bạn',
  // Referral
  spread_the_word_and: 'Chia sẻ thông tin và',
  earn_reward: 'Nhận thưởng',
  campaign_name: 'Tên chiến dịch',
  id_number: 'Số ID',
  registered: 'Đăng ký',
  total_deposits: 'Tổng nạp tiền',
  last_deposit: 'Lần nạp tiền cuối',
  vip_point: 'Điểm VIP',
  commission: 'Hoa hồng',
  referral: 'Giới thiệu',
  register_date: 'Ngày đăng ký',
  display_name: 'Tên hiển thị',
  agents: 'Đại lý',
  transaction_date: 'Ngày giao dịch',
  transaction_type: 'Loại giao dịch',
  export: 'Trích xuất',
  campaigns: 'Chiến dịch',
  create_a_new_campaign: 'Tạo chiến dịch mới',
  claim_income: 'Nhận thu nhập',
  per_players: 'Theo Người chơi',
  per_agents: 'Theo Đại lý',
  per_day: 'Theo Ngày',
  per_week: 'Theo Tuần',
  per_month: 'Theo Tháng',
  per_country: 'Theo Nước',
  date: 'Ngày',
  total_income: 'Tổng thu nhập',
  total_user_register: 'Tổng người chơi đăng ký',
  total_user_bet: 'Tổng người chơi đặt cược',
  total_transaction: 'Tổng giao dịch',
  refer_description:
    'Giới thiệu bạn bè đến Chương trình liên kết của Ohplay.club và kiếm tiền hoa hồng cho mỗi người dùng đăng ký và chơi qua liên kết của bạn, bất kể kết quả của họ như thế nào',
  download_banner: 'Tải Banner',
  search: 'Tìm kiếm',
  clicks: 'Nhấp chuột',
  referrals: 'Giới thiệu',
  deposit_users: 'Người nạp tiền',
  total_commission: 'Tổng hoa hồng',
  commission_rate: 'Tỷ lệ hoa hồng',
  features: 'Đặc trưng',
  link: 'Đường dẫn',
  code: 'Mã',
  today: 'Hôm nay',
  this_week: 'Tuần này',
  last_week: 'Tuần trước',
  overall: 'Tổng quan',
  commission_available: 'Hoa hồng có sẵn',
  claim: 'Nhận',
  log_in: 'Đăng nhập',
  to_see_your_rank: 'để xem thứ hạng của bạn',
  start_in: 'Bắt đầu vào ...',
  reconnect: 'Kết nối lại',
  controls: 'điều khiển',
  leaderboard: 'bảng xếp hạng',
  bet_next: 'cược tiếp theo',
  betting: 'đang cược',
  // Tip
  message_is_required: 'Tin nhắn là bắt buộc',
  tip_with_any_currency_in_CoCo: 'Mẹo với bất kỳ loại tiền tệ nào trong CoCo',
  receiver_id: 'ID người nhận',
  enter_user_id: 'Nhập ID người dùng',
  transaction_fee: 'Phí giao dịch',
  message: 'Tin nhắn',
  give_your_friends_some_messages_here:
    'Gửi cho bạn bè của bạn một số tin nhắn ở đây...',
  continue: 'Tiếp tục',
  successfully_tipped: 'Đã tip thành công',
  you_are_tipping_to: 'Bạn đang tip',
  your_tip_request_has_been_confirmed:
    'Yêu cầu tiền boa của bạn đã được xác nhận.',
  you_can_track_the_progress_on_the_wallet_transaction_history:
    'Bạn có thể theo dõi tiến trình trên Lịch sử giao dịch trên Ví.',
  view_history: 'Xem lịch sử',
  tip_failed: 'Mẹo không thành công',
  your_tip_request_was_unsuccessful_due_to_an_abnormality:
    'Yêu cầu tiền boa của bạn không thành công do có sự bất thường.',
  please_try_again_later: 'Vui lòng thử lại sau.',
  ok_i_got_it: 'Được rồi, tôi hiểu rồi',
  tip_information: 'Thông tin mẹo',
  password_is_required: 'Cần có mật khẩu',
  tip_verification: 'Xác minh tiền boa',
  authentication_secure: 'Xác thực an toàn',
  to_process_tipping_please: 'Để xử lý tiền boa, vui lòng',
  first_to_secure_your_assets: 'đầu tiên để bảo đảm tài sản của bạn',
  step_verification: 'Xác minh 2 bước',
  enter_your_login_password: 'Nhập mật khẩu đăng nhập của bạn',
  enter_the_2fa_code_appear_on_your_google_authenticator_app:
    'Nhập Mã 2FA xuất hiện trên Ứng dụng Google Authenticator của bạn',
  authorize: 'Ủy quyền',
  // 30/09
  sign_up_in_and_earn: 'Đăng ký / Đăng nhập và kiếm tiền',
  learn_more_about_our_affiliate_program:
    'Tìm hiểu thêm về chương trình liên kết của chúng tôi',
  referral_link: 'Liên kết giới thiệu',
  create: 'Tạo nên',
  referral_users: 'Người dùng giới thiệu',
  registered_date: 'Ngày đăng ký',
  send_tip: 'Gửi TIP',
  delete: 'Xóa bỏ',
  accept: 'Chấp nhận',
  close: 'Đóng',
  member_downline: 'Tuyến dưới của thành viên',
  new_player: 'Người chơi mới',
  claim_commission_failed: 'Ủy ban yêu cầu bồi thường không thành công',
  claim_commission_successfully: 'Hoa hồng yêu cầu thành công',
  create_campaign_successfully: 'Tạo chiến dịch thành công',
  create_campaign_failed: 'Tạo chiến dịch không thành công',
  any_date: 'Bất kỳ ngày nào',
  // Mini CoCo
  stop_if_single_win_exceeds_by: 'Dừng lại nếu chiến thắng duy nhất vượt quá',
  // Host
  show_filter: 'Hiển thị bộ lọc',
  filter: 'Lọc',
  default_campaign: 'Chiến dịch mặc định',
  select_all: 'Chọn tất cả',
  role: 'vai trò',
  hello_world: 'Chào thế giới',
  // GameBank => Overview
  gb_capacity: 'Dung lượng GB',
  game_bank_capacity: 'Dung lượng ngân hàng trò chơi',
  the_maximum_amount_that_you_can_invest:
    'Số tiền tối đa mà bạn có thể đầu tư.',
  // Crash
  finish_bet: 'Kết thúc cược',
  finishing_bet: 'Cược kết thúc',
  // Referral
  delete_invitation: 'Xóa lời mời',
  you_had_been_invited_to_become_an_agent_by: 'Bạn đã được mời làm đại lý bởi',
  confirming_to_delete_this_invitation:
    'Bằng cách xác nhận xóa lời mời này, hành động này sẽ không được hoàn tác. Vẫn muốn quyết định?',
  successfully_accepted_invitation: 'Bạn đã chấp nhận lời mời thành công',
  the_house: 'Ngôi nhà',
  invited_you_to_become_an_agent: 'mời bạn trở thành đại lý',
  campaign_name_1: 'Tên chiến dịch 1',
  available_commission: 'Hoa hồng có sẵn',
  there_are_no_commission: 'Không có hoa hồng',
  please: 'Vui lòng',
  login2: 'đăng nhập',
  to_see_your_rewards: 'để xem phần thưởng của bạn',
  you_have_successfully_accepted_the_invitation:
    'Bạn đã chấp nhận lời mời thành công',
  //
  downline_report: 'Báo cáo tuyến dưới',
  referral_faq_subscription:
    'Để biết thêm chi tiết về chương trình liên kết, vui lòng liên hệ ',
  internet_connection_restored: 'Đã khôi phục kết nối Internet',
  lost_connect_to_network: 'Mất kết nối với Mạng',
  // 10/10
  user_profile: 'Thông tin người dùng',
  leave_a_tip: 'Để lại một mẹo',
  your_commission_rate: 'Tỷ lệ hoa hồng của bạn',
  affiliate_terms_conditions: 'Điều khoản & Điều kiện liên kết',
  REFERRAL_CODE_OR_NAME_EXISTS: 'Tên chiến dịch hoặc mã giới thiệu đã tồn tại',
  swap_unavailable: 'Cặp trao đổi hiện không được hỗ trợ, vui lòng thử lại sau',
  please_enter_swap_amount: 'Vui lòng nhập số tiền hoán đổi',
  become_our_agent_Contact_business:
    'Trở thành đại lý của chúng tôi? Liên hệ kinh doanh:',
  earn_commission_rewards:
    'Kiếm phần thưởng hoa hồng bất cứ khi nào bạn bè của bạn đặt cược vào trò chơi của chúng tôi',
  turn_your_friends_and_followers_into_weekly_commissions:
    'Biến bạn bè và người theo dõi của bạn thành hoa hồng hàng tuần với chương trình liên kết của Coco. Nó đơn giản, có lợi nhuận và hoàn toàn minh bạch',
  // FAQ
  why_should_i_be_a_Coco_affiliate:
    'Tại sao tôi nên trở thành Cộng tác viên của Coco?',
  adding_our_link_and_or_banner_to_your_website:
    'Nếu bạn có một trang web hoặc kênh xã hội có lưu lượng truy cập nhất định, tại sao không tối đa hóa tiềm năng của nó? Bằng cách thêm liên kết và/hoặc biểu ngữ của chúng tôi vào trang web của bạn, bạn có thể tạo một cách mới, hiệu quả để kiếm thêm thu nhập ngay lập tức.',
  how_do_I_get_paid_for_being_an_affiliate:
    'Làm thế nào để tôi được trả tiền khi trở thành một chi nhánh?',
  the_banner_or_link_you_create_contains_your_personal_tracking_code:
    'Biểu ngữ hoặc liên kết bạn tạo có chứa mã theo dõi cá nhân của bạn. Khi người chơi truy cập Coco, mã này được lưu trữ trong cookie trên máy tính của họ. Khi người dùng đăng ký, chúng tôi sẽ xác định họ là người được bạn giới thiệu. Người chơi này sẽ liên kết vĩnh viễn với bạn và bạn tiếp tục kiếm tiền hoa hồng từ hoạt động của họ suốt đời.',
  how_a_new_player_linked_to_me:
    'Làm thế nào một người chơi mới liên kết với tôi?',
  how_exactly_is_my_affiliate_commission_calculated:
    'Hoa hồng liên kết của tôi được tính chính xác như thế nào?',
  the_commission_for_each_bet: `Hoa hồng cho mỗi lần đặt cược được tính dựa trên công thức dưới đây: Hoa hồng = Tiền cược * lợi nhuận nhà cái của trò chơi * tỷ lệ hoa hồng trong đó:
      - Tổng số tiền cược: Tổng số tiền cá cược trò chơi được tạo ra bởi tất cả người dùng được đơn vị liên kết giới thiệu.
      - Tỷ lệ kèo nhà cái: Tỷ lệ thua trung bình trên số tiền đặt cược ban đầu. Tỷ lệ lợi nhuận nhà cái của mỗi trò chơi được xác định là 1 trừ RTP (Trở lại người chơi).
      - Tỷ lệ hoa hồng: Tỷ lệ phụ thuộc vào vai trò liên kết của người dùng và được hiển thị trên trang Liên kết.`,
  how_to_join_the_affiliate_program:
    'Làm thế nào để tham gia chương trình liên kết?',
  if_you_already_have_a_Coco_account: `Nếu bạn đã có tài khoản Coco, chỉ cần làm theo các bước sau:
      1. Truy cập trang liên kết.
      2. Tạo chiến dịch mới bằng mã giới thiệu duy nhất của bạn.
      3. Chia sẻ liên kết giới thiệu của bạn trên trang web, phương tiện truyền thông xã hội hoặc bất kỳ nền tảng có lưu lượng truy cập cao nào.
      4. Yêu cầu hoa hồng hàng tuần của bạn từ những người dùng mà bạn đã giới thiệu.`,
  how_can_i_keep_track_of_my_performance:
    'Làm cách nào tôi có thể theo dõi hiệu suất của mình?',
  once_your_affiliate_link_is_generated:
    'Khi liên kết liên kết của bạn được tạo, bạn có thể đăng nhập vào bảng điều khiển liên kết. Ở đó, bạn có quyền truy cập để xem tất cả số liệu thống kê của mình và có thể lọc thông tin trong báo cáo dựa trên nhiều yếu tố khác nhau.',
  i_have_big_audience_how_i_can_get_special_deals:
    'Tôi có lượng khán giả lớn làm thế nào tôi có thể nhận được ưu đãi đặc biệt?',
  if_you_have_a_website_with_substantial_traffic:
    'Nếu bạn có trang web có lưu lượng truy cập đáng kể hoặc tài khoản mạng xã hội có lượng khán giả lớn, bạn có thể liên hệ với chúng tôi theo địa chỉ business@Ohplay.club để nhận ưu đãi độc quyền.',
  // Crash
  maximum_is_9900: `Tối đa là "9900"`,
  minium_is_101: `Tối thiểu là "1,01"`,
  insufficient_currency: 'Không đủ tiền',
  this_game_does_not_support_this_currency:
    'Trò chơi này không hỗ trợ loại tiền này, vui lòng chuyển sang tài sản khác để tiếp tục chơi.',
  unknown: 'không xác định',
  you_should_verify_account_first_before_tipping_your_friends:
    'Bạn nên xác minh tài khoản trước \n trước khi tip cho bạn bè',
  must_be_only_digits: 'Chỉ được phép có chữ số',
  min_length_ID_at_least_14_number: 'ID có độ dài tối thiểu ít nhất 14 số',
  receiver_is_required: 'Cần có bộ thu',
  tip_amount_must_specify_a_number: 'Số tiền tip phải chỉ định một số',
  tip_amount_must_greater_than_0: 'Số tiền tip phải lớn hơn 0',
  tip_amount_is_required: 'Số tiền tip là bắt buộc',
  how_to_see_other_ID: 'Làm thế nào để xem ID khác?',
  get_your_friends_ID_from_their_profiles:
    'Nhận ID bạn bè của bạn từ hồ sơ của họ.',
  tips_amount: 'Số tiền tip',
  total_spending_amount: 'Tổng số tiền chi tiêu',
  withdrawing_your_balance:
    'Bạn nên xác minh tài khoản của mình trước khi rút số dư',
  pls_enter_code_send_to_tel: 'vui lòng nhập mã gửi tới điện thoại',
  please_enter_withdraw_address: 'Vui lòng nhập địa chỉ rút tiền',
  your_profile_had_been_verified: 'Hồ sơ của bạn đã được xác minh',
  SWAP_UNAVAILABLE: 'Thiếu cân bằng',
  // Maintain
  this_page_is_under_maintenance:
    'RẤT LẤY LÀM TIẾC! Trang này đang được bảo trì',
  please_give_us_some_minutes_to_fix_the_issue:
    'Vui lòng cho chúng tôi vài phút để khắc phục sự cố',
  // 404
  we_cant_find_that_page: `Rất tiếc! Chúng tôi không thể tìm thấy trang đó.`,
  you_can_still_enjoy_the_best_casino_experience_at_Coco: `Tuy nhiên, đừng lo lắng, bạn vẫn có thể tận hưởng trải nghiệm sòng bạc TỐT NHẤT tại Ohplay.club.`,
  //
  region_prohibited: 'Khu vực bị cấm',
  coco_game_is_not_available_in_your_region:
    'Rất tiếc, Ohplay.club không có sẵn ở khu vực của bạn.',
  due_to_our_gaming_license_we_cannot_accept_players_from_your_country:
    'Do giấy phép chơi trò chơi của chúng tôi, chúng tôi không thể chấp nhận người chơi từ quốc gia của bạn. Liên hệ với chúng tôi qua support@Ohplay.club nếu bạn cần hỗ trợ thêm.',
  congrats: 'chúc mừng',
  won_the_most: 'thắng nhiều nhất!',
  coco_took_it_all: 'Coco đã lấy tất cả!',
  page: 'Trang',
  refresh: 'Làm mới',
  // Popup
  register_to_get: 'Đăng ký để có được',
  player_to_earn_the: 'Người chơi để kiếm được',
  $10_prize: 'giải thưởng $10',
  sign_up_now: 'Đăng ký ngay',
  // Popup 2
  you_receive: 'BẠN NHẬN ĐƯỢC',
  weekly: 'hàng tuần',
  see_detail: 'xem chi tiết',
  swap_amount_need_to_larger_than_min:
    'Số tiền hoán đổi cần lớn hơn mức tối thiểu',
  crypto_play: 'Chơi với tiền điện tử',
  fun_play: 'Chơi với đồng FUN',
  // Popup 3
  play_with: 'CHƠI VỚI',
  earn: 'KIẾM',
  real: 'ĐÔ LA',
  dollars: 'THẬT',
  big_wins: 'Thắng lớn',
  what_is_gift_code: 'Mã quà tặng là gì?',
  gift_code_info:
    'Mã quà tặng giống như một mã đặc biệt được tạo thành từ các chữ cái và số. Khi đổi mã này, bạn sẽ nhận được phần thưởng đặc biệt từ',
  gift_code_cod:
    'Mỗi gift code đều có giá trị và thời hạn sử dụng riêng. Tất cả phần thưởng được thanh toán dưới dạng mã thông báo COD',
  there_are_various_ways_to_get_Coco_gift_codes:
    'Có nhiều cách khác nhau để nhận mã quà tặng Coco, chẳng hạn như tham gia các sự kiện truyền thông xã hội độc quyền, hoạt động cộng đồng, tiền thưởng hoặc nhiệm vụ đặc biệt.',
  how_to_get_Coco_gift_code: 'Làm thế nào để nhận được mã quà tặng Coco?',
  how_to_use_Coco_gift_code: 'Cách sử dụng mã quà tặng Coco?',
  simply_enter_the_gift_code:
    'Chỉ cần nhập mã quà tặng và phần thưởng sẽ tự động được thêm vào số dư của bạn.',
  giftcode_required: 'Yêu cầu mã quà tặng',
  bonus_code_claimed_successfully: 'Mã thưởng đã được xác nhận thành công',
  claim_your_free_reward: 'Yêu cầu phần thưởng miễn phí của bạn',
  enter_your_giftcode: 'Nhập mã quà tặng của bạn',
  claim_bonus: 'Yêu cầu tiền thưởng',
  what_is_giftcode: 'Giftcode là gì',
  what_is_COCO_giftcode: 'Mã quà tặng COCO là gì?',
  got_it: 'Hiểu rồi',
  won: 'thắng',

  // 8/12
  use_your_COD_gift_to_bet_and_win_at_Cocogame:
    'Sử dụng quà tặng COD của bạn để đặt cược và giành chiến thắng tại Ohplay.club',
  gift_code: 'Mã quà tặng',
  // 15/12
  mini_Coco_is_Allways_3x3_Slot_machine_game:
    'Mini Coco là trò chơi máy đánh bạc 3x3 27 dòng.',
  rule_content: `Quy tắc: các ký hiệu bắt đầu từ cột đầu tiên, liên tiếp từ trái sang phải. Khoản thanh toán chỉ tính giá trị cao nhất trên một dòng.`,
  jackpot2: 'giải độc đắc',
  explode_if_you_collect_3_WILD: 'Phát nổ nếu bạn thu thập được 3 WILD.',
  wild_can_subsitute_for_all_symbols:
    'Wild có thể thay thế cho tất cả các biểu tượng.',
  to_coco_vip_club: 'Đến Câu lạc bộ VIP COCO',
  welcome_vip_club_text:
    'Người chơi chơi lâu nhất và đặt cược cao nhất sẽ nhận được lời mời độc quyền vào Câu lạc bộ VIP. Chúng tôi cung cấp cho các thành viên của mình trải nghiệm cao cấp và tiền thưởng.',
  welcome_to_only: 'Chào mừng bạn đến với',
  welcome_only: 'chào mừng',
  to_join_club: 'tham gia Câu lạc bộ VIP của chúng tôi',
  sign_in_or_sign_up: '登录注册',
  how_vip_club_work: 'Đăng nhập/ Đăng ký',
  ranking_system_title: 'Hệ thống xếp hạng COCO VIP',
  ranking_system_text:
    'Tham gia hệ thống xếp hạng nền tảng của chúng tôi để mở ra một thế giới cơ hội và sự công nhận! Hãy tham gia cùng chúng tôi ngay hôm nay để nâng cao hồ sơ của bạn, chơi các trò chơi của chúng tôi và tận hưởng những lợi ích khi trở thành người chơi nổi bật. Đừng bỏ lỡ cơ hội được công nhận - hãy trở thành một phần của câu chuyện thành công ngay hôm nay!',
  start_your_referrals: 'Bắt đầu đề xuất của bạn',

  // 3/1
  series: 'Loạt',
  multiple: 'Nhiều',
  result_will_be_display_here: 'Kết quả sẽ được hiển thị ở đây',
  bet_head: 'Đầu cược',
  bet_tail: 'Đặt cược đuôi',
  instant: 'Lập tức',
  multiply: 'nhân',
  coin_flip_instant_mode_desc:
    'Đơn giản chỉ cần đặt cược Đầu hoặc Đuôi hoặc thiết lập Đặt cược tự động',
  auto_bet_setup: 'Thiết lập cược tự động',
  you_win_title: 'Bạn thắng',
  select_network_to_continue: 'Để tiếp tục, hãy chọn mạng',
  ensure_withdraw_address:
    'Hãy đảm bảo bạn nhập đúng địa chỉ; nếu không, tiền của bạn sẽ bị mất và không thể lấy lại được.',
  click_bet_to_choose_coin_side: 'Bấm đặt cược để chọn mặt xu',

  copied_share_in_chat: 'Đã sao chép, chia sẻ trong cuộc trò chuyện!',
  copied_share_online: 'Sao chép, chia sẻ trực tuyến!',
  game_id: 'Mã số game',
  server_seed_hash: 'Hạt giống máy chủ (Băm)',
  client_seed: 'Hạt giống khách hàng',
  nonce: 'Nonce',
  about_COD: 'Về COD',
  coco_dollar_info:
    'COD (Coco Dollar) là loại tiền tệ đặc biệt được COCO.GAME ra mắt. Với COD, bạn có thể tham gia trò chơi hoặc đưa ra lời khuyên cho người chơi khác.',
  can_COD_be_exchanged_to_any_other_currency:
    'COD có thể được đổi sang bất kỳ loại tiền tệ nào khác không?',
  you_can_swap_cod:
    'Bạn có thể hoán đổi COD lấy các loại tiền tệ khác bất kỳ lúc nào bằng cách sử dụng Coco Swap, dựa trên tỷ giá hối đoái hiện tại.',
  get_daily_bonus_up_to_5000_COD:
    'Nhận tiền thưởng hàng ngày lên tới 5.000 COD',
  by_playing_games_tips_coin_dropping_and_rain_bonus:
    'Bằng cách chơi trò chơi, mẹo, thả xu và thưởng mưa.',
  see_how: 'Xem cách',
  how_to_unlock_COD: 'Cách mở khóa COD',
  locked_COD_is_earned_through_specific_bonuses_like_deposit_bonuses:
    'COD bị khóa kiếm được thông qua các phần thưởng cụ thể như tiền thưởng gửi tiền.',
  unlocking_COD_is_simple:
    'Mở khóa COD rất đơn giản; nó tương tự như cào lại và mở khóa tương ứng với số tiền cược của bạn.',

  // Chat
  please_slow_down: 'Vui long chậm lại.',
  links_must_include_your_website_domain:
    'Các liên kết phải bao gồm tên miền trang web của bạn.',
  chat_is_hidden: 'Trò chuyện bị ẩn',
  show_chat: 'Hiển thị cuộc trò chuyện',
  pinned_message: 'Tin nhắn được ghim',

  // 12/01
  deposit_bonus: 'Tiền thưởng gửi tiền',
  rakeback: 'Rakeback',
  spin: 'Quay',
  get_extra_300_bonus_on_minimum_of_10_USDT_deposit:
    'Nhận thêm 300% tiền thưởng khi gửi tối thiểu 10,00 USDT',
  min_deposit: 'Tiền gửi tối thiểu:',
  max_claim: 'Yêu cầu tối đa:',
  withdraw_successful: 'Rút tiền thành công',
  your_withdrawal_request_has_been_confirmed:
    'Yêu cầu rút tiền của bạn đã được xác nhận. Bạn có thể theo dõi tiến trình trên Lịch sử giao dịch trên Ví.',
  pi_balance_in_wallet: 'Số dư Pi trong ví',
  your_Pi_address: 'Địa chỉ Pi của bạn',
  fill_in_carefully_according_to_the_specific_currency:
    'Điền cẩn thận theo loại tiền cụ thể',
  you_will_receive_Pi_to_your_address_within_1_working_day:
    'Bạn sẽ nhận được Pi đến địa chỉ của bạn trong vòng 1 ngày làm việc.',
  please_make_sure_you_enter_the_correct_Pi_address_otherwise_your_funds_will_not_be_reversed:
    'Hãy đảm bảo bạn nhập đúng địa chỉ Pi; nếu không, tiền của bạn sẽ không bị đảo ngược.',
  current_capacity: 'Công suất hiện tại',
  what_is_CODG_capacity: 'Công suất CODG là gì?',
  rng_certificated_by: 'RNG được chứng nhận bởi',
  play_with_balance_in: 'Chơi với số dư',
  today_biggest_winners: 'Top người chơi thắng lớn nhất hôm nay',
};

export default vi;
