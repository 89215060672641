import { ReactComponent as LogOut } from 'assets/icons/logout.svg';
import { ReactComponent as ProfileSettings } from 'assets/icons/profile-settings.svg';
import { ReactComponent as TransactionIcon } from 'assets/icons/transaction.svg';
import { ReactComponent as VIPClubIcon } from 'assets/icons/vip-club.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/wallet.svg';
import { useAuthContext } from 'auth/useAuthContext';
import { varTranEnter, varTranExit } from 'components/animate';
import V2TransactionsDialog from 'components/TransactionsDialog';
import WalletDialog from 'components/WalletDialog';
import { AnimatePresence, motion } from 'framer-motion';
import useLocales from 'locales/useLocales';
import V2VipClubDialog from 'pages/VipClub/components/VipClubDialogV2/VipClubDialog_v2';
import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { profilePaths } from 'routes/paths';

import {
  Box,
  ClickAwayListener,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import { Wrapper } from './styles';

// ----------------------------------------------------------------

export default function UserPopover({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: VoidFunction;
}) {
  const navigate = useNavigate();
  const { logout } = useAuthContext();
  const { translate } = useLocales();
  const [isWalletDialogOpen, setIsWalletDialogOpen] = React.useState(false);
  const [isTransactionDialogOpen, setIsTransactionDialogOpen] =
    React.useState(false);

  const [isVipClubsDialogOpen, setIsVipClubsDialogOpen] = React.useState(false);

  const config = [
    {
      type: 'group',
      key: 'group1',
      child: [
        {
          title: 'wallet',
          icon: <WalletIcon width="24px" height="24px" />,
          onClick: () => {
            setIsWalletDialogOpen(true);
          },
        },
        {
          title: 'transactions',
          icon: <TransactionIcon width="24px" height="24px" />,
          onClick: () => {
            setIsTransactionDialogOpen(true);
          },
        },
      ],
    },
    {
      title: 'vip_club',
      icon: <VIPClubIcon width="24px" height="24px" />,
      onClick: () => {
        setIsVipClubsDialogOpen(true);
      },
    },
    {
      title: 'profile_settings',
      icon: <ProfileSettings width="24px" height="24px" />,
      onClick: () => {
        navigate(`/${profilePaths.page}`, { replace: true });
      },
    },
  ];

  const variants = {
    initial: { y: 30, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: varTranEnter({ durationIn: 0.2, easeIn: 'easeIn' }),
    },
    exit: {
      y: 30,
      opacity: 0,
      transition: {
        ...varTranExit({ durationOut: 0.3, easeOut: 'easeOut' }),
        delay: 0.2,
      },
    },
  };

  return (
    <AnimatePresence>
      {open && (
        <ClickAwayListener onClickAway={handleClose}>
          <Wrapper
            key="user_popover"
            initial="initial"
            animate="animate"
            exit="exit"
            component={motion.div}
            variants={variants}
            className="user_popover"
          >
            <Stack spacing="5px" width="100%">
              {config.map((item) => {
                if (item.type && item.type === 'group') {
                  return (
                    <Fragment key={`${item.title}-${item.type}`}>
                      {item.child.map((childItem) => (
                        <Box
                          key={`group${childItem.title}`}
                          className="group_item"
                          onClick={() => {
                            handleClose();
                            childItem.onClick();
                          }}
                        >
                          <div>{childItem.icon}</div>
                          <Typography
                            ml={1.2}
                            variant="subtitle2"
                            fontSize={14}
                            fontWeight={500}
                          >
                            {translate(childItem.title)}
                          </Typography>
                        </Box>
                      ))}
                    </Fragment>
                  );
                }
                return (
                  <Box
                    className="item"
                    key={`item${item.title}`}
                    onClick={() => {
                      item.onClick();
                      handleClose();
                    }}
                  >
                    <div>{item.icon}</div>
                    <Typography
                      ml={1.2}
                      variant="subtitle2"
                      fontSize={14}
                      fontWeight={500}
                    >
                      {translate(item.title)}
                    </Typography>
                  </Box>
                );
              })}
            </Stack>

            <Divider
              sx={{
                margin: '10px 0',
                borderColor: '#2F2B60',
              }}
            />
            <Box className="logout_box" onClick={() => logout()}>
              <LogOut width="24px" height="24px" />
              <Typography
                variant="subtitle2"
                ml={0.5}
                fontSize={14}
                fontWeight={500}
              >
                {translate('logout')}
              </Typography>
            </Box>
          </Wrapper>
        </ClickAwayListener>
      )}
      {/*  eslint-disable-next-line no-nested-ternary */}
      {isWalletDialogOpen ? (
        <WalletDialog
          isOpen={isWalletDialogOpen}
          handleClose={() => setIsWalletDialogOpen(false)}
        />
      ) : null}
      {isTransactionDialogOpen && (
        <V2TransactionsDialog
          isOpen={isTransactionDialogOpen}
          handleClose={() => setIsTransactionDialogOpen(false)}
        />
      )}
      {isVipClubsDialogOpen && (
        <V2VipClubDialog
          open={isVipClubsDialogOpen}
          handleClose={() => setIsVipClubsDialogOpen(false)}
          handleOpenWalletDialog={() => setIsWalletDialogOpen(true)}
        />
      )}
    </AnimatePresence>
  );
}
