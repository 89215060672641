/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  /** The EmailAddress scalar type constitutes a valid email address, represented as a UTF-8 character sequence. The scalar follows the specification defined by the HTML Spec https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: any;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  UUID: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type ActiveDevice = {
  __typename?: 'ActiveDevice';
  current: Scalars['Boolean'];
  device: Scalars['String'];
  id: Scalars['ID'];
  lastSeenAt?: Maybe<Scalars['DateTime']>;
  lastSeenIpAddress?: Maybe<Scalars['String']>;
};

export type Agency = {
  __typename?: 'Agency';
  /** The house of current agency, `NULL` when this is coco platform */
  house?: Maybe<House>;
  id: Scalars['ID'];
  maxCommissionRate: Scalars['Decimal'];
  nickname?: Maybe<Scalars['String']>;
  status: AgencyStatus;
  userId: Scalars['ID'];
};

export type AgencyInfo = {
  __typename?: 'AgencyInfo';
  agency?: Maybe<Agency>;
  children: PageableOfAgencyMember;
};


export type AgencyInfoChildrenArgs = {
  nickname?: InputMaybe<Scalars['String']>;
  onlyAgency?: InputMaybe<Scalars['Boolean']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  userId?: InputMaybe<Scalars['ID']>;
};

export type AgencyMember = {
  __typename?: 'AgencyMember';
  /** The commission to parent of this member */
  commissions: Array<CommissionValue>;
  id: Scalars['ID'];
  isAgency: Scalars['Boolean'];
  maxCommissionRate: Scalars['Decimal'];
  memberCount: Scalars['Int'];
  nickname?: Maybe<Scalars['String']>;
  playerCount: Scalars['Int'];
  /** The wager of current member and it's downline members */
  wagerEquivalentUsd: Scalars['Decimal'];
};


export type AgencyMemberCommissionsArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
};


export type AgencyMemberPlayerCountArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
};


export type AgencyMemberWagerEquivalentUsdArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export enum AgencyStatus {
  Accepted = 'ACCEPTED',
  Inviting = 'INVITING',
  Rejected = 'REJECTED'
}

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type BaccaratBetData = {
  __typename?: 'BaccaratBetData';
  bankerResult?: Maybe<BaccaratResult>;
  betBankerAmount: Scalars['Decimal'];
  betBankerPairAmount: Scalars['Decimal'];
  betPlayerAmount: Scalars['Decimal'];
  betPlayerPairAmount: Scalars['Decimal'];
  betTieAmount: Scalars['Decimal'];
  playerResult?: Maybe<BaccaratResult>;
};

export type BaccaratResult = {
  __typename?: 'BaccaratResult';
  cards: Array<Card>;
  total: Scalars['Int'];
};

export type Bet = {
  __typename?: 'Bet';
  betAmount: Scalars['Decimal'];
  betChargeAmount: Scalars['Decimal'];
  betData?: Maybe<BetDataType>;
  createdAt: Scalars['DateTime'];
  currency: Currency;
  equivalentUsdRate: Scalars['Decimal'];
  game: Game;
  id: Scalars['ID'];
  payoutAmount: Scalars['Decimal'];
  payoutRate: Scalars['Decimal'];
  rngResult?: Maybe<RngResult>;
  /** Null when user's privacy is anonymous, except current user */
  user?: Maybe<User>;
};

export type BetDataType = BaccaratBetData | BitFarmBetData | CoinFlipBetData | CrashBetData | CryptoQuestBetData | DiceBetData | LimboBetData | MinesBetData | MiniCryptoBetData | MiniPokerBetData | MochiMonsterBetData | PlinkoBetData | ThreeDiceBetData | VideoPokerBetData;

export type BetMode = {
  __typename?: 'BetMode';
  mode: Scalars['Int'];
  overList?: Maybe<RoomDetail>;
  underList?: Maybe<RoomDetail>;
};

export type BetResponse = {
  __typename?: 'BetResponse';
  /** The balance after the bet (NOTE: might not equals latest balance if result is waiting). Not available when the bet is null or result is not in state waiting/success. */
  balance?: Maybe<Scalars['Decimal']>;
  bet?: Maybe<Bet>;
  jackpotAmount?: Maybe<Scalars['Decimal']>;
  result: BetResult;
};

export enum BetResult {
  GameBankNotAvailable = 'GAME_BANK_NOT_AVAILABLE',
  GameNotAvailable = 'GAME_NOT_AVAILABLE',
  InsufficientAmount = 'INSUFFICIENT_AMOUNT',
  InvalidBetParams = 'INVALID_BET_PARAMS',
  InvalidBetState = 'INVALID_BET_STATE',
  OverMaxBet = 'OVER_MAX_BET',
  Success = 'SUCCESS',
  UnderMinBet = 'UNDER_MIN_BET',
  Waiting = 'WAITING'
}

export type BetRoom = {
  __typename?: 'BetRoom';
  betRoomSetting: BetRoomSetting;
  id: Scalars['ID'];
  jackpotAmount?: Maybe<Scalars['Decimal']>;
  status: BetRoomStatus;
};

export type BetRoomSetting = {
  __typename?: 'BetRoomSetting';
  currency: Currency;
  defaultBetAmount: Scalars['Decimal'];
  id: Scalars['ID'];
  maximumBetAmount?: Maybe<Scalars['Decimal']>;
  minimumBetAmount: Scalars['Decimal'];
};

export enum BetRoomStatus {
  Closed = 'CLOSED',
  Closing = 'CLOSING',
  Ready = 'READY'
}

export type BetSummary = {
  __typename?: 'BetSummary';
  betAmount: Scalars['Decimal'];
  currency: Currency;
  equivalentUsdRate: Scalars['Decimal'];
  game: Game;
  id: Scalars['ID'];
  payoutAmount: Scalars['Decimal'];
  payoutRate: Scalars['Decimal'];
  /** Null when user's privacy is anonymous, except current user */
  user?: Maybe<User>;
};

export type BitFarmBetData = {
  __typename?: 'BitFarmBetData';
  betLines: Scalars['Int'];
};

export enum BlockchainNetwork {
  Bep20 = 'BEP20',
  Erc20 = 'ERC20',
  Ethereum = 'ETHEREUM',
  Litecoin = 'LITECOIN',
  None = 'NONE',
  Polygon = 'POLYGON',
  Segwit = 'SEGWIT',
  Solana = 'SOLANA',
  Trc20 = 'TRC20'
}

export type BuyNftTransaction = {
  __typename?: 'BuyNftTransaction';
  amount: Scalars['Decimal'];
  equivalentUsdRate: Scalars['Decimal'];
  id: Scalars['ID'];
  quantityNft: Scalars['ID'];
  stockCard?: Maybe<StockCard>;
  type?: Maybe<TransactionType>;
  user?: Maybe<User>;
};

export type Card = {
  __typename?: 'Card';
  rank: Scalars['Int'];
  suit: Scalars['String'];
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  message: Scalars['String'];
  sender: ChatUser;
};

export type ChatUser = {
  __typename?: 'ChatUser';
  avatar?: Maybe<Photo>;
  nickname?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  vipLevel: Scalars['Int'];
};

export type CoinFlipBetData = {
  __typename?: 'CoinFlipBetData';
  currentMultiplier: Scalars['Decimal'];
  flippedRounds: Array<Scalars['Boolean']>;
  nextMultiplier: Scalars['Decimal'];
};

export type CommissionValue = {
  __typename?: 'CommissionValue';
  availableAmount: Scalars['Decimal'];
  claimedAmount: Scalars['Decimal'];
  currency: Currency;
  totalAmount: Scalars['Decimal'];
};

export type ConfigCrypto = {
  __typename?: 'ConfigCrypto';
  baseCurrencyId: Scalars['ID'];
  defaultBet: Scalars['Decimal'];
  equivalentUsdRate: Scalars['Decimal'];
  id: Scalars['ID'];
  maxBet: Scalars['Decimal'];
  minBet: Scalars['Decimal'];
  mode: Scalars['Int'];
  shortName?: Maybe<Scalars['String']>;
};

export type CrashBetData = {
  __typename?: 'CrashBetData';
  roundId: Scalars['ID'];
};

export type CrashBetDetail = {
  __typename?: 'CrashBetDetail';
  amount: Scalars['Decimal'];
  currency: Currency;
  id: Scalars['ID'];
  payoutAmount?: Maybe<Scalars['Decimal']>;
  payoutRate: Scalars['Decimal'];
  /** Null when user's privacy is anonymous, except current user */
  user?: Maybe<User>;
};

export type CrashPredictorEvent = {
  __typename?: 'CrashPredictorEvent';
  crashPredictorResults: Array<CrashPredictorResult>;
  date: Scalars['Date'];
  end: Scalars['DateTime'];
  id: Scalars['ID'];
  minimumEquivalentUsdBetAmount: Scalars['Decimal'];
  myPredictor?: Maybe<CrashPredictorResult>;
  start: Scalars['DateTime'];
  totalPrize: Scalars['Decimal'];
};

export type CrashPredictorResult = {
  __typename?: 'CrashPredictorResult';
  amount?: Maybe<Scalars['Decimal']>;
  cashout: Scalars['Decimal'];
  crashResult: Scalars['Decimal'];
  id: Scalars['ID'];
  rank: Scalars['Int'];
  totalWager: Scalars['Decimal'];
  user?: Maybe<User>;
};

export type CrashRoundData = {
  __typename?: 'CrashRoundData';
  players: Array<Maybe<CrashBetDetail>>;
  result: Scalars['Decimal'];
};

export type CrashTopLuckyResult = {
  __typename?: 'CrashTopLuckyResult';
  betAmount: Scalars['Decimal'];
  payoutRate: Scalars['Decimal'];
  rank: Scalars['Int'];
  user?: Maybe<User>;
};

export type CrashTopProfitResult = {
  __typename?: 'CrashTopProfitResult';
  profit: Scalars['Decimal'];
  rank: Scalars['Int'];
  user?: Maybe<User>;
};

export type CreateGameBankRequestInput = {
  gameBankSettingId: Scalars['ID'];
  houseId: Scalars['ID'];
  investable: Scalars['Boolean'];
  label?: InputMaybe<Scalars['String']>;
  listing: Scalars['Boolean'];
};

export type CryptoAddress = {
  __typename?: 'CryptoAddress';
  address: Scalars['String'];
};

export type CryptoQuestBetData = {
  __typename?: 'CryptoQuestBetData';
  betLines: Scalars['Int'];
};

export type CryptoWithdrawalInfo = {
  __typename?: 'CryptoWithdrawalInfo';
  decimalDigits: Scalars['Int'];
  feeAmount: Scalars['Decimal'];
  feeRate: Scalars['Decimal'];
  id: Scalars['String'];
  minimumAmount: Scalars['Decimal'];
};

export type CryptoWithdrawalTransaction = {
  __typename?: 'CryptoWithdrawalTransaction';
  amount: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  currency: Currency;
  equivalentUsdRate: Scalars['Decimal'];
  id: Scalars['ID'];
  status: WithdrawalStatus;
  tx?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type CurrenciesReactiveVar = {
  __typename?: 'CurrenciesReactiveVar';
  currencies?: Maybe<Array<Maybe<Currency>>>;
};

export type Currency = {
  __typename?: 'Currency';
  /** List blockchain networks supported by the currency, null if the currency CANNOT deposit/withdrawal via blockchain */
  blockchainNetworks?: Maybe<Array<BlockchainNetwork>>;
  decimalDigits: Scalars['Int'];
  equivalentUsdRate: Scalars['Decimal'];
  id: Scalars['ID'];
  name: Scalars['String'];
  shortName: Scalars['String'];
  type: CurrencyType;
};

export enum CurrencyType {
  Crypto = 'CRYPTO',
  Fiat = 'FIAT'
}

export type CurrentBet = {
  __typename?: 'CurrentBet';
  isOver: Scalars['Boolean'];
  mode: Scalars['Int'];
  totalBetValueToBaseCurrency: Scalars['Decimal'];
  userId: Scalars['ID'];
};

export type DailyContestEvent = {
  __typename?: 'DailyContestEvent';
  dailyContestResults: Array<DailyContestResult>;
  date: Scalars['Date'];
  doubleWagerGame?: Maybe<Game>;
  end: Scalars['DateTime'];
  id: Scalars['ID'];
  myWager: Scalars['Decimal'];
  start: Scalars['DateTime'];
  totalPrize: Scalars['Decimal'];
};

export type DailyContestResult = {
  __typename?: 'DailyContestResult';
  amount?: Maybe<Scalars['Decimal']>;
  id: Scalars['ID'];
  rank: Scalars['Int'];
  totalWager: Scalars['Decimal'];
  user?: Maybe<User>;
};

export enum DepositStatus {
  Completed = 'COMPLETED',
  Credit = 'CREDIT',
  New = 'NEW'
}

export type DepositTransaction = {
  __typename?: 'DepositTransaction';
  amount: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  currency: Currency;
  equivalentUsdRate: Scalars['Decimal'];
  id: Scalars['ID'];
  status: DepositStatus;
  tx?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type DiceBetData = {
  __typename?: 'DiceBetData';
  betValue: Scalars['Decimal'];
  isRollOver: Scalars['Boolean'];
  result: Scalars['Decimal'];
  winChance: Scalars['Decimal'];
};

export type ExGame = {
  __typename?: 'ExGame';
  provider?: Maybe<ExProvider>;
  slug: Scalars['String'];
  /** List of supported currency ids for this game */
  supportedCurrencyIds: Array<Scalars['ID']>;
  thumbnail?: Maybe<Photo>;
  title: Scalars['String'];
};

export enum ExGameCategory {
  GameShows = 'GAME_SHOWS',
  LiveCasino = 'LIVE_CASINO',
  NewReleases = 'NEW_RELEASES',
  Slots = 'SLOTS'
}

export enum ExGameSorting {
  Featured = 'FEATURED',
  Popular = 'POPULAR',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC'
}

export type ExProvider = {
  __typename?: 'ExProvider';
  gameCount: Scalars['Int'];
  logo?: Maybe<Photo>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Fiat = {
  __typename?: 'Fiat';
  name?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Decimal']>;
};

export type FunCrashPredictorEvent = {
  __typename?: 'FunCrashPredictorEvent';
  crashPredictorResults: Array<FunCrashPredictorResult>;
  date: Scalars['Date'];
  end: Scalars['DateTime'];
  id: Scalars['ID'];
  minimumFunBetAmount: Scalars['Decimal'];
  myPredictor?: Maybe<FunCrashPredictorResult>;
  start: Scalars['DateTime'];
  totalPrize: Scalars['Decimal'];
};

export type FunCrashPredictorResult = {
  __typename?: 'FunCrashPredictorResult';
  amount?: Maybe<Scalars['Decimal']>;
  cashout: Scalars['Decimal'];
  crashResult: Scalars['Decimal'];
  id: Scalars['ID'];
  rank: Scalars['Int'];
  totalWager: Scalars['Decimal'];
  user?: Maybe<User>;
};

export type Game = {
  __typename?: 'Game';
  /** User already added the game to list favorites or not */
  favored: Scalars['Boolean'];
  /** Total favorites */
  favorites: Scalars['Int'];
  gameBase: GameBase;
  id: Scalars['ID'];
  /** Current user already liked the game or not */
  liked: Scalars['Boolean'];
  /** Total likes */
  likes: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
};


export type GameFavoredArgs = {
  userId?: InputMaybe<Scalars['ID']>;
};


export type GameLikedArgs = {
  userId?: InputMaybe<Scalars['ID']>;
};

export type GameBank = {
  __typename?: 'GameBank';
  allInvestorMaxShareRate?: Maybe<Scalars['Decimal']>;
  amount: Scalars['Decimal'];
  currency: Currency;
  depositMinimumAmount: Scalars['Decimal'];
  dilutionFeeRate: Scalars['Decimal'];
  id: Scalars['ID'];
  investable: Scalars['Boolean'];
  isAvailable: Scalars['Boolean'];
  listing: Scalars['Boolean'];
  maxProfit?: Maybe<Scalars['Decimal']>;
  shareSummary: ShareSummary;
  singleInvestorMaxShareRate?: Maybe<Scalars['Decimal']>;
  withdrawalFeeRate: Scalars['Decimal'];
};

export type GameBankPortfolio = {
  __typename?: 'GameBankPortfolio';
  amount: Scalars['Decimal'];
  currency: Currency;
  gameBankId: Scalars['ID'];
  games: Game;
  id: Scalars['ID'];
  players: Scalars['Int'];
  profit: Scalars['Decimal'];
  profitStats: Array<Scalars['Decimal']>;
};

export enum GameBankPortfolioSortField {
  Amount = 'AMOUNT',
  Currency = 'CURRENCY',
  Players = 'PLAYERS',
  Profit = 'PROFIT'
}

export type GameBankPortfolioSummaryType = {
  __typename?: 'GameBankPortfolioSummaryType';
  latestInvests?: Maybe<Array<InvestTransaction>>;
  total: Scalars['Decimal'];
};

export type GameBankStat = {
  __typename?: 'GameBankStat';
  amount: Scalars['Decimal'];
  id: Scalars['String'];
  investAmount: Scalars['Decimal'];
  myInvest?: Maybe<Scalars['Decimal']>;
  time: Scalars['DateTime'];
};

export type GameBase = {
  __typename?: 'GameBase';
  codeName: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  isMiniGame: Scalars['Boolean'];
  maxWinRate: Scalars['Decimal'];
  previews: Array<Photo>;
  producer: Producer;
  releaseDate: Scalars['Date'];
  reviewing: Scalars['String'];
  rtp: Scalars['Decimal'];
  summary: Scalars['String'];
  tags: Array<Scalars['String']>;
  thumbnail?: Maybe<Photo>;
  type: GameType;
};

export type GameFund = {
  __typename?: 'GameFund';
  betRooms: Array<BetRoom>;
  gameBank?: Maybe<GameBank>;
  id: Scalars['ID'];
};

export type GameResult = {
  __typename?: 'GameResult';
  dice1: Scalars['Int'];
  dice2: Scalars['Int'];
  dice3: Scalars['Int'];
  diceSum: Scalars['Int'];
  id?: Maybe<Scalars['Long']>;
  rngResult?: Maybe<RngResult>;
  winSpotId: Scalars['Int'];
};

export type GameRoom = {
  __typename?: 'GameRoom';
  betModes?: Maybe<Array<Maybe<BetMode>>>;
  id?: Maybe<Scalars['Long']>;
  threeDiceTopRoundWinners?: Maybe<Array<Maybe<TopWin>>>;
  winSpotId: Scalars['Int'];
};

export type GameRoundExtend = {
  __typename?: 'GameRoundExtend';
  id?: Maybe<Scalars['ID']>;
  nextRoundId: Scalars['ID'];
  remainBetting: Scalars['Int'];
  remainWaiting: Scalars['Int'];
  status: Scalars['Int'];
};

export enum GameType {
  Multiplayer = 'MULTIPLAYER',
  None = 'NONE',
  Slot = 'SLOT',
  SlotWithJackpot = 'SLOT_WITH_JACKPOT'
}

export type GraphError = {
  __typename?: 'GraphError';
  code?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type HideSmallWalletReactiveVar = {
  __typename?: 'HideSmallWalletReactiveVar';
  hideSmallWalletAmount?: Maybe<Scalars['Boolean']>;
};

export type House = {
  __typename?: 'House';
  domain: Scalars['String'];
  /** List host staffs, only available when query `myHost` */
  houseUsers: Array<HouseUser>;
  id: Scalars['ID'];
  logo?: Maybe<Photo>;
  name: Scalars['String'];
  /** @deprecated Use domain instead */
  slug: Scalars['String'];
  thumbnail?: Maybe<Photo>;
};

export type HouseGame = {
  __typename?: 'HouseGame';
  game: Game;
  gameFund?: Maybe<GameFund>;
  id: Scalars['ID'];
};


export type HouseGameGameFundArgs = {
  currencyId: Scalars['ID'];
};

export enum HouseRole {
  Admin = 'ADMIN',
  Agency = 'AGENCY',
  Owner = 'OWNER'
}

export type HouseUser = {
  __typename?: 'HouseUser';
  id: Scalars['ID'];
  role: HouseRole;
  user: User;
};

export type Identification = {
  __typename?: 'Identification';
  address: Scalars['String'];
  city: Scalars['String'];
  countryCode: Scalars['String'];
  dateOfBirth: Scalars['Date'];
  firstName: Scalars['String'];
  gender: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  postalCode: Scalars['String'];
};

export type IdentificationInput = {
  address: Scalars['String'];
  city: Scalars['String'];
  countryCode: Scalars['String'];
  dateOfBirth: Scalars['Date'];
  firstName: Scalars['String'];
  gender: Scalars['String'];
  lastName: Scalars['String'];
  postalCode: Scalars['String'];
};

export enum IdentificationStatus {
  Accepted = 'ACCEPTED',
  New = 'NEW',
  Rejected = 'REJECTED',
  Reviewing = 'REVIEWING'
}

export type InternalTransfer = {
  __typename?: 'InternalTransfer';
  amount: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  currency: Currency;
  equivalentUsdRate: Scalars['Decimal'];
  fee: Scalars['Decimal'];
  id: Scalars['ID'];
  message: Scalars['String'];
  receiverAvatar?: Maybe<Photo>;
  receiverId: Scalars['ID'];
  receiverNickname?: Maybe<Scalars['String']>;
  senderAvatar?: Maybe<Photo>;
  senderId: Scalars['ID'];
  senderNickname?: Maybe<Scalars['String']>;
  status: InternalTransferStatus;
};

export enum InternalTransferStatus {
  Completed = 'COMPLETED',
  Insufficient = 'INSUFFICIENT',
  New = 'NEW',
  ReceiverCredit = 'RECEIVER_CREDIT',
  SenderSubtracted = 'SENDER_SUBTRACTED'
}

export type InvestResult = {
  __typename?: 'InvestResult';
  gameBank: GameBank;
  investTransaction: InvestTransaction;
};

export type InvestTransaction = {
  __typename?: 'InvestTransaction';
  amount: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  currency: Currency;
  equivalentUsdRate: Scalars['Decimal'];
  fee?: Maybe<Scalars['Decimal']>;
  id: Scalars['ID'];
  isHouse: Scalars['Boolean'];
  type: InvestType;
  /** Null when user's privacy is anonymous, except current user */
  user?: Maybe<User>;
};

export enum InvestType {
  Deposit = 'DEPOSIT',
  Withdrawal = 'WITHDRAWAL'
}

export type Investor = {
  __typename?: 'Investor';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  investAmount: Scalars['Decimal'];
  isHouse: Scalars['Boolean'];
  shareRate: Scalars['Decimal'];
  /** Null when user's privacy is anonymous, except current user */
  user?: Maybe<User>;
};

export type InvestorPortfolio = {
  __typename?: 'InvestorPortfolio';
  currency: Currency;
  gameBankAmount: Scalars['Decimal'];
  gameBankId: Scalars['ID'];
  games: Game;
  id: Scalars['ID'];
  investAmount: Scalars['Decimal'];
  profitRate: Scalars['Decimal'];
  shareRate: Scalars['Decimal'];
};

export type Jackpot = {
  __typename?: 'Jackpot';
  id?: Maybe<Scalars['Long']>;
  mode: Scalars['Int'];
  value?: Maybe<Scalars['Decimal']>;
};

export type JackpotHistory = {
  __typename?: 'JackpotHistory';
  createdTime: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isWinStreak: Scalars['Boolean'];
  jackpotPay: Scalars['Decimal'];
  jackpotWinList?: Maybe<Array<Maybe<StreakWinList>>>;
  mode: Scalars['Int'];
  result3D?: Maybe<Array<Maybe<Scalars['Int']>>>;
  roundId: Scalars['ID'];
};

export type JackpotWinBet = {
  __typename?: 'JackpotWinBet';
  at: Scalars['DateTime'];
  betId: Scalars['ID'];
  currency: Currency;
  game: Game;
  jackpotCount: Scalars['Int'];
  jackpotTotalAmount: Scalars['Decimal'];
  price: Scalars['Decimal'];
  /** Null when user's privacy is anonymous, except current user */
  user?: Maybe<User>;
};

export type LaunchOptions = {
  __typename?: 'LaunchOptions';
  gameUrl: Scalars['String'];
  strategy: Scalars['String'];
};

export type LimboBetData = {
  __typename?: 'LimboBetData';
  betMultiplier: Scalars['Decimal'];
  result: Scalars['Decimal'];
};

export type ListedGame = {
  __typename?: 'ListedGame';
  houseGame: HouseGame;
  id: Scalars['ID'];
  order: Scalars['Int'];
};

export type LiveStatsData = {
  __typename?: 'LiveStatsData';
  loseRounds?: Maybe<Scalars['Int']>;
  profits?: Maybe<Array<Maybe<Scalars['Decimal']>>>;
  totalBetAmount?: Maybe<Scalars['Decimal']>;
  totalProfit?: Maybe<Scalars['Decimal']>;
  winRounds?: Maybe<Scalars['Int']>;
};

export type LiveStatsReactiveVar = {
  __typename?: 'LiveStatsReactiveVar';
  liveStats?: Maybe<LiveStatsData>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginResult = {
  __typename?: 'LoginResult';
  accessToken: Scalars['String'];
  registered: Scalars['Boolean'];
};

/** This object has been deprecated, should use `VipUser` object */
export type LoyalUserType = {
  __typename?: 'LoyalUserType';
  VipLevel: Scalars['Int'];
  currentLevelPoint: Scalars['Long'];
  id: Scalars['Long'];
  nextLevelPoint: Scalars['Long'];
  totalWagerEquivalentUsdAmount: Scalars['Decimal'];
  vipPoint: Scalars['Decimal'];
};

export enum MarketType {
  Buying = 'BUYING',
  Closed = 'CLOSED',
  ComingSoon = 'COMING_SOON',
  Raising = 'RAISING'
}

export type MeType = {
  __typename?: 'MeType';
  activeDevices: Array<ActiveDevice>;
  defaultReferralCode: ReferralCode;
  id: Scalars['ID'];
  /** @deprecated No longer supported. */
  loyalUser: LoyalUserType;
  profile: Profile;
  user: User;
  userWallets: Array<UserWallet>;
  vipUser: VipUser;
  /** NOTE: should re-query this field when checking for withdrawal wager requirements. */
  withdrawalRequiredBetAmountUsd: Scalars['Decimal'];
};

export type MinesBetData = {
  __typename?: 'MinesBetData';
  currentMultiplier: Scalars['Decimal'];
  minesCells?: Maybe<Array<Scalars['Int']>>;
  minesCount: Scalars['Int'];
  nextMultiplier: Scalars['Decimal'];
  openedCells: Array<Scalars['Int']>;
};

export type MiniCryptoBetData = {
  __typename?: 'MiniCryptoBetData';
  reels: Array<Array<MiniCryptoSymbol>>;
  winJackpotTotalAmount: Scalars['Decimal'];
  winLines: Array<MiniCryptoWinLine>;
};

export enum MiniCryptoSymbol {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  Wild = 'WILD'
}

export type MiniCryptoWinLine = {
  __typename?: 'MiniCryptoWinLine';
  rows: Array<Scalars['Int']>;
  winRate: Scalars['Decimal'];
};

export type MiniPokerBetData = {
  __typename?: 'MiniPokerBetData';
  miniPokerResult?: Maybe<MiniPokerResult>;
  winJackpotTotalAmount: Scalars['Decimal'];
};

export type MiniPokerResult = {
  __typename?: 'MiniPokerResult';
  cards: Array<Card>;
  rate: Scalars['Decimal'];
  type: MiniPokerWinType;
};

export enum MiniPokerWinType {
  Flush = 'FLUSH',
  FourOfKind = 'FOUR_OF_KIND',
  FullHouse = 'FULL_HOUSE',
  JackOrBetter = 'JACK_OR_BETTER',
  None = 'NONE',
  RoyalFlush = 'ROYAL_FLUSH',
  Straight = 'STRAIGHT',
  StraightFlush = 'STRAIGHT_FLUSH',
  ThreeOfKind = 'THREE_OF_KIND',
  TwoPairs = 'TWO_PAIRS'
}

export type MochiMonsterBetData = {
  __typename?: 'MochiMonsterBetData';
  betAmount: Scalars['Decimal'];
  type: MochiMonsterGameType;
};

export enum MochiMonsterGameType {
  Ante = 'ANTE',
  Base = 'BASE',
  Buy = 'BUY'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Change or set password for current user */
  changePassword: PayloadOfProfile;
  chat: ChatMessage;
  claimCommissions: PayloadOfBoolean;
  coinFlipBet: BetResponse;
  coinFlipCashout: BetResponse;
  coinFlipOpen: BetResponse;
  /** Create an withdrawal for crypto currency */
  createCryptoWithdrawal: PayloadOfCryptoWithdrawalTransaction;
  createGameBank: PayloadOfGameBank;
  /** Create an internal transfer, send amount to another user */
  createInternalTransfer: PayloadOfInternalTransfer;
  createReferralCode: PayloadOfReferralCode;
  diceBet: BetResponse;
  favorite: Game;
  houseDeposit: PayloadOfInvestResult;
  houseWithdraw: PayloadOfInvestResult;
  investorDeposit: PayloadOfInvestResult;
  investorWithdraw: PayloadOfInvestResult;
  like: Game;
  limboBet: BetResponse;
  /** Log in by email/password */
  login: PayloadOfLoginResult;
  /** Log in via Google by using idToken */
  loginGoogle: PayloadOfLoginResult;
  /** Sign out for user by remove current active device */
  logout: PayloadOfBoolean;
  minesBet: BetResponse;
  minesCashout: BetResponse;
  minesOpen: BetResponse;
  miniCryptoBet: BetResponse;
  miniPokerBet: BetResponse;
  orisunBuyNft: PayloadOfBuyNftTransaction;
  orisunRegisterWhitelist: PayloadOfWhiteListUsers;
  plinkoBet: BetResponse;
  /** Marked read all personal notifications */
  readAllPersonalNotifications: PayloadOfUnreadNotification;
  /** Marked read all system notifications */
  readAllSystemNotifications: PayloadOfUnreadNotification;
  /** Update new password with activate token */
  recoverPassword: PayloadOfBoolean;
  redeemCode: PayloadOfRedeemResult;
  /** Register new account by email */
  register: PayloadOfLoginResult;
  /** Remove active device, client should manual clean cache of the list active devices */
  removeActiveDevice: PayloadOfBoolean;
  /** Create an withdrawal for crypto currency */
  requestExGameDemo: PayloadOfLaunchOptions;
  /** Create an withdrawal for crypto currency */
  requestExGamePlay: PayloadOfLaunchOptions;
  /** Request recover password via email */
  requestRecoverPassword: PayloadOfBoolean;
  responseAgencyInvite: PayloadOfAgencyInfo;
  /** Set profile hidden to public feed */
  setHidden: PayloadOfProfile;
  setupHouseGame: PayloadOfHouseGame;
  setupListedGame: PayloadOfListedGame;
  swap: PayloadOfSwapTransaction;
  threeDiceBet: ThreeDiceBetResponse;
  transfiRequestBuyCrypto: PayloadOfString;
  /** Update user avatar */
  updateAvatar: PayloadOfUser;
  /** Change email of user, allow only when current email is inactivated */
  updateEmail: PayloadOfProfile;
  updateIdentification: PayloadOfUserIdentification;
  /** Update user nickname */
  updateNickname: PayloadOfProfile;
  uploadIdentificationPhotos: PayloadOfUserIdentification;
  /** Verify email by token sent to the email */
  verifyEmail: PayloadOfProfile;
  videoPokerBet: BetResponse;
  videoPokerDeal: BetResponse;
  vipClaimInstantBonus: PayloadOfBoolean;
  vipClaimMonthBonus: PayloadOfBoolean;
  vipClaimWeekBonus: PayloadOfBoolean;
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword?: InputMaybe<Scalars['String']>;
};


export type MutationChatArgs = {
  channel: Scalars['String'];
  message: Scalars['String'];
};


export type MutationCoinFlipBetArgs = {
  amount: Scalars['Decimal'];
  betRoomId: Scalars['ID'];
  turns?: InputMaybe<Array<Scalars['Boolean']>>;
};


export type MutationCoinFlipCashoutArgs = {
  betRoomId: Scalars['ID'];
};


export type MutationCoinFlipOpenArgs = {
  betRoomId: Scalars['ID'];
  isHead: Scalars['Boolean'];
};


export type MutationCreateCryptoWithdrawalArgs = {
  address: Scalars['String'];
  amount: Scalars['Decimal'];
  currencyId: Scalars['ID'];
  network: BlockchainNetwork;
};


export type MutationCreateGameBankArgs = {
  input: CreateGameBankRequestInput;
};


export type MutationCreateInternalTransferArgs = {
  amount: Scalars['Decimal'];
  currencyId: Scalars['ID'];
  message: Scalars['String'];
  receiverId: Scalars['ID'];
};


export type MutationCreateReferralCodeArgs = {
  code: Scalars['String'];
  name: Scalars['String'];
};


export type MutationDiceBetArgs = {
  amount: Scalars['Decimal'];
  betRoomId: Scalars['ID'];
  isRollOver: Scalars['Boolean'];
  value: Scalars['Decimal'];
};


export type MutationFavoriteArgs = {
  gameId: Scalars['ID'];
  value: Scalars['Boolean'];
};


export type MutationHouseDepositArgs = {
  amount: Scalars['Decimal'];
  gameBankId: Scalars['ID'];
};


export type MutationHouseWithdrawArgs = {
  amount?: InputMaybe<Scalars['Decimal']>;
  gameBankId: Scalars['ID'];
};


export type MutationInvestorDepositArgs = {
  amount: Scalars['Decimal'];
  gameBankId: Scalars['ID'];
};


export type MutationInvestorWithdrawArgs = {
  amount?: InputMaybe<Scalars['Decimal']>;
  byPercent: Scalars['Boolean'];
  gameBankId: Scalars['ID'];
};


export type MutationLikeArgs = {
  gameId: Scalars['ID'];
  value: Scalars['Boolean'];
};


export type MutationLimboBetArgs = {
  amount: Scalars['Decimal'];
  betRoomId: Scalars['ID'];
  multiplier: Scalars['Decimal'];
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationLoginGoogleArgs = {
  channel?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};


export type MutationMinesBetArgs = {
  amount: Scalars['Decimal'];
  betRoomId: Scalars['ID'];
  cells?: InputMaybe<Array<Scalars['Int']>>;
  mines: Scalars['Int'];
};


export type MutationMinesCashoutArgs = {
  betRoomId: Scalars['ID'];
};


export type MutationMinesOpenArgs = {
  betRoomId: Scalars['ID'];
  cellIdx: Scalars['Int'];
};


export type MutationMiniCryptoBetArgs = {
  betRoomId: Scalars['ID'];
};


export type MutationMiniPokerBetArgs = {
  betRoomId: Scalars['ID'];
};


export type MutationOrisunBuyNftArgs = {
  quantityNft: Scalars['Long'];
  stockCardId: Scalars['ID'];
};


export type MutationOrisunRegisterWhitelistArgs = {
  stockCardId: Scalars['ID'];
};


export type MutationPlinkoBetArgs = {
  amount: Scalars['Decimal'];
  betRoomId: Scalars['ID'];
  risk: PlinkoRisk;
  rows: Scalars['Int'];
};


export type MutationReadAllSystemNotificationsArgs = {
  lastId: Scalars['ID'];
};


export type MutationRecoverPasswordArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationRedeemCodeArgs = {
  code: Scalars['String'];
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationRemoveActiveDeviceArgs = {
  deviceId: Scalars['ID'];
};


export type MutationRequestExGameDemoArgs = {
  isMobile?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Scalars['String']>;
  returnUrl: Scalars['String'];
  slug: Scalars['String'];
};


export type MutationRequestExGamePlayArgs = {
  currencyId: Scalars['ID'];
  isMobile?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Scalars['String']>;
  returnUrl: Scalars['String'];
  slug: Scalars['String'];
};


export type MutationRequestRecoverPasswordArgs = {
  email: Scalars['EmailAddress'];
};


export type MutationResponseAgencyInviteArgs = {
  accepted: Scalars['Boolean'];
};


export type MutationSetHiddenArgs = {
  value: Scalars['Boolean'];
};


export type MutationSetupHouseGameArgs = {
  input: SetupHouseGameRequestInput;
};


export type MutationSetupListedGameArgs = {
  input: SetupListedGameRequestInput;
};


export type MutationSwapArgs = {
  amount: Scalars['Decimal'];
  fromId: Scalars['ID'];
  toId: Scalars['ID'];
};


export type MutationThreeDiceBetArgs = {
  amount: Scalars['Decimal'];
  currencyId: Scalars['Long'];
  isOver: Scalars['Boolean'];
  roundId: Scalars['Long'];
};


export type MutationTransfiRequestBuyCryptoArgs = {
  amount: Scalars['Decimal'];
  currencyId: Scalars['Long'];
  fiat: Scalars['String'];
  primaryColor?: InputMaybe<Scalars['String']>;
  returnUrl: Scalars['String'];
  secondaryColor?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAvatarArgs = {
  file: Scalars['Upload'];
};


export type MutationUpdateEmailArgs = {
  email: Scalars['EmailAddress'];
};


export type MutationUpdateIdentificationArgs = {
  input: IdentificationInput;
};


export type MutationUpdateNicknameArgs = {
  nickname: Scalars['String'];
};


export type MutationUploadIdentificationPhotosArgs = {
  idBack?: InputMaybe<Scalars['Upload']>;
  idFront?: InputMaybe<Scalars['Upload']>;
  passport?: InputMaybe<Scalars['Upload']>;
  selfie: Scalars['Upload'];
};


export type MutationVerifyEmailArgs = {
  token: Scalars['String'];
};


export type MutationVideoPokerBetArgs = {
  amount: Scalars['Decimal'];
  betRoomId: Scalars['ID'];
  selectHands?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationVideoPokerDealArgs = {
  betRoomId: Scalars['ID'];
  holdCards?: InputMaybe<Array<Scalars['Int']>>;
};

export type Notification = {
  __typename?: 'Notification';
  actionText?: Maybe<Scalars['String']>;
  actionUrl?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  id: Scalars['ID'];
  publishedAt: Scalars['DateTime'];
  thumbnail?: Maybe<Photo>;
  title: Scalars['String'];
};

export type OwnerNft = {
  __typename?: 'OwnerNft';
  id: Scalars['ID'];
  projectNft: ProjectNft;
  totalNft: Scalars['ID'];
};

export type PageableOfAgencyMember = {
  __typename?: 'PageableOfAgencyMember';
  data: Array<AgencyMember>;
  hasMore: Scalars['Boolean'];
};

export type PageableOfBet = {
  __typename?: 'PageableOfBet';
  data: Array<Bet>;
  hasMore: Scalars['Boolean'];
};

export type PageableOfDepositTransaction = {
  __typename?: 'PageableOfDepositTransaction';
  data: Array<DepositTransaction>;
  hasMore: Scalars['Boolean'];
};

export type PageableOfExGame = {
  __typename?: 'PageableOfExGame';
  data: Array<ExGame>;
  hasMore: Scalars['Boolean'];
};

export type PageableOfGameBankPortfolio = {
  __typename?: 'PageableOfGameBankPortfolio';
  data: Array<GameBankPortfolio>;
  hasMore: Scalars['Boolean'];
};

export type PageableOfHouseGame = {
  __typename?: 'PageableOfHouseGame';
  data: Array<HouseGame>;
  hasMore: Scalars['Boolean'];
};

export type PageableOfInternalTransfer = {
  __typename?: 'PageableOfInternalTransfer';
  data: Array<InternalTransfer>;
  hasMore: Scalars['Boolean'];
};

export type PageableOfInvestTransaction = {
  __typename?: 'PageableOfInvestTransaction';
  data: Array<InvestTransaction>;
  hasMore: Scalars['Boolean'];
};

export type PageableOfInvestor = {
  __typename?: 'PageableOfInvestor';
  data: Array<Investor>;
  hasMore: Scalars['Boolean'];
};

export type PageableOfJackpotWinBet = {
  __typename?: 'PageableOfJackpotWinBet';
  data: Array<JackpotWinBet>;
  hasMore: Scalars['Boolean'];
};

export type PageableOfNotification = {
  __typename?: 'PageableOfNotification';
  data: Array<Notification>;
  hasMore: Scalars['Boolean'];
};

export type PageableOfOwnerNft = {
  __typename?: 'PageableOfOwnerNft';
  data: Array<OwnerNft>;
  hasMore: Scalars['Boolean'];
};

export type PageableOfProjectNft = {
  __typename?: 'PageableOfProjectNft';
  data: Array<ProjectNft>;
  hasMore: Scalars['Boolean'];
};

export type PageableOfProjectProfitInfo = {
  __typename?: 'PageableOfProjectProfitInfo';
  data: Array<ProjectProfitInfo>;
  hasMore: Scalars['Boolean'];
};

export type PageableOfReferralUser = {
  __typename?: 'PageableOfReferralUser';
  data: Array<ReferralUser>;
  hasMore: Scalars['Boolean'];
};

export type PageableOfRound = {
  __typename?: 'PageableOfRound';
  data: Array<Round>;
  hasMore: Scalars['Boolean'];
};

export type PageableOfStockCard = {
  __typename?: 'PageableOfStockCard';
  data: Array<StockCard>;
  hasMore: Scalars['Boolean'];
};

export type PageableOfTransactionInfo = {
  __typename?: 'PageableOfTransactionInfo';
  data: Array<TransactionInfo>;
  hasMore: Scalars['Boolean'];
};

export type PageableOfWithdrawalTransaction = {
  __typename?: 'PageableOfWithdrawalTransaction';
  data: Array<WithdrawalTransaction>;
  hasMore: Scalars['Boolean'];
};

export type PayloadOfAgencyInfo = {
  __typename?: 'PayloadOfAgencyInfo';
  content?: Maybe<AgencyInfo>;
  error?: Maybe<GraphError>;
  success: Scalars['Boolean'];
};

export type PayloadOfBoolean = {
  __typename?: 'PayloadOfBoolean';
  content?: Maybe<Scalars['Boolean']>;
  error?: Maybe<GraphError>;
  success: Scalars['Boolean'];
};

export type PayloadOfBuyNftTransaction = {
  __typename?: 'PayloadOfBuyNftTransaction';
  content?: Maybe<BuyNftTransaction>;
  error?: Maybe<GraphError>;
  success: Scalars['Boolean'];
};

export type PayloadOfCryptoWithdrawalTransaction = {
  __typename?: 'PayloadOfCryptoWithdrawalTransaction';
  content?: Maybe<CryptoWithdrawalTransaction>;
  error?: Maybe<GraphError>;
  success: Scalars['Boolean'];
};

export type PayloadOfGameBank = {
  __typename?: 'PayloadOfGameBank';
  content?: Maybe<GameBank>;
  error?: Maybe<GraphError>;
  success: Scalars['Boolean'];
};

export type PayloadOfHouseGame = {
  __typename?: 'PayloadOfHouseGame';
  content?: Maybe<HouseGame>;
  error?: Maybe<GraphError>;
  success: Scalars['Boolean'];
};

export type PayloadOfInternalTransfer = {
  __typename?: 'PayloadOfInternalTransfer';
  content?: Maybe<InternalTransfer>;
  error?: Maybe<GraphError>;
  success: Scalars['Boolean'];
};

export type PayloadOfInvestResult = {
  __typename?: 'PayloadOfInvestResult';
  content?: Maybe<InvestResult>;
  error?: Maybe<GraphError>;
  success: Scalars['Boolean'];
};

export type PayloadOfLaunchOptions = {
  __typename?: 'PayloadOfLaunchOptions';
  content?: Maybe<LaunchOptions>;
  error?: Maybe<GraphError>;
  success: Scalars['Boolean'];
};

export type PayloadOfListedGame = {
  __typename?: 'PayloadOfListedGame';
  content?: Maybe<ListedGame>;
  error?: Maybe<GraphError>;
  success: Scalars['Boolean'];
};

export type PayloadOfLoginResult = {
  __typename?: 'PayloadOfLoginResult';
  content?: Maybe<LoginResult>;
  error?: Maybe<GraphError>;
  success: Scalars['Boolean'];
};

export type PayloadOfProfile = {
  __typename?: 'PayloadOfProfile';
  content?: Maybe<Profile>;
  error?: Maybe<GraphError>;
  success: Scalars['Boolean'];
};

export type PayloadOfRedeemResult = {
  __typename?: 'PayloadOfRedeemResult';
  content?: Maybe<RedeemResult>;
  error?: Maybe<GraphError>;
  success: Scalars['Boolean'];
};

export type PayloadOfReferralCode = {
  __typename?: 'PayloadOfReferralCode';
  content?: Maybe<ReferralCode>;
  error?: Maybe<GraphError>;
  success: Scalars['Boolean'];
};

export type PayloadOfString = {
  __typename?: 'PayloadOfString';
  content?: Maybe<Scalars['String']>;
  error?: Maybe<GraphError>;
  success: Scalars['Boolean'];
};

export type PayloadOfSwapTransaction = {
  __typename?: 'PayloadOfSwapTransaction';
  content?: Maybe<SwapTransaction>;
  error?: Maybe<GraphError>;
  success: Scalars['Boolean'];
};

export type PayloadOfUnreadNotification = {
  __typename?: 'PayloadOfUnreadNotification';
  content?: Maybe<UnreadNotification>;
  error?: Maybe<GraphError>;
  success: Scalars['Boolean'];
};

export type PayloadOfUser = {
  __typename?: 'PayloadOfUser';
  content?: Maybe<User>;
  error?: Maybe<GraphError>;
  success: Scalars['Boolean'];
};

export type PayloadOfUserIdentification = {
  __typename?: 'PayloadOfUserIdentification';
  content?: Maybe<UserIdentification>;
  error?: Maybe<GraphError>;
  success: Scalars['Boolean'];
};

export type PayloadOfWhiteListUsers = {
  __typename?: 'PayloadOfWhiteListUsers';
  content?: Maybe<WhiteListUsers>;
  error?: Maybe<GraphError>;
  success: Scalars['Boolean'];
};

export type Photo = {
  __typename?: 'Photo';
  id: Scalars['ID'];
  large: Scalars['String'];
  medium: Scalars['String'];
  small: Scalars['String'];
  square: Scalars['String'];
  uri: Scalars['String'];
};

export type PlinkoBetData = {
  __typename?: 'PlinkoBetData';
  plinkResult: Scalars['String'];
  risk: PlinkoRisk;
  rows: Scalars['Int'];
};

export enum PlinkoRisk {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type Producer = {
  __typename?: 'Producer';
  branch: Scalars['String'];
  description: Scalars['String'];
  foundedYear: Scalars['Int'];
  gameCount: Scalars['Int'];
  id: Scalars['ID'];
  logo?: Maybe<Photo>;
  name: Scalars['String'];
  summary: Scalars['String'];
};

export type Profile = {
  __typename?: 'Profile';
  email?: Maybe<Scalars['EmailAddress']>;
  emailVerified: Scalars['Boolean'];
  hasPassword: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type ProjectNft = {
  __typename?: 'ProjectNft';
  capitalShare: Scalars['Decimal'];
  category: ProjectNftCategory;
  coverUrl?: Maybe<Photo>;
  description: Scalars['String'];
  iconUrl?: Maybe<Photo>;
  id: Scalars['ID'];
  link: Scalars['String'];
  name: Scalars['String'];
  priceNow?: Maybe<Scalars['Decimal']>;
  totalSupply: Scalars['ID'];
  type: MarketType;
};

export enum ProjectNftCategory {
  Casino = 'CASINO',
  Game = 'GAME',
  GameBank = 'GAME_BANK'
}

export type ProjectProfitInfo = {
  __typename?: 'ProjectProfitInfo';
  month: Scalars['Decimal'];
  projectNft: ProjectNft;
  week: Scalars['Decimal'];
  year: Scalars['Decimal'];
  yesterday: Scalars['Decimal'];
};

export type Query = {
  __typename?: 'Query';
  VideoPokerCurrentBet?: Maybe<BetResponse>;
  bet?: Maybe<Bet>;
  chats: Array<ChatMessage>;
  coinFlipCurrentBet?: Maybe<BetResponse>;
  crashPredictor?: Maybe<CrashPredictorEvent>;
  crashTopLucky: Array<Maybe<CrashTopLuckyResult>>;
  crashTopProfit: Array<Maybe<CrashTopProfitResult>>;
  /** Get an address to deposit for a crypto currency */
  cryptoDepositAddress?: Maybe<CryptoAddress>;
  /** Withdrawal info of a crypto currency, null means not supported */
  cryptoWithdrawalInfo?: Maybe<CryptoWithdrawalInfo>;
  currencies: Array<Currency>;
  dailyContest?: Maybe<DailyContestEvent>;
  /** deposit histories of an user */
  depositTransactions: PageableOfDepositTransaction;
  /** Ex game, null if not available */
  exGame?: Maybe<ExGame>;
  exGames?: Maybe<PageableOfExGame>;
  exProviders: Array<ExProvider>;
  funCrashPredictor?: Maybe<FunCrashPredictorEvent>;
  /** Game of the house, null if not available */
  game?: Maybe<HouseGame>;
  gameBankInvestors?: Maybe<PageableOfInvestor>;
  gameBankPortfolioSummary: GameBankPortfolioSummaryType;
  games?: Maybe<PageableOfHouseGame>;
  hideSmallWalletAmount?: Maybe<Scalars['Boolean']>;
  highBets: Array<BetSummary>;
  /** List transfer settings for supported currencies, `fee` = `<transfer amount> * `TransferFeeRate` + `TransferFeeAmount` */
  internalTransferSettings: Array<TransferSetting>;
  /** internal transfer histories of an user */
  internalTransfers: PageableOfInternalTransfer;
  investTransactions?: Maybe<PageableOfInvestTransaction>;
  /** list of jackpot win bets */
  jackpotWinBets: PageableOfJackpotWinBet;
  listGameBankPortfolio?: Maybe<PageableOfGameBankPortfolio>;
  listGameBankStat: Array<GameBankStat>;
  liveStats?: Maybe<LiveStatsData>;
  me: MeType;
  minesCurrentBet?: Maybe<BetResponse>;
  myAgency?: Maybe<AgencyInfo>;
  /** list of my bets */
  myBets: PageableOfBet;
  myCommissions: Array<CommissionValue>;
  myListInvestorPortfolio: Array<InvestorPortfolio>;
  myNfts?: Maybe<PageableOfOwnerNft>;
  myReferralCodes: Array<ReferralCode>;
  myReferralUsers?: Maybe<PageableOfReferralUser>;
  orisunProjects?: Maybe<PageableOfProjectNft>;
  orisunStock?: Maybe<StockCard>;
  orisunStocks?: Maybe<PageableOfStockCard>;
  /** Get list personal notification */
  personalNotifications: PageableOfNotification;
  projectNftProfits?: Maybe<PageableOfProjectProfitInfo>;
  recentBets: Array<BetSummary>;
  round?: Maybe<Round>;
  /** multiplayer game rounds */
  rounds: PageableOfRound;
  storageCurrency?: Maybe<Currency>;
  /** List all swap-able currencies */
  swapPairs: Array<Maybe<SwapPair>>;
  /** Get list system notification */
  systemNotifications: PageableOfNotification;
  threeDiceCurrentGame?: Maybe<ThreeDiceQueryResponse>;
  threeDiceRoundDetails?: Maybe<GameRoom>;
  threeDiceTopDaily?: Maybe<Array<Maybe<TopWin>>>;
  /** Get top 3 users have biggest total win amount today */
  todayTopBiggestWin: Array<Maybe<TopBiggestWinResult>>;
  totalNftsEquivalentUsd?: Maybe<Scalars['Decimal']>;
  /** transactions of an user */
  transactions: PageableOfTransactionInfo;
  /** transfi buycrypto histories of an user */
  transfiBuyCrypto: PageableOfInternalTransfer;
  transfiSupportedFiats: Array<TransfiFiat>;
  unreadNotification: UnreadNotification;
  userIdentification: UserIdentification;
  userNftProfits: Array<Maybe<UserProfitInfo>>;
  viewInFiat?: Maybe<ViewInFiat>;
  /** withdrawal histories of an user */
  withdrawalTransactions: PageableOfWithdrawalTransaction;
};


export type QueryVideoPokerCurrentBetArgs = {
  gameId: Scalars['ID'];
};


export type QueryBetArgs = {
  id: Scalars['ID'];
};


export type QueryChatsArgs = {
  channel: Scalars['String'];
};


export type QueryCoinFlipCurrentBetArgs = {
  gameId: Scalars['ID'];
};


export type QueryCrashPredictorArgs = {
  date?: InputMaybe<Scalars['Date']>;
  gameId: Scalars['ID'];
};


export type QueryCrashTopLuckyArgs = {
  date?: InputMaybe<Scalars['Date']>;
  gameId: Scalars['ID'];
};


export type QueryCrashTopProfitArgs = {
  date?: InputMaybe<Scalars['Date']>;
  gameId: Scalars['ID'];
};


export type QueryCryptoDepositAddressArgs = {
  currencyId: Scalars['ID'];
  network: BlockchainNetwork;
};


export type QueryCryptoWithdrawalInfoArgs = {
  currencyId: Scalars['ID'];
  network: BlockchainNetwork;
};


export type QueryDailyContestArgs = {
  date?: InputMaybe<Scalars['Date']>;
};


export type QueryDepositTransactionsArgs = {
  currencyId?: InputMaybe<Scalars['ID']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type QueryExGameArgs = {
  slug: Scalars['String'];
};


export type QueryExGamesArgs = {
  category?: InputMaybe<ExGameCategory>;
  filter?: InputMaybe<Scalars['String']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  provider?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<ExGameSorting>;
};


export type QueryFunCrashPredictorArgs = {
  date?: InputMaybe<Scalars['Date']>;
  gameId: Scalars['ID'];
};


export type QueryGameArgs = {
  slug: Scalars['String'];
};


export type QueryGameBankInvestorsArgs = {
  gameBankId: Scalars['ID'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type QueryGamesArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  producerId?: InputMaybe<Scalars['ID']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryHighBetsArgs = {
  gameId?: InputMaybe<Scalars['ID']>;
};


export type QueryInternalTransfersArgs = {
  currencyId?: InputMaybe<Scalars['ID']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type QueryInvestTransactionsArgs = {
  gameBankId: Scalars['ID'];
  onlyHost?: InputMaybe<Scalars['Boolean']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type QueryJackpotWinBetsArgs = {
  gameId?: InputMaybe<Scalars['ID']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type QueryListGameBankPortfolioArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortField?: InputMaybe<GameBankPortfolioSortField>;
  sortType?: InputMaybe<SortType>;
};


export type QueryListGameBankStatArgs = {
  gameBankId: Scalars['ID'];
  periodMinutes?: InputMaybe<Scalars['Int']>;
  time?: InputMaybe<Scalars['DateTime']>;
};


export type QueryMinesCurrentBetArgs = {
  gameId: Scalars['ID'];
};


export type QueryMyBetsArgs = {
  currencyId?: InputMaybe<Scalars['Int']>;
  gameId?: InputMaybe<Scalars['ID']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type QueryMyNftsArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type QueryMyReferralUsersArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  nickname?: InputMaybe<Scalars['String']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  referralCodeId?: InputMaybe<Scalars['ID']>;
  toDate?: InputMaybe<Scalars['Date']>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type QueryOrisunProjectsArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type QueryOrisunStockArgs = {
  id: Scalars['ID'];
};


export type QueryOrisunStocksArgs = {
  isFunded: Scalars['Boolean'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  projectId?: InputMaybe<Scalars['ID']>;
};


export type QueryPersonalNotificationsArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type QueryProjectNftProfitsArgs = {
  category?: InputMaybe<ProjectNftCategory>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type QueryRecentBetsArgs = {
  gameId?: InputMaybe<Scalars['ID']>;
};


export type QueryRoundArgs = {
  id: Scalars['ID'];
};


export type QueryRoundsArgs = {
  gameId: Scalars['ID'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type QuerySystemNotificationsArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type QueryThreeDiceCurrentGameArgs = {
  currencyId: Scalars['ID'];
};


export type QueryThreeDiceRoundDetailsArgs = {
  currencyId?: InputMaybe<Scalars['ID']>;
  roundId?: InputMaybe<Scalars['ID']>;
};


export type QueryThreeDiceTopDailyArgs = {
  currencyId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['Date']>;
};


export type QueryTransactionsArgs = {
  currencyId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  group?: InputMaybe<TransactionGroup>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryTransfiBuyCryptoArgs = {
  currencyId?: InputMaybe<Scalars['ID']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type QueryUserNftProfitsArgs = {
  days: Scalars['Int'];
};


export type QueryWithdrawalTransactionsArgs = {
  currencyId?: InputMaybe<Scalars['ID']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type RedeemResult = {
  __typename?: 'RedeemResult';
  amount: Scalars['Decimal'];
  currency: Currency;
};

export type ReferralCode = {
  __typename?: 'ReferralCode';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** NOTE: Display as `Nickname` when this value is empty */
  name: Scalars['String'];
  totalCommissions: Array<CommissionValue>;
  totalDepositUsers?: Maybe<Scalars['Int']>;
  totalDeposits?: Maybe<Scalars['Int']>;
  totalUsers?: Maybe<Scalars['Int']>;
  url: Scalars['String'];
};

export type ReferralUser = {
  __typename?: 'ReferralUser';
  commissions: Array<CommissionValue>;
  /** This is registered date of the current referral user */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  lastDeposit?: Maybe<Scalars['Date']>;
  nickname?: Maybe<Scalars['String']>;
  referralCode: ReferralCode;
  totalDeposits: Scalars['Int'];
  wagerEquivalentUsd: Scalars['Decimal'];
};


export type ReferralUserCommissionsArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
};


export type ReferralUserWagerEquivalentUsdArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type RegisterInput = {
  channel?: InputMaybe<Scalars['String']>;
  email: Scalars['EmailAddress'];
  password: Scalars['String'];
  referralCode?: InputMaybe<Scalars['String']>;
};

export type RngResult = {
  __typename?: 'RngResult';
  id: Scalars['String'];
  message: Scalars['String'];
  rng: Scalars['String'];
  rngPublicKey: Scalars['String'];
};

export type RoomDetail = {
  __typename?: 'RoomDetail';
  listBet?: Maybe<Array<Maybe<ThreeDiceBetData>>>;
  totalAccount: Scalars['Int'];
  totalBet: Scalars['Decimal'];
};

export type Round = {
  __typename?: 'Round';
  id: Scalars['ID'];
  rngResult?: Maybe<RngResult>;
  roundData?: Maybe<RoundDataType>;
};

export type RoundDataType = CrashRoundData;

export type SetupHouseGameRequestInput = {
  betRoomSettingIds: Array<Scalars['ID']>;
  gameBankId: Scalars['ID'];
  gameId: Scalars['ID'];
  houseId: Scalars['ID'];
  listing: Scalars['Boolean'];
};

export type SetupListedGameRequestInput = {
  houseGameId: Scalars['ID'];
  houseId: Scalars['ID'];
  order: Scalars['Int'];
};

export type ShareSummary = {
  __typename?: 'ShareSummary';
  hostInvestAmount: Scalars['Decimal'];
  hostShareRate: Scalars['Decimal'];
  totalInvestAmount: Scalars['Decimal'];
  yourInvestAmount?: Maybe<Scalars['Decimal']>;
  yourProfit?: Maybe<Scalars['Decimal']>;
  yourShareRate?: Maybe<Scalars['Decimal']>;
};

export type ShowInsufficientBalancePopoverReactiveVar = {
  __typename?: 'ShowInsufficientBalancePopoverReactiveVar';
  isShowInsufficientBalancePopOver?: Maybe<Scalars['Boolean']>;
};

export type ShowOverGameBankPopoverReactiveVar = {
  __typename?: 'ShowOverGameBankPopoverReactiveVar';
  isShowOverGameBankPopOver?: Maybe<Scalars['Boolean']>;
};

export enum SortType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StockCard = {
  __typename?: 'StockCard';
  currency: Currency;
  dateShow: Scalars['DateTime'];
  end: Scalars['DateTime'];
  iconUrl?: Maybe<Photo>;
  id: Scalars['ID'];
  isCanBuy: Scalars['Boolean'];
  isWhiteList: Scalars['Boolean'];
  maxBuy: Scalars['ID'];
  minBuy: Scalars['ID'];
  price: Scalars['Decimal'];
  projectNft: ProjectNft;
  round: Scalars['Int'];
  start: Scalars['DateTime'];
  totalSales: Scalars['ID'];
  totalSold: Scalars['ID'];
  type: MarketType;
};

export type StorageCurrencyReactiveVar = {
  __typename?: 'StorageCurrencyReactiveVar';
  storageCurrency?: Maybe<Currency>;
};

export type StreakLog = {
  __typename?: 'StreakLog';
  count: Scalars['Int'];
  lastIsOver: Scalars['Boolean'];
  lastRoundId: Scalars['ID'];
  mode: Scalars['Int'];
  streakType: Scalars['Int'];
  /** Null when user's privacy is anonymous, except current user */
  user?: Maybe<User>;
  userId: Scalars['ID'];
  wager: Scalars['Decimal'];
  winValue: Scalars['Decimal'];
};

export type StreakWinList = {
  __typename?: 'StreakWinList';
  count: Scalars['Int'];
  rateWin: Scalars['Int'];
  topLevel: Scalars['Int'];
  /** Null when user's privacy is anonymous, except current user */
  user?: Maybe<User>;
  userId: Scalars['ID'];
  wager: Scalars['Decimal'];
  winValue: Scalars['Decimal'];
};

export type Subscription = {
  __typename?: 'Subscription';
  chat: ChatMessage;
  highBet: BetSummary;
  jackpotWon?: Maybe<JackpotWinBet>;
  recentBet: BetSummary;
  threeDice: ThreeDiceSubResponse;
  userWalletChanged: UserWallet;
};


export type SubscriptionChatArgs = {
  channel: Scalars['String'];
};


export type SubscriptionHighBetArgs = {
  gameId?: InputMaybe<Scalars['ID']>;
};


export type SubscriptionRecentBetArgs = {
  gameId?: InputMaybe<Scalars['ID']>;
};


export type SubscriptionThreeDiceArgs = {
  mode?: InputMaybe<Scalars['ID']>;
};

export type SwapPair = {
  __typename?: 'SwapPair';
  feeAmount?: Maybe<Scalars['Decimal']>;
  feeRate?: Maybe<Scalars['Decimal']>;
  from: Currency;
  id: Scalars['ID'];
  maximumAmount?: Maybe<Scalars['Decimal']>;
  minimumAmount: Scalars['Decimal'];
  rate?: Maybe<Scalars['Decimal']>;
  to: Currency;
};

export enum SwapStatus {
  Completed = 'COMPLETED',
  Credit = 'CREDIT',
  Insufficient = 'INSUFFICIENT',
  New = 'NEW'
}

export type SwapTransaction = {
  __typename?: 'SwapTransaction';
  amount: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  fee: Scalars['Decimal'];
  finalAmount: Scalars['Decimal'];
  from: Currency;
  fromUsdRate: Scalars['Decimal'];
  id: Scalars['ID'];
  rate: Scalars['Decimal'];
  status?: Maybe<SwapStatus>;
  to: Currency;
  toUsdRate: Scalars['Decimal'];
  updatedAt: Scalars['DateTime'];
};

export type ThreeDiceBetData = {
  __typename?: 'ThreeDiceBetData';
  betValue: Scalars['Decimal'];
  betValueToBaseCurrency: Scalars['Decimal'];
  currencyId: Scalars['ID'];
  gameResults?: Maybe<GameResult>;
  houseId: Scalars['ID'];
  id: Scalars['ID'];
  isOver: Scalars['Boolean'];
  mode?: Maybe<Scalars['Int']>;
  rateToBaseCurrency: Scalars['Decimal'];
  refundValue: Scalars['Decimal'];
  refundValueToBaseCurrency: Scalars['Decimal'];
  remainBetting: Scalars['Int'];
  roundId?: Maybe<Scalars['Long']>;
  status: Scalars['Int'];
  totalBetValueToBaseCurrency: Scalars['Decimal'];
  /** Null when user's privacy is anonymous, except current user */
  user?: Maybe<User>;
  winValue: Scalars['Decimal'];
  winValueToBaseCurrency: Scalars['Decimal'];
};

export type ThreeDiceBetResponse = {
  __typename?: 'ThreeDiceBetResponse';
  betResult: BetResult;
  mode: Scalars['Int'];
  remainAmount: Scalars['Decimal'];
};

export type ThreeDiceQueryResponse = {
  __typename?: 'ThreeDiceQueryResponse';
  betLogsCurrentRounds?: Maybe<Array<Maybe<ThreeDiceBetData>>>;
  currentRoom?: Maybe<GameRoom>;
  currentRound?: Maybe<GameRoundExtend>;
  currentStreak?: Maybe<StreakLog>;
  foreCasts?: Maybe<Array<Maybe<GameResult>>>;
  gameResult?: Maybe<GameResult>;
  jackpot?: Maybe<Jackpot>;
  jackpotWins?: Maybe<Array<Maybe<JackpotHistory>>>;
  message?: Maybe<Scalars['String']>;
  mode: Scalars['Int'];
  modeInfo?: Maybe<ConfigCrypto>;
  myCurrentBet?: Maybe<CurrentBet>;
  responseCode?: Maybe<Scalars['Int']>;
  streakLeaderBoards?: Maybe<Array<Maybe<StreakLog>>>;
  userHistories?: Maybe<Array<Maybe<ThreeDiceBetData>>>;
};

export type ThreeDiceSubResponse = {
  __typename?: 'ThreeDiceSubResponse';
  bet?: Maybe<ThreeDiceBetData>;
  currentRoom?: Maybe<GameRoom>;
  currentRound?: Maybe<GameRoundExtend>;
  gameResult?: Maybe<GameResult>;
  jackpot?: Maybe<Jackpot>;
  jackpotWin?: Maybe<JackpotHistory>;
  refunds?: Maybe<Array<Maybe<WinLog>>>;
  winLogs?: Maybe<Array<Maybe<WinLog>>>;
};

export type TopBiggestWinResult = {
  __typename?: 'TopBiggestWinResult';
  rank: Scalars['Int'];
  totalWinAmountUsd: Scalars['Decimal'];
  user?: Maybe<User>;
};

export type TopWin = {
  __typename?: 'TopWin';
  mode: Scalars['Int'];
  /** Null when user's privacy is anonymous, except current user */
  user?: Maybe<User>;
  userId: Scalars['ID'];
  wager: Scalars['Decimal'];
  winValueToBaseCurrency: Scalars['Decimal'];
};

export enum TransactionGroup {
  Account = 'ACCOUNT',
  Affiliate = 'AFFILIATE',
  Bonus = 'BONUS',
  Game = 'GAME',
  InternalTransfer = 'INTERNAL_TRANSFER',
  Invest = 'INVEST',
  Swap = 'SWAP'
}

export type TransactionInfo = {
  __typename?: 'TransactionInfo';
  amount: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  currency: Currency;
  equivalentUsdRate: Scalars['Decimal'];
  group: TransactionGroup;
  id: Scalars['ID'];
  itemId: Scalars['ID'];
  label: Scalars['String'];
  remainAmount: Scalars['Decimal'];
};

export enum TransactionType {
  Airdrop = 'AIRDROP',
  BuyStock = 'BUY_STOCK'
}

export type TransferSetting = {
  __typename?: 'TransferSetting';
  currency: Currency;
  transferFeeAmount: Scalars['Decimal'];
  transferFeeRate: Scalars['Decimal'];
  transferMinimumAmount: Scalars['Decimal'];
};

export type TransfiFiat = {
  __typename?: 'TransfiFiat';
  country: Scalars['String'];
  maxAmount: Scalars['Decimal'];
  minAmount: Scalars['Decimal'];
  symbol: Scalars['String'];
};

export type UnreadNotification = {
  __typename?: 'UnreadNotification';
  personal: Scalars['Int'];
  system: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Photo>;
  id: Scalars['ID'];
  nickname: Scalars['String'];
  vipLevel: Scalars['Int'];
};

export type UserIdentification = {
  __typename?: 'UserIdentification';
  identification?: Maybe<Identification>;
  status: IdentificationStatus;
  userId: Scalars['ID'];
};

export type UserProfitInfo = {
  __typename?: 'UserProfitInfo';
  casino: Scalars['Decimal'];
  date: Scalars['Date'];
  game: Scalars['Decimal'];
  gameBank: Scalars['Decimal'];
  total: Scalars['Decimal'];
};

export type UserWallet = {
  __typename?: 'UserWallet';
  amount: Scalars['Decimal'];
  currency: Currency;
  currencyId: Scalars['ID'];
  id: Scalars['ID'];
  lockedAmount?: Maybe<Scalars['Decimal']>;
};

export type VideoPokerBetData = {
  __typename?: 'VideoPokerBetData';
  holdCards?: Maybe<Array<Scalars['Int']>>;
  initialCards: Array<Card>;
  selectHands?: Maybe<Array<Scalars['Int']>>;
  videoPokerResults: Array<Maybe<VideoPokerResult>>;
};

export type VideoPokerResult = {
  __typename?: 'VideoPokerResult';
  handIdx: Scalars['Int'];
  newCards: Array<Card>;
  winRate: Scalars['Decimal'];
  winType: VideoPokerWinType;
};

export enum VideoPokerWinType {
  Flush = 'FLUSH',
  FourOfKind = 'FOUR_OF_KIND',
  FullHouse = 'FULL_HOUSE',
  JackOrBetter = 'JACK_OR_BETTER',
  None = 'NONE',
  RoyalFlush = 'ROYAL_FLUSH',
  Straight = 'STRAIGHT',
  StraightFlush = 'STRAIGHT_FLUSH',
  ThreeOfKind = 'THREE_OF_KIND',
  TwoPairs = 'TWO_PAIRS'
}

export type ViewInFiat = {
  __typename?: 'ViewInFiat';
  fiat?: Maybe<Fiat>;
  status?: Maybe<Scalars['Boolean']>;
};

export type ViewInFiatReactiveVar = {
  __typename?: 'ViewInFiatReactiveVar';
  viewInFiat?: Maybe<ViewInFiat>;
};

export type VipUser = {
  __typename?: 'VipUser';
  betAmountUsd: Scalars['Decimal'];
  currentLevelPoint: Scalars['Long'];
  id: Scalars['ID'];
  nextLevelPoint: Scalars['Long'];
  vipLevel: Scalars['Int'];
  vipPoint: Scalars['Decimal'];
  vipUserCurrencies: Array<VipUserCurrency>;
};

export type VipUserCurrency = {
  __typename?: 'VipUserCurrency';
  currency: Currency;
  id: Scalars['ID'];
  instantBonusAvailableAmount: Scalars['Decimal'];
  lastMonthBonusAmount?: Maybe<Scalars['Decimal']>;
  lastWeekBonusAmount?: Maybe<Scalars['Decimal']>;
};

export enum WhiteListStatus {
  Approved = 'APPROVED',
  Registered = 'REGISTERED',
  Rejected = 'REJECTED'
}

export type WhiteListUsers = {
  __typename?: 'WhiteListUsers';
  id: Scalars['ID'];
  status: WhiteListStatus;
  stockCard: StockCard;
  user?: Maybe<User>;
};

export type WinLog = {
  __typename?: 'WinLog';
  awardedTime: Scalars['DateTime'];
  betId: Scalars['ID'];
  currencyId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isOver: Scalars['Boolean'];
  mode?: Maybe<Scalars['Int']>;
  rateToBaseCurrency: Scalars['Decimal'];
  refundValue: Scalars['Decimal'];
  roundId?: Maybe<Scalars['Long']>;
  /** Null when user's privacy is anonymous, except current user */
  user?: Maybe<User>;
  userId: Scalars['ID'];
  winType: Scalars['Int'];
  winValue: Scalars['Decimal'];
};

export enum WithdrawalStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Credit = 'CREDIT',
  Insufficient = 'INSUFFICIENT',
  New = 'NEW',
  Processing = 'PROCESSING',
  Rollback = 'ROLLBACK'
}

export type WithdrawalTransaction = {
  __typename?: 'WithdrawalTransaction';
  amount: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  currency: Currency;
  equivalentUsdRate: Scalars['Decimal'];
  id: Scalars['ID'];
  status: WithdrawalStatus;
  tx?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'PayloadOfLoginResult', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null, content?: { __typename?: 'LoginResult', accessToken: string } | null } };

export type LoginGoogleMutationVariables = Exact<{
  token: Scalars['String'];
  referralCode?: InputMaybe<Scalars['String']>;
}>;


export type LoginGoogleMutation = { __typename?: 'Mutation', loginGoogle: { __typename?: 'PayloadOfLoginResult', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null, content?: { __typename?: 'LoginResult', accessToken: string } | null } };

export type RegisterMutationVariables = Exact<{
  email: Scalars['EmailAddress'];
  password: Scalars['String'];
  referralCode?: InputMaybe<Scalars['String']>;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'PayloadOfLoginResult', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null, content?: { __typename?: 'LoginResult', accessToken: string } | null } };

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: { __typename?: 'PayloadOfProfile', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null, content?: { __typename?: 'Profile', id: string, hasPassword: boolean, email?: any | null, hidden: boolean } | null } };

export type RequestRecoverPasswordMutationVariables = Exact<{
  email: Scalars['EmailAddress'];
}>;


export type RequestRecoverPasswordMutation = { __typename?: 'Mutation', requestRecoverPassword: { __typename?: 'PayloadOfBoolean', success: boolean, content?: boolean | null, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null } };

export type RecoverPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type RecoverPasswordMutation = { __typename?: 'Mutation', recoverPassword: { __typename?: 'PayloadOfBoolean', success: boolean, content?: boolean | null, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: { __typename?: 'PayloadOfBoolean', success: boolean, content?: boolean | null } };

export type RequestExGameDemoMutationVariables = Exact<{
  slug: Scalars['String'];
  returnUrl: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  isMobile?: InputMaybe<Scalars['Boolean']>;
}>;


export type RequestExGameDemoMutation = { __typename?: 'Mutation', requestExGameDemo: { __typename?: 'PayloadOfLaunchOptions', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null, content?: { __typename?: 'LaunchOptions', gameUrl: string, strategy: string } | null } };

export type RequestExGamePlayMutationVariables = Exact<{
  currencyId: Scalars['ID'];
  slug: Scalars['String'];
  returnUrl: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  isMobile?: InputMaybe<Scalars['Boolean']>;
}>;


export type RequestExGamePlayMutation = { __typename?: 'Mutation', requestExGamePlay: { __typename?: 'PayloadOfLaunchOptions', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null, content?: { __typename?: 'LaunchOptions', gameUrl: string, strategy: string } | null } };

export type FavoriteMutationVariables = Exact<{
  gameId: Scalars['ID'];
  value: Scalars['Boolean'];
}>;


export type FavoriteMutation = { __typename?: 'Mutation', favorite: { __typename?: 'Game', favorites: number, favored: boolean } };

export type LikeMutationVariables = Exact<{
  gameId: Scalars['ID'];
  value: Scalars['Boolean'];
}>;


export type LikeMutation = { __typename?: 'Mutation', like: { __typename?: 'Game', likes: number, liked: boolean } };

export type MiniPokerBetMutationVariables = Exact<{
  betRoomId: Scalars['ID'];
}>;


export type MiniPokerBetMutation = { __typename?: 'Mutation', miniPokerBet: { __typename?: 'BetResponse', result: BetResult, bet?: { __typename?: 'Bet', id: string, betAmount: any, betChargeAmount: any, payoutRate: any, payoutAmount: any, betData?: { __typename?: 'BaccaratBetData' } | { __typename?: 'BitFarmBetData' } | { __typename?: 'CoinFlipBetData' } | { __typename?: 'CrashBetData' } | { __typename?: 'CryptoQuestBetData' } | { __typename?: 'DiceBetData' } | { __typename?: 'LimboBetData' } | { __typename?: 'MinesBetData' } | { __typename?: 'MiniCryptoBetData' } | { __typename?: 'MiniPokerBetData', winJackpotTotalAmount: any, miniPokerResult?: { __typename?: 'MiniPokerResult', type: MiniPokerWinType, rate: any, cards: Array<{ __typename?: 'Card', rank: number, suit: string }> } | null } | { __typename?: 'MochiMonsterBetData' } | { __typename?: 'PlinkoBetData' } | { __typename?: 'ThreeDiceBetData' } | { __typename?: 'VideoPokerBetData' } | null } | null } };

export type DiceBetMutationVariables = Exact<{
  betRoomId: Scalars['ID'];
  amount: Scalars['Decimal'];
  isRollOver: Scalars['Boolean'];
  value: Scalars['Decimal'];
}>;


export type DiceBetMutation = { __typename?: 'Mutation', diceBet: { __typename?: 'BetResponse', result: BetResult, bet?: { __typename?: 'Bet', id: string, betAmount: any, betChargeAmount: any, payoutRate: any, payoutAmount: any, equivalentUsdRate: any, currency: { __typename?: 'Currency', id: string, shortName: string, equivalentUsdRate: any, decimalDigits: number }, betData?: { __typename?: 'BaccaratBetData' } | { __typename?: 'BitFarmBetData' } | { __typename?: 'CoinFlipBetData' } | { __typename?: 'CrashBetData' } | { __typename?: 'CryptoQuestBetData' } | { __typename?: 'DiceBetData', isRollOver: boolean, result: any, betValue: any, winChance: any } | { __typename?: 'LimboBetData' } | { __typename?: 'MinesBetData' } | { __typename?: 'MiniCryptoBetData' } | { __typename?: 'MiniPokerBetData' } | { __typename?: 'MochiMonsterBetData' } | { __typename?: 'PlinkoBetData' } | { __typename?: 'ThreeDiceBetData' } | { __typename?: 'VideoPokerBetData' } | null } | null } };

export type MinesBetMutationVariables = Exact<{
  betRoomId: Scalars['ID'];
  amount: Scalars['Decimal'];
  mines: Scalars['Int'];
  cells?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type MinesBetMutation = { __typename?: 'Mutation', minesBet: { __typename?: 'BetResponse', result: BetResult, bet?: { __typename?: 'Bet', id: string, betAmount: any, betChargeAmount: any, payoutRate: any, payoutAmount: any, equivalentUsdRate: any, currency: { __typename?: 'Currency', id: string, shortName: string, equivalentUsdRate: any, decimalDigits: number }, betData?: { __typename?: 'BaccaratBetData' } | { __typename?: 'BitFarmBetData' } | { __typename?: 'CoinFlipBetData' } | { __typename?: 'CrashBetData' } | { __typename?: 'CryptoQuestBetData' } | { __typename?: 'DiceBetData' } | { __typename?: 'LimboBetData' } | { __typename?: 'MinesBetData', minesCount: number, currentMultiplier: any, nextMultiplier: any, minesCells?: Array<number> | null, openedCells: Array<number> } | { __typename?: 'MiniCryptoBetData' } | { __typename?: 'MiniPokerBetData' } | { __typename?: 'MochiMonsterBetData' } | { __typename?: 'PlinkoBetData' } | { __typename?: 'ThreeDiceBetData' } | { __typename?: 'VideoPokerBetData' } | null } | null } };

export type MinesOpenMutationVariables = Exact<{
  betRoomId: Scalars['ID'];
  cellIdx: Scalars['Int'];
}>;


export type MinesOpenMutation = { __typename?: 'Mutation', minesOpen: { __typename?: 'BetResponse', result: BetResult, bet?: { __typename?: 'Bet', id: string, betAmount: any, betChargeAmount: any, payoutRate: any, payoutAmount: any, equivalentUsdRate: any, currency: { __typename?: 'Currency', id: string, shortName: string, equivalentUsdRate: any, decimalDigits: number }, betData?: { __typename?: 'BaccaratBetData' } | { __typename?: 'BitFarmBetData' } | { __typename?: 'CoinFlipBetData' } | { __typename?: 'CrashBetData' } | { __typename?: 'CryptoQuestBetData' } | { __typename?: 'DiceBetData' } | { __typename?: 'LimboBetData' } | { __typename?: 'MinesBetData', minesCount: number, currentMultiplier: any, nextMultiplier: any, minesCells?: Array<number> | null, openedCells: Array<number> } | { __typename?: 'MiniCryptoBetData' } | { __typename?: 'MiniPokerBetData' } | { __typename?: 'MochiMonsterBetData' } | { __typename?: 'PlinkoBetData' } | { __typename?: 'ThreeDiceBetData' } | { __typename?: 'VideoPokerBetData' } | null } | null } };

export type MinesCashOutMutationVariables = Exact<{
  betRoomId: Scalars['ID'];
}>;


export type MinesCashOutMutation = { __typename?: 'Mutation', minesCashout: { __typename?: 'BetResponse', result: BetResult, bet?: { __typename?: 'Bet', id: string, betAmount: any, betChargeAmount: any, payoutRate: any, payoutAmount: any, equivalentUsdRate: any, currency: { __typename?: 'Currency', id: string, shortName: string, equivalentUsdRate: any, decimalDigits: number }, betData?: { __typename?: 'BaccaratBetData' } | { __typename?: 'BitFarmBetData' } | { __typename?: 'CoinFlipBetData' } | { __typename?: 'CrashBetData' } | { __typename?: 'CryptoQuestBetData' } | { __typename?: 'DiceBetData' } | { __typename?: 'LimboBetData' } | { __typename?: 'MinesBetData', minesCount: number, currentMultiplier: any, nextMultiplier: any, minesCells?: Array<number> | null, openedCells: Array<number> } | { __typename?: 'MiniCryptoBetData' } | { __typename?: 'MiniPokerBetData' } | { __typename?: 'MochiMonsterBetData' } | { __typename?: 'PlinkoBetData' } | { __typename?: 'ThreeDiceBetData' } | { __typename?: 'VideoPokerBetData' } | null } | null } };

export type LimboBetMutationVariables = Exact<{
  betRoomId: Scalars['ID'];
  amount: Scalars['Decimal'];
  multiplier: Scalars['Decimal'];
}>;


export type LimboBetMutation = { __typename?: 'Mutation', limboBet: { __typename?: 'BetResponse', result: BetResult, bet?: { __typename?: 'Bet', id: string, betAmount: any, betChargeAmount: any, payoutRate: any, payoutAmount: any, equivalentUsdRate: any, betData?: { __typename?: 'BaccaratBetData' } | { __typename?: 'BitFarmBetData' } | { __typename?: 'CoinFlipBetData' } | { __typename?: 'CrashBetData' } | { __typename?: 'CryptoQuestBetData' } | { __typename?: 'DiceBetData' } | { __typename?: 'LimboBetData', betMultiplier: any, result: any } | { __typename?: 'MinesBetData' } | { __typename?: 'MiniCryptoBetData' } | { __typename?: 'MiniPokerBetData' } | { __typename?: 'MochiMonsterBetData' } | { __typename?: 'PlinkoBetData' } | { __typename?: 'ThreeDiceBetData' } | { __typename?: 'VideoPokerBetData' } | null, currency: { __typename?: 'Currency', id: string, shortName: string, equivalentUsdRate: any, decimalDigits: number } } | null } };

export type MiniCryptoBetMutationVariables = Exact<{
  betRoomId: Scalars['ID'];
}>;


export type MiniCryptoBetMutation = { __typename?: 'Mutation', miniCryptoBet: { __typename?: 'BetResponse', result: BetResult, jackpotAmount?: any | null, bet?: { __typename?: 'Bet', id: string, betAmount: any, betChargeAmount: any, payoutRate: any, payoutAmount: any, equivalentUsdRate: any, createdAt: any, betData?: { __typename?: 'BaccaratBetData' } | { __typename?: 'BitFarmBetData' } | { __typename?: 'CoinFlipBetData' } | { __typename?: 'CrashBetData' } | { __typename?: 'CryptoQuestBetData' } | { __typename?: 'DiceBetData' } | { __typename?: 'LimboBetData' } | { __typename?: 'MinesBetData' } | { __typename?: 'MiniCryptoBetData', reels: Array<Array<MiniCryptoSymbol>>, winJackpotTotalAmount: any, winLines: Array<{ __typename?: 'MiniCryptoWinLine', winRate: any, rows: Array<number> }> } | { __typename?: 'MiniPokerBetData' } | { __typename?: 'MochiMonsterBetData' } | { __typename?: 'PlinkoBetData' } | { __typename?: 'ThreeDiceBetData' } | { __typename?: 'VideoPokerBetData' } | null } | null } };

export type CoinFlipBetMutationVariables = Exact<{
  betRoomId: Scalars['ID'];
  amount: Scalars['Decimal'];
  turns?: InputMaybe<Array<Scalars['Boolean']> | Scalars['Boolean']>;
}>;


export type CoinFlipBetMutation = { __typename?: 'Mutation', coinFlipBet: { __typename?: 'BetResponse', result: BetResult, bet?: { __typename?: 'Bet', id: string, betAmount: any, payoutRate: any, payoutAmount: any, betChargeAmount: any, equivalentUsdRate: any, betData?: { __typename?: 'BaccaratBetData' } | { __typename?: 'BitFarmBetData' } | { __typename?: 'CoinFlipBetData', currentMultiplier: any, nextMultiplier: any, flippedRounds: Array<boolean> } | { __typename?: 'CrashBetData' } | { __typename?: 'CryptoQuestBetData' } | { __typename?: 'DiceBetData' } | { __typename?: 'LimboBetData' } | { __typename?: 'MinesBetData' } | { __typename?: 'MiniCryptoBetData' } | { __typename?: 'MiniPokerBetData' } | { __typename?: 'MochiMonsterBetData' } | { __typename?: 'PlinkoBetData' } | { __typename?: 'ThreeDiceBetData' } | { __typename?: 'VideoPokerBetData' } | null, currency: { __typename?: 'Currency', id: string, shortName: string, equivalentUsdRate: any, decimalDigits: number } } | null } };

export type CoinFlipOpenMutationVariables = Exact<{
  betRoomId: Scalars['ID'];
  isHead: Scalars['Boolean'];
}>;


export type CoinFlipOpenMutation = { __typename?: 'Mutation', coinFlipOpen: { __typename?: 'BetResponse', result: BetResult, bet?: { __typename?: 'Bet', id: string, betAmount: any, payoutRate: any, payoutAmount: any, betChargeAmount: any, equivalentUsdRate: any, betData?: { __typename?: 'BaccaratBetData' } | { __typename?: 'BitFarmBetData' } | { __typename?: 'CoinFlipBetData', currentMultiplier: any, nextMultiplier: any, flippedRounds: Array<boolean> } | { __typename?: 'CrashBetData' } | { __typename?: 'CryptoQuestBetData' } | { __typename?: 'DiceBetData' } | { __typename?: 'LimboBetData' } | { __typename?: 'MinesBetData' } | { __typename?: 'MiniCryptoBetData' } | { __typename?: 'MiniPokerBetData' } | { __typename?: 'MochiMonsterBetData' } | { __typename?: 'PlinkoBetData' } | { __typename?: 'ThreeDiceBetData' } | { __typename?: 'VideoPokerBetData' } | null, currency: { __typename?: 'Currency', id: string, shortName: string, equivalentUsdRate: any, decimalDigits: number } } | null } };

export type CoinFlipCashoutMutationVariables = Exact<{
  betRoomId: Scalars['ID'];
}>;


export type CoinFlipCashoutMutation = { __typename?: 'Mutation', coinFlipCashout: { __typename?: 'BetResponse', result: BetResult, bet?: { __typename?: 'Bet', id: string, betAmount: any, payoutRate: any, payoutAmount: any, betChargeAmount: any, equivalentUsdRate: any, betData?: { __typename?: 'BaccaratBetData' } | { __typename?: 'BitFarmBetData' } | { __typename?: 'CoinFlipBetData', currentMultiplier: any, nextMultiplier: any, flippedRounds: Array<boolean> } | { __typename?: 'CrashBetData' } | { __typename?: 'CryptoQuestBetData' } | { __typename?: 'DiceBetData' } | { __typename?: 'LimboBetData' } | { __typename?: 'MinesBetData' } | { __typename?: 'MiniCryptoBetData' } | { __typename?: 'MiniPokerBetData' } | { __typename?: 'MochiMonsterBetData' } | { __typename?: 'PlinkoBetData' } | { __typename?: 'ThreeDiceBetData' } | { __typename?: 'VideoPokerBetData' } | null, currency: { __typename?: 'Currency', id: string, shortName: string, equivalentUsdRate: any, decimalDigits: number } } | null } };

export type UpdateNickNameMutationVariables = Exact<{
  nickname: Scalars['String'];
}>;


export type UpdateNickNameMutation = { __typename?: 'Mutation', updateNickname: { __typename?: 'PayloadOfProfile', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null, content?: { __typename?: 'Profile', id: string, hasPassword: boolean, email?: any | null, hidden: boolean } | null } };

export type SetHiddenProfileMutationVariables = Exact<{
  isHidden: Scalars['Boolean'];
}>;


export type SetHiddenProfileMutation = { __typename?: 'Mutation', setHidden: { __typename?: 'PayloadOfProfile', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null, content?: { __typename?: 'Profile', id: string, hasPassword: boolean, email?: any | null, hidden: boolean } | null } };

export type UpdateEmailMutationVariables = Exact<{
  email: Scalars['EmailAddress'];
}>;


export type UpdateEmailMutation = { __typename?: 'Mutation', updateEmail: { __typename?: 'PayloadOfProfile', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null, content?: { __typename?: 'Profile', id: string, hasPassword: boolean, email?: any | null, hidden: boolean } | null } };

export type VerifyEmailMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyEmailMutation = { __typename?: 'Mutation', verifyEmail: { __typename?: 'PayloadOfProfile', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null } };

export type RemoveActiveDeviceMutationVariables = Exact<{
  deviceId: Scalars['ID'];
}>;


export type RemoveActiveDeviceMutation = { __typename?: 'Mutation', removeActiveDevice: { __typename?: 'PayloadOfBoolean', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null } };

export type UpdateIdentificationMutationVariables = Exact<{
  input: IdentificationInput;
}>;


export type UpdateIdentificationMutation = { __typename?: 'Mutation', updateIdentification: { __typename?: 'PayloadOfUserIdentification', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null, content?: { __typename?: 'UserIdentification', status: IdentificationStatus, userId: string, identification?: { __typename?: 'Identification', id: string, firstName: string, lastName: string, gender: string, dateOfBirth: any, address: string, city: string, postalCode: string, countryCode: string } | null } | null } };

export type UploadIdentificationPhotosMutationVariables = Exact<{
  idFront?: InputMaybe<Scalars['Upload']>;
  idBack?: InputMaybe<Scalars['Upload']>;
  passport?: InputMaybe<Scalars['Upload']>;
  selfie: Scalars['Upload'];
}>;


export type UploadIdentificationPhotosMutation = { __typename?: 'Mutation', uploadIdentificationPhotos: { __typename?: 'PayloadOfUserIdentification', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null } };

export type UpdateAvatarMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UpdateAvatarMutation = { __typename?: 'Mutation', updateAvatar: { __typename?: 'PayloadOfUser', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null, content?: { __typename?: 'User', id: string, nickname: string, avatar?: { __typename?: 'Photo', id: string, uri: string, square: string, small: string, medium: string, large: string } | null } | null } };

export type SentChatMutationVariables = Exact<{
  channel: Scalars['String'];
  message: Scalars['String'];
}>;


export type SentChatMutation = { __typename?: 'Mutation', chat: { __typename?: 'ChatMessage', id: any, message: string, createdAt: any, sender: { __typename?: 'ChatUser', userId?: string | null, nickname?: string | null, vipLevel: number, avatar?: { __typename?: 'Photo', uri: string, square: string } | null } } };

export type VipClaimInstantBonusMutationVariables = Exact<{ [key: string]: never; }>;


export type VipClaimInstantBonusMutation = { __typename?: 'Mutation', vipClaimInstantBonus: { __typename?: 'PayloadOfBoolean', success: boolean, content?: boolean | null, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null } };

export type VipClaimWeekBonusMutationVariables = Exact<{ [key: string]: never; }>;


export type VipClaimWeekBonusMutation = { __typename?: 'Mutation', vipClaimWeekBonus: { __typename?: 'PayloadOfBoolean', success: boolean, content?: boolean | null, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null } };

export type VipClaimMonthBonusMutationVariables = Exact<{ [key: string]: never; }>;


export type VipClaimMonthBonusMutation = { __typename?: 'Mutation', vipClaimMonthBonus: { __typename?: 'PayloadOfBoolean', success: boolean, content?: boolean | null, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null } };

export type CreateReferralCodeMutationVariables = Exact<{
  name: Scalars['String'];
  code: Scalars['String'];
}>;


export type CreateReferralCodeMutation = { __typename?: 'Mutation', createReferralCode: { __typename?: 'PayloadOfReferralCode', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null, content?: { __typename?: 'ReferralCode', id: string, name: string, code: string } | null } };

export type ClaimCommissionsMutationVariables = Exact<{ [key: string]: never; }>;


export type ClaimCommissionsMutation = { __typename?: 'Mutation', claimCommissions: { __typename?: 'PayloadOfBoolean', success: boolean, content?: boolean | null, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null } };

export type ResponseAgencyInviteMutationVariables = Exact<{
  accepted: Scalars['Boolean'];
}>;


export type ResponseAgencyInviteMutation = { __typename?: 'Mutation', responseAgencyInvite: { __typename?: 'PayloadOfAgencyInfo', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null } };

export type CreateInternalTransferMutationVariables = Exact<{
  receiverId: Scalars['ID'];
  currencyId: Scalars['ID'];
  amount: Scalars['Decimal'];
  message: Scalars['String'];
}>;


export type CreateInternalTransferMutation = { __typename?: 'Mutation', createInternalTransfer: { __typename?: 'PayloadOfInternalTransfer', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null, content?: { __typename?: 'InternalTransfer', id: string, senderId: string, senderNickname?: string | null, receiverId: string, receiverNickname?: string | null, amount: any, message: string, equivalentUsdRate: any, status: InternalTransferStatus, createdAt: any, senderAvatar?: { __typename?: 'Photo', uri: string, square: string, small: string, medium: string, large: string } | null, receiverAvatar?: { __typename?: 'Photo', uri: string, square: string, small: string, medium: string, large: string } | null, currency: { __typename?: 'Currency', id: string, name: string, shortName: string, type: CurrencyType, decimalDigits: number, equivalentUsdRate: any, blockchainNetworks?: Array<BlockchainNetwork> | null } } | null } };

export type RedeemCodeMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type RedeemCodeMutation = { __typename?: 'Mutation', redeemCode: { __typename?: 'PayloadOfRedeemResult', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null, content?: { __typename?: 'RedeemResult', amount: any, currency: { __typename?: 'Currency', id: string, name: string, shortName: string, type: CurrencyType, decimalDigits: number, equivalentUsdRate: any, blockchainNetworks?: Array<BlockchainNetwork> | null } } | null } };

export type CreateCryptoWithdrawalMutationVariables = Exact<{
  currencyId: Scalars['ID'];
  network: BlockchainNetwork;
  address: Scalars['String'];
  amount: Scalars['Decimal'];
}>;


export type CreateCryptoWithdrawalMutation = { __typename?: 'Mutation', createCryptoWithdrawal: { __typename?: 'PayloadOfCryptoWithdrawalTransaction', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null } };

export type TransfiRequestBuyCryptoMutationVariables = Exact<{
  currencyId: Scalars['Long'];
  amount: Scalars['Decimal'];
  fiat: Scalars['String'];
  returnUrl: Scalars['String'];
}>;


export type TransfiRequestBuyCryptoMutation = { __typename?: 'Mutation', transfiRequestBuyCrypto: { __typename?: 'PayloadOfString', success: boolean, content?: string | null, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null } };

export type DepositGameBankMutationVariables = Exact<{
  gameBankId: Scalars['ID'];
  amount: Scalars['Decimal'];
}>;


export type DepositGameBankMutation = { __typename?: 'Mutation', investorDeposit: { __typename?: 'PayloadOfInvestResult', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null, content?: { __typename?: 'InvestResult', investTransaction: { __typename?: 'InvestTransaction', amount: any, equivalentUsdRate: any, currency: { __typename?: 'Currency', id: string } } } | null } };

export type WithdrawGameBankMutationVariables = Exact<{
  gameBankId: Scalars['ID'];
  amount?: InputMaybe<Scalars['Decimal']>;
  byPercent: Scalars['Boolean'];
}>;


export type WithdrawGameBankMutation = { __typename?: 'Mutation', investorWithdraw: { __typename?: 'PayloadOfInvestResult', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null, content?: { __typename?: 'InvestResult', investTransaction: { __typename?: 'InvestTransaction', amount: any, equivalentUsdRate: any, currency: { __typename?: 'Currency', id: string } } } | null } };

export type SwapCurrencyMutationVariables = Exact<{
  fromId: Scalars['ID'];
  toId: Scalars['ID'];
  amount: Scalars['Decimal'];
}>;


export type SwapCurrencyMutation = { __typename?: 'Mutation', swap: { __typename?: 'PayloadOfSwapTransaction', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null, content?: { __typename?: 'SwapTransaction', id: string, status?: SwapStatus | null, fromUsdRate: any, toUsdRate: any, amount: any, rate: any, from: { __typename?: 'Currency', id: string, name: string, shortName: string, decimalDigits: number, equivalentUsdRate: any }, to: { __typename?: 'Currency', id: string, name: string, shortName: string, decimalDigits: number, equivalentUsdRate: any } } | null } };

export type MyBetsQueryVariables = Exact<{
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  gameId?: InputMaybe<Scalars['ID']>;
  currencyId?: InputMaybe<Scalars['Int']>;
}>;


export type MyBetsQuery = { __typename?: 'Query', myBets: { __typename?: 'PageableOfBet', hasMore: boolean, data: Array<{ __typename?: 'Bet', id: string, createdAt: any, betAmount: any, betChargeAmount: any, payoutRate: any, payoutAmount: any, equivalentUsdRate: any, currency: { __typename?: 'Currency', id: string, shortName: string, decimalDigits: number, equivalentUsdRate: any }, betData?: { __typename?: 'BaccaratBetData' } | { __typename?: 'BitFarmBetData' } | { __typename?: 'CoinFlipBetData' } | { __typename?: 'CrashBetData' } | { __typename?: 'CryptoQuestBetData' } | { __typename?: 'DiceBetData', result: any, betValue: any } | { __typename?: 'LimboBetData', betMultiplier: any, result: any } | { __typename?: 'MinesBetData' } | { __typename?: 'MiniCryptoBetData' } | { __typename?: 'MiniPokerBetData' } | { __typename?: 'MochiMonsterBetData' } | { __typename?: 'PlinkoBetData' } | { __typename?: 'ThreeDiceBetData' } | { __typename?: 'VideoPokerBetData' } | null }> } };

export type NewMyBetFragment = { __typename?: 'Bet', id: string, betAmount: any, payoutRate: any, payoutAmount: any, equivalentUsdRate: any, currency: { __typename?: 'Currency', id: string, shortName: string, equivalentUsdRate: any, decimalDigits: number } };

export type BetQueryVariables = Exact<{
  betId: Scalars['ID'];
}>;


export type BetQuery = { __typename?: 'Query', bet?: { __typename?: 'Bet', id: string, createdAt: any, betAmount: any, betChargeAmount: any, payoutRate: any, payoutAmount: any, equivalentUsdRate: any, rngResult?: { __typename?: 'RngResult', id: string, message: string, rng: string, rngPublicKey: string } | null, game: { __typename?: 'Game', id: string, name: string, slug: string, gameBase: { __typename?: 'GameBase', thumbnail?: { __typename?: 'Photo', square: string } | null, producer: { __typename?: 'Producer', name: string } } }, user?: { __typename?: 'User', id: string, nickname: string, avatar?: { __typename?: 'Photo', square: string } | null } | null, currency: { __typename?: 'Currency', id: string, shortName: string, decimalDigits: number, equivalentUsdRate: any } } | null };

export type JackpotWinnersQueryVariables = Exact<{
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  gameId?: InputMaybe<Scalars['ID']>;
}>;


export type JackpotWinnersQuery = { __typename?: 'Query', jackpotWinBets: { __typename?: 'PageableOfJackpotWinBet', hasMore: boolean, data: Array<{ __typename?: 'JackpotWinBet', betId: string, jackpotCount: number, jackpotTotalAmount: any, price: any, at: any, game: { __typename?: 'Game', id: string, slug: string, name: string }, currency: { __typename?: 'Currency', id: string, shortName: string, decimalDigits: number, equivalentUsdRate: any }, user?: { __typename?: 'User', id: string, nickname: string, vipLevel: number, avatar?: { __typename?: 'Photo', square: string } | null } | null }> } };

export type RecentBetsQueryVariables = Exact<{
  gameId?: InputMaybe<Scalars['ID']>;
}>;


export type RecentBetsQuery = { __typename?: 'Query', recentBets: Array<{ __typename?: 'BetSummary', id: string, betAmount: any, payoutRate: any, payoutAmount: any, equivalentUsdRate: any, currency: { __typename?: 'Currency', id: string, shortName: string, decimalDigits: number, equivalentUsdRate: any }, game: { __typename?: 'Game', id: string, name: string }, user?: { __typename?: 'User', id: string, nickname: string, avatar?: { __typename?: 'Photo', square: string } | null } | null }> };

export type HighBetsQueryVariables = Exact<{
  gameId?: InputMaybe<Scalars['ID']>;
}>;


export type HighBetsQuery = { __typename?: 'Query', highBets: Array<{ __typename?: 'BetSummary', id: string, equivalentUsdRate: any, betAmount: any, payoutRate: any, payoutAmount: any, game: { __typename?: 'Game', id: string, name: string, gameBase: { __typename?: 'GameBase', thumbnail?: { __typename?: 'Photo', square: string } | null } }, currency: { __typename?: 'Currency', id: string, shortName: string, decimalDigits: number, equivalentUsdRate: any }, user?: { __typename?: 'User', id: string, nickname: string, avatar?: { __typename?: 'Photo', square: string } | null } | null }> };

export type TodayTopBiggestWinQueryVariables = Exact<{ [key: string]: never; }>;


export type TodayTopBiggestWinQuery = { __typename?: 'Query', todayTopBiggestWin: Array<{ __typename?: 'TopBiggestWinResult', rank: number, totalWinAmountUsd: any, user?: { __typename?: 'User', id: string, nickname: string, avatar?: { __typename?: 'Photo', uri: string, square: string, small: string, medium: string, large: string } | null } | null } | null> };

export type DailyContestQueryVariables = Exact<{
  date?: InputMaybe<Scalars['Date']>;
}>;


export type DailyContestQuery = { __typename?: 'Query', dailyContest?: { __typename?: 'DailyContestEvent', id: string, start: any, end: any, date: any, totalPrize: any, doubleWagerGame?: { __typename?: 'Game', id: string, name: string, slug: string, gameBase: { __typename?: 'GameBase', thumbnail?: { __typename?: 'Photo', square: string } | null } } | null } | null };

export type DailyContestResultsQueryVariables = Exact<{
  date?: InputMaybe<Scalars['Date']>;
}>;


export type DailyContestResultsQuery = { __typename?: 'Query', dailyContest?: { __typename?: 'DailyContestEvent', myWager: any, dailyContestResults: Array<{ __typename?: 'DailyContestResult', id: string, rank: number, totalWager: any, amount?: any | null, user?: { __typename?: 'User', id: string, nickname: string, avatar?: { __typename?: 'Photo', square: string } | null } | null }> } | null };

export type CrashPredictorQueryVariables = Exact<{
  date?: InputMaybe<Scalars['Date']>;
  gameId: Scalars['ID'];
}>;


export type CrashPredictorQuery = { __typename?: 'Query', crashPredictor?: { __typename?: 'CrashPredictorEvent', id: string, start: any, end: any, date: any, totalPrize: any, crashPredictorResults: Array<{ __typename?: 'CrashPredictorResult', id: string, rank: number, cashout: any, crashResult: any, totalWager: any, amount?: any | null, user?: { __typename?: 'User', id: string, nickname: string, vipLevel: number, avatar?: { __typename?: 'Photo', square: string } | null } | null }>, myPredictor?: { __typename?: 'CrashPredictorResult', id: string, rank: number, cashout: any, crashResult: any, totalWager: any, amount?: any | null } | null } | null };

export type FunCrashPredictorQueryVariables = Exact<{
  gameId: Scalars['ID'];
  date?: InputMaybe<Scalars['Date']>;
}>;


export type FunCrashPredictorQuery = { __typename?: 'Query', funCrashPredictor?: { __typename?: 'FunCrashPredictorEvent', id: string, start: any, end: any, date: any, totalPrize: any, crashPredictorResults: Array<{ __typename?: 'FunCrashPredictorResult', id: string, rank: number, cashout: any, crashResult: any, totalWager: any, amount?: any | null, user?: { __typename?: 'User', id: string, nickname: string, vipLevel: number, avatar?: { __typename?: 'Photo', square: string } | null } | null }>, myPredictor?: { __typename?: 'FunCrashPredictorResult', id: string, rank: number, cashout: any, crashResult: any, totalWager: any, amount?: any | null } | null } | null };

export type ExProvidersQueryVariables = Exact<{ [key: string]: never; }>;


export type ExProvidersQuery = { __typename?: 'Query', exProviders: Array<{ __typename?: 'ExProvider', slug: string, name: string, gameCount: number, logo?: { __typename?: 'Photo', square: string, small: string } | null }> };

export type ExGamesQueryVariables = Exact<{
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  category?: InputMaybe<ExGameCategory>;
  provider?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<ExGameSorting>;
}>;


export type ExGamesQuery = { __typename?: 'Query', exGames?: { __typename?: 'PageableOfExGame', hasMore: boolean, data: Array<{ __typename?: 'ExGame', slug: string, title: string, supportedCurrencyIds: Array<string>, thumbnail?: { __typename?: 'Photo', square: string } | null, provider?: { __typename?: 'ExProvider', slug: string, name: string, gameCount: number, logo?: { __typename?: 'Photo', square: string, small: string } | null } | null }> } | null };

export type ExGameQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ExGameQuery = { __typename?: 'Query', exGame?: { __typename?: 'ExGame', slug: string, title: string, supportedCurrencyIds: Array<string>, thumbnail?: { __typename?: 'Photo', square: string } | null, provider?: { __typename?: 'ExProvider', slug: string, name: string, gameCount: number, logo?: { __typename?: 'Photo', square: string, small: string } | null } | null } | null };

export type GamesQueryVariables = Exact<{
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  producerId?: InputMaybe<Scalars['ID']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GamesQuery = { __typename?: 'Query', games?: { __typename?: 'PageableOfHouseGame', hasMore: boolean, data: Array<{ __typename?: 'HouseGame', id: string, game: { __typename?: 'Game', id: string, name: string, slug: string, gameBase: { __typename?: 'GameBase', id: string, type: GameType, isMiniGame: boolean, tags: Array<string>, rtp: any, maxWinRate: any, thumbnail?: { __typename?: 'Photo', square: string, large: string, medium: string } | null, producer: { __typename?: 'Producer', name: string, gameCount: number, branch: string } } } }> } | null };

export type SlotWithJackpotGamesQueryVariables = Exact<{
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  currencyId: Scalars['ID'];
  producerId?: InputMaybe<Scalars['ID']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type SlotWithJackpotGamesQuery = { __typename?: 'Query', games?: { __typename?: 'PageableOfHouseGame', hasMore: boolean, data: Array<{ __typename?: 'HouseGame', id: string, game: { __typename?: 'Game', id: string, name: string, slug: string, gameBase: { __typename?: 'GameBase', id: string, type: GameType, isMiniGame: boolean, tags: Array<string>, rtp: any, maxWinRate: any, thumbnail?: { __typename?: 'Photo', square: string, large: string, medium: string } | null, producer: { __typename?: 'Producer', name: string, gameCount: number, branch: string } } }, gameFund?: { __typename?: 'GameFund', id: string, betRooms: Array<{ __typename?: 'BetRoom', id: string, jackpotAmount?: any | null, betRoomSetting: { __typename?: 'BetRoomSetting', minimumBetAmount: any } }> } | null }> } | null };

export type GamesFragment = { __typename?: 'PageableOfHouseGame', hasMore: boolean, data: Array<{ __typename?: 'HouseGame', id: string, game: { __typename?: 'Game', id: string, name: string, slug: string, gameBase: { __typename?: 'GameBase', id: string, type: GameType, isMiniGame: boolean, tags: Array<string>, rtp: any, maxWinRate: any, thumbnail?: { __typename?: 'Photo', square: string, large: string, medium: string } | null, producer: { __typename?: 'Producer', id: string, name: string, gameCount: number, branch: string } } } }> };

export type GameQueryVariables = Exact<{
  slug: Scalars['String'];
  currencyId: Scalars['ID'];
}>;


export type GameQuery = { __typename?: 'Query', game?: { __typename?: 'HouseGame', id: string, game: { __typename?: 'Game', id: string, name: string, slug: string, likes: number, liked: boolean, favorites: number, favored: boolean, gameBase: { __typename?: 'GameBase', id: string, type: GameType, isMiniGame: boolean, releaseDate: any, summary: string, description: string, reviewing: string, tags: Array<string>, rtp: any, maxWinRate: any, thumbnail?: { __typename?: 'Photo', square: string } | null, previews: Array<{ __typename?: 'Photo', square: string, large: string }>, producer: { __typename?: 'Producer', name: string, gameCount: number, logo?: { __typename?: 'Photo', square: string } | null } } }, gameFund?: { __typename?: 'GameFund', id: string, gameBank?: { __typename?: 'GameBank', id: string, isAvailable: boolean, investable: boolean, listing: boolean, depositMinimumAmount: any, allInvestorMaxShareRate?: any | null, singleInvestorMaxShareRate?: any | null, dilutionFeeRate: any, withdrawalFeeRate: any, amount: any, maxProfit?: any | null, currency: { __typename?: 'Currency', id: string, name: string, shortName: string, type: CurrencyType, decimalDigits: number, equivalentUsdRate: any } } | null, betRooms: Array<{ __typename?: 'BetRoom', id: string, status: BetRoomStatus, jackpotAmount?: any | null, betRoomSetting: { __typename?: 'BetRoomSetting', id: string, minimumBetAmount: any, maximumBetAmount?: any | null, defaultBetAmount: any, currency: { __typename?: 'Currency', id: string, shortName: string, equivalentUsdRate: any, decimalDigits: number } } }> } | null } | null };

export type GetGameBankQueryVariables = Exact<{
  slug: Scalars['String'];
  currencyId: Scalars['ID'];
}>;


export type GetGameBankQuery = { __typename?: 'Query', game?: { __typename?: 'HouseGame', id: string, gameFund?: { __typename?: 'GameFund', gameBank?: { __typename?: 'GameBank', id: string, isAvailable: boolean, investable: boolean, listing: boolean, depositMinimumAmount: any, allInvestorMaxShareRate?: any | null, singleInvestorMaxShareRate?: any | null, dilutionFeeRate: any, withdrawalFeeRate: any, amount: any, currency: { __typename?: 'Currency', id: string, name: string, shortName: string, type: CurrencyType, decimalDigits: number, equivalentUsdRate: any, blockchainNetworks?: Array<BlockchainNetwork> | null }, shareSummary: { __typename?: 'ShareSummary', totalInvestAmount: any, hostInvestAmount: any, hostShareRate: any, yourInvestAmount?: any | null, yourShareRate?: any | null, yourProfit?: any | null } } | null, betRooms: Array<{ __typename?: 'BetRoom', id: string, jackpotAmount?: any | null, betRoomSetting: { __typename?: 'BetRoomSetting', id: string, minimumBetAmount: any, maximumBetAmount?: any | null, defaultBetAmount: any } }> } | null } | null };

export type MinesCurrentBetQueryVariables = Exact<{
  gameId: Scalars['ID'];
}>;


export type MinesCurrentBetQuery = { __typename?: 'Query', minesCurrentBet?: { __typename?: 'BetResponse', result: BetResult, bet?: { __typename?: 'Bet', id: string, betAmount: any, betChargeAmount: any, payoutRate: any, payoutAmount: any, equivalentUsdRate: any, betData?: { __typename?: 'BaccaratBetData' } | { __typename?: 'BitFarmBetData' } | { __typename?: 'CoinFlipBetData' } | { __typename?: 'CrashBetData' } | { __typename?: 'CryptoQuestBetData' } | { __typename?: 'DiceBetData' } | { __typename?: 'LimboBetData' } | { __typename?: 'MinesBetData', minesCount: number, currentMultiplier: any, nextMultiplier: any, minesCells?: Array<number> | null, openedCells: Array<number> } | { __typename?: 'MiniCryptoBetData' } | { __typename?: 'MiniPokerBetData' } | { __typename?: 'MochiMonsterBetData' } | { __typename?: 'PlinkoBetData' } | { __typename?: 'ThreeDiceBetData' } | { __typename?: 'VideoPokerBetData' } | null, currency: { __typename?: 'Currency', id: string, name: string, shortName: string, decimalDigits: number, equivalentUsdRate: any } } | null } | null };

export type CoinFlipCurrentBetQueryVariables = Exact<{
  gameId: Scalars['ID'];
}>;


export type CoinFlipCurrentBetQuery = { __typename?: 'Query', coinFlipCurrentBet?: { __typename?: 'BetResponse', bet?: { __typename?: 'Bet', id: string, betAmount: any, payoutRate: any, payoutAmount: any, equivalentUsdRate: any, currency: { __typename?: 'Currency', id: string, shortName: string, equivalentUsdRate: any, decimalDigits: number }, betData?: { __typename?: 'BaccaratBetData' } | { __typename?: 'BitFarmBetData' } | { __typename?: 'CoinFlipBetData', currentMultiplier: any, nextMultiplier: any, flippedRounds: Array<boolean> } | { __typename?: 'CrashBetData' } | { __typename?: 'CryptoQuestBetData' } | { __typename?: 'DiceBetData' } | { __typename?: 'LimboBetData' } | { __typename?: 'MinesBetData' } | { __typename?: 'MiniCryptoBetData' } | { __typename?: 'MiniPokerBetData' } | { __typename?: 'MochiMonsterBetData' } | { __typename?: 'PlinkoBetData' } | { __typename?: 'ThreeDiceBetData' } | { __typename?: 'VideoPokerBetData' } | null } | null } | null };

export type CrashRoundResultQueryVariables = Exact<{
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  gameId: Scalars['ID'];
}>;


export type CrashRoundResultQuery = { __typename?: 'Query', rounds: { __typename?: 'PageableOfRound', hasMore: boolean, data: Array<{ __typename?: 'Round', id: string, roundData?: { __typename?: 'CrashRoundData', result: any } | null }> } };

export type ListGameBankStatQueryVariables = Exact<{
  gameBankId: Scalars['ID'];
  periodMinutes?: InputMaybe<Scalars['Int']>;
  time?: InputMaybe<Scalars['DateTime']>;
}>;


export type ListGameBankStatQuery = { __typename?: 'Query', listGameBankStat: Array<{ __typename?: 'GameBankStat', id: string, amount: any, investAmount: any, myInvest?: any | null, time: any }> };

export type JackpotAmountQueryVariables = Exact<{
  slug: Scalars['String'];
  currencyId: Scalars['ID'];
}>;


export type JackpotAmountQuery = { __typename?: 'Query', game?: { __typename?: 'HouseGame', id: string, game: { __typename?: 'Game', id: string, slug: string, gameBase: { __typename?: 'GameBase', id: string, type: GameType } }, gameFund?: { __typename?: 'GameFund', id: string, betRooms: Array<{ __typename?: 'BetRoom', id: string, jackpotAmount?: any | null }> } | null } | null };

export type ThreeDiceCurrentRoundQueryVariables = Exact<{
  currencyId: Scalars['ID'];
}>;


export type ThreeDiceCurrentRoundQuery = { __typename?: 'Query', threeDiceCurrentGame?: { __typename?: 'ThreeDiceQueryResponse', currentRound?: { __typename?: 'GameRoundExtend', id?: string | null, nextRoundId: string } | null } | null };

export type ThreeDiceRoundDetailQueryVariables = Exact<{
  roundId: Scalars['ID'];
  currencyId: Scalars['ID'];
}>;


export type ThreeDiceRoundDetailQuery = { __typename?: 'Query', threeDiceRoundDetails?: { __typename?: 'GameRoom', winSpotId: number, betModes?: Array<{ __typename?: 'BetMode', mode: number, overList?: { __typename?: 'RoomDetail', totalAccount: number, totalBet: any, listBet?: Array<{ __typename?: 'ThreeDiceBetData', id: string, winValue: any, currencyId: string, isOver: boolean, refundValue: any, rateToBaseCurrency: any, totalBetValueToBaseCurrency: any, betValueToBaseCurrency: any, betValue: any, user?: { __typename?: 'User', id: string, nickname: string, avatar?: { __typename?: 'Photo', square: string } | null } | null } | null> | null } | null, underList?: { __typename?: 'RoomDetail', totalAccount: number, totalBet: any, listBet?: Array<{ __typename?: 'ThreeDiceBetData', id: string, winValue: any, currencyId: string, refundValue: any, isOver: boolean, rateToBaseCurrency: any, totalBetValueToBaseCurrency: any, betValueToBaseCurrency: any, betValue: any, user?: { __typename?: 'User', id: string, nickname: string, avatar?: { __typename?: 'Photo', square: string } | null } | null } | null> | null } | null } | null> | null } | null };

export type ThreeDiceLeaderBoardQueryVariables = Exact<{
  currencyId: Scalars['ID'];
}>;


export type ThreeDiceLeaderBoardQuery = { __typename?: 'Query', threeDiceCurrentGame?: { __typename?: 'ThreeDiceQueryResponse', currentStreak?: { __typename?: 'StreakLog', count: number, mode: number, wager: any, streakType: number, winValue: any } | null, streakLeaderBoards?: Array<{ __typename?: 'StreakLog', count: number, mode: number, wager: any, userId: string, streakType: number, winValue: any, user?: { __typename?: 'User', id: string, nickname: string, avatar?: { __typename?: 'Photo', square: string } | null } | null } | null> | null } | null };

export type ThreeDiceEventHistoriesQueryVariables = Exact<{
  currencyId: Scalars['ID'];
}>;


export type ThreeDiceEventHistoriesQuery = { __typename?: 'Query', threeDiceCurrentGame?: { __typename?: 'ThreeDiceQueryResponse', jackpotWins?: Array<{ __typename?: 'JackpotHistory', id: string, jackpotPay: any, roundId: string, result3D?: Array<number | null> | null, createdTime: any, jackpotWinList?: Array<{ __typename?: 'StreakWinList', winValue: any, count: number, wager: any, user?: { __typename?: 'User', id: string, nickname: string, avatar?: { __typename?: 'Photo', square: string } | null } | null } | null> | null } | null> | null } | null };

export type ThreeDiceTopDailyQueryVariables = Exact<{
  date: Scalars['Date'];
  currencyId: Scalars['ID'];
}>;


export type ThreeDiceTopDailyQuery = { __typename?: 'Query', threeDiceTopDaily?: Array<{ __typename?: 'TopWin', wager: any, winValueToBaseCurrency: any, user?: { __typename?: 'User', id: string, nickname: string, avatar?: { __typename?: 'Photo', square: string } | null } | null } | null> | null };

export type ThreeDiceHistoriesQueryVariables = Exact<{
  currencyId: Scalars['ID'];
}>;


export type ThreeDiceHistoriesQuery = { __typename?: 'Query', threeDiceCurrentGame?: { __typename?: 'ThreeDiceQueryResponse', foreCasts?: Array<{ __typename?: 'GameResult', id?: any | null, diceSum: number, dice1: number, dice2: number, dice3: number, rngResult?: { __typename?: 'RngResult', rng: string } | null } | null> | null, userHistories?: Array<{ __typename?: 'ThreeDiceBetData', id: string, winValue: any, currencyId: string, roundId?: any | null, isOver: boolean, refundValue: any, rateToBaseCurrency: any, totalBetValueToBaseCurrency: any, betValueToBaseCurrency: any, betValue: any, gameResults?: { __typename?: 'GameResult', diceSum: number, winSpotId: number } | null } | null> | null } | null };

export type VideoPokerCurrentBetQueryVariables = Exact<{
  gameId: Scalars['ID'];
}>;


export type VideoPokerCurrentBetQuery = { __typename?: 'Query', VideoPokerCurrentBet?: { __typename?: 'BetResponse', result: BetResult, bet?: { __typename?: 'Bet', id: string, betAmount: any, betChargeAmount: any, payoutRate: any, payoutAmount: any, equivalentUsdRate: any, betData?: { __typename?: 'BaccaratBetData' } | { __typename?: 'BitFarmBetData' } | { __typename?: 'CoinFlipBetData' } | { __typename?: 'CrashBetData' } | { __typename?: 'CryptoQuestBetData' } | { __typename?: 'DiceBetData' } | { __typename?: 'LimboBetData' } | { __typename?: 'MinesBetData' } | { __typename?: 'MiniCryptoBetData' } | { __typename?: 'MiniPokerBetData' } | { __typename?: 'MochiMonsterBetData' } | { __typename?: 'PlinkoBetData' } | { __typename?: 'ThreeDiceBetData' } | { __typename?: 'VideoPokerBetData', selectHands?: Array<number> | null, holdCards?: Array<number> | null, initialCards: Array<{ __typename?: 'Card', rank: number, suit: string }> } | null, currency: { __typename?: 'Currency', id: string, shortName: string, decimalDigits: number, equivalentUsdRate: any } } | null } | null };

export type VideoPokerBetMutationVariables = Exact<{
  betRoomId: Scalars['ID'];
  amount: Scalars['Decimal'];
  selectHands?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type VideoPokerBetMutation = { __typename?: 'Mutation', videoPokerBet: { __typename?: 'BetResponse', result: BetResult, balance?: any | null, bet?: { __typename?: 'Bet', id: string, betAmount: any, betChargeAmount: any, payoutRate: any, payoutAmount: any, equivalentUsdRate: any, betData?: { __typename?: 'BaccaratBetData' } | { __typename?: 'BitFarmBetData' } | { __typename?: 'CoinFlipBetData' } | { __typename?: 'CrashBetData' } | { __typename?: 'CryptoQuestBetData' } | { __typename?: 'DiceBetData' } | { __typename?: 'LimboBetData' } | { __typename?: 'MinesBetData' } | { __typename?: 'MiniCryptoBetData' } | { __typename?: 'MiniPokerBetData' } | { __typename?: 'MochiMonsterBetData' } | { __typename?: 'PlinkoBetData' } | { __typename?: 'ThreeDiceBetData' } | { __typename?: 'VideoPokerBetData', selectHands?: Array<number> | null, holdCards?: Array<number> | null, initialCards: Array<{ __typename?: 'Card', rank: number, suit: string }> } | null, currency: { __typename?: 'Currency', id: string, shortName: string, decimalDigits: number, equivalentUsdRate: any } } | null } };

export type VideoPokerDealMutationVariables = Exact<{
  betRoomId: Scalars['ID'];
  holdCards?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type VideoPokerDealMutation = { __typename?: 'Mutation', videoPokerDeal: { __typename?: 'BetResponse', result: BetResult, balance?: any | null, bet?: { __typename?: 'Bet', id: string, betAmount: any, betChargeAmount: any, payoutRate: any, payoutAmount: any, equivalentUsdRate: any, currency: { __typename?: 'Currency', id: string, shortName: string, decimalDigits: number, equivalentUsdRate: any }, betData?: { __typename?: 'BaccaratBetData' } | { __typename?: 'BitFarmBetData' } | { __typename?: 'CoinFlipBetData' } | { __typename?: 'CrashBetData' } | { __typename?: 'CryptoQuestBetData' } | { __typename?: 'DiceBetData' } | { __typename?: 'LimboBetData' } | { __typename?: 'MinesBetData' } | { __typename?: 'MiniCryptoBetData' } | { __typename?: 'MiniPokerBetData' } | { __typename?: 'MochiMonsterBetData' } | { __typename?: 'PlinkoBetData' } | { __typename?: 'ThreeDiceBetData' } | { __typename?: 'VideoPokerBetData', selectHands?: Array<number> | null, holdCards?: Array<number> | null, initialCards: Array<{ __typename?: 'Card', rank: number, suit: string }>, videoPokerResults: Array<{ __typename?: 'VideoPokerResult', handIdx: number, winType: VideoPokerWinType, winRate: any, newCards: Array<{ __typename?: 'Card', rank: number, suit: string }> } | null> } | null } | null } };

export type UserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type UserInfoQuery = { __typename?: 'Query', me: { __typename?: 'MeType', user: { __typename?: 'User', id: string, nickname: string, vipLevel: number, avatar?: { __typename?: 'Photo', id: string, uri: string, square: string, small: string, medium: string, large: string } | null } } };

export type UserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type UserProfileQuery = { __typename?: 'Query', me: { __typename?: 'MeType', profile: { __typename?: 'Profile', id: string, hasPassword: boolean, email?: any | null, emailVerified: boolean, hidden: boolean } } };

export type UserWalletsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserWalletsQuery = { __typename?: 'Query', me: { __typename?: 'MeType', userWallets: Array<{ __typename?: 'UserWallet', id: string, currencyId: string, amount: any, lockedAmount?: any | null }> } };

export type ActiveDevicesQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveDevicesQuery = { __typename?: 'Query', me: { __typename?: 'MeType', activeDevices: Array<{ __typename?: 'ActiveDevice', id: string, device: string, lastSeenIpAddress?: string | null, lastSeenAt?: any | null, current: boolean }> } };

export type UserIdentificationQueryVariables = Exact<{ [key: string]: never; }>;


export type UserIdentificationQuery = { __typename?: 'Query', userIdentification: { __typename?: 'UserIdentification', userId: string, status: IdentificationStatus, identification?: { __typename?: 'Identification', id: string, firstName: string, lastName: string, gender: string, dateOfBirth: any, address: string, city: string, postalCode: string, countryCode: string } | null } };

export type LoyalUserQueryVariables = Exact<{ [key: string]: never; }>;


export type LoyalUserQuery = { __typename?: 'Query', me: { __typename?: 'MeType', withdrawalRequiredBetAmountUsd: any, vipUser: { __typename?: 'VipUser', id: string, vipPoint: any, vipLevel: number, betAmountUsd: any, currentLevelPoint: any, nextLevelPoint: any, vipUserCurrencies: Array<{ __typename?: 'VipUserCurrency', id: string, instantBonusAvailableAmount: any, lastWeekBonusAmount?: any | null, lastMonthBonusAmount?: any | null, currency: { __typename?: 'Currency', id: string, name: string, shortName: string, type: CurrencyType, decimalDigits: number, equivalentUsdRate: any, blockchainNetworks?: Array<BlockchainNetwork> | null } }> } } };

export type UserChatsQueryVariables = Exact<{
  channel: Scalars['String'];
}>;


export type UserChatsQuery = { __typename?: 'Query', chats: Array<{ __typename?: 'ChatMessage', id: any, message: string, createdAt: any, sender: { __typename?: 'ChatUser', userId?: string | null, nickname?: string | null, vipLevel: number, avatar?: { __typename?: 'Photo', uri: string, square: string } | null } }> };

export type SystemNotificationsQueryVariables = Exact<{
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
}>;


export type SystemNotificationsQuery = { __typename?: 'Query', systemNotifications: { __typename?: 'PageableOfNotification', hasMore: boolean, data: Array<{ __typename?: 'Notification', id: string, title: string, content: string, publishedAt: any, actionText?: string | null, actionUrl?: string | null, thumbnail?: { __typename?: 'Photo', uri: string, square: string, small: string, medium: string, large: string } | null }> } };

export type PersonalNotificationsQueryVariables = Exact<{
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
}>;


export type PersonalNotificationsQuery = { __typename?: 'Query', personalNotifications: { __typename?: 'PageableOfNotification', hasMore: boolean, data: Array<{ __typename?: 'Notification', id: string, title: string, content: string, publishedAt: any, actionText?: string | null, actionUrl?: string | null, thumbnail?: { __typename?: 'Photo', uri: string, square: string, small: string, medium: string, large: string } | null }> } };

export type UnreadNotificationQueryVariables = Exact<{ [key: string]: never; }>;


export type UnreadNotificationQuery = { __typename?: 'Query', unreadNotification: { __typename?: 'UnreadNotification', system: number, personal: number } };

export type ReadAllSystemNotificationsMutationVariables = Exact<{
  lastId: Scalars['ID'];
}>;


export type ReadAllSystemNotificationsMutation = { __typename?: 'Mutation', readAllSystemNotifications: { __typename?: 'PayloadOfUnreadNotification', success: boolean, content?: { __typename?: 'UnreadNotification', system: number, personal: number } | null, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null } };

export type ReadAllPersonalNotificationsMutationVariables = Exact<{ [key: string]: never; }>;


export type ReadAllPersonalNotificationsMutation = { __typename?: 'Mutation', readAllPersonalNotifications: { __typename?: 'PayloadOfUnreadNotification', success: boolean, error?: { __typename?: 'GraphError', code?: string | null, message: string } | null, content?: { __typename?: 'UnreadNotification', system: number, personal: number } | null } };

export type MyDefaultReferralCodeQueryVariables = Exact<{ [key: string]: never; }>;


export type MyDefaultReferralCodeQuery = { __typename?: 'Query', me: { __typename?: 'MeType', defaultReferralCode: { __typename?: 'ReferralCode', id: string, url: string } } };

export type MyReferralCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type MyReferralCodesQuery = { __typename?: 'Query', myReferralCodes: Array<{ __typename?: 'ReferralCode', id: string, name: string, code: string, createdAt: any, totalUsers?: number | null, totalDeposits?: number | null, totalDepositUsers?: number | null, url: string, totalCommissions: Array<{ __typename?: 'CommissionValue', totalAmount: any, availableAmount: any, currency: { __typename?: 'Currency', id: string, decimalDigits: number, shortName: string, equivalentUsdRate: any } }> }> };

export type MyReferralUsersQueryVariables = Exact<{
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  nickname?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
  referralCodeId?: InputMaybe<Scalars['ID']>;
}>;


export type MyReferralUsersQuery = { __typename?: 'Query', myReferralUsers?: { __typename?: 'PageableOfReferralUser', hasMore: boolean, data: Array<{ __typename?: 'ReferralUser', id: string, createdAt: any, nickname?: string | null, totalDeposits: number, lastDeposit?: any | null, wagerEquivalentUsd: any, commissions: Array<{ __typename?: 'CommissionValue', totalAmount: any, availableAmount: any, currency: { __typename?: 'Currency', id: string, shortName: string, decimalDigits: number, equivalentUsdRate: any } }>, referralCode: { __typename?: 'ReferralCode', name: string, totalCommissions: Array<{ __typename?: 'CommissionValue', availableAmount: any, totalAmount: any, currency: { __typename?: 'Currency', id: string, shortName: string, decimalDigits: number, equivalentUsdRate: any } }> } }> } | null };

export type MyCommissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyCommissionsQuery = { __typename?: 'Query', myCommissions: Array<{ __typename?: 'CommissionValue', availableAmount: any, totalAmount: any, currency: { __typename?: 'Currency', id: string, shortName: string, decimalDigits: number, equivalentUsdRate: any } }> };

export type MyAgencyInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type MyAgencyInfoQuery = { __typename?: 'Query', myAgency?: { __typename?: 'AgencyInfo', agency?: { __typename?: 'Agency', id: string, status: AgencyStatus, userId: string, nickname?: string | null, maxCommissionRate: any, house?: { __typename?: 'House', id: string, name: string, slug: string } | null } | null } | null };

export type MyAgencyMembersQueryVariables = Exact<{
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  userId?: InputMaybe<Scalars['ID']>;
  nickname?: InputMaybe<Scalars['String']>;
  onlyAgency?: InputMaybe<Scalars['Boolean']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
}>;


export type MyAgencyMembersQuery = { __typename?: 'Query', myAgency?: { __typename?: 'AgencyInfo', children: { __typename?: 'PageableOfAgencyMember', hasMore: boolean, data: Array<{ __typename?: 'AgencyMember', id: string, nickname?: string | null, isAgency: boolean, maxCommissionRate: any, memberCount: number, playerCount: number, wagerEquivalentUsd: any, commissions: Array<{ __typename?: 'CommissionValue', totalAmount: any, availableAmount: any, currency: { __typename?: 'Currency', id: string, shortName: string, equivalentUsdRate: any, decimalDigits: number } }> }> } } | null };

export type InternalTransferSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type InternalTransferSettingsQuery = { __typename?: 'Query', internalTransferSettings: Array<{ __typename?: 'TransferSetting', transferFeeAmount: any, transferFeeRate: any, transferMinimumAmount: any, currency: { __typename?: 'Currency', id: string, name: string, shortName: string, type: CurrencyType, decimalDigits: number, equivalentUsdRate: any, blockchainNetworks?: Array<BlockchainNetwork> | null } }> };

export type InternalTransfersQueryVariables = Exact<{
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  currencyId?: InputMaybe<Scalars['ID']>;
}>;


export type InternalTransfersQuery = { __typename?: 'Query', internalTransfers: { __typename?: 'PageableOfInternalTransfer', hasMore: boolean, data: Array<{ __typename?: 'InternalTransfer', id: string, senderId: string, senderNickname?: string | null, receiverId: string, receiverNickname?: string | null, amount: any, message: string, equivalentUsdRate: any, status: InternalTransferStatus, fee: any, createdAt: any, currency: { __typename?: 'Currency', id: string, name: string, shortName: string, type: CurrencyType, decimalDigits: number, equivalentUsdRate: any, blockchainNetworks?: Array<BlockchainNetwork> | null }, senderAvatar?: { __typename?: 'Photo', uri: string, square: string, small: string, medium: string, large: string } | null, receiverAvatar?: { __typename?: 'Photo', uri: string, square: string, small: string, medium: string, large: string } | null }> } };

export type CurrenciesQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrenciesQuery = { __typename?: 'Query', currencies: Array<{ __typename?: 'Currency', id: string, name: string, shortName: string, type: CurrencyType, decimalDigits: number, equivalentUsdRate: any, blockchainNetworks?: Array<BlockchainNetwork> | null }> };

export type FiatsQueryVariables = Exact<{ [key: string]: never; }>;


export type FiatsQuery = { __typename?: 'Query', transfiSupportedFiats: Array<{ __typename?: 'TransfiFiat', symbol: string, country: string, minAmount: any, maxAmount: any }> };

export type CryptoDepositAddressQueryVariables = Exact<{
  currencyId: Scalars['ID'];
  network: BlockchainNetwork;
}>;


export type CryptoDepositAddressQuery = { __typename?: 'Query', cryptoDepositAddress?: { __typename?: 'CryptoAddress', address: string } | null };

export type CryptoWithdrawalInfoQueryVariables = Exact<{
  currencyId: Scalars['ID'];
  network: BlockchainNetwork;
}>;


export type CryptoWithdrawalInfoQuery = { __typename?: 'Query', cryptoWithdrawalInfo?: { __typename?: 'CryptoWithdrawalInfo', id: string, feeAmount: any, feeRate: any, minimumAmount: any } | null };

export type DepositTransactionsQueryVariables = Exact<{
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  currencyId?: InputMaybe<Scalars['ID']>;
}>;


export type DepositTransactionsQuery = { __typename?: 'Query', depositTransactions: { __typename?: 'PageableOfDepositTransaction', hasMore: boolean, data: Array<{ __typename?: 'DepositTransaction', id: string, amount: any, equivalentUsdRate: any, status: DepositStatus, createdAt: any, tx?: string | null, currency: { __typename?: 'Currency', name: string, shortName: string, decimalDigits: number, equivalentUsdRate: any } }> } };

export type WithdrawalTransactionsQueryVariables = Exact<{
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  currencyId?: InputMaybe<Scalars['ID']>;
}>;


export type WithdrawalTransactionsQuery = { __typename?: 'Query', withdrawalTransactions: { __typename?: 'PageableOfWithdrawalTransaction', hasMore: boolean, data: Array<{ __typename?: 'WithdrawalTransaction', id: string, amount: any, status: WithdrawalStatus, createdAt: any, equivalentUsdRate: any, tx?: string | null, currency: { __typename?: 'Currency', name: string, shortName: string, decimalDigits: number, equivalentUsdRate: any } }> } };

export type TransactionsQueryVariables = Exact<{
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  currencyId?: InputMaybe<Scalars['ID']>;
  group?: InputMaybe<TransactionGroup>;
}>;


export type TransactionsQuery = { __typename?: 'Query', transactions: { __typename?: 'PageableOfTransactionInfo', hasMore: boolean, data: Array<{ __typename?: 'TransactionInfo', id: string, group: TransactionGroup, label: string, amount: any, remainAmount: any, equivalentUsdRate: any, createdAt: any, currency: { __typename?: 'Currency', id: string, name: string, shortName: string, decimalDigits: number, equivalentUsdRate: any, blockchainNetworks?: Array<BlockchainNetwork> | null } }> } };

export type GameBankInvestTransactionsQueryVariables = Exact<{
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  gameBankId: Scalars['ID'];
}>;


export type GameBankInvestTransactionsQuery = { __typename?: 'Query', investTransactions?: { __typename?: 'PageableOfInvestTransaction', hasMore: boolean, data: Array<{ __typename?: 'InvestTransaction', id: string, isHouse: boolean, type: InvestType, amount: any, fee?: any | null, createdAt: any, user?: { __typename?: 'User', id: string, nickname: string, vipLevel: number } | null }> } | null };

export type GameBankSharesQueryVariables = Exact<{
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  gameBankId: Scalars['ID'];
}>;


export type GameBankSharesQuery = { __typename?: 'Query', gameBankInvestors?: { __typename?: 'PageableOfInvestor', hasMore: boolean, data: Array<{ __typename?: 'Investor', id: string, investAmount: any, shareRate: any, isHouse: boolean, createdAt: any, user?: { __typename?: 'User', nickname: string } | null }> } | null };

export type SwapPairsQueryVariables = Exact<{ [key: string]: never; }>;


export type SwapPairsQuery = { __typename?: 'Query', swapPairs: Array<{ __typename?: 'SwapPair', id: string, minimumAmount: any, maximumAmount?: any | null, feeRate?: any | null, feeAmount?: any | null, rate?: any | null, from: { __typename?: 'Currency', id: string, name: string, shortName: string, decimalDigits: number, equivalentUsdRate: any }, to: { __typename?: 'Currency', id: string, name: string, shortName: string, decimalDigits: number, equivalentUsdRate: any } } | null> };

export type RecentBetSubscriptionVariables = Exact<{
  gameId?: InputMaybe<Scalars['ID']>;
}>;


export type RecentBetSubscription = { __typename?: 'Subscription', recentBet: { __typename?: 'BetSummary', id: string, betAmount: any, payoutRate: any, payoutAmount: any, equivalentUsdRate: any, currency: { __typename?: 'Currency', id: string, shortName: string, decimalDigits: number, equivalentUsdRate: any }, game: { __typename?: 'Game', id: string, name: string }, user?: { __typename?: 'User', id: string, nickname: string, avatar?: { __typename?: 'Photo', square: string } | null } | null } };

export type HighBetSubscriptionVariables = Exact<{
  gameId?: InputMaybe<Scalars['ID']>;
}>;


export type HighBetSubscription = { __typename?: 'Subscription', highBet: { __typename?: 'BetSummary', id: string, equivalentUsdRate: any, betAmount: any, payoutRate: any, payoutAmount: any, game: { __typename?: 'Game', id: string, name: string, gameBase: { __typename?: 'GameBase', thumbnail?: { __typename?: 'Photo', square: string } | null } }, currency: { __typename?: 'Currency', id: string, shortName: string, decimalDigits: number, equivalentUsdRate: any }, user?: { __typename?: 'User', id: string, nickname: string, avatar?: { __typename?: 'Photo', square: string } | null } | null } };

export type ChatSubscriptionVariables = Exact<{
  channel: Scalars['String'];
}>;


export type ChatSubscription = { __typename?: 'Subscription', chat: { __typename?: 'ChatMessage', id: any, message: string, createdAt: any, sender: { __typename?: 'ChatUser', userId?: string | null, nickname?: string | null, vipLevel: number, avatar?: { __typename?: 'Photo', uri: string, square: string } | null } } };

export type WalletChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type WalletChangedSubscription = { __typename?: 'Subscription', userWalletChanged: { __typename?: 'UserWallet', id: string, currencyId: string, amount: any, lockedAmount?: any | null, currency: { __typename?: 'Currency', id: string, shortName: string, decimalDigits: number } } };

export type LiveStatsDataQueryVariables = Exact<{ [key: string]: never; }>;


export type LiveStatsDataQuery = { __typename?: 'Query', liveStats?: { __typename?: 'LiveStatsData', profits?: Array<any | null> | null, totalBetAmount?: any | null, totalProfit?: any | null, loseRounds?: number | null, winRounds?: number | null } | null };

export type ViewInFiatQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewInFiatQuery = { __typename?: 'Query', viewInFiat?: { __typename?: 'ViewInFiat', status?: boolean | null, fiat?: { __typename?: 'Fiat', name?: string | null, rate?: any | null } | null } | null };

export type StorageCurrencyQueryVariables = Exact<{ [key: string]: never; }>;


export type StorageCurrencyQuery = { __typename?: 'Query', storageCurrency?: { __typename?: 'Currency', id: string, shortName: string, decimalDigits: number, equivalentUsdRate: any } | null };

export type HideSmallWalletAmountQueryVariables = Exact<{ [key: string]: never; }>;


export type HideSmallWalletAmountQuery = { __typename?: 'Query', hideSmallWalletAmount?: boolean | null };

export const NewMyBetFragmentDoc = gql`
    fragment NewMyBet on Bet {
  id
  betAmount
  payoutRate
  payoutAmount
  equivalentUsdRate
  currency {
    id
    shortName
    equivalentUsdRate
    decimalDigits
  }
}
    `;
export const GamesFragmentDoc = gql`
    fragment Games on PageableOfHouseGame {
  hasMore
  data {
    id
    game {
      id
      name
      slug
      gameBase {
        id
        type
        isMiniGame
        thumbnail {
          square
          large
          medium
        }
        tags
        rtp
        maxWinRate
        producer {
          id
          name
          gameCount
          branch
        }
      }
    }
  }
}
    `;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(input: {email: $email, password: $password}) {
    success
    error {
      code
      message
    }
    content {
      accessToken
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LoginGoogleDocument = gql`
    mutation LoginGoogle($token: String!, $referralCode: String) {
  loginGoogle(token: $token, referralCode: $referralCode) {
    success
    error {
      code
      message
    }
    content {
      accessToken
    }
  }
}
    `;
export type LoginGoogleMutationFn = Apollo.MutationFunction<LoginGoogleMutation, LoginGoogleMutationVariables>;

/**
 * __useLoginGoogleMutation__
 *
 * To run a mutation, you first call `useLoginGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginGoogleMutation, { data, loading, error }] = useLoginGoogleMutation({
 *   variables: {
 *      token: // value for 'token'
 *      referralCode: // value for 'referralCode'
 *   },
 * });
 */
export function useLoginGoogleMutation(baseOptions?: Apollo.MutationHookOptions<LoginGoogleMutation, LoginGoogleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginGoogleMutation, LoginGoogleMutationVariables>(LoginGoogleDocument, options);
      }
export type LoginGoogleMutationHookResult = ReturnType<typeof useLoginGoogleMutation>;
export type LoginGoogleMutationResult = Apollo.MutationResult<LoginGoogleMutation>;
export type LoginGoogleMutationOptions = Apollo.BaseMutationOptions<LoginGoogleMutation, LoginGoogleMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($email: EmailAddress!, $password: String!, $referralCode: String) {
  register(
    input: {email: $email, password: $password, referralCode: $referralCode}
  ) {
    success
    error {
      code
      message
    }
    content {
      accessToken
    }
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      referralCode: // value for 'referralCode'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
  changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
    success
    error {
      code
      message
    }
    content {
      id
      hasPassword
      email
      hidden
    }
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const RequestRecoverPasswordDocument = gql`
    mutation RequestRecoverPassword($email: EmailAddress!) {
  requestRecoverPassword(email: $email) {
    success
    error {
      code
      message
    }
    content
  }
}
    `;
export type RequestRecoverPasswordMutationFn = Apollo.MutationFunction<RequestRecoverPasswordMutation, RequestRecoverPasswordMutationVariables>;

/**
 * __useRequestRecoverPasswordMutation__
 *
 * To run a mutation, you first call `useRequestRecoverPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestRecoverPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestRecoverPasswordMutation, { data, loading, error }] = useRequestRecoverPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestRecoverPasswordMutation(baseOptions?: Apollo.MutationHookOptions<RequestRecoverPasswordMutation, RequestRecoverPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestRecoverPasswordMutation, RequestRecoverPasswordMutationVariables>(RequestRecoverPasswordDocument, options);
      }
export type RequestRecoverPasswordMutationHookResult = ReturnType<typeof useRequestRecoverPasswordMutation>;
export type RequestRecoverPasswordMutationResult = Apollo.MutationResult<RequestRecoverPasswordMutation>;
export type RequestRecoverPasswordMutationOptions = Apollo.BaseMutationOptions<RequestRecoverPasswordMutation, RequestRecoverPasswordMutationVariables>;
export const RecoverPasswordDocument = gql`
    mutation RecoverPassword($token: String!, $newPassword: String!) {
  recoverPassword(token: $token, newPassword: $newPassword) {
    success
    error {
      code
      message
    }
    content
  }
}
    `;
export type RecoverPasswordMutationFn = Apollo.MutationFunction<RecoverPasswordMutation, RecoverPasswordMutationVariables>;

/**
 * __useRecoverPasswordMutation__
 *
 * To run a mutation, you first call `useRecoverPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverPasswordMutation, { data, loading, error }] = useRecoverPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useRecoverPasswordMutation(baseOptions?: Apollo.MutationHookOptions<RecoverPasswordMutation, RecoverPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecoverPasswordMutation, RecoverPasswordMutationVariables>(RecoverPasswordDocument, options);
      }
export type RecoverPasswordMutationHookResult = ReturnType<typeof useRecoverPasswordMutation>;
export type RecoverPasswordMutationResult = Apollo.MutationResult<RecoverPasswordMutation>;
export type RecoverPasswordMutationOptions = Apollo.BaseMutationOptions<RecoverPasswordMutation, RecoverPasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    success
    content
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RequestExGameDemoDocument = gql`
    mutation RequestExGameDemo($slug: String!, $returnUrl: String!, $locale: String, $isMobile: Boolean) {
  requestExGameDemo(
    slug: $slug
    returnUrl: $returnUrl
    locale: $locale
    isMobile: $isMobile
  ) {
    success
    error {
      code
      message
    }
    content {
      gameUrl
      strategy
    }
  }
}
    `;
export type RequestExGameDemoMutationFn = Apollo.MutationFunction<RequestExGameDemoMutation, RequestExGameDemoMutationVariables>;

/**
 * __useRequestExGameDemoMutation__
 *
 * To run a mutation, you first call `useRequestExGameDemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestExGameDemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestExGameDemoMutation, { data, loading, error }] = useRequestExGameDemoMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      returnUrl: // value for 'returnUrl'
 *      locale: // value for 'locale'
 *      isMobile: // value for 'isMobile'
 *   },
 * });
 */
export function useRequestExGameDemoMutation(baseOptions?: Apollo.MutationHookOptions<RequestExGameDemoMutation, RequestExGameDemoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestExGameDemoMutation, RequestExGameDemoMutationVariables>(RequestExGameDemoDocument, options);
      }
export type RequestExGameDemoMutationHookResult = ReturnType<typeof useRequestExGameDemoMutation>;
export type RequestExGameDemoMutationResult = Apollo.MutationResult<RequestExGameDemoMutation>;
export type RequestExGameDemoMutationOptions = Apollo.BaseMutationOptions<RequestExGameDemoMutation, RequestExGameDemoMutationVariables>;
export const RequestExGamePlayDocument = gql`
    mutation RequestExGamePlay($currencyId: ID!, $slug: String!, $returnUrl: String!, $locale: String, $isMobile: Boolean) {
  requestExGamePlay(
    currencyId: $currencyId
    slug: $slug
    returnUrl: $returnUrl
    locale: $locale
    isMobile: $isMobile
  ) {
    success
    error {
      code
      message
    }
    content {
      gameUrl
      strategy
    }
  }
}
    `;
export type RequestExGamePlayMutationFn = Apollo.MutationFunction<RequestExGamePlayMutation, RequestExGamePlayMutationVariables>;

/**
 * __useRequestExGamePlayMutation__
 *
 * To run a mutation, you first call `useRequestExGamePlayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestExGamePlayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestExGamePlayMutation, { data, loading, error }] = useRequestExGamePlayMutation({
 *   variables: {
 *      currencyId: // value for 'currencyId'
 *      slug: // value for 'slug'
 *      returnUrl: // value for 'returnUrl'
 *      locale: // value for 'locale'
 *      isMobile: // value for 'isMobile'
 *   },
 * });
 */
export function useRequestExGamePlayMutation(baseOptions?: Apollo.MutationHookOptions<RequestExGamePlayMutation, RequestExGamePlayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestExGamePlayMutation, RequestExGamePlayMutationVariables>(RequestExGamePlayDocument, options);
      }
export type RequestExGamePlayMutationHookResult = ReturnType<typeof useRequestExGamePlayMutation>;
export type RequestExGamePlayMutationResult = Apollo.MutationResult<RequestExGamePlayMutation>;
export type RequestExGamePlayMutationOptions = Apollo.BaseMutationOptions<RequestExGamePlayMutation, RequestExGamePlayMutationVariables>;
export const FavoriteDocument = gql`
    mutation Favorite($gameId: ID!, $value: Boolean!) {
  favorite(gameId: $gameId, value: $value) {
    favorites
    favored
  }
}
    `;
export type FavoriteMutationFn = Apollo.MutationFunction<FavoriteMutation, FavoriteMutationVariables>;

/**
 * __useFavoriteMutation__
 *
 * To run a mutation, you first call `useFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [favoriteMutation, { data, loading, error }] = useFavoriteMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<FavoriteMutation, FavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FavoriteMutation, FavoriteMutationVariables>(FavoriteDocument, options);
      }
export type FavoriteMutationHookResult = ReturnType<typeof useFavoriteMutation>;
export type FavoriteMutationResult = Apollo.MutationResult<FavoriteMutation>;
export type FavoriteMutationOptions = Apollo.BaseMutationOptions<FavoriteMutation, FavoriteMutationVariables>;
export const LikeDocument = gql`
    mutation Like($gameId: ID!, $value: Boolean!) {
  like(gameId: $gameId, value: $value) {
    likes
    liked
  }
}
    `;
export type LikeMutationFn = Apollo.MutationFunction<LikeMutation, LikeMutationVariables>;

/**
 * __useLikeMutation__
 *
 * To run a mutation, you first call `useLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeMutation, { data, loading, error }] = useLikeMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useLikeMutation(baseOptions?: Apollo.MutationHookOptions<LikeMutation, LikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LikeMutation, LikeMutationVariables>(LikeDocument, options);
      }
export type LikeMutationHookResult = ReturnType<typeof useLikeMutation>;
export type LikeMutationResult = Apollo.MutationResult<LikeMutation>;
export type LikeMutationOptions = Apollo.BaseMutationOptions<LikeMutation, LikeMutationVariables>;
export const MiniPokerBetDocument = gql`
    mutation MiniPokerBet($betRoomId: ID!) {
  miniPokerBet(betRoomId: $betRoomId) {
    result
    bet {
      id
      betAmount
      betChargeAmount
      payoutRate
      payoutAmount
      betData {
        ... on MiniPokerBetData {
          winJackpotTotalAmount
          miniPokerResult {
            type
            rate
            cards {
              rank
              suit
            }
          }
        }
      }
    }
  }
}
    `;
export type MiniPokerBetMutationFn = Apollo.MutationFunction<MiniPokerBetMutation, MiniPokerBetMutationVariables>;

/**
 * __useMiniPokerBetMutation__
 *
 * To run a mutation, you first call `useMiniPokerBetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMiniPokerBetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [miniPokerBetMutation, { data, loading, error }] = useMiniPokerBetMutation({
 *   variables: {
 *      betRoomId: // value for 'betRoomId'
 *   },
 * });
 */
export function useMiniPokerBetMutation(baseOptions?: Apollo.MutationHookOptions<MiniPokerBetMutation, MiniPokerBetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MiniPokerBetMutation, MiniPokerBetMutationVariables>(MiniPokerBetDocument, options);
      }
export type MiniPokerBetMutationHookResult = ReturnType<typeof useMiniPokerBetMutation>;
export type MiniPokerBetMutationResult = Apollo.MutationResult<MiniPokerBetMutation>;
export type MiniPokerBetMutationOptions = Apollo.BaseMutationOptions<MiniPokerBetMutation, MiniPokerBetMutationVariables>;
export const DiceBetDocument = gql`
    mutation DiceBet($betRoomId: ID!, $amount: Decimal!, $isRollOver: Boolean!, $value: Decimal!) {
  diceBet(
    betRoomId: $betRoomId
    amount: $amount
    isRollOver: $isRollOver
    value: $value
  ) {
    bet {
      id
      betAmount
      betChargeAmount
      payoutRate
      payoutAmount
      equivalentUsdRate
      currency {
        id
        shortName
        equivalentUsdRate
        decimalDigits
      }
      betData {
        ... on DiceBetData {
          isRollOver
          result
          betValue
          winChance
        }
      }
    }
    result
  }
}
    `;
export type DiceBetMutationFn = Apollo.MutationFunction<DiceBetMutation, DiceBetMutationVariables>;

/**
 * __useDiceBetMutation__
 *
 * To run a mutation, you first call `useDiceBetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiceBetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [diceBetMutation, { data, loading, error }] = useDiceBetMutation({
 *   variables: {
 *      betRoomId: // value for 'betRoomId'
 *      amount: // value for 'amount'
 *      isRollOver: // value for 'isRollOver'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useDiceBetMutation(baseOptions?: Apollo.MutationHookOptions<DiceBetMutation, DiceBetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DiceBetMutation, DiceBetMutationVariables>(DiceBetDocument, options);
      }
export type DiceBetMutationHookResult = ReturnType<typeof useDiceBetMutation>;
export type DiceBetMutationResult = Apollo.MutationResult<DiceBetMutation>;
export type DiceBetMutationOptions = Apollo.BaseMutationOptions<DiceBetMutation, DiceBetMutationVariables>;
export const MinesBetDocument = gql`
    mutation MinesBet($betRoomId: ID!, $amount: Decimal!, $mines: Int!, $cells: [Int!]) {
  minesBet(betRoomId: $betRoomId, amount: $amount, mines: $mines, cells: $cells) {
    result
    bet {
      id
      betAmount
      betChargeAmount
      payoutRate
      payoutAmount
      equivalentUsdRate
      currency {
        id
        shortName
        equivalentUsdRate
        decimalDigits
      }
      betData {
        ... on MinesBetData {
          minesCount
          currentMultiplier
          nextMultiplier
          minesCells
          openedCells
        }
      }
    }
  }
}
    `;
export type MinesBetMutationFn = Apollo.MutationFunction<MinesBetMutation, MinesBetMutationVariables>;

/**
 * __useMinesBetMutation__
 *
 * To run a mutation, you first call `useMinesBetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMinesBetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [minesBetMutation, { data, loading, error }] = useMinesBetMutation({
 *   variables: {
 *      betRoomId: // value for 'betRoomId'
 *      amount: // value for 'amount'
 *      mines: // value for 'mines'
 *      cells: // value for 'cells'
 *   },
 * });
 */
export function useMinesBetMutation(baseOptions?: Apollo.MutationHookOptions<MinesBetMutation, MinesBetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MinesBetMutation, MinesBetMutationVariables>(MinesBetDocument, options);
      }
export type MinesBetMutationHookResult = ReturnType<typeof useMinesBetMutation>;
export type MinesBetMutationResult = Apollo.MutationResult<MinesBetMutation>;
export type MinesBetMutationOptions = Apollo.BaseMutationOptions<MinesBetMutation, MinesBetMutationVariables>;
export const MinesOpenDocument = gql`
    mutation MinesOpen($betRoomId: ID!, $cellIdx: Int!) {
  minesOpen(betRoomId: $betRoomId, cellIdx: $cellIdx) {
    result
    bet {
      id
      betAmount
      betChargeAmount
      payoutRate
      payoutAmount
      equivalentUsdRate
      currency {
        id
        shortName
        equivalentUsdRate
        decimalDigits
      }
      betData {
        ... on MinesBetData {
          minesCount
          currentMultiplier
          nextMultiplier
          minesCells
          openedCells
        }
      }
    }
  }
}
    `;
export type MinesOpenMutationFn = Apollo.MutationFunction<MinesOpenMutation, MinesOpenMutationVariables>;

/**
 * __useMinesOpenMutation__
 *
 * To run a mutation, you first call `useMinesOpenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMinesOpenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [minesOpenMutation, { data, loading, error }] = useMinesOpenMutation({
 *   variables: {
 *      betRoomId: // value for 'betRoomId'
 *      cellIdx: // value for 'cellIdx'
 *   },
 * });
 */
export function useMinesOpenMutation(baseOptions?: Apollo.MutationHookOptions<MinesOpenMutation, MinesOpenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MinesOpenMutation, MinesOpenMutationVariables>(MinesOpenDocument, options);
      }
export type MinesOpenMutationHookResult = ReturnType<typeof useMinesOpenMutation>;
export type MinesOpenMutationResult = Apollo.MutationResult<MinesOpenMutation>;
export type MinesOpenMutationOptions = Apollo.BaseMutationOptions<MinesOpenMutation, MinesOpenMutationVariables>;
export const MinesCashOutDocument = gql`
    mutation MinesCashOut($betRoomId: ID!) {
  minesCashout(betRoomId: $betRoomId) {
    result
    bet {
      id
      betAmount
      betChargeAmount
      payoutRate
      payoutAmount
      equivalentUsdRate
      currency {
        id
        shortName
        equivalentUsdRate
        decimalDigits
      }
      betData {
        ... on MinesBetData {
          minesCount
          currentMultiplier
          nextMultiplier
          minesCells
          openedCells
        }
      }
    }
  }
}
    `;
export type MinesCashOutMutationFn = Apollo.MutationFunction<MinesCashOutMutation, MinesCashOutMutationVariables>;

/**
 * __useMinesCashOutMutation__
 *
 * To run a mutation, you first call `useMinesCashOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMinesCashOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [minesCashOutMutation, { data, loading, error }] = useMinesCashOutMutation({
 *   variables: {
 *      betRoomId: // value for 'betRoomId'
 *   },
 * });
 */
export function useMinesCashOutMutation(baseOptions?: Apollo.MutationHookOptions<MinesCashOutMutation, MinesCashOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MinesCashOutMutation, MinesCashOutMutationVariables>(MinesCashOutDocument, options);
      }
export type MinesCashOutMutationHookResult = ReturnType<typeof useMinesCashOutMutation>;
export type MinesCashOutMutationResult = Apollo.MutationResult<MinesCashOutMutation>;
export type MinesCashOutMutationOptions = Apollo.BaseMutationOptions<MinesCashOutMutation, MinesCashOutMutationVariables>;
export const LimboBetDocument = gql`
    mutation LimboBet($betRoomId: ID!, $amount: Decimal!, $multiplier: Decimal!) {
  limboBet(betRoomId: $betRoomId, amount: $amount, multiplier: $multiplier) {
    result
    bet {
      id
      betAmount
      betChargeAmount
      payoutRate
      payoutAmount
      equivalentUsdRate
      betData {
        ... on LimboBetData {
          betMultiplier
          result
        }
      }
      currency {
        id
        shortName
        equivalentUsdRate
        decimalDigits
      }
    }
  }
}
    `;
export type LimboBetMutationFn = Apollo.MutationFunction<LimboBetMutation, LimboBetMutationVariables>;

/**
 * __useLimboBetMutation__
 *
 * To run a mutation, you first call `useLimboBetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLimboBetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [limboBetMutation, { data, loading, error }] = useLimboBetMutation({
 *   variables: {
 *      betRoomId: // value for 'betRoomId'
 *      amount: // value for 'amount'
 *      multiplier: // value for 'multiplier'
 *   },
 * });
 */
export function useLimboBetMutation(baseOptions?: Apollo.MutationHookOptions<LimboBetMutation, LimboBetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LimboBetMutation, LimboBetMutationVariables>(LimboBetDocument, options);
      }
export type LimboBetMutationHookResult = ReturnType<typeof useLimboBetMutation>;
export type LimboBetMutationResult = Apollo.MutationResult<LimboBetMutation>;
export type LimboBetMutationOptions = Apollo.BaseMutationOptions<LimboBetMutation, LimboBetMutationVariables>;
export const MiniCryptoBetDocument = gql`
    mutation MiniCryptoBet($betRoomId: ID!) {
  miniCryptoBet(betRoomId: $betRoomId) {
    result
    bet {
      id
      betAmount
      betChargeAmount
      payoutRate
      payoutAmount
      equivalentUsdRate
      createdAt
      betData {
        ... on MiniCryptoBetData {
          reels
          winLines {
            winRate
            rows
          }
          winJackpotTotalAmount
        }
      }
    }
    jackpotAmount
  }
}
    `;
export type MiniCryptoBetMutationFn = Apollo.MutationFunction<MiniCryptoBetMutation, MiniCryptoBetMutationVariables>;

/**
 * __useMiniCryptoBetMutation__
 *
 * To run a mutation, you first call `useMiniCryptoBetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMiniCryptoBetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [miniCryptoBetMutation, { data, loading, error }] = useMiniCryptoBetMutation({
 *   variables: {
 *      betRoomId: // value for 'betRoomId'
 *   },
 * });
 */
export function useMiniCryptoBetMutation(baseOptions?: Apollo.MutationHookOptions<MiniCryptoBetMutation, MiniCryptoBetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MiniCryptoBetMutation, MiniCryptoBetMutationVariables>(MiniCryptoBetDocument, options);
      }
export type MiniCryptoBetMutationHookResult = ReturnType<typeof useMiniCryptoBetMutation>;
export type MiniCryptoBetMutationResult = Apollo.MutationResult<MiniCryptoBetMutation>;
export type MiniCryptoBetMutationOptions = Apollo.BaseMutationOptions<MiniCryptoBetMutation, MiniCryptoBetMutationVariables>;
export const CoinFlipBetDocument = gql`
    mutation CoinFlipBet($betRoomId: ID!, $amount: Decimal!, $turns: [Boolean!]) {
  coinFlipBet(betRoomId: $betRoomId, amount: $amount, turns: $turns) {
    result
    bet {
      id
      betAmount
      payoutRate
      payoutAmount
      betChargeAmount
      equivalentUsdRate
      betData {
        ... on CoinFlipBetData {
          currentMultiplier
          nextMultiplier
          flippedRounds
        }
      }
      currency {
        id
        shortName
        equivalentUsdRate
        decimalDigits
      }
    }
  }
}
    `;
export type CoinFlipBetMutationFn = Apollo.MutationFunction<CoinFlipBetMutation, CoinFlipBetMutationVariables>;

/**
 * __useCoinFlipBetMutation__
 *
 * To run a mutation, you first call `useCoinFlipBetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoinFlipBetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coinFlipBetMutation, { data, loading, error }] = useCoinFlipBetMutation({
 *   variables: {
 *      betRoomId: // value for 'betRoomId'
 *      amount: // value for 'amount'
 *      turns: // value for 'turns'
 *   },
 * });
 */
export function useCoinFlipBetMutation(baseOptions?: Apollo.MutationHookOptions<CoinFlipBetMutation, CoinFlipBetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CoinFlipBetMutation, CoinFlipBetMutationVariables>(CoinFlipBetDocument, options);
      }
export type CoinFlipBetMutationHookResult = ReturnType<typeof useCoinFlipBetMutation>;
export type CoinFlipBetMutationResult = Apollo.MutationResult<CoinFlipBetMutation>;
export type CoinFlipBetMutationOptions = Apollo.BaseMutationOptions<CoinFlipBetMutation, CoinFlipBetMutationVariables>;
export const CoinFlipOpenDocument = gql`
    mutation CoinFlipOpen($betRoomId: ID!, $isHead: Boolean!) {
  coinFlipOpen(betRoomId: $betRoomId, isHead: $isHead) {
    result
    bet {
      id
      betAmount
      payoutRate
      payoutAmount
      betChargeAmount
      equivalentUsdRate
      betData {
        ... on CoinFlipBetData {
          currentMultiplier
          nextMultiplier
          flippedRounds
        }
      }
      currency {
        id
        shortName
        equivalentUsdRate
        decimalDigits
      }
    }
  }
}
    `;
export type CoinFlipOpenMutationFn = Apollo.MutationFunction<CoinFlipOpenMutation, CoinFlipOpenMutationVariables>;

/**
 * __useCoinFlipOpenMutation__
 *
 * To run a mutation, you first call `useCoinFlipOpenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoinFlipOpenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coinFlipOpenMutation, { data, loading, error }] = useCoinFlipOpenMutation({
 *   variables: {
 *      betRoomId: // value for 'betRoomId'
 *      isHead: // value for 'isHead'
 *   },
 * });
 */
export function useCoinFlipOpenMutation(baseOptions?: Apollo.MutationHookOptions<CoinFlipOpenMutation, CoinFlipOpenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CoinFlipOpenMutation, CoinFlipOpenMutationVariables>(CoinFlipOpenDocument, options);
      }
export type CoinFlipOpenMutationHookResult = ReturnType<typeof useCoinFlipOpenMutation>;
export type CoinFlipOpenMutationResult = Apollo.MutationResult<CoinFlipOpenMutation>;
export type CoinFlipOpenMutationOptions = Apollo.BaseMutationOptions<CoinFlipOpenMutation, CoinFlipOpenMutationVariables>;
export const CoinFlipCashoutDocument = gql`
    mutation CoinFlipCashout($betRoomId: ID!) {
  coinFlipCashout(betRoomId: $betRoomId) {
    result
    bet {
      id
      betAmount
      payoutRate
      payoutAmount
      betChargeAmount
      equivalentUsdRate
      betData {
        ... on CoinFlipBetData {
          currentMultiplier
          nextMultiplier
          flippedRounds
        }
      }
      currency {
        id
        shortName
        equivalentUsdRate
        decimalDigits
      }
    }
  }
}
    `;
export type CoinFlipCashoutMutationFn = Apollo.MutationFunction<CoinFlipCashoutMutation, CoinFlipCashoutMutationVariables>;

/**
 * __useCoinFlipCashoutMutation__
 *
 * To run a mutation, you first call `useCoinFlipCashoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoinFlipCashoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coinFlipCashoutMutation, { data, loading, error }] = useCoinFlipCashoutMutation({
 *   variables: {
 *      betRoomId: // value for 'betRoomId'
 *   },
 * });
 */
export function useCoinFlipCashoutMutation(baseOptions?: Apollo.MutationHookOptions<CoinFlipCashoutMutation, CoinFlipCashoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CoinFlipCashoutMutation, CoinFlipCashoutMutationVariables>(CoinFlipCashoutDocument, options);
      }
export type CoinFlipCashoutMutationHookResult = ReturnType<typeof useCoinFlipCashoutMutation>;
export type CoinFlipCashoutMutationResult = Apollo.MutationResult<CoinFlipCashoutMutation>;
export type CoinFlipCashoutMutationOptions = Apollo.BaseMutationOptions<CoinFlipCashoutMutation, CoinFlipCashoutMutationVariables>;
export const UpdateNickNameDocument = gql`
    mutation UpdateNickName($nickname: String!) {
  updateNickname(nickname: $nickname) {
    success
    error {
      code
      message
    }
    content {
      id
      hasPassword
      email
      hidden
    }
  }
}
    `;
export type UpdateNickNameMutationFn = Apollo.MutationFunction<UpdateNickNameMutation, UpdateNickNameMutationVariables>;

/**
 * __useUpdateNickNameMutation__
 *
 * To run a mutation, you first call `useUpdateNickNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNickNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNickNameMutation, { data, loading, error }] = useUpdateNickNameMutation({
 *   variables: {
 *      nickname: // value for 'nickname'
 *   },
 * });
 */
export function useUpdateNickNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNickNameMutation, UpdateNickNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNickNameMutation, UpdateNickNameMutationVariables>(UpdateNickNameDocument, options);
      }
export type UpdateNickNameMutationHookResult = ReturnType<typeof useUpdateNickNameMutation>;
export type UpdateNickNameMutationResult = Apollo.MutationResult<UpdateNickNameMutation>;
export type UpdateNickNameMutationOptions = Apollo.BaseMutationOptions<UpdateNickNameMutation, UpdateNickNameMutationVariables>;
export const SetHiddenProfileDocument = gql`
    mutation SetHiddenProfile($isHidden: Boolean!) {
  setHidden(value: $isHidden) {
    success
    error {
      code
      message
    }
    content {
      id
      hasPassword
      email
      hidden
    }
  }
}
    `;
export type SetHiddenProfileMutationFn = Apollo.MutationFunction<SetHiddenProfileMutation, SetHiddenProfileMutationVariables>;

/**
 * __useSetHiddenProfileMutation__
 *
 * To run a mutation, you first call `useSetHiddenProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetHiddenProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setHiddenProfileMutation, { data, loading, error }] = useSetHiddenProfileMutation({
 *   variables: {
 *      isHidden: // value for 'isHidden'
 *   },
 * });
 */
export function useSetHiddenProfileMutation(baseOptions?: Apollo.MutationHookOptions<SetHiddenProfileMutation, SetHiddenProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetHiddenProfileMutation, SetHiddenProfileMutationVariables>(SetHiddenProfileDocument, options);
      }
export type SetHiddenProfileMutationHookResult = ReturnType<typeof useSetHiddenProfileMutation>;
export type SetHiddenProfileMutationResult = Apollo.MutationResult<SetHiddenProfileMutation>;
export type SetHiddenProfileMutationOptions = Apollo.BaseMutationOptions<SetHiddenProfileMutation, SetHiddenProfileMutationVariables>;
export const UpdateEmailDocument = gql`
    mutation UpdateEmail($email: EmailAddress!) {
  updateEmail(email: $email) {
    success
    error {
      code
      message
    }
    content {
      id
      hasPassword
      email
      hidden
    }
  }
}
    `;
export type UpdateEmailMutationFn = Apollo.MutationFunction<UpdateEmailMutation, UpdateEmailMutationVariables>;

/**
 * __useUpdateEmailMutation__
 *
 * To run a mutation, you first call `useUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailMutation, { data, loading, error }] = useUpdateEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmailMutation, UpdateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmailMutation, UpdateEmailMutationVariables>(UpdateEmailDocument, options);
      }
export type UpdateEmailMutationHookResult = ReturnType<typeof useUpdateEmailMutation>;
export type UpdateEmailMutationResult = Apollo.MutationResult<UpdateEmailMutation>;
export type UpdateEmailMutationOptions = Apollo.BaseMutationOptions<UpdateEmailMutation, UpdateEmailMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation VerifyEmail($token: String!) {
  verifyEmail(token: $token) {
    success
    error {
      code
      message
    }
  }
}
    `;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const RemoveActiveDeviceDocument = gql`
    mutation RemoveActiveDevice($deviceId: ID!) {
  removeActiveDevice(deviceId: $deviceId) {
    success
    error {
      code
      message
    }
  }
}
    `;
export type RemoveActiveDeviceMutationFn = Apollo.MutationFunction<RemoveActiveDeviceMutation, RemoveActiveDeviceMutationVariables>;

/**
 * __useRemoveActiveDeviceMutation__
 *
 * To run a mutation, you first call `useRemoveActiveDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveActiveDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeActiveDeviceMutation, { data, loading, error }] = useRemoveActiveDeviceMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useRemoveActiveDeviceMutation(baseOptions?: Apollo.MutationHookOptions<RemoveActiveDeviceMutation, RemoveActiveDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveActiveDeviceMutation, RemoveActiveDeviceMutationVariables>(RemoveActiveDeviceDocument, options);
      }
export type RemoveActiveDeviceMutationHookResult = ReturnType<typeof useRemoveActiveDeviceMutation>;
export type RemoveActiveDeviceMutationResult = Apollo.MutationResult<RemoveActiveDeviceMutation>;
export type RemoveActiveDeviceMutationOptions = Apollo.BaseMutationOptions<RemoveActiveDeviceMutation, RemoveActiveDeviceMutationVariables>;
export const UpdateIdentificationDocument = gql`
    mutation UpdateIdentification($input: IdentificationInput!) {
  updateIdentification(input: $input) {
    success
    error {
      code
      message
    }
    content {
      status
      userId
      identification {
        id
        firstName
        lastName
        gender
        dateOfBirth
        address
        city
        postalCode
        countryCode
      }
    }
  }
}
    `;
export type UpdateIdentificationMutationFn = Apollo.MutationFunction<UpdateIdentificationMutation, UpdateIdentificationMutationVariables>;

/**
 * __useUpdateIdentificationMutation__
 *
 * To run a mutation, you first call `useUpdateIdentificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIdentificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIdentificationMutation, { data, loading, error }] = useUpdateIdentificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIdentificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIdentificationMutation, UpdateIdentificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIdentificationMutation, UpdateIdentificationMutationVariables>(UpdateIdentificationDocument, options);
      }
export type UpdateIdentificationMutationHookResult = ReturnType<typeof useUpdateIdentificationMutation>;
export type UpdateIdentificationMutationResult = Apollo.MutationResult<UpdateIdentificationMutation>;
export type UpdateIdentificationMutationOptions = Apollo.BaseMutationOptions<UpdateIdentificationMutation, UpdateIdentificationMutationVariables>;
export const UploadIdentificationPhotosDocument = gql`
    mutation UploadIdentificationPhotos($idFront: Upload, $idBack: Upload, $passport: Upload, $selfie: Upload!) {
  uploadIdentificationPhotos(
    idFront: $idFront
    idBack: $idBack
    passport: $passport
    selfie: $selfie
  ) {
    success
    error {
      code
      message
    }
  }
}
    `;
export type UploadIdentificationPhotosMutationFn = Apollo.MutationFunction<UploadIdentificationPhotosMutation, UploadIdentificationPhotosMutationVariables>;

/**
 * __useUploadIdentificationPhotosMutation__
 *
 * To run a mutation, you first call `useUploadIdentificationPhotosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadIdentificationPhotosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadIdentificationPhotosMutation, { data, loading, error }] = useUploadIdentificationPhotosMutation({
 *   variables: {
 *      idFront: // value for 'idFront'
 *      idBack: // value for 'idBack'
 *      passport: // value for 'passport'
 *      selfie: // value for 'selfie'
 *   },
 * });
 */
export function useUploadIdentificationPhotosMutation(baseOptions?: Apollo.MutationHookOptions<UploadIdentificationPhotosMutation, UploadIdentificationPhotosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadIdentificationPhotosMutation, UploadIdentificationPhotosMutationVariables>(UploadIdentificationPhotosDocument, options);
      }
export type UploadIdentificationPhotosMutationHookResult = ReturnType<typeof useUploadIdentificationPhotosMutation>;
export type UploadIdentificationPhotosMutationResult = Apollo.MutationResult<UploadIdentificationPhotosMutation>;
export type UploadIdentificationPhotosMutationOptions = Apollo.BaseMutationOptions<UploadIdentificationPhotosMutation, UploadIdentificationPhotosMutationVariables>;
export const UpdateAvatarDocument = gql`
    mutation UpdateAvatar($file: Upload!) {
  updateAvatar(file: $file) {
    success
    error {
      code
      message
    }
    content {
      id
      avatar {
        id @client
        uri
        square
        small
        medium
        large
      }
      nickname
    }
  }
}
    `;
export type UpdateAvatarMutationFn = Apollo.MutationFunction<UpdateAvatarMutation, UpdateAvatarMutationVariables>;

/**
 * __useUpdateAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAvatarMutation, { data, loading, error }] = useUpdateAvatarMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdateAvatarMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAvatarMutation, UpdateAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAvatarMutation, UpdateAvatarMutationVariables>(UpdateAvatarDocument, options);
      }
export type UpdateAvatarMutationHookResult = ReturnType<typeof useUpdateAvatarMutation>;
export type UpdateAvatarMutationResult = Apollo.MutationResult<UpdateAvatarMutation>;
export type UpdateAvatarMutationOptions = Apollo.BaseMutationOptions<UpdateAvatarMutation, UpdateAvatarMutationVariables>;
export const SentChatDocument = gql`
    mutation SentChat($channel: String!, $message: String!) {
  chat(channel: $channel, message: $message) {
    id
    message
    createdAt
    sender {
      userId
      nickname
      avatar {
        uri
        square
      }
      vipLevel
    }
  }
}
    `;
export type SentChatMutationFn = Apollo.MutationFunction<SentChatMutation, SentChatMutationVariables>;

/**
 * __useSentChatMutation__
 *
 * To run a mutation, you first call `useSentChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSentChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sentChatMutation, { data, loading, error }] = useSentChatMutation({
 *   variables: {
 *      channel: // value for 'channel'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSentChatMutation(baseOptions?: Apollo.MutationHookOptions<SentChatMutation, SentChatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SentChatMutation, SentChatMutationVariables>(SentChatDocument, options);
      }
export type SentChatMutationHookResult = ReturnType<typeof useSentChatMutation>;
export type SentChatMutationResult = Apollo.MutationResult<SentChatMutation>;
export type SentChatMutationOptions = Apollo.BaseMutationOptions<SentChatMutation, SentChatMutationVariables>;
export const VipClaimInstantBonusDocument = gql`
    mutation VipClaimInstantBonus {
  vipClaimInstantBonus {
    success
    error {
      code
      message
    }
    content
  }
}
    `;
export type VipClaimInstantBonusMutationFn = Apollo.MutationFunction<VipClaimInstantBonusMutation, VipClaimInstantBonusMutationVariables>;

/**
 * __useVipClaimInstantBonusMutation__
 *
 * To run a mutation, you first call `useVipClaimInstantBonusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVipClaimInstantBonusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vipClaimInstantBonusMutation, { data, loading, error }] = useVipClaimInstantBonusMutation({
 *   variables: {
 *   },
 * });
 */
export function useVipClaimInstantBonusMutation(baseOptions?: Apollo.MutationHookOptions<VipClaimInstantBonusMutation, VipClaimInstantBonusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VipClaimInstantBonusMutation, VipClaimInstantBonusMutationVariables>(VipClaimInstantBonusDocument, options);
      }
export type VipClaimInstantBonusMutationHookResult = ReturnType<typeof useVipClaimInstantBonusMutation>;
export type VipClaimInstantBonusMutationResult = Apollo.MutationResult<VipClaimInstantBonusMutation>;
export type VipClaimInstantBonusMutationOptions = Apollo.BaseMutationOptions<VipClaimInstantBonusMutation, VipClaimInstantBonusMutationVariables>;
export const VipClaimWeekBonusDocument = gql`
    mutation VipClaimWeekBonus {
  vipClaimWeekBonus {
    success
    error {
      code
      message
    }
    content
  }
}
    `;
export type VipClaimWeekBonusMutationFn = Apollo.MutationFunction<VipClaimWeekBonusMutation, VipClaimWeekBonusMutationVariables>;

/**
 * __useVipClaimWeekBonusMutation__
 *
 * To run a mutation, you first call `useVipClaimWeekBonusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVipClaimWeekBonusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vipClaimWeekBonusMutation, { data, loading, error }] = useVipClaimWeekBonusMutation({
 *   variables: {
 *   },
 * });
 */
export function useVipClaimWeekBonusMutation(baseOptions?: Apollo.MutationHookOptions<VipClaimWeekBonusMutation, VipClaimWeekBonusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VipClaimWeekBonusMutation, VipClaimWeekBonusMutationVariables>(VipClaimWeekBonusDocument, options);
      }
export type VipClaimWeekBonusMutationHookResult = ReturnType<typeof useVipClaimWeekBonusMutation>;
export type VipClaimWeekBonusMutationResult = Apollo.MutationResult<VipClaimWeekBonusMutation>;
export type VipClaimWeekBonusMutationOptions = Apollo.BaseMutationOptions<VipClaimWeekBonusMutation, VipClaimWeekBonusMutationVariables>;
export const VipClaimMonthBonusDocument = gql`
    mutation VipClaimMonthBonus {
  vipClaimMonthBonus {
    success
    error {
      code
      message
    }
    content
  }
}
    `;
export type VipClaimMonthBonusMutationFn = Apollo.MutationFunction<VipClaimMonthBonusMutation, VipClaimMonthBonusMutationVariables>;

/**
 * __useVipClaimMonthBonusMutation__
 *
 * To run a mutation, you first call `useVipClaimMonthBonusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVipClaimMonthBonusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vipClaimMonthBonusMutation, { data, loading, error }] = useVipClaimMonthBonusMutation({
 *   variables: {
 *   },
 * });
 */
export function useVipClaimMonthBonusMutation(baseOptions?: Apollo.MutationHookOptions<VipClaimMonthBonusMutation, VipClaimMonthBonusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VipClaimMonthBonusMutation, VipClaimMonthBonusMutationVariables>(VipClaimMonthBonusDocument, options);
      }
export type VipClaimMonthBonusMutationHookResult = ReturnType<typeof useVipClaimMonthBonusMutation>;
export type VipClaimMonthBonusMutationResult = Apollo.MutationResult<VipClaimMonthBonusMutation>;
export type VipClaimMonthBonusMutationOptions = Apollo.BaseMutationOptions<VipClaimMonthBonusMutation, VipClaimMonthBonusMutationVariables>;
export const CreateReferralCodeDocument = gql`
    mutation CreateReferralCode($name: String!, $code: String!) {
  createReferralCode(name: $name, code: $code) {
    success
    error {
      code
      message
    }
    content {
      id
      name
      code
    }
  }
}
    `;
export type CreateReferralCodeMutationFn = Apollo.MutationFunction<CreateReferralCodeMutation, CreateReferralCodeMutationVariables>;

/**
 * __useCreateReferralCodeMutation__
 *
 * To run a mutation, you first call `useCreateReferralCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferralCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferralCodeMutation, { data, loading, error }] = useCreateReferralCodeMutation({
 *   variables: {
 *      name: // value for 'name'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useCreateReferralCodeMutation(baseOptions?: Apollo.MutationHookOptions<CreateReferralCodeMutation, CreateReferralCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReferralCodeMutation, CreateReferralCodeMutationVariables>(CreateReferralCodeDocument, options);
      }
export type CreateReferralCodeMutationHookResult = ReturnType<typeof useCreateReferralCodeMutation>;
export type CreateReferralCodeMutationResult = Apollo.MutationResult<CreateReferralCodeMutation>;
export type CreateReferralCodeMutationOptions = Apollo.BaseMutationOptions<CreateReferralCodeMutation, CreateReferralCodeMutationVariables>;
export const ClaimCommissionsDocument = gql`
    mutation ClaimCommissions {
  claimCommissions {
    success
    content
    error {
      code
      message
    }
  }
}
    `;
export type ClaimCommissionsMutationFn = Apollo.MutationFunction<ClaimCommissionsMutation, ClaimCommissionsMutationVariables>;

/**
 * __useClaimCommissionsMutation__
 *
 * To run a mutation, you first call `useClaimCommissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimCommissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimCommissionsMutation, { data, loading, error }] = useClaimCommissionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useClaimCommissionsMutation(baseOptions?: Apollo.MutationHookOptions<ClaimCommissionsMutation, ClaimCommissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClaimCommissionsMutation, ClaimCommissionsMutationVariables>(ClaimCommissionsDocument, options);
      }
export type ClaimCommissionsMutationHookResult = ReturnType<typeof useClaimCommissionsMutation>;
export type ClaimCommissionsMutationResult = Apollo.MutationResult<ClaimCommissionsMutation>;
export type ClaimCommissionsMutationOptions = Apollo.BaseMutationOptions<ClaimCommissionsMutation, ClaimCommissionsMutationVariables>;
export const ResponseAgencyInviteDocument = gql`
    mutation ResponseAgencyInvite($accepted: Boolean!) {
  responseAgencyInvite(accepted: $accepted) {
    success
    error {
      code
      message
    }
  }
}
    `;
export type ResponseAgencyInviteMutationFn = Apollo.MutationFunction<ResponseAgencyInviteMutation, ResponseAgencyInviteMutationVariables>;

/**
 * __useResponseAgencyInviteMutation__
 *
 * To run a mutation, you first call `useResponseAgencyInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResponseAgencyInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [responseAgencyInviteMutation, { data, loading, error }] = useResponseAgencyInviteMutation({
 *   variables: {
 *      accepted: // value for 'accepted'
 *   },
 * });
 */
export function useResponseAgencyInviteMutation(baseOptions?: Apollo.MutationHookOptions<ResponseAgencyInviteMutation, ResponseAgencyInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResponseAgencyInviteMutation, ResponseAgencyInviteMutationVariables>(ResponseAgencyInviteDocument, options);
      }
export type ResponseAgencyInviteMutationHookResult = ReturnType<typeof useResponseAgencyInviteMutation>;
export type ResponseAgencyInviteMutationResult = Apollo.MutationResult<ResponseAgencyInviteMutation>;
export type ResponseAgencyInviteMutationOptions = Apollo.BaseMutationOptions<ResponseAgencyInviteMutation, ResponseAgencyInviteMutationVariables>;
export const CreateInternalTransferDocument = gql`
    mutation CreateInternalTransfer($receiverId: ID!, $currencyId: ID!, $amount: Decimal!, $message: String!) {
  createInternalTransfer(
    receiverId: $receiverId
    currencyId: $currencyId
    amount: $amount
    message: $message
  ) {
    success
    error {
      code
      message
    }
    content {
      id
      senderId
      senderNickname
      receiverId
      receiverNickname
      amount
      message
      equivalentUsdRate
      status
      createdAt
      senderAvatar {
        uri
        square
        small
        medium
        large
      }
      receiverAvatar {
        uri
        square
        small
        medium
        large
      }
      currency {
        id
        name
        shortName
        type
        decimalDigits
        equivalentUsdRate
        blockchainNetworks
      }
    }
  }
}
    `;
export type CreateInternalTransferMutationFn = Apollo.MutationFunction<CreateInternalTransferMutation, CreateInternalTransferMutationVariables>;

/**
 * __useCreateInternalTransferMutation__
 *
 * To run a mutation, you first call `useCreateInternalTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInternalTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInternalTransferMutation, { data, loading, error }] = useCreateInternalTransferMutation({
 *   variables: {
 *      receiverId: // value for 'receiverId'
 *      currencyId: // value for 'currencyId'
 *      amount: // value for 'amount'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useCreateInternalTransferMutation(baseOptions?: Apollo.MutationHookOptions<CreateInternalTransferMutation, CreateInternalTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInternalTransferMutation, CreateInternalTransferMutationVariables>(CreateInternalTransferDocument, options);
      }
export type CreateInternalTransferMutationHookResult = ReturnType<typeof useCreateInternalTransferMutation>;
export type CreateInternalTransferMutationResult = Apollo.MutationResult<CreateInternalTransferMutation>;
export type CreateInternalTransferMutationOptions = Apollo.BaseMutationOptions<CreateInternalTransferMutation, CreateInternalTransferMutationVariables>;
export const RedeemCodeDocument = gql`
    mutation RedeemCode($code: String!) {
  redeemCode(code: $code) {
    success
    error {
      code
      message
    }
    content {
      amount
      currency {
        id
        name
        shortName
        type
        decimalDigits
        equivalentUsdRate
        blockchainNetworks
      }
    }
  }
}
    `;
export type RedeemCodeMutationFn = Apollo.MutationFunction<RedeemCodeMutation, RedeemCodeMutationVariables>;

/**
 * __useRedeemCodeMutation__
 *
 * To run a mutation, you first call `useRedeemCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemCodeMutation, { data, loading, error }] = useRedeemCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useRedeemCodeMutation(baseOptions?: Apollo.MutationHookOptions<RedeemCodeMutation, RedeemCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RedeemCodeMutation, RedeemCodeMutationVariables>(RedeemCodeDocument, options);
      }
export type RedeemCodeMutationHookResult = ReturnType<typeof useRedeemCodeMutation>;
export type RedeemCodeMutationResult = Apollo.MutationResult<RedeemCodeMutation>;
export type RedeemCodeMutationOptions = Apollo.BaseMutationOptions<RedeemCodeMutation, RedeemCodeMutationVariables>;
export const CreateCryptoWithdrawalDocument = gql`
    mutation CreateCryptoWithdrawal($currencyId: ID!, $network: BlockchainNetwork!, $address: String!, $amount: Decimal!) {
  createCryptoWithdrawal(
    currencyId: $currencyId
    network: $network
    address: $address
    amount: $amount
  ) {
    success
    error {
      code
      message
    }
  }
}
    `;
export type CreateCryptoWithdrawalMutationFn = Apollo.MutationFunction<CreateCryptoWithdrawalMutation, CreateCryptoWithdrawalMutationVariables>;

/**
 * __useCreateCryptoWithdrawalMutation__
 *
 * To run a mutation, you first call `useCreateCryptoWithdrawalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCryptoWithdrawalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCryptoWithdrawalMutation, { data, loading, error }] = useCreateCryptoWithdrawalMutation({
 *   variables: {
 *      currencyId: // value for 'currencyId'
 *      network: // value for 'network'
 *      address: // value for 'address'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useCreateCryptoWithdrawalMutation(baseOptions?: Apollo.MutationHookOptions<CreateCryptoWithdrawalMutation, CreateCryptoWithdrawalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCryptoWithdrawalMutation, CreateCryptoWithdrawalMutationVariables>(CreateCryptoWithdrawalDocument, options);
      }
export type CreateCryptoWithdrawalMutationHookResult = ReturnType<typeof useCreateCryptoWithdrawalMutation>;
export type CreateCryptoWithdrawalMutationResult = Apollo.MutationResult<CreateCryptoWithdrawalMutation>;
export type CreateCryptoWithdrawalMutationOptions = Apollo.BaseMutationOptions<CreateCryptoWithdrawalMutation, CreateCryptoWithdrawalMutationVariables>;
export const TransfiRequestBuyCryptoDocument = gql`
    mutation TransfiRequestBuyCrypto($currencyId: Long!, $amount: Decimal!, $fiat: String!, $returnUrl: String!) {
  transfiRequestBuyCrypto(
    currencyId: $currencyId
    amount: $amount
    fiat: $fiat
    returnUrl: $returnUrl
  ) {
    success
    error {
      code
      message
    }
    content
  }
}
    `;
export type TransfiRequestBuyCryptoMutationFn = Apollo.MutationFunction<TransfiRequestBuyCryptoMutation, TransfiRequestBuyCryptoMutationVariables>;

/**
 * __useTransfiRequestBuyCryptoMutation__
 *
 * To run a mutation, you first call `useTransfiRequestBuyCryptoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransfiRequestBuyCryptoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transfiRequestBuyCryptoMutation, { data, loading, error }] = useTransfiRequestBuyCryptoMutation({
 *   variables: {
 *      currencyId: // value for 'currencyId'
 *      amount: // value for 'amount'
 *      fiat: // value for 'fiat'
 *      returnUrl: // value for 'returnUrl'
 *   },
 * });
 */
export function useTransfiRequestBuyCryptoMutation(baseOptions?: Apollo.MutationHookOptions<TransfiRequestBuyCryptoMutation, TransfiRequestBuyCryptoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransfiRequestBuyCryptoMutation, TransfiRequestBuyCryptoMutationVariables>(TransfiRequestBuyCryptoDocument, options);
      }
export type TransfiRequestBuyCryptoMutationHookResult = ReturnType<typeof useTransfiRequestBuyCryptoMutation>;
export type TransfiRequestBuyCryptoMutationResult = Apollo.MutationResult<TransfiRequestBuyCryptoMutation>;
export type TransfiRequestBuyCryptoMutationOptions = Apollo.BaseMutationOptions<TransfiRequestBuyCryptoMutation, TransfiRequestBuyCryptoMutationVariables>;
export const DepositGameBankDocument = gql`
    mutation DepositGameBank($gameBankId: ID!, $amount: Decimal!) {
  investorDeposit(gameBankId: $gameBankId, amount: $amount) {
    success
    error {
      code
      message
    }
    content {
      investTransaction {
        amount
        equivalentUsdRate
        currency {
          id
        }
      }
    }
  }
}
    `;
export type DepositGameBankMutationFn = Apollo.MutationFunction<DepositGameBankMutation, DepositGameBankMutationVariables>;

/**
 * __useDepositGameBankMutation__
 *
 * To run a mutation, you first call `useDepositGameBankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepositGameBankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [depositGameBankMutation, { data, loading, error }] = useDepositGameBankMutation({
 *   variables: {
 *      gameBankId: // value for 'gameBankId'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useDepositGameBankMutation(baseOptions?: Apollo.MutationHookOptions<DepositGameBankMutation, DepositGameBankMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DepositGameBankMutation, DepositGameBankMutationVariables>(DepositGameBankDocument, options);
      }
export type DepositGameBankMutationHookResult = ReturnType<typeof useDepositGameBankMutation>;
export type DepositGameBankMutationResult = Apollo.MutationResult<DepositGameBankMutation>;
export type DepositGameBankMutationOptions = Apollo.BaseMutationOptions<DepositGameBankMutation, DepositGameBankMutationVariables>;
export const WithdrawGameBankDocument = gql`
    mutation WithdrawGameBank($gameBankId: ID!, $amount: Decimal, $byPercent: Boolean!) {
  investorWithdraw(
    gameBankId: $gameBankId
    amount: $amount
    byPercent: $byPercent
  ) {
    success
    error {
      code
      message
    }
    content {
      investTransaction {
        amount
        equivalentUsdRate
        currency {
          id
        }
      }
    }
  }
}
    `;
export type WithdrawGameBankMutationFn = Apollo.MutationFunction<WithdrawGameBankMutation, WithdrawGameBankMutationVariables>;

/**
 * __useWithdrawGameBankMutation__
 *
 * To run a mutation, you first call `useWithdrawGameBankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawGameBankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawGameBankMutation, { data, loading, error }] = useWithdrawGameBankMutation({
 *   variables: {
 *      gameBankId: // value for 'gameBankId'
 *      amount: // value for 'amount'
 *      byPercent: // value for 'byPercent'
 *   },
 * });
 */
export function useWithdrawGameBankMutation(baseOptions?: Apollo.MutationHookOptions<WithdrawGameBankMutation, WithdrawGameBankMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WithdrawGameBankMutation, WithdrawGameBankMutationVariables>(WithdrawGameBankDocument, options);
      }
export type WithdrawGameBankMutationHookResult = ReturnType<typeof useWithdrawGameBankMutation>;
export type WithdrawGameBankMutationResult = Apollo.MutationResult<WithdrawGameBankMutation>;
export type WithdrawGameBankMutationOptions = Apollo.BaseMutationOptions<WithdrawGameBankMutation, WithdrawGameBankMutationVariables>;
export const SwapCurrencyDocument = gql`
    mutation SwapCurrency($fromId: ID!, $toId: ID!, $amount: Decimal!) {
  swap(fromId: $fromId, toId: $toId, amount: $amount) {
    success
    error {
      code
      message
    }
    content {
      id
      status
      fromUsdRate
      toUsdRate
      amount
      rate
      from {
        id
        name
        shortName
        decimalDigits
        equivalentUsdRate
      }
      to {
        id
        name
        shortName
        decimalDigits
        equivalentUsdRate
      }
    }
  }
}
    `;
export type SwapCurrencyMutationFn = Apollo.MutationFunction<SwapCurrencyMutation, SwapCurrencyMutationVariables>;

/**
 * __useSwapCurrencyMutation__
 *
 * To run a mutation, you first call `useSwapCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwapCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [swapCurrencyMutation, { data, loading, error }] = useSwapCurrencyMutation({
 *   variables: {
 *      fromId: // value for 'fromId'
 *      toId: // value for 'toId'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useSwapCurrencyMutation(baseOptions?: Apollo.MutationHookOptions<SwapCurrencyMutation, SwapCurrencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SwapCurrencyMutation, SwapCurrencyMutationVariables>(SwapCurrencyDocument, options);
      }
export type SwapCurrencyMutationHookResult = ReturnType<typeof useSwapCurrencyMutation>;
export type SwapCurrencyMutationResult = Apollo.MutationResult<SwapCurrencyMutation>;
export type SwapCurrencyMutationOptions = Apollo.BaseMutationOptions<SwapCurrencyMutation, SwapCurrencyMutationVariables>;
export const MyBetsDocument = gql`
    query MyBets($page: Int!, $pageSize: Int!, $gameId: ID, $currencyId: Int) {
  myBets(
    page: $page
    pageSize: $pageSize
    gameId: $gameId
    currencyId: $currencyId
  ) {
    hasMore
    data {
      id
      createdAt
      betAmount
      betChargeAmount
      payoutRate
      payoutAmount
      equivalentUsdRate
      currency {
        id
        shortName
        decimalDigits
        equivalentUsdRate
      }
      betData {
        ... on LimboBetData {
          betMultiplier
          result
        }
        ... on DiceBetData {
          result
          betValue
        }
      }
    }
  }
}
    `;

/**
 * __useMyBetsQuery__
 *
 * To run a query within a React component, call `useMyBetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyBetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBetsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      gameId: // value for 'gameId'
 *      currencyId: // value for 'currencyId'
 *   },
 * });
 */
export function useMyBetsQuery(baseOptions: Apollo.QueryHookOptions<MyBetsQuery, MyBetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyBetsQuery, MyBetsQueryVariables>(MyBetsDocument, options);
      }
export function useMyBetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyBetsQuery, MyBetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyBetsQuery, MyBetsQueryVariables>(MyBetsDocument, options);
        }
export type MyBetsQueryHookResult = ReturnType<typeof useMyBetsQuery>;
export type MyBetsLazyQueryHookResult = ReturnType<typeof useMyBetsLazyQuery>;
export type MyBetsQueryResult = Apollo.QueryResult<MyBetsQuery, MyBetsQueryVariables>;
export const BetDocument = gql`
    query Bet($betId: ID!) {
  bet(id: $betId) {
    id
    createdAt
    betAmount
    betChargeAmount
    payoutRate
    payoutAmount
    equivalentUsdRate
    rngResult {
      id
      message
      rng
      rngPublicKey
    }
    game {
      id
      name
      slug
      gameBase {
        thumbnail {
          square
        }
        producer {
          name
        }
      }
    }
    user {
      id
      nickname
      avatar {
        square
      }
    }
    currency {
      id
      shortName
      decimalDigits
      equivalentUsdRate
    }
  }
}
    `;

/**
 * __useBetQuery__
 *
 * To run a query within a React component, call `useBetQuery` and pass it any options that fit your needs.
 * When your component renders, `useBetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBetQuery({
 *   variables: {
 *      betId: // value for 'betId'
 *   },
 * });
 */
export function useBetQuery(baseOptions: Apollo.QueryHookOptions<BetQuery, BetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BetQuery, BetQueryVariables>(BetDocument, options);
      }
export function useBetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BetQuery, BetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BetQuery, BetQueryVariables>(BetDocument, options);
        }
export type BetQueryHookResult = ReturnType<typeof useBetQuery>;
export type BetLazyQueryHookResult = ReturnType<typeof useBetLazyQuery>;
export type BetQueryResult = Apollo.QueryResult<BetQuery, BetQueryVariables>;
export const JackpotWinnersDocument = gql`
    query JackpotWinners($page: Int!, $pageSize: Int!, $gameId: ID) {
  jackpotWinBets(page: $page, pageSize: $pageSize, gameId: $gameId) {
    hasMore
    data {
      betId
      jackpotCount
      jackpotTotalAmount
      price
      at
      game {
        id
        slug
        name
      }
      currency {
        id
        shortName
        decimalDigits
        equivalentUsdRate
      }
      user {
        id
        avatar {
          square
        }
        nickname
        vipLevel
      }
    }
  }
}
    `;

/**
 * __useJackpotWinnersQuery__
 *
 * To run a query within a React component, call `useJackpotWinnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useJackpotWinnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJackpotWinnersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useJackpotWinnersQuery(baseOptions: Apollo.QueryHookOptions<JackpotWinnersQuery, JackpotWinnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JackpotWinnersQuery, JackpotWinnersQueryVariables>(JackpotWinnersDocument, options);
      }
export function useJackpotWinnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JackpotWinnersQuery, JackpotWinnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JackpotWinnersQuery, JackpotWinnersQueryVariables>(JackpotWinnersDocument, options);
        }
export type JackpotWinnersQueryHookResult = ReturnType<typeof useJackpotWinnersQuery>;
export type JackpotWinnersLazyQueryHookResult = ReturnType<typeof useJackpotWinnersLazyQuery>;
export type JackpotWinnersQueryResult = Apollo.QueryResult<JackpotWinnersQuery, JackpotWinnersQueryVariables>;
export const RecentBetsDocument = gql`
    query RecentBets($gameId: ID) {
  recentBets(gameId: $gameId) {
    id
    betAmount
    payoutRate
    payoutAmount
    equivalentUsdRate
    currency {
      id
      shortName
      decimalDigits
      equivalentUsdRate
    }
    game {
      id
      name
    }
    user {
      id
      avatar {
        square
      }
      nickname
    }
  }
}
    `;

/**
 * __useRecentBetsQuery__
 *
 * To run a query within a React component, call `useRecentBetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentBetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentBetsQuery({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useRecentBetsQuery(baseOptions?: Apollo.QueryHookOptions<RecentBetsQuery, RecentBetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecentBetsQuery, RecentBetsQueryVariables>(RecentBetsDocument, options);
      }
export function useRecentBetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecentBetsQuery, RecentBetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecentBetsQuery, RecentBetsQueryVariables>(RecentBetsDocument, options);
        }
export type RecentBetsQueryHookResult = ReturnType<typeof useRecentBetsQuery>;
export type RecentBetsLazyQueryHookResult = ReturnType<typeof useRecentBetsLazyQuery>;
export type RecentBetsQueryResult = Apollo.QueryResult<RecentBetsQuery, RecentBetsQueryVariables>;
export const HighBetsDocument = gql`
    query HighBets($gameId: ID) {
  highBets(gameId: $gameId) {
    id
    equivalentUsdRate
    betAmount
    payoutRate
    payoutAmount
    game {
      id
      name
      gameBase {
        thumbnail {
          square
        }
      }
    }
    currency {
      id
      shortName
      decimalDigits
      equivalentUsdRate
    }
    user {
      id
      avatar {
        square
      }
      nickname
    }
  }
}
    `;

/**
 * __useHighBetsQuery__
 *
 * To run a query within a React component, call `useHighBetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHighBetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHighBetsQuery({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useHighBetsQuery(baseOptions?: Apollo.QueryHookOptions<HighBetsQuery, HighBetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HighBetsQuery, HighBetsQueryVariables>(HighBetsDocument, options);
      }
export function useHighBetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HighBetsQuery, HighBetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HighBetsQuery, HighBetsQueryVariables>(HighBetsDocument, options);
        }
export type HighBetsQueryHookResult = ReturnType<typeof useHighBetsQuery>;
export type HighBetsLazyQueryHookResult = ReturnType<typeof useHighBetsLazyQuery>;
export type HighBetsQueryResult = Apollo.QueryResult<HighBetsQuery, HighBetsQueryVariables>;
export const TodayTopBiggestWinDocument = gql`
    query TodayTopBiggestWin {
  todayTopBiggestWin {
    rank
    totalWinAmountUsd
    user {
      id
      nickname
      avatar {
        uri
        square
        small
        medium
        large
      }
    }
  }
}
    `;

/**
 * __useTodayTopBiggestWinQuery__
 *
 * To run a query within a React component, call `useTodayTopBiggestWinQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodayTopBiggestWinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodayTopBiggestWinQuery({
 *   variables: {
 *   },
 * });
 */
export function useTodayTopBiggestWinQuery(baseOptions?: Apollo.QueryHookOptions<TodayTopBiggestWinQuery, TodayTopBiggestWinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TodayTopBiggestWinQuery, TodayTopBiggestWinQueryVariables>(TodayTopBiggestWinDocument, options);
      }
export function useTodayTopBiggestWinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TodayTopBiggestWinQuery, TodayTopBiggestWinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TodayTopBiggestWinQuery, TodayTopBiggestWinQueryVariables>(TodayTopBiggestWinDocument, options);
        }
export type TodayTopBiggestWinQueryHookResult = ReturnType<typeof useTodayTopBiggestWinQuery>;
export type TodayTopBiggestWinLazyQueryHookResult = ReturnType<typeof useTodayTopBiggestWinLazyQuery>;
export type TodayTopBiggestWinQueryResult = Apollo.QueryResult<TodayTopBiggestWinQuery, TodayTopBiggestWinQueryVariables>;
export const DailyContestDocument = gql`
    query DailyContest($date: Date) {
  dailyContest(date: $date) {
    id
    start
    end
    date
    totalPrize
    doubleWagerGame {
      id
      name
      slug
      gameBase {
        thumbnail {
          square
        }
      }
    }
  }
}
    `;

/**
 * __useDailyContestQuery__
 *
 * To run a query within a React component, call `useDailyContestQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyContestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyContestQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDailyContestQuery(baseOptions?: Apollo.QueryHookOptions<DailyContestQuery, DailyContestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DailyContestQuery, DailyContestQueryVariables>(DailyContestDocument, options);
      }
export function useDailyContestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DailyContestQuery, DailyContestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DailyContestQuery, DailyContestQueryVariables>(DailyContestDocument, options);
        }
export type DailyContestQueryHookResult = ReturnType<typeof useDailyContestQuery>;
export type DailyContestLazyQueryHookResult = ReturnType<typeof useDailyContestLazyQuery>;
export type DailyContestQueryResult = Apollo.QueryResult<DailyContestQuery, DailyContestQueryVariables>;
export const DailyContestResultsDocument = gql`
    query DailyContestResults($date: Date) {
  dailyContest(date: $date) {
    dailyContestResults {
      id
      rank
      totalWager
      amount
      user {
        id
        avatar {
          square
        }
        nickname
      }
    }
    myWager
  }
}
    `;

/**
 * __useDailyContestResultsQuery__
 *
 * To run a query within a React component, call `useDailyContestResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyContestResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyContestResultsQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDailyContestResultsQuery(baseOptions?: Apollo.QueryHookOptions<DailyContestResultsQuery, DailyContestResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DailyContestResultsQuery, DailyContestResultsQueryVariables>(DailyContestResultsDocument, options);
      }
export function useDailyContestResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DailyContestResultsQuery, DailyContestResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DailyContestResultsQuery, DailyContestResultsQueryVariables>(DailyContestResultsDocument, options);
        }
export type DailyContestResultsQueryHookResult = ReturnType<typeof useDailyContestResultsQuery>;
export type DailyContestResultsLazyQueryHookResult = ReturnType<typeof useDailyContestResultsLazyQuery>;
export type DailyContestResultsQueryResult = Apollo.QueryResult<DailyContestResultsQuery, DailyContestResultsQueryVariables>;
export const CrashPredictorDocument = gql`
    query CrashPredictor($date: Date, $gameId: ID!) {
  crashPredictor(date: $date, gameId: $gameId) {
    id
    start
    end
    date
    totalPrize
    crashPredictorResults {
      id
      rank
      cashout
      crashResult
      totalWager
      amount
      user {
        id
        avatar {
          square
        }
        nickname
        vipLevel
      }
    }
    myPredictor {
      id
      rank
      cashout
      crashResult
      totalWager
      amount
    }
  }
}
    `;

/**
 * __useCrashPredictorQuery__
 *
 * To run a query within a React component, call `useCrashPredictorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrashPredictorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrashPredictorQuery({
 *   variables: {
 *      date: // value for 'date'
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useCrashPredictorQuery(baseOptions: Apollo.QueryHookOptions<CrashPredictorQuery, CrashPredictorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrashPredictorQuery, CrashPredictorQueryVariables>(CrashPredictorDocument, options);
      }
export function useCrashPredictorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrashPredictorQuery, CrashPredictorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrashPredictorQuery, CrashPredictorQueryVariables>(CrashPredictorDocument, options);
        }
export type CrashPredictorQueryHookResult = ReturnType<typeof useCrashPredictorQuery>;
export type CrashPredictorLazyQueryHookResult = ReturnType<typeof useCrashPredictorLazyQuery>;
export type CrashPredictorQueryResult = Apollo.QueryResult<CrashPredictorQuery, CrashPredictorQueryVariables>;
export const FunCrashPredictorDocument = gql`
    query FunCrashPredictor($gameId: ID!, $date: Date) {
  funCrashPredictor(gameId: $gameId, date: $date) {
    id
    start
    end
    date
    totalPrize
    crashPredictorResults {
      id
      rank
      cashout
      crashResult
      totalWager
      amount
      user {
        id
        avatar {
          square
        }
        nickname
        vipLevel
      }
    }
    myPredictor {
      id
      rank
      cashout
      crashResult
      totalWager
      amount
    }
  }
}
    `;

/**
 * __useFunCrashPredictorQuery__
 *
 * To run a query within a React component, call `useFunCrashPredictorQuery` and pass it any options that fit your needs.
 * When your component renders, `useFunCrashPredictorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFunCrashPredictorQuery({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useFunCrashPredictorQuery(baseOptions: Apollo.QueryHookOptions<FunCrashPredictorQuery, FunCrashPredictorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FunCrashPredictorQuery, FunCrashPredictorQueryVariables>(FunCrashPredictorDocument, options);
      }
export function useFunCrashPredictorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FunCrashPredictorQuery, FunCrashPredictorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FunCrashPredictorQuery, FunCrashPredictorQueryVariables>(FunCrashPredictorDocument, options);
        }
export type FunCrashPredictorQueryHookResult = ReturnType<typeof useFunCrashPredictorQuery>;
export type FunCrashPredictorLazyQueryHookResult = ReturnType<typeof useFunCrashPredictorLazyQuery>;
export type FunCrashPredictorQueryResult = Apollo.QueryResult<FunCrashPredictorQuery, FunCrashPredictorQueryVariables>;
export const ExProvidersDocument = gql`
    query ExProviders {
  exProviders {
    slug
    name
    gameCount
    logo {
      square
      small
    }
  }
}
    `;

/**
 * __useExProvidersQuery__
 *
 * To run a query within a React component, call `useExProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useExProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useExProvidersQuery(baseOptions?: Apollo.QueryHookOptions<ExProvidersQuery, ExProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExProvidersQuery, ExProvidersQueryVariables>(ExProvidersDocument, options);
      }
export function useExProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExProvidersQuery, ExProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExProvidersQuery, ExProvidersQueryVariables>(ExProvidersDocument, options);
        }
export type ExProvidersQueryHookResult = ReturnType<typeof useExProvidersQuery>;
export type ExProvidersLazyQueryHookResult = ReturnType<typeof useExProvidersLazyQuery>;
export type ExProvidersQueryResult = Apollo.QueryResult<ExProvidersQuery, ExProvidersQueryVariables>;
export const ExGamesDocument = gql`
    query ExGames($page: Int!, $pageSize: Int!, $category: ExGameCategory, $provider: String, $filter: String, $sort: ExGameSorting) {
  exGames(
    page: $page
    pageSize: $pageSize
    category: $category
    provider: $provider
    filter: $filter
    sort: $sort
  ) {
    hasMore
    data {
      slug
      title
      thumbnail {
        square
      }
      supportedCurrencyIds
      provider {
        slug
        name
        gameCount
        logo {
          square
          small
        }
      }
    }
  }
}
    `;

/**
 * __useExGamesQuery__
 *
 * To run a query within a React component, call `useExGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExGamesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      category: // value for 'category'
 *      provider: // value for 'provider'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useExGamesQuery(baseOptions: Apollo.QueryHookOptions<ExGamesQuery, ExGamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExGamesQuery, ExGamesQueryVariables>(ExGamesDocument, options);
      }
export function useExGamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExGamesQuery, ExGamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExGamesQuery, ExGamesQueryVariables>(ExGamesDocument, options);
        }
export type ExGamesQueryHookResult = ReturnType<typeof useExGamesQuery>;
export type ExGamesLazyQueryHookResult = ReturnType<typeof useExGamesLazyQuery>;
export type ExGamesQueryResult = Apollo.QueryResult<ExGamesQuery, ExGamesQueryVariables>;
export const ExGameDocument = gql`
    query ExGame($slug: String!) {
  exGame(slug: $slug) {
    slug
    title
    thumbnail {
      square
    }
    supportedCurrencyIds
    provider {
      slug
      name
      gameCount
      logo {
        square
        small
      }
    }
  }
}
    `;

/**
 * __useExGameQuery__
 *
 * To run a query within a React component, call `useExGameQuery` and pass it any options that fit your needs.
 * When your component renders, `useExGameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExGameQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useExGameQuery(baseOptions: Apollo.QueryHookOptions<ExGameQuery, ExGameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExGameQuery, ExGameQueryVariables>(ExGameDocument, options);
      }
export function useExGameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExGameQuery, ExGameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExGameQuery, ExGameQueryVariables>(ExGameDocument, options);
        }
export type ExGameQueryHookResult = ReturnType<typeof useExGameQuery>;
export type ExGameLazyQueryHookResult = ReturnType<typeof useExGameLazyQuery>;
export type ExGameQueryResult = Apollo.QueryResult<ExGameQuery, ExGameQueryVariables>;
export const GamesDocument = gql`
    query Games($page: Int!, $pageSize: Int!, $producerId: ID, $tags: [String!]) {
  games(page: $page, pageSize: $pageSize, producerId: $producerId, tags: $tags) {
    hasMore
    data {
      id
      game {
        id
        name
        slug
        gameBase {
          id
          type
          isMiniGame
          thumbnail {
            square
            large
            medium
          }
          tags
          rtp
          maxWinRate
          producer {
            name
            gameCount
            branch
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGamesQuery__
 *
 * To run a query within a React component, call `useGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGamesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      producerId: // value for 'producerId'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGamesQuery(baseOptions: Apollo.QueryHookOptions<GamesQuery, GamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GamesQuery, GamesQueryVariables>(GamesDocument, options);
      }
export function useGamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GamesQuery, GamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GamesQuery, GamesQueryVariables>(GamesDocument, options);
        }
export type GamesQueryHookResult = ReturnType<typeof useGamesQuery>;
export type GamesLazyQueryHookResult = ReturnType<typeof useGamesLazyQuery>;
export type GamesQueryResult = Apollo.QueryResult<GamesQuery, GamesQueryVariables>;
export const SlotWithJackpotGamesDocument = gql`
    query SlotWithJackpotGames($page: Int!, $pageSize: Int!, $currencyId: ID!, $producerId: ID, $tags: [String!]) {
  games(page: $page, pageSize: $pageSize, producerId: $producerId, tags: $tags) {
    hasMore
    data {
      id
      game {
        id
        name
        slug
        gameBase {
          id
          type
          isMiniGame
          thumbnail {
            square
            large
            medium
          }
          tags
          rtp
          maxWinRate
          producer {
            name
            gameCount
            branch
          }
        }
      }
      gameFund(currencyId: $currencyId) {
        id
        betRooms {
          id
          jackpotAmount
          betRoomSetting {
            minimumBetAmount
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSlotWithJackpotGamesQuery__
 *
 * To run a query within a React component, call `useSlotWithJackpotGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlotWithJackpotGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlotWithJackpotGamesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      currencyId: // value for 'currencyId'
 *      producerId: // value for 'producerId'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useSlotWithJackpotGamesQuery(baseOptions: Apollo.QueryHookOptions<SlotWithJackpotGamesQuery, SlotWithJackpotGamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SlotWithJackpotGamesQuery, SlotWithJackpotGamesQueryVariables>(SlotWithJackpotGamesDocument, options);
      }
export function useSlotWithJackpotGamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SlotWithJackpotGamesQuery, SlotWithJackpotGamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SlotWithJackpotGamesQuery, SlotWithJackpotGamesQueryVariables>(SlotWithJackpotGamesDocument, options);
        }
export type SlotWithJackpotGamesQueryHookResult = ReturnType<typeof useSlotWithJackpotGamesQuery>;
export type SlotWithJackpotGamesLazyQueryHookResult = ReturnType<typeof useSlotWithJackpotGamesLazyQuery>;
export type SlotWithJackpotGamesQueryResult = Apollo.QueryResult<SlotWithJackpotGamesQuery, SlotWithJackpotGamesQueryVariables>;
export const GameDocument = gql`
    query Game($slug: String!, $currencyId: ID!) {
  game(slug: $slug) {
    id
    game {
      id
      name
      slug
      likes
      liked
      favorites
      favored
      gameBase {
        id
        type
        isMiniGame
        thumbnail {
          square
        }
        releaseDate
        summary
        description
        reviewing
        previews {
          square
          large
        }
        tags
        rtp
        maxWinRate
        producer {
          name
          gameCount
          logo {
            square
          }
        }
      }
    }
    gameFund(currencyId: $currencyId) {
      id
      gameBank {
        id
        isAvailable
        investable
        listing
        depositMinimumAmount
        allInvestorMaxShareRate
        singleInvestorMaxShareRate
        dilutionFeeRate
        withdrawalFeeRate
        amount
        maxProfit
        currency {
          id
          name
          shortName
          type
          decimalDigits
          equivalentUsdRate
        }
      }
      betRooms {
        id
        status
        betRoomSetting {
          id
          minimumBetAmount
          maximumBetAmount
          defaultBetAmount
          currency {
            id
            shortName
            equivalentUsdRate
            decimalDigits
          }
        }
        jackpotAmount
      }
    }
  }
}
    `;

/**
 * __useGameQuery__
 *
 * To run a query within a React component, call `useGameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      currencyId: // value for 'currencyId'
 *   },
 * });
 */
export function useGameQuery(baseOptions: Apollo.QueryHookOptions<GameQuery, GameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GameQuery, GameQueryVariables>(GameDocument, options);
      }
export function useGameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GameQuery, GameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GameQuery, GameQueryVariables>(GameDocument, options);
        }
export type GameQueryHookResult = ReturnType<typeof useGameQuery>;
export type GameLazyQueryHookResult = ReturnType<typeof useGameLazyQuery>;
export type GameQueryResult = Apollo.QueryResult<GameQuery, GameQueryVariables>;
export const GetGameBankDocument = gql`
    query GetGameBank($slug: String!, $currencyId: ID!) {
  game(slug: $slug) {
    id
    gameFund(currencyId: $currencyId) {
      gameBank {
        id
        isAvailable
        investable
        listing
        depositMinimumAmount
        allInvestorMaxShareRate
        singleInvestorMaxShareRate
        dilutionFeeRate
        withdrawalFeeRate
        amount
        currency {
          id
          name
          shortName
          type
          decimalDigits
          equivalentUsdRate
          blockchainNetworks
        }
        shareSummary {
          totalInvestAmount
          hostInvestAmount
          hostShareRate
          yourInvestAmount
          yourShareRate
          yourProfit
        }
      }
      betRooms {
        id
        betRoomSetting {
          id
          minimumBetAmount
          maximumBetAmount
          defaultBetAmount
        }
        jackpotAmount
      }
    }
  }
}
    `;

/**
 * __useGetGameBankQuery__
 *
 * To run a query within a React component, call `useGetGameBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameBankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameBankQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      currencyId: // value for 'currencyId'
 *   },
 * });
 */
export function useGetGameBankQuery(baseOptions: Apollo.QueryHookOptions<GetGameBankQuery, GetGameBankQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGameBankQuery, GetGameBankQueryVariables>(GetGameBankDocument, options);
      }
export function useGetGameBankLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGameBankQuery, GetGameBankQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGameBankQuery, GetGameBankQueryVariables>(GetGameBankDocument, options);
        }
export type GetGameBankQueryHookResult = ReturnType<typeof useGetGameBankQuery>;
export type GetGameBankLazyQueryHookResult = ReturnType<typeof useGetGameBankLazyQuery>;
export type GetGameBankQueryResult = Apollo.QueryResult<GetGameBankQuery, GetGameBankQueryVariables>;
export const MinesCurrentBetDocument = gql`
    query MinesCurrentBet($gameId: ID!) {
  minesCurrentBet(gameId: $gameId) {
    result
    bet {
      id
      betAmount
      betChargeAmount
      payoutRate
      payoutAmount
      equivalentUsdRate
      betData {
        ... on MinesBetData {
          minesCount
          currentMultiplier
          nextMultiplier
          minesCells
          openedCells
        }
      }
      currency {
        id
        name
        shortName
        decimalDigits
        equivalentUsdRate
      }
    }
  }
}
    `;

/**
 * __useMinesCurrentBetQuery__
 *
 * To run a query within a React component, call `useMinesCurrentBetQuery` and pass it any options that fit your needs.
 * When your component renders, `useMinesCurrentBetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMinesCurrentBetQuery({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useMinesCurrentBetQuery(baseOptions: Apollo.QueryHookOptions<MinesCurrentBetQuery, MinesCurrentBetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MinesCurrentBetQuery, MinesCurrentBetQueryVariables>(MinesCurrentBetDocument, options);
      }
export function useMinesCurrentBetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MinesCurrentBetQuery, MinesCurrentBetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MinesCurrentBetQuery, MinesCurrentBetQueryVariables>(MinesCurrentBetDocument, options);
        }
export type MinesCurrentBetQueryHookResult = ReturnType<typeof useMinesCurrentBetQuery>;
export type MinesCurrentBetLazyQueryHookResult = ReturnType<typeof useMinesCurrentBetLazyQuery>;
export type MinesCurrentBetQueryResult = Apollo.QueryResult<MinesCurrentBetQuery, MinesCurrentBetQueryVariables>;
export const CoinFlipCurrentBetDocument = gql`
    query CoinFlipCurrentBet($gameId: ID!) {
  coinFlipCurrentBet(gameId: $gameId) {
    bet {
      id
      betAmount
      payoutRate
      payoutAmount
      equivalentUsdRate
      currency {
        id
        shortName
        equivalentUsdRate
        decimalDigits
      }
      betData {
        ... on CoinFlipBetData {
          currentMultiplier
          nextMultiplier
          flippedRounds
        }
      }
    }
  }
}
    `;

/**
 * __useCoinFlipCurrentBetQuery__
 *
 * To run a query within a React component, call `useCoinFlipCurrentBetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoinFlipCurrentBetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoinFlipCurrentBetQuery({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useCoinFlipCurrentBetQuery(baseOptions: Apollo.QueryHookOptions<CoinFlipCurrentBetQuery, CoinFlipCurrentBetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoinFlipCurrentBetQuery, CoinFlipCurrentBetQueryVariables>(CoinFlipCurrentBetDocument, options);
      }
export function useCoinFlipCurrentBetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoinFlipCurrentBetQuery, CoinFlipCurrentBetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoinFlipCurrentBetQuery, CoinFlipCurrentBetQueryVariables>(CoinFlipCurrentBetDocument, options);
        }
export type CoinFlipCurrentBetQueryHookResult = ReturnType<typeof useCoinFlipCurrentBetQuery>;
export type CoinFlipCurrentBetLazyQueryHookResult = ReturnType<typeof useCoinFlipCurrentBetLazyQuery>;
export type CoinFlipCurrentBetQueryResult = Apollo.QueryResult<CoinFlipCurrentBetQuery, CoinFlipCurrentBetQueryVariables>;
export const CrashRoundResultDocument = gql`
    query CrashRoundResult($page: Int!, $pageSize: Int!, $gameId: ID!) {
  rounds(page: $page, pageSize: $pageSize, gameId: $gameId) {
    hasMore
    data {
      id
      roundData {
        ... on CrashRoundData {
          result
        }
      }
    }
  }
}
    `;

/**
 * __useCrashRoundResultQuery__
 *
 * To run a query within a React component, call `useCrashRoundResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrashRoundResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrashRoundResultQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useCrashRoundResultQuery(baseOptions: Apollo.QueryHookOptions<CrashRoundResultQuery, CrashRoundResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrashRoundResultQuery, CrashRoundResultQueryVariables>(CrashRoundResultDocument, options);
      }
export function useCrashRoundResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrashRoundResultQuery, CrashRoundResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrashRoundResultQuery, CrashRoundResultQueryVariables>(CrashRoundResultDocument, options);
        }
export type CrashRoundResultQueryHookResult = ReturnType<typeof useCrashRoundResultQuery>;
export type CrashRoundResultLazyQueryHookResult = ReturnType<typeof useCrashRoundResultLazyQuery>;
export type CrashRoundResultQueryResult = Apollo.QueryResult<CrashRoundResultQuery, CrashRoundResultQueryVariables>;
export const ListGameBankStatDocument = gql`
    query ListGameBankStat($gameBankId: ID!, $periodMinutes: Int, $time: DateTime) {
  listGameBankStat(
    gameBankId: $gameBankId
    periodMinutes: $periodMinutes
    time: $time
  ) {
    id
    amount
    investAmount
    myInvest
    time
  }
}
    `;

/**
 * __useListGameBankStatQuery__
 *
 * To run a query within a React component, call `useListGameBankStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGameBankStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGameBankStatQuery({
 *   variables: {
 *      gameBankId: // value for 'gameBankId'
 *      periodMinutes: // value for 'periodMinutes'
 *      time: // value for 'time'
 *   },
 * });
 */
export function useListGameBankStatQuery(baseOptions: Apollo.QueryHookOptions<ListGameBankStatQuery, ListGameBankStatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListGameBankStatQuery, ListGameBankStatQueryVariables>(ListGameBankStatDocument, options);
      }
export function useListGameBankStatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListGameBankStatQuery, ListGameBankStatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListGameBankStatQuery, ListGameBankStatQueryVariables>(ListGameBankStatDocument, options);
        }
export type ListGameBankStatQueryHookResult = ReturnType<typeof useListGameBankStatQuery>;
export type ListGameBankStatLazyQueryHookResult = ReturnType<typeof useListGameBankStatLazyQuery>;
export type ListGameBankStatQueryResult = Apollo.QueryResult<ListGameBankStatQuery, ListGameBankStatQueryVariables>;
export const JackpotAmountDocument = gql`
    query JackpotAmount($slug: String!, $currencyId: ID!) {
  game(slug: $slug) {
    id
    game {
      id
      slug
      gameBase {
        id
        type
      }
    }
    gameFund(currencyId: $currencyId) {
      id
      betRooms {
        id
        jackpotAmount
      }
    }
  }
}
    `;

/**
 * __useJackpotAmountQuery__
 *
 * To run a query within a React component, call `useJackpotAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useJackpotAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJackpotAmountQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      currencyId: // value for 'currencyId'
 *   },
 * });
 */
export function useJackpotAmountQuery(baseOptions: Apollo.QueryHookOptions<JackpotAmountQuery, JackpotAmountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JackpotAmountQuery, JackpotAmountQueryVariables>(JackpotAmountDocument, options);
      }
export function useJackpotAmountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JackpotAmountQuery, JackpotAmountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JackpotAmountQuery, JackpotAmountQueryVariables>(JackpotAmountDocument, options);
        }
export type JackpotAmountQueryHookResult = ReturnType<typeof useJackpotAmountQuery>;
export type JackpotAmountLazyQueryHookResult = ReturnType<typeof useJackpotAmountLazyQuery>;
export type JackpotAmountQueryResult = Apollo.QueryResult<JackpotAmountQuery, JackpotAmountQueryVariables>;
export const ThreeDiceCurrentRoundDocument = gql`
    query ThreeDiceCurrentRound($currencyId: ID!) {
  threeDiceCurrentGame(currencyId: $currencyId) {
    currentRound {
      id
      nextRoundId
    }
  }
}
    `;

/**
 * __useThreeDiceCurrentRoundQuery__
 *
 * To run a query within a React component, call `useThreeDiceCurrentRoundQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreeDiceCurrentRoundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreeDiceCurrentRoundQuery({
 *   variables: {
 *      currencyId: // value for 'currencyId'
 *   },
 * });
 */
export function useThreeDiceCurrentRoundQuery(baseOptions: Apollo.QueryHookOptions<ThreeDiceCurrentRoundQuery, ThreeDiceCurrentRoundQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThreeDiceCurrentRoundQuery, ThreeDiceCurrentRoundQueryVariables>(ThreeDiceCurrentRoundDocument, options);
      }
export function useThreeDiceCurrentRoundLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThreeDiceCurrentRoundQuery, ThreeDiceCurrentRoundQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThreeDiceCurrentRoundQuery, ThreeDiceCurrentRoundQueryVariables>(ThreeDiceCurrentRoundDocument, options);
        }
export type ThreeDiceCurrentRoundQueryHookResult = ReturnType<typeof useThreeDiceCurrentRoundQuery>;
export type ThreeDiceCurrentRoundLazyQueryHookResult = ReturnType<typeof useThreeDiceCurrentRoundLazyQuery>;
export type ThreeDiceCurrentRoundQueryResult = Apollo.QueryResult<ThreeDiceCurrentRoundQuery, ThreeDiceCurrentRoundQueryVariables>;
export const ThreeDiceRoundDetailDocument = gql`
    query ThreeDiceRoundDetail($roundId: ID!, $currencyId: ID!) {
  threeDiceRoundDetails(roundId: $roundId, currencyId: $currencyId) {
    winSpotId
    betModes {
      mode
      overList {
        totalAccount
        totalBet
        listBet {
          id
          winValue
          currencyId
          isOver
          refundValue
          rateToBaseCurrency
          totalBetValueToBaseCurrency
          betValueToBaseCurrency
          betValue
          user {
            id
            avatar {
              square
            }
            nickname
          }
        }
      }
      underList {
        totalAccount
        totalBet
        listBet {
          id
          winValue
          currencyId
          refundValue
          isOver
          rateToBaseCurrency
          totalBetValueToBaseCurrency
          betValueToBaseCurrency
          betValue
          user {
            id
            avatar {
              square
            }
            nickname
          }
        }
      }
    }
  }
}
    `;

/**
 * __useThreeDiceRoundDetailQuery__
 *
 * To run a query within a React component, call `useThreeDiceRoundDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreeDiceRoundDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreeDiceRoundDetailQuery({
 *   variables: {
 *      roundId: // value for 'roundId'
 *      currencyId: // value for 'currencyId'
 *   },
 * });
 */
export function useThreeDiceRoundDetailQuery(baseOptions: Apollo.QueryHookOptions<ThreeDiceRoundDetailQuery, ThreeDiceRoundDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThreeDiceRoundDetailQuery, ThreeDiceRoundDetailQueryVariables>(ThreeDiceRoundDetailDocument, options);
      }
export function useThreeDiceRoundDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThreeDiceRoundDetailQuery, ThreeDiceRoundDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThreeDiceRoundDetailQuery, ThreeDiceRoundDetailQueryVariables>(ThreeDiceRoundDetailDocument, options);
        }
export type ThreeDiceRoundDetailQueryHookResult = ReturnType<typeof useThreeDiceRoundDetailQuery>;
export type ThreeDiceRoundDetailLazyQueryHookResult = ReturnType<typeof useThreeDiceRoundDetailLazyQuery>;
export type ThreeDiceRoundDetailQueryResult = Apollo.QueryResult<ThreeDiceRoundDetailQuery, ThreeDiceRoundDetailQueryVariables>;
export const ThreeDiceLeaderBoardDocument = gql`
    query ThreeDiceLeaderBoard($currencyId: ID!) {
  threeDiceCurrentGame(currencyId: $currencyId) {
    currentStreak {
      count
      mode
      wager
      streakType
      winValue
    }
    streakLeaderBoards {
      count
      mode
      wager
      userId
      streakType
      winValue
      user {
        id
        avatar {
          square
        }
        nickname
      }
    }
  }
}
    `;

/**
 * __useThreeDiceLeaderBoardQuery__
 *
 * To run a query within a React component, call `useThreeDiceLeaderBoardQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreeDiceLeaderBoardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreeDiceLeaderBoardQuery({
 *   variables: {
 *      currencyId: // value for 'currencyId'
 *   },
 * });
 */
export function useThreeDiceLeaderBoardQuery(baseOptions: Apollo.QueryHookOptions<ThreeDiceLeaderBoardQuery, ThreeDiceLeaderBoardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThreeDiceLeaderBoardQuery, ThreeDiceLeaderBoardQueryVariables>(ThreeDiceLeaderBoardDocument, options);
      }
export function useThreeDiceLeaderBoardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThreeDiceLeaderBoardQuery, ThreeDiceLeaderBoardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThreeDiceLeaderBoardQuery, ThreeDiceLeaderBoardQueryVariables>(ThreeDiceLeaderBoardDocument, options);
        }
export type ThreeDiceLeaderBoardQueryHookResult = ReturnType<typeof useThreeDiceLeaderBoardQuery>;
export type ThreeDiceLeaderBoardLazyQueryHookResult = ReturnType<typeof useThreeDiceLeaderBoardLazyQuery>;
export type ThreeDiceLeaderBoardQueryResult = Apollo.QueryResult<ThreeDiceLeaderBoardQuery, ThreeDiceLeaderBoardQueryVariables>;
export const ThreeDiceEventHistoriesDocument = gql`
    query ThreeDiceEventHistories($currencyId: ID!) {
  threeDiceCurrentGame(currencyId: $currencyId) {
    jackpotWins {
      id
      jackpotPay
      roundId
      result3D
      jackpotWinList {
        winValue
        count
        wager
        user {
          id
          avatar {
            square
          }
          nickname
        }
      }
      createdTime
    }
  }
}
    `;

/**
 * __useThreeDiceEventHistoriesQuery__
 *
 * To run a query within a React component, call `useThreeDiceEventHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreeDiceEventHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreeDiceEventHistoriesQuery({
 *   variables: {
 *      currencyId: // value for 'currencyId'
 *   },
 * });
 */
export function useThreeDiceEventHistoriesQuery(baseOptions: Apollo.QueryHookOptions<ThreeDiceEventHistoriesQuery, ThreeDiceEventHistoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThreeDiceEventHistoriesQuery, ThreeDiceEventHistoriesQueryVariables>(ThreeDiceEventHistoriesDocument, options);
      }
export function useThreeDiceEventHistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThreeDiceEventHistoriesQuery, ThreeDiceEventHistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThreeDiceEventHistoriesQuery, ThreeDiceEventHistoriesQueryVariables>(ThreeDiceEventHistoriesDocument, options);
        }
export type ThreeDiceEventHistoriesQueryHookResult = ReturnType<typeof useThreeDiceEventHistoriesQuery>;
export type ThreeDiceEventHistoriesLazyQueryHookResult = ReturnType<typeof useThreeDiceEventHistoriesLazyQuery>;
export type ThreeDiceEventHistoriesQueryResult = Apollo.QueryResult<ThreeDiceEventHistoriesQuery, ThreeDiceEventHistoriesQueryVariables>;
export const ThreeDiceTopDailyDocument = gql`
    query ThreeDiceTopDaily($date: Date!, $currencyId: ID!) {
  threeDiceTopDaily(date: $date, currencyId: $currencyId) {
    wager
    user {
      id
      avatar {
        square
      }
      nickname
    }
    winValueToBaseCurrency
  }
}
    `;

/**
 * __useThreeDiceTopDailyQuery__
 *
 * To run a query within a React component, call `useThreeDiceTopDailyQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreeDiceTopDailyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreeDiceTopDailyQuery({
 *   variables: {
 *      date: // value for 'date'
 *      currencyId: // value for 'currencyId'
 *   },
 * });
 */
export function useThreeDiceTopDailyQuery(baseOptions: Apollo.QueryHookOptions<ThreeDiceTopDailyQuery, ThreeDiceTopDailyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThreeDiceTopDailyQuery, ThreeDiceTopDailyQueryVariables>(ThreeDiceTopDailyDocument, options);
      }
export function useThreeDiceTopDailyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThreeDiceTopDailyQuery, ThreeDiceTopDailyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThreeDiceTopDailyQuery, ThreeDiceTopDailyQueryVariables>(ThreeDiceTopDailyDocument, options);
        }
export type ThreeDiceTopDailyQueryHookResult = ReturnType<typeof useThreeDiceTopDailyQuery>;
export type ThreeDiceTopDailyLazyQueryHookResult = ReturnType<typeof useThreeDiceTopDailyLazyQuery>;
export type ThreeDiceTopDailyQueryResult = Apollo.QueryResult<ThreeDiceTopDailyQuery, ThreeDiceTopDailyQueryVariables>;
export const ThreeDiceHistoriesDocument = gql`
    query ThreeDiceHistories($currencyId: ID!) {
  threeDiceCurrentGame(currencyId: $currencyId) {
    foreCasts {
      id
      diceSum
      dice1
      dice2
      dice3
      rngResult {
        rng
      }
    }
    userHistories {
      id
      winValue
      currencyId
      roundId
      isOver
      refundValue
      rateToBaseCurrency
      totalBetValueToBaseCurrency
      betValueToBaseCurrency
      betValue
      gameResults {
        diceSum
        winSpotId
      }
    }
  }
}
    `;

/**
 * __useThreeDiceHistoriesQuery__
 *
 * To run a query within a React component, call `useThreeDiceHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreeDiceHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreeDiceHistoriesQuery({
 *   variables: {
 *      currencyId: // value for 'currencyId'
 *   },
 * });
 */
export function useThreeDiceHistoriesQuery(baseOptions: Apollo.QueryHookOptions<ThreeDiceHistoriesQuery, ThreeDiceHistoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThreeDiceHistoriesQuery, ThreeDiceHistoriesQueryVariables>(ThreeDiceHistoriesDocument, options);
      }
export function useThreeDiceHistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThreeDiceHistoriesQuery, ThreeDiceHistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThreeDiceHistoriesQuery, ThreeDiceHistoriesQueryVariables>(ThreeDiceHistoriesDocument, options);
        }
export type ThreeDiceHistoriesQueryHookResult = ReturnType<typeof useThreeDiceHistoriesQuery>;
export type ThreeDiceHistoriesLazyQueryHookResult = ReturnType<typeof useThreeDiceHistoriesLazyQuery>;
export type ThreeDiceHistoriesQueryResult = Apollo.QueryResult<ThreeDiceHistoriesQuery, ThreeDiceHistoriesQueryVariables>;
export const VideoPokerCurrentBetDocument = gql`
    query VideoPokerCurrentBet($gameId: ID!) {
  VideoPokerCurrentBet(gameId: $gameId) {
    result
    bet {
      id
      betAmount
      betChargeAmount
      payoutRate
      payoutAmount
      equivalentUsdRate
      betData {
        ... on VideoPokerBetData {
          selectHands
          holdCards
          initialCards {
            rank
            suit
          }
        }
      }
      currency {
        id
        shortName
        decimalDigits
        equivalentUsdRate
      }
    }
  }
}
    `;

/**
 * __useVideoPokerCurrentBetQuery__
 *
 * To run a query within a React component, call `useVideoPokerCurrentBetQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoPokerCurrentBetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoPokerCurrentBetQuery({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useVideoPokerCurrentBetQuery(baseOptions: Apollo.QueryHookOptions<VideoPokerCurrentBetQuery, VideoPokerCurrentBetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideoPokerCurrentBetQuery, VideoPokerCurrentBetQueryVariables>(VideoPokerCurrentBetDocument, options);
      }
export function useVideoPokerCurrentBetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideoPokerCurrentBetQuery, VideoPokerCurrentBetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideoPokerCurrentBetQuery, VideoPokerCurrentBetQueryVariables>(VideoPokerCurrentBetDocument, options);
        }
export type VideoPokerCurrentBetQueryHookResult = ReturnType<typeof useVideoPokerCurrentBetQuery>;
export type VideoPokerCurrentBetLazyQueryHookResult = ReturnType<typeof useVideoPokerCurrentBetLazyQuery>;
export type VideoPokerCurrentBetQueryResult = Apollo.QueryResult<VideoPokerCurrentBetQuery, VideoPokerCurrentBetQueryVariables>;
export const VideoPokerBetDocument = gql`
    mutation VideoPokerBet($betRoomId: ID!, $amount: Decimal!, $selectHands: [Int!]) {
  videoPokerBet(betRoomId: $betRoomId, amount: $amount, selectHands: $selectHands) {
    result
    bet {
      id
      betAmount
      betChargeAmount
      payoutRate
      payoutAmount
      equivalentUsdRate
      betData {
        ... on VideoPokerBetData {
          selectHands
          holdCards
          initialCards {
            rank
            suit
          }
        }
      }
      currency {
        id
        shortName
        decimalDigits
        equivalentUsdRate
      }
    }
    balance
  }
}
    `;
export type VideoPokerBetMutationFn = Apollo.MutationFunction<VideoPokerBetMutation, VideoPokerBetMutationVariables>;

/**
 * __useVideoPokerBetMutation__
 *
 * To run a mutation, you first call `useVideoPokerBetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoPokerBetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoPokerBetMutation, { data, loading, error }] = useVideoPokerBetMutation({
 *   variables: {
 *      betRoomId: // value for 'betRoomId'
 *      amount: // value for 'amount'
 *      selectHands: // value for 'selectHands'
 *   },
 * });
 */
export function useVideoPokerBetMutation(baseOptions?: Apollo.MutationHookOptions<VideoPokerBetMutation, VideoPokerBetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VideoPokerBetMutation, VideoPokerBetMutationVariables>(VideoPokerBetDocument, options);
      }
export type VideoPokerBetMutationHookResult = ReturnType<typeof useVideoPokerBetMutation>;
export type VideoPokerBetMutationResult = Apollo.MutationResult<VideoPokerBetMutation>;
export type VideoPokerBetMutationOptions = Apollo.BaseMutationOptions<VideoPokerBetMutation, VideoPokerBetMutationVariables>;
export const VideoPokerDealDocument = gql`
    mutation VideoPokerDeal($betRoomId: ID!, $holdCards: [Int!]) {
  videoPokerDeal(betRoomId: $betRoomId, holdCards: $holdCards) {
    result
    bet {
      id
      betAmount
      betChargeAmount
      payoutRate
      payoutAmount
      equivalentUsdRate
      currency {
        id
        shortName
        decimalDigits
        equivalentUsdRate
      }
      betData {
        ... on VideoPokerBetData {
          selectHands
          holdCards
          initialCards {
            rank
            suit
          }
          videoPokerResults {
            handIdx
            winType
            winRate
            newCards {
              rank
              suit
            }
          }
        }
      }
    }
    balance
  }
}
    `;
export type VideoPokerDealMutationFn = Apollo.MutationFunction<VideoPokerDealMutation, VideoPokerDealMutationVariables>;

/**
 * __useVideoPokerDealMutation__
 *
 * To run a mutation, you first call `useVideoPokerDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoPokerDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoPokerDealMutation, { data, loading, error }] = useVideoPokerDealMutation({
 *   variables: {
 *      betRoomId: // value for 'betRoomId'
 *      holdCards: // value for 'holdCards'
 *   },
 * });
 */
export function useVideoPokerDealMutation(baseOptions?: Apollo.MutationHookOptions<VideoPokerDealMutation, VideoPokerDealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VideoPokerDealMutation, VideoPokerDealMutationVariables>(VideoPokerDealDocument, options);
      }
export type VideoPokerDealMutationHookResult = ReturnType<typeof useVideoPokerDealMutation>;
export type VideoPokerDealMutationResult = Apollo.MutationResult<VideoPokerDealMutation>;
export type VideoPokerDealMutationOptions = Apollo.BaseMutationOptions<VideoPokerDealMutation, VideoPokerDealMutationVariables>;
export const UserInfoDocument = gql`
    query UserInfo {
  me {
    user {
      id
      nickname
      avatar {
        id @client
        uri
        square
        small
        medium
        large
      }
      vipLevel
    }
  }
}
    `;

/**
 * __useUserInfoQuery__
 *
 * To run a query within a React component, call `useUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, options);
      }
export function useUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, options);
        }
export type UserInfoQueryHookResult = ReturnType<typeof useUserInfoQuery>;
export type UserInfoLazyQueryHookResult = ReturnType<typeof useUserInfoLazyQuery>;
export type UserInfoQueryResult = Apollo.QueryResult<UserInfoQuery, UserInfoQueryVariables>;
export const UserProfileDocument = gql`
    query UserProfile {
  me {
    profile {
      id
      hasPassword
      email
      emailVerified
      hidden
    }
  }
}
    `;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
      }
export function useUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
        }
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>;
export type UserProfileQueryResult = Apollo.QueryResult<UserProfileQuery, UserProfileQueryVariables>;
export const UserWalletsDocument = gql`
    query UserWallets {
  me {
    userWallets {
      id
      currencyId
      amount
      lockedAmount
    }
  }
}
    `;

/**
 * __useUserWalletsQuery__
 *
 * To run a query within a React component, call `useUserWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWalletsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserWalletsQuery(baseOptions?: Apollo.QueryHookOptions<UserWalletsQuery, UserWalletsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserWalletsQuery, UserWalletsQueryVariables>(UserWalletsDocument, options);
      }
export function useUserWalletsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWalletsQuery, UserWalletsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserWalletsQuery, UserWalletsQueryVariables>(UserWalletsDocument, options);
        }
export type UserWalletsQueryHookResult = ReturnType<typeof useUserWalletsQuery>;
export type UserWalletsLazyQueryHookResult = ReturnType<typeof useUserWalletsLazyQuery>;
export type UserWalletsQueryResult = Apollo.QueryResult<UserWalletsQuery, UserWalletsQueryVariables>;
export const ActiveDevicesDocument = gql`
    query ActiveDevices {
  me {
    activeDevices {
      id
      device
      lastSeenIpAddress
      lastSeenAt
      current
    }
  }
}
    `;

/**
 * __useActiveDevicesQuery__
 *
 * To run a query within a React component, call `useActiveDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveDevicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveDevicesQuery(baseOptions?: Apollo.QueryHookOptions<ActiveDevicesQuery, ActiveDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveDevicesQuery, ActiveDevicesQueryVariables>(ActiveDevicesDocument, options);
      }
export function useActiveDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveDevicesQuery, ActiveDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveDevicesQuery, ActiveDevicesQueryVariables>(ActiveDevicesDocument, options);
        }
export type ActiveDevicesQueryHookResult = ReturnType<typeof useActiveDevicesQuery>;
export type ActiveDevicesLazyQueryHookResult = ReturnType<typeof useActiveDevicesLazyQuery>;
export type ActiveDevicesQueryResult = Apollo.QueryResult<ActiveDevicesQuery, ActiveDevicesQueryVariables>;
export const UserIdentificationDocument = gql`
    query UserIdentification {
  userIdentification {
    userId
    status
    identification {
      id
      firstName
      lastName
      gender
      dateOfBirth
      address
      city
      postalCode
      countryCode
    }
  }
}
    `;

/**
 * __useUserIdentificationQuery__
 *
 * To run a query within a React component, call `useUserIdentificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIdentificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIdentificationQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserIdentificationQuery(baseOptions?: Apollo.QueryHookOptions<UserIdentificationQuery, UserIdentificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserIdentificationQuery, UserIdentificationQueryVariables>(UserIdentificationDocument, options);
      }
export function useUserIdentificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserIdentificationQuery, UserIdentificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserIdentificationQuery, UserIdentificationQueryVariables>(UserIdentificationDocument, options);
        }
export type UserIdentificationQueryHookResult = ReturnType<typeof useUserIdentificationQuery>;
export type UserIdentificationLazyQueryHookResult = ReturnType<typeof useUserIdentificationLazyQuery>;
export type UserIdentificationQueryResult = Apollo.QueryResult<UserIdentificationQuery, UserIdentificationQueryVariables>;
export const LoyalUserDocument = gql`
    query LoyalUser {
  me {
    vipUser {
      id
      vipPoint
      vipLevel
      betAmountUsd
      vipUserCurrencies {
        id
        instantBonusAvailableAmount
        lastWeekBonusAmount
        lastMonthBonusAmount
        currency {
          id
          name
          shortName
          type
          decimalDigits
          equivalentUsdRate
          blockchainNetworks
        }
      }
      currentLevelPoint
      nextLevelPoint
    }
    withdrawalRequiredBetAmountUsd
  }
}
    `;

/**
 * __useLoyalUserQuery__
 *
 * To run a query within a React component, call `useLoyalUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyalUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyalUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoyalUserQuery(baseOptions?: Apollo.QueryHookOptions<LoyalUserQuery, LoyalUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoyalUserQuery, LoyalUserQueryVariables>(LoyalUserDocument, options);
      }
export function useLoyalUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoyalUserQuery, LoyalUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoyalUserQuery, LoyalUserQueryVariables>(LoyalUserDocument, options);
        }
export type LoyalUserQueryHookResult = ReturnType<typeof useLoyalUserQuery>;
export type LoyalUserLazyQueryHookResult = ReturnType<typeof useLoyalUserLazyQuery>;
export type LoyalUserQueryResult = Apollo.QueryResult<LoyalUserQuery, LoyalUserQueryVariables>;
export const UserChatsDocument = gql`
    query UserChats($channel: String!) {
  chats(channel: $channel) {
    id
    message
    createdAt
    sender {
      userId
      nickname
      avatar {
        uri
        square
      }
      vipLevel
    }
  }
}
    `;

/**
 * __useUserChatsQuery__
 *
 * To run a query within a React component, call `useUserChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserChatsQuery({
 *   variables: {
 *      channel: // value for 'channel'
 *   },
 * });
 */
export function useUserChatsQuery(baseOptions: Apollo.QueryHookOptions<UserChatsQuery, UserChatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserChatsQuery, UserChatsQueryVariables>(UserChatsDocument, options);
      }
export function useUserChatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserChatsQuery, UserChatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserChatsQuery, UserChatsQueryVariables>(UserChatsDocument, options);
        }
export type UserChatsQueryHookResult = ReturnType<typeof useUserChatsQuery>;
export type UserChatsLazyQueryHookResult = ReturnType<typeof useUserChatsLazyQuery>;
export type UserChatsQueryResult = Apollo.QueryResult<UserChatsQuery, UserChatsQueryVariables>;
export const SystemNotificationsDocument = gql`
    query SystemNotifications($page: Int!, $pageSize: Int!) {
  systemNotifications(page: $page, pageSize: $pageSize) {
    hasMore
    data {
      id
      title
      content
      publishedAt
      actionText
      actionUrl
      thumbnail {
        uri
        square
        small
        medium
        large
      }
    }
  }
}
    `;

/**
 * __useSystemNotificationsQuery__
 *
 * To run a query within a React component, call `useSystemNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemNotificationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useSystemNotificationsQuery(baseOptions: Apollo.QueryHookOptions<SystemNotificationsQuery, SystemNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SystemNotificationsQuery, SystemNotificationsQueryVariables>(SystemNotificationsDocument, options);
      }
export function useSystemNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemNotificationsQuery, SystemNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SystemNotificationsQuery, SystemNotificationsQueryVariables>(SystemNotificationsDocument, options);
        }
export type SystemNotificationsQueryHookResult = ReturnType<typeof useSystemNotificationsQuery>;
export type SystemNotificationsLazyQueryHookResult = ReturnType<typeof useSystemNotificationsLazyQuery>;
export type SystemNotificationsQueryResult = Apollo.QueryResult<SystemNotificationsQuery, SystemNotificationsQueryVariables>;
export const PersonalNotificationsDocument = gql`
    query PersonalNotifications($page: Int!, $pageSize: Int!) {
  personalNotifications(page: $page, pageSize: $pageSize) {
    hasMore
    data {
      id
      title
      content
      publishedAt
      actionText
      actionUrl
      thumbnail {
        uri
        square
        small
        medium
        large
      }
    }
  }
}
    `;

/**
 * __usePersonalNotificationsQuery__
 *
 * To run a query within a React component, call `usePersonalNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalNotificationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function usePersonalNotificationsQuery(baseOptions: Apollo.QueryHookOptions<PersonalNotificationsQuery, PersonalNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PersonalNotificationsQuery, PersonalNotificationsQueryVariables>(PersonalNotificationsDocument, options);
      }
export function usePersonalNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PersonalNotificationsQuery, PersonalNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PersonalNotificationsQuery, PersonalNotificationsQueryVariables>(PersonalNotificationsDocument, options);
        }
export type PersonalNotificationsQueryHookResult = ReturnType<typeof usePersonalNotificationsQuery>;
export type PersonalNotificationsLazyQueryHookResult = ReturnType<typeof usePersonalNotificationsLazyQuery>;
export type PersonalNotificationsQueryResult = Apollo.QueryResult<PersonalNotificationsQuery, PersonalNotificationsQueryVariables>;
export const UnreadNotificationDocument = gql`
    query UnreadNotification {
  unreadNotification {
    system
    personal
  }
}
    `;

/**
 * __useUnreadNotificationQuery__
 *
 * To run a query within a React component, call `useUnreadNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnreadNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadNotificationQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnreadNotificationQuery(baseOptions?: Apollo.QueryHookOptions<UnreadNotificationQuery, UnreadNotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnreadNotificationQuery, UnreadNotificationQueryVariables>(UnreadNotificationDocument, options);
      }
export function useUnreadNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnreadNotificationQuery, UnreadNotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnreadNotificationQuery, UnreadNotificationQueryVariables>(UnreadNotificationDocument, options);
        }
export type UnreadNotificationQueryHookResult = ReturnType<typeof useUnreadNotificationQuery>;
export type UnreadNotificationLazyQueryHookResult = ReturnType<typeof useUnreadNotificationLazyQuery>;
export type UnreadNotificationQueryResult = Apollo.QueryResult<UnreadNotificationQuery, UnreadNotificationQueryVariables>;
export const ReadAllSystemNotificationsDocument = gql`
    mutation ReadAllSystemNotifications($lastId: ID!) {
  readAllSystemNotifications(lastId: $lastId) {
    success
    content {
      system
      personal
    }
    error {
      code
      message
    }
  }
}
    `;
export type ReadAllSystemNotificationsMutationFn = Apollo.MutationFunction<ReadAllSystemNotificationsMutation, ReadAllSystemNotificationsMutationVariables>;

/**
 * __useReadAllSystemNotificationsMutation__
 *
 * To run a mutation, you first call `useReadAllSystemNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadAllSystemNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readAllSystemNotificationsMutation, { data, loading, error }] = useReadAllSystemNotificationsMutation({
 *   variables: {
 *      lastId: // value for 'lastId'
 *   },
 * });
 */
export function useReadAllSystemNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<ReadAllSystemNotificationsMutation, ReadAllSystemNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadAllSystemNotificationsMutation, ReadAllSystemNotificationsMutationVariables>(ReadAllSystemNotificationsDocument, options);
      }
export type ReadAllSystemNotificationsMutationHookResult = ReturnType<typeof useReadAllSystemNotificationsMutation>;
export type ReadAllSystemNotificationsMutationResult = Apollo.MutationResult<ReadAllSystemNotificationsMutation>;
export type ReadAllSystemNotificationsMutationOptions = Apollo.BaseMutationOptions<ReadAllSystemNotificationsMutation, ReadAllSystemNotificationsMutationVariables>;
export const ReadAllPersonalNotificationsDocument = gql`
    mutation ReadAllPersonalNotifications {
  readAllPersonalNotifications {
    success
    error {
      code
      message
    }
    content {
      system
      personal
    }
  }
}
    `;
export type ReadAllPersonalNotificationsMutationFn = Apollo.MutationFunction<ReadAllPersonalNotificationsMutation, ReadAllPersonalNotificationsMutationVariables>;

/**
 * __useReadAllPersonalNotificationsMutation__
 *
 * To run a mutation, you first call `useReadAllPersonalNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadAllPersonalNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readAllPersonalNotificationsMutation, { data, loading, error }] = useReadAllPersonalNotificationsMutation({
 *   variables: {
 *   },
 * });
 */
export function useReadAllPersonalNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<ReadAllPersonalNotificationsMutation, ReadAllPersonalNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadAllPersonalNotificationsMutation, ReadAllPersonalNotificationsMutationVariables>(ReadAllPersonalNotificationsDocument, options);
      }
export type ReadAllPersonalNotificationsMutationHookResult = ReturnType<typeof useReadAllPersonalNotificationsMutation>;
export type ReadAllPersonalNotificationsMutationResult = Apollo.MutationResult<ReadAllPersonalNotificationsMutation>;
export type ReadAllPersonalNotificationsMutationOptions = Apollo.BaseMutationOptions<ReadAllPersonalNotificationsMutation, ReadAllPersonalNotificationsMutationVariables>;
export const MyDefaultReferralCodeDocument = gql`
    query MyDefaultReferralCode {
  me {
    defaultReferralCode {
      id
      url
    }
  }
}
    `;

/**
 * __useMyDefaultReferralCodeQuery__
 *
 * To run a query within a React component, call `useMyDefaultReferralCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyDefaultReferralCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyDefaultReferralCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyDefaultReferralCodeQuery(baseOptions?: Apollo.QueryHookOptions<MyDefaultReferralCodeQuery, MyDefaultReferralCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyDefaultReferralCodeQuery, MyDefaultReferralCodeQueryVariables>(MyDefaultReferralCodeDocument, options);
      }
export function useMyDefaultReferralCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyDefaultReferralCodeQuery, MyDefaultReferralCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyDefaultReferralCodeQuery, MyDefaultReferralCodeQueryVariables>(MyDefaultReferralCodeDocument, options);
        }
export type MyDefaultReferralCodeQueryHookResult = ReturnType<typeof useMyDefaultReferralCodeQuery>;
export type MyDefaultReferralCodeLazyQueryHookResult = ReturnType<typeof useMyDefaultReferralCodeLazyQuery>;
export type MyDefaultReferralCodeQueryResult = Apollo.QueryResult<MyDefaultReferralCodeQuery, MyDefaultReferralCodeQueryVariables>;
export const MyReferralCodesDocument = gql`
    query MyReferralCodes {
  myReferralCodes {
    id
    name
    code
    createdAt
    totalCommissions {
      currency {
        id
        decimalDigits
        shortName
        equivalentUsdRate
      }
      totalAmount
      availableAmount
    }
    totalUsers
    totalDeposits
    totalDepositUsers
    url
  }
}
    `;

/**
 * __useMyReferralCodesQuery__
 *
 * To run a query within a React component, call `useMyReferralCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyReferralCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyReferralCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyReferralCodesQuery(baseOptions?: Apollo.QueryHookOptions<MyReferralCodesQuery, MyReferralCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyReferralCodesQuery, MyReferralCodesQueryVariables>(MyReferralCodesDocument, options);
      }
export function useMyReferralCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyReferralCodesQuery, MyReferralCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyReferralCodesQuery, MyReferralCodesQueryVariables>(MyReferralCodesDocument, options);
        }
export type MyReferralCodesQueryHookResult = ReturnType<typeof useMyReferralCodesQuery>;
export type MyReferralCodesLazyQueryHookResult = ReturnType<typeof useMyReferralCodesLazyQuery>;
export type MyReferralCodesQueryResult = Apollo.QueryResult<MyReferralCodesQuery, MyReferralCodesQueryVariables>;
export const MyReferralUsersDocument = gql`
    query MyReferralUsers($page: Int!, $pageSize: Int!, $nickname: String, $userId: ID, $fromDate: Date, $toDate: Date, $referralCodeId: ID) {
  myReferralUsers(
    page: $page
    pageSize: $pageSize
    nickname: $nickname
    userId: $userId
    toDate: $toDate
    fromDate: $fromDate
    referralCodeId: $referralCodeId
  ) {
    hasMore
    data {
      id
      createdAt
      nickname
      totalDeposits
      lastDeposit
      wagerEquivalentUsd(fromDate: $fromDate, toDate: $toDate)
      commissions(fromDate: $fromDate, toDate: $toDate) {
        currency {
          id
          shortName
          decimalDigits
          equivalentUsdRate
        }
        totalAmount
        availableAmount
      }
      referralCode {
        name
        totalCommissions {
          availableAmount
          totalAmount
          currency {
            id
            shortName
            decimalDigits
            equivalentUsdRate
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMyReferralUsersQuery__
 *
 * To run a query within a React component, call `useMyReferralUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyReferralUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyReferralUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      nickname: // value for 'nickname'
 *      userId: // value for 'userId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      referralCodeId: // value for 'referralCodeId'
 *   },
 * });
 */
export function useMyReferralUsersQuery(baseOptions: Apollo.QueryHookOptions<MyReferralUsersQuery, MyReferralUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyReferralUsersQuery, MyReferralUsersQueryVariables>(MyReferralUsersDocument, options);
      }
export function useMyReferralUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyReferralUsersQuery, MyReferralUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyReferralUsersQuery, MyReferralUsersQueryVariables>(MyReferralUsersDocument, options);
        }
export type MyReferralUsersQueryHookResult = ReturnType<typeof useMyReferralUsersQuery>;
export type MyReferralUsersLazyQueryHookResult = ReturnType<typeof useMyReferralUsersLazyQuery>;
export type MyReferralUsersQueryResult = Apollo.QueryResult<MyReferralUsersQuery, MyReferralUsersQueryVariables>;
export const MyCommissionsDocument = gql`
    query MyCommissions {
  myCommissions {
    availableAmount
    totalAmount
    currency {
      id
      shortName
      decimalDigits
      equivalentUsdRate
    }
  }
}
    `;

/**
 * __useMyCommissionsQuery__
 *
 * To run a query within a React component, call `useMyCommissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCommissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCommissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCommissionsQuery(baseOptions?: Apollo.QueryHookOptions<MyCommissionsQuery, MyCommissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyCommissionsQuery, MyCommissionsQueryVariables>(MyCommissionsDocument, options);
      }
export function useMyCommissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyCommissionsQuery, MyCommissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyCommissionsQuery, MyCommissionsQueryVariables>(MyCommissionsDocument, options);
        }
export type MyCommissionsQueryHookResult = ReturnType<typeof useMyCommissionsQuery>;
export type MyCommissionsLazyQueryHookResult = ReturnType<typeof useMyCommissionsLazyQuery>;
export type MyCommissionsQueryResult = Apollo.QueryResult<MyCommissionsQuery, MyCommissionsQueryVariables>;
export const MyAgencyInfoDocument = gql`
    query MyAgencyInfo {
  myAgency {
    agency {
      id
      status
      userId
      nickname
      maxCommissionRate
      house {
        id
        name
        slug
      }
    }
  }
}
    `;

/**
 * __useMyAgencyInfoQuery__
 *
 * To run a query within a React component, call `useMyAgencyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAgencyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAgencyInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyAgencyInfoQuery(baseOptions?: Apollo.QueryHookOptions<MyAgencyInfoQuery, MyAgencyInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyAgencyInfoQuery, MyAgencyInfoQueryVariables>(MyAgencyInfoDocument, options);
      }
export function useMyAgencyInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyAgencyInfoQuery, MyAgencyInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyAgencyInfoQuery, MyAgencyInfoQueryVariables>(MyAgencyInfoDocument, options);
        }
export type MyAgencyInfoQueryHookResult = ReturnType<typeof useMyAgencyInfoQuery>;
export type MyAgencyInfoLazyQueryHookResult = ReturnType<typeof useMyAgencyInfoLazyQuery>;
export type MyAgencyInfoQueryResult = Apollo.QueryResult<MyAgencyInfoQuery, MyAgencyInfoQueryVariables>;
export const MyAgencyMembersDocument = gql`
    query MyAgencyMembers($page: Int!, $pageSize: Int!, $userId: ID, $nickname: String, $onlyAgency: Boolean, $fromDate: Date, $toDate: Date) {
  myAgency {
    children(
      page: $page
      pageSize: $pageSize
      userId: $userId
      nickname: $nickname
      onlyAgency: $onlyAgency
    ) {
      hasMore
      data {
        id
        nickname
        isAgency
        maxCommissionRate
        memberCount
        playerCount(fromDate: $fromDate, toDate: $toDate)
        wagerEquivalentUsd(fromDate: $fromDate, toDate: $toDate)
        commissions(fromDate: $fromDate, toDate: $toDate) {
          currency {
            id
            shortName
            equivalentUsdRate
            decimalDigits
          }
          totalAmount
          availableAmount
        }
      }
    }
  }
}
    `;

/**
 * __useMyAgencyMembersQuery__
 *
 * To run a query within a React component, call `useMyAgencyMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAgencyMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAgencyMembersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      userId: // value for 'userId'
 *      nickname: // value for 'nickname'
 *      onlyAgency: // value for 'onlyAgency'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useMyAgencyMembersQuery(baseOptions: Apollo.QueryHookOptions<MyAgencyMembersQuery, MyAgencyMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyAgencyMembersQuery, MyAgencyMembersQueryVariables>(MyAgencyMembersDocument, options);
      }
export function useMyAgencyMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyAgencyMembersQuery, MyAgencyMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyAgencyMembersQuery, MyAgencyMembersQueryVariables>(MyAgencyMembersDocument, options);
        }
export type MyAgencyMembersQueryHookResult = ReturnType<typeof useMyAgencyMembersQuery>;
export type MyAgencyMembersLazyQueryHookResult = ReturnType<typeof useMyAgencyMembersLazyQuery>;
export type MyAgencyMembersQueryResult = Apollo.QueryResult<MyAgencyMembersQuery, MyAgencyMembersQueryVariables>;
export const InternalTransferSettingsDocument = gql`
    query InternalTransferSettings {
  internalTransferSettings {
    transferFeeAmount
    transferFeeRate
    currency {
      id
      name
      shortName
      type
      decimalDigits
      equivalentUsdRate
      blockchainNetworks
    }
    transferMinimumAmount
  }
}
    `;

/**
 * __useInternalTransferSettingsQuery__
 *
 * To run a query within a React component, call `useInternalTransferSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalTransferSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalTransferSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInternalTransferSettingsQuery(baseOptions?: Apollo.QueryHookOptions<InternalTransferSettingsQuery, InternalTransferSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InternalTransferSettingsQuery, InternalTransferSettingsQueryVariables>(InternalTransferSettingsDocument, options);
      }
export function useInternalTransferSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InternalTransferSettingsQuery, InternalTransferSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InternalTransferSettingsQuery, InternalTransferSettingsQueryVariables>(InternalTransferSettingsDocument, options);
        }
export type InternalTransferSettingsQueryHookResult = ReturnType<typeof useInternalTransferSettingsQuery>;
export type InternalTransferSettingsLazyQueryHookResult = ReturnType<typeof useInternalTransferSettingsLazyQuery>;
export type InternalTransferSettingsQueryResult = Apollo.QueryResult<InternalTransferSettingsQuery, InternalTransferSettingsQueryVariables>;
export const InternalTransfersDocument = gql`
    query InternalTransfers($page: Int!, $pageSize: Int!, $currencyId: ID) {
  internalTransfers(page: $page, pageSize: $pageSize, currencyId: $currencyId) {
    hasMore
    data {
      id
      senderId
      senderNickname
      receiverId
      receiverNickname
      amount
      message
      equivalentUsdRate
      status
      fee
      createdAt
      currency {
        id
        name
        shortName
        type
        decimalDigits
        equivalentUsdRate
        blockchainNetworks
      }
      senderAvatar {
        uri
        square
        small
        medium
        large
      }
      receiverAvatar {
        uri
        square
        small
        medium
        large
      }
    }
  }
}
    `;

/**
 * __useInternalTransfersQuery__
 *
 * To run a query within a React component, call `useInternalTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalTransfersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      currencyId: // value for 'currencyId'
 *   },
 * });
 */
export function useInternalTransfersQuery(baseOptions: Apollo.QueryHookOptions<InternalTransfersQuery, InternalTransfersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InternalTransfersQuery, InternalTransfersQueryVariables>(InternalTransfersDocument, options);
      }
export function useInternalTransfersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InternalTransfersQuery, InternalTransfersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InternalTransfersQuery, InternalTransfersQueryVariables>(InternalTransfersDocument, options);
        }
export type InternalTransfersQueryHookResult = ReturnType<typeof useInternalTransfersQuery>;
export type InternalTransfersLazyQueryHookResult = ReturnType<typeof useInternalTransfersLazyQuery>;
export type InternalTransfersQueryResult = Apollo.QueryResult<InternalTransfersQuery, InternalTransfersQueryVariables>;
export const CurrenciesDocument = gql`
    query Currencies {
  currencies {
    id
    name
    shortName
    type
    decimalDigits
    equivalentUsdRate
    blockchainNetworks
  }
}
    `;

/**
 * __useCurrenciesQuery__
 *
 * To run a query within a React component, call `useCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrenciesQuery, CurrenciesQueryVariables>(CurrenciesDocument, options);
      }
export function useCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrenciesQuery, CurrenciesQueryVariables>(CurrenciesDocument, options);
        }
export type CurrenciesQueryHookResult = ReturnType<typeof useCurrenciesQuery>;
export type CurrenciesLazyQueryHookResult = ReturnType<typeof useCurrenciesLazyQuery>;
export type CurrenciesQueryResult = Apollo.QueryResult<CurrenciesQuery, CurrenciesQueryVariables>;
export const FiatsDocument = gql`
    query Fiats {
  transfiSupportedFiats {
    symbol
    country
    minAmount
    maxAmount
  }
}
    `;

/**
 * __useFiatsQuery__
 *
 * To run a query within a React component, call `useFiatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFiatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFiatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFiatsQuery(baseOptions?: Apollo.QueryHookOptions<FiatsQuery, FiatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FiatsQuery, FiatsQueryVariables>(FiatsDocument, options);
      }
export function useFiatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FiatsQuery, FiatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FiatsQuery, FiatsQueryVariables>(FiatsDocument, options);
        }
export type FiatsQueryHookResult = ReturnType<typeof useFiatsQuery>;
export type FiatsLazyQueryHookResult = ReturnType<typeof useFiatsLazyQuery>;
export type FiatsQueryResult = Apollo.QueryResult<FiatsQuery, FiatsQueryVariables>;
export const CryptoDepositAddressDocument = gql`
    query CryptoDepositAddress($currencyId: ID!, $network: BlockchainNetwork!) {
  cryptoDepositAddress(currencyId: $currencyId, network: $network) {
    address
  }
}
    `;

/**
 * __useCryptoDepositAddressQuery__
 *
 * To run a query within a React component, call `useCryptoDepositAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useCryptoDepositAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCryptoDepositAddressQuery({
 *   variables: {
 *      currencyId: // value for 'currencyId'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useCryptoDepositAddressQuery(baseOptions: Apollo.QueryHookOptions<CryptoDepositAddressQuery, CryptoDepositAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CryptoDepositAddressQuery, CryptoDepositAddressQueryVariables>(CryptoDepositAddressDocument, options);
      }
export function useCryptoDepositAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CryptoDepositAddressQuery, CryptoDepositAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CryptoDepositAddressQuery, CryptoDepositAddressQueryVariables>(CryptoDepositAddressDocument, options);
        }
export type CryptoDepositAddressQueryHookResult = ReturnType<typeof useCryptoDepositAddressQuery>;
export type CryptoDepositAddressLazyQueryHookResult = ReturnType<typeof useCryptoDepositAddressLazyQuery>;
export type CryptoDepositAddressQueryResult = Apollo.QueryResult<CryptoDepositAddressQuery, CryptoDepositAddressQueryVariables>;
export const CryptoWithdrawalInfoDocument = gql`
    query CryptoWithdrawalInfo($currencyId: ID!, $network: BlockchainNetwork!) {
  cryptoWithdrawalInfo(currencyId: $currencyId, network: $network) {
    id
    feeAmount
    feeRate
    minimumAmount
  }
}
    `;

/**
 * __useCryptoWithdrawalInfoQuery__
 *
 * To run a query within a React component, call `useCryptoWithdrawalInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCryptoWithdrawalInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCryptoWithdrawalInfoQuery({
 *   variables: {
 *      currencyId: // value for 'currencyId'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useCryptoWithdrawalInfoQuery(baseOptions: Apollo.QueryHookOptions<CryptoWithdrawalInfoQuery, CryptoWithdrawalInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CryptoWithdrawalInfoQuery, CryptoWithdrawalInfoQueryVariables>(CryptoWithdrawalInfoDocument, options);
      }
export function useCryptoWithdrawalInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CryptoWithdrawalInfoQuery, CryptoWithdrawalInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CryptoWithdrawalInfoQuery, CryptoWithdrawalInfoQueryVariables>(CryptoWithdrawalInfoDocument, options);
        }
export type CryptoWithdrawalInfoQueryHookResult = ReturnType<typeof useCryptoWithdrawalInfoQuery>;
export type CryptoWithdrawalInfoLazyQueryHookResult = ReturnType<typeof useCryptoWithdrawalInfoLazyQuery>;
export type CryptoWithdrawalInfoQueryResult = Apollo.QueryResult<CryptoWithdrawalInfoQuery, CryptoWithdrawalInfoQueryVariables>;
export const DepositTransactionsDocument = gql`
    query DepositTransactions($page: Int!, $pageSize: Int!, $currencyId: ID) {
  depositTransactions(page: $page, pageSize: $pageSize, currencyId: $currencyId) {
    hasMore
    data {
      id
      amount
      equivalentUsdRate
      status
      createdAt
      currency {
        name
        shortName
        decimalDigits
        equivalentUsdRate
      }
      tx
    }
  }
}
    `;

/**
 * __useDepositTransactionsQuery__
 *
 * To run a query within a React component, call `useDepositTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepositTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositTransactionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      currencyId: // value for 'currencyId'
 *   },
 * });
 */
export function useDepositTransactionsQuery(baseOptions: Apollo.QueryHookOptions<DepositTransactionsQuery, DepositTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepositTransactionsQuery, DepositTransactionsQueryVariables>(DepositTransactionsDocument, options);
      }
export function useDepositTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepositTransactionsQuery, DepositTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepositTransactionsQuery, DepositTransactionsQueryVariables>(DepositTransactionsDocument, options);
        }
export type DepositTransactionsQueryHookResult = ReturnType<typeof useDepositTransactionsQuery>;
export type DepositTransactionsLazyQueryHookResult = ReturnType<typeof useDepositTransactionsLazyQuery>;
export type DepositTransactionsQueryResult = Apollo.QueryResult<DepositTransactionsQuery, DepositTransactionsQueryVariables>;
export const WithdrawalTransactionsDocument = gql`
    query WithdrawalTransactions($page: Int!, $pageSize: Int!, $currencyId: ID) {
  withdrawalTransactions(
    page: $page
    pageSize: $pageSize
    currencyId: $currencyId
  ) {
    hasMore
    data {
      id
      amount
      status
      createdAt
      equivalentUsdRate
      currency {
        name
        shortName
        decimalDigits
        equivalentUsdRate
      }
      tx
    }
  }
}
    `;

/**
 * __useWithdrawalTransactionsQuery__
 *
 * To run a query within a React component, call `useWithdrawalTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWithdrawalTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWithdrawalTransactionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      currencyId: // value for 'currencyId'
 *   },
 * });
 */
export function useWithdrawalTransactionsQuery(baseOptions: Apollo.QueryHookOptions<WithdrawalTransactionsQuery, WithdrawalTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WithdrawalTransactionsQuery, WithdrawalTransactionsQueryVariables>(WithdrawalTransactionsDocument, options);
      }
export function useWithdrawalTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WithdrawalTransactionsQuery, WithdrawalTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WithdrawalTransactionsQuery, WithdrawalTransactionsQueryVariables>(WithdrawalTransactionsDocument, options);
        }
export type WithdrawalTransactionsQueryHookResult = ReturnType<typeof useWithdrawalTransactionsQuery>;
export type WithdrawalTransactionsLazyQueryHookResult = ReturnType<typeof useWithdrawalTransactionsLazyQuery>;
export type WithdrawalTransactionsQueryResult = Apollo.QueryResult<WithdrawalTransactionsQuery, WithdrawalTransactionsQueryVariables>;
export const TransactionsDocument = gql`
    query Transactions($page: Int!, $pageSize: Int!, $currencyId: ID, $group: TransactionGroup) {
  transactions(
    page: $page
    pageSize: $pageSize
    currencyId: $currencyId
    group: $group
  ) {
    hasMore
    data {
      id
      group
      label
      amount
      remainAmount
      equivalentUsdRate
      createdAt
      currency {
        id
        name
        shortName
        decimalDigits
        equivalentUsdRate
        blockchainNetworks
      }
    }
  }
}
    `;

/**
 * __useTransactionsQuery__
 *
 * To run a query within a React component, call `useTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      currencyId: // value for 'currencyId'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useTransactionsQuery(baseOptions: Apollo.QueryHookOptions<TransactionsQuery, TransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionsQuery, TransactionsQueryVariables>(TransactionsDocument, options);
      }
export function useTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionsQuery, TransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionsQuery, TransactionsQueryVariables>(TransactionsDocument, options);
        }
export type TransactionsQueryHookResult = ReturnType<typeof useTransactionsQuery>;
export type TransactionsLazyQueryHookResult = ReturnType<typeof useTransactionsLazyQuery>;
export type TransactionsQueryResult = Apollo.QueryResult<TransactionsQuery, TransactionsQueryVariables>;
export const GameBankInvestTransactionsDocument = gql`
    query GameBankInvestTransactions($page: Int!, $pageSize: Int!, $gameBankId: ID!) {
  investTransactions(gameBankId: $gameBankId, page: $page, pageSize: $pageSize) {
    hasMore
    data {
      id
      isHouse
      type
      amount
      fee
      createdAt
      user {
        id
        nickname
        vipLevel
      }
    }
  }
}
    `;

/**
 * __useGameBankInvestTransactionsQuery__
 *
 * To run a query within a React component, call `useGameBankInvestTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameBankInvestTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameBankInvestTransactionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      gameBankId: // value for 'gameBankId'
 *   },
 * });
 */
export function useGameBankInvestTransactionsQuery(baseOptions: Apollo.QueryHookOptions<GameBankInvestTransactionsQuery, GameBankInvestTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GameBankInvestTransactionsQuery, GameBankInvestTransactionsQueryVariables>(GameBankInvestTransactionsDocument, options);
      }
export function useGameBankInvestTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GameBankInvestTransactionsQuery, GameBankInvestTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GameBankInvestTransactionsQuery, GameBankInvestTransactionsQueryVariables>(GameBankInvestTransactionsDocument, options);
        }
export type GameBankInvestTransactionsQueryHookResult = ReturnType<typeof useGameBankInvestTransactionsQuery>;
export type GameBankInvestTransactionsLazyQueryHookResult = ReturnType<typeof useGameBankInvestTransactionsLazyQuery>;
export type GameBankInvestTransactionsQueryResult = Apollo.QueryResult<GameBankInvestTransactionsQuery, GameBankInvestTransactionsQueryVariables>;
export const GameBankSharesDocument = gql`
    query GameBankShares($page: Int!, $pageSize: Int!, $gameBankId: ID!) {
  gameBankInvestors(gameBankId: $gameBankId, page: $page, pageSize: $pageSize) {
    hasMore
    data {
      id
      investAmount
      shareRate
      isHouse
      createdAt
      user {
        nickname
      }
    }
  }
}
    `;

/**
 * __useGameBankSharesQuery__
 *
 * To run a query within a React component, call `useGameBankSharesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameBankSharesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameBankSharesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      gameBankId: // value for 'gameBankId'
 *   },
 * });
 */
export function useGameBankSharesQuery(baseOptions: Apollo.QueryHookOptions<GameBankSharesQuery, GameBankSharesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GameBankSharesQuery, GameBankSharesQueryVariables>(GameBankSharesDocument, options);
      }
export function useGameBankSharesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GameBankSharesQuery, GameBankSharesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GameBankSharesQuery, GameBankSharesQueryVariables>(GameBankSharesDocument, options);
        }
export type GameBankSharesQueryHookResult = ReturnType<typeof useGameBankSharesQuery>;
export type GameBankSharesLazyQueryHookResult = ReturnType<typeof useGameBankSharesLazyQuery>;
export type GameBankSharesQueryResult = Apollo.QueryResult<GameBankSharesQuery, GameBankSharesQueryVariables>;
export const SwapPairsDocument = gql`
    query SwapPairs {
  swapPairs {
    id
    from {
      id
      name
      shortName
      decimalDigits
      equivalentUsdRate
    }
    to {
      id
      name
      shortName
      decimalDigits
      equivalentUsdRate
    }
    minimumAmount
    maximumAmount
    feeRate
    feeAmount
    rate
  }
}
    `;

/**
 * __useSwapPairsQuery__
 *
 * To run a query within a React component, call `useSwapPairsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSwapPairsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSwapPairsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSwapPairsQuery(baseOptions?: Apollo.QueryHookOptions<SwapPairsQuery, SwapPairsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SwapPairsQuery, SwapPairsQueryVariables>(SwapPairsDocument, options);
      }
export function useSwapPairsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SwapPairsQuery, SwapPairsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SwapPairsQuery, SwapPairsQueryVariables>(SwapPairsDocument, options);
        }
export type SwapPairsQueryHookResult = ReturnType<typeof useSwapPairsQuery>;
export type SwapPairsLazyQueryHookResult = ReturnType<typeof useSwapPairsLazyQuery>;
export type SwapPairsQueryResult = Apollo.QueryResult<SwapPairsQuery, SwapPairsQueryVariables>;
export const RecentBetDocument = gql`
    subscription RecentBet($gameId: ID) {
  recentBet(gameId: $gameId) {
    id
    betAmount
    payoutRate
    payoutAmount
    equivalentUsdRate
    currency {
      id
      shortName
      decimalDigits
      equivalentUsdRate
    }
    game {
      id
      name
    }
    user {
      id
      avatar {
        square
      }
      nickname
    }
  }
}
    `;

/**
 * __useRecentBetSubscription__
 *
 * To run a query within a React component, call `useRecentBetSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRecentBetSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentBetSubscription({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useRecentBetSubscription(baseOptions?: Apollo.SubscriptionHookOptions<RecentBetSubscription, RecentBetSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RecentBetSubscription, RecentBetSubscriptionVariables>(RecentBetDocument, options);
      }
export type RecentBetSubscriptionHookResult = ReturnType<typeof useRecentBetSubscription>;
export type RecentBetSubscriptionResult = Apollo.SubscriptionResult<RecentBetSubscription>;
export const HighBetDocument = gql`
    subscription HighBet($gameId: ID) {
  highBet(gameId: $gameId) {
    id
    equivalentUsdRate
    betAmount
    payoutRate
    payoutAmount
    game {
      id
      name
      gameBase {
        thumbnail {
          square
        }
      }
    }
    currency {
      id
      shortName
      decimalDigits
      equivalentUsdRate
    }
    user {
      id
      avatar {
        square
      }
      nickname
    }
  }
}
    `;

/**
 * __useHighBetSubscription__
 *
 * To run a query within a React component, call `useHighBetSubscription` and pass it any options that fit your needs.
 * When your component renders, `useHighBetSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHighBetSubscription({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useHighBetSubscription(baseOptions?: Apollo.SubscriptionHookOptions<HighBetSubscription, HighBetSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<HighBetSubscription, HighBetSubscriptionVariables>(HighBetDocument, options);
      }
export type HighBetSubscriptionHookResult = ReturnType<typeof useHighBetSubscription>;
export type HighBetSubscriptionResult = Apollo.SubscriptionResult<HighBetSubscription>;
export const ChatDocument = gql`
    subscription Chat($channel: String!) {
  chat(channel: $channel) {
    id
    message
    createdAt
    sender {
      userId
      nickname
      avatar {
        uri
        square
      }
      vipLevel
    }
  }
}
    `;

/**
 * __useChatSubscription__
 *
 * To run a query within a React component, call `useChatSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatSubscription({
 *   variables: {
 *      channel: // value for 'channel'
 *   },
 * });
 */
export function useChatSubscription(baseOptions: Apollo.SubscriptionHookOptions<ChatSubscription, ChatSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ChatSubscription, ChatSubscriptionVariables>(ChatDocument, options);
      }
export type ChatSubscriptionHookResult = ReturnType<typeof useChatSubscription>;
export type ChatSubscriptionResult = Apollo.SubscriptionResult<ChatSubscription>;
export const WalletChangedDocument = gql`
    subscription WalletChanged {
  userWalletChanged {
    id
    currencyId
    amount
    lockedAmount
    currency {
      id
      shortName
      decimalDigits
    }
  }
}
    `;

/**
 * __useWalletChangedSubscription__
 *
 * To run a query within a React component, call `useWalletChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWalletChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useWalletChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<WalletChangedSubscription, WalletChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<WalletChangedSubscription, WalletChangedSubscriptionVariables>(WalletChangedDocument, options);
      }
export type WalletChangedSubscriptionHookResult = ReturnType<typeof useWalletChangedSubscription>;
export type WalletChangedSubscriptionResult = Apollo.SubscriptionResult<WalletChangedSubscription>;
export const LiveStatsDataDocument = gql`
    query LiveStatsData {
  liveStats @client {
    profits
    totalBetAmount
    totalProfit
    loseRounds
    winRounds
  }
}
    `;

/**
 * __useLiveStatsDataQuery__
 *
 * To run a query within a React component, call `useLiveStatsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveStatsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveStatsDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useLiveStatsDataQuery(baseOptions?: Apollo.QueryHookOptions<LiveStatsDataQuery, LiveStatsDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LiveStatsDataQuery, LiveStatsDataQueryVariables>(LiveStatsDataDocument, options);
      }
export function useLiveStatsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LiveStatsDataQuery, LiveStatsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LiveStatsDataQuery, LiveStatsDataQueryVariables>(LiveStatsDataDocument, options);
        }
export type LiveStatsDataQueryHookResult = ReturnType<typeof useLiveStatsDataQuery>;
export type LiveStatsDataLazyQueryHookResult = ReturnType<typeof useLiveStatsDataLazyQuery>;
export type LiveStatsDataQueryResult = Apollo.QueryResult<LiveStatsDataQuery, LiveStatsDataQueryVariables>;
export const ViewInFiatDocument = gql`
    query ViewInFiat {
  viewInFiat @client {
    status
    fiat {
      name
      rate
    }
  }
}
    `;

/**
 * __useViewInFiatQuery__
 *
 * To run a query within a React component, call `useViewInFiatQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewInFiatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewInFiatQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewInFiatQuery(baseOptions?: Apollo.QueryHookOptions<ViewInFiatQuery, ViewInFiatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewInFiatQuery, ViewInFiatQueryVariables>(ViewInFiatDocument, options);
      }
export function useViewInFiatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewInFiatQuery, ViewInFiatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewInFiatQuery, ViewInFiatQueryVariables>(ViewInFiatDocument, options);
        }
export type ViewInFiatQueryHookResult = ReturnType<typeof useViewInFiatQuery>;
export type ViewInFiatLazyQueryHookResult = ReturnType<typeof useViewInFiatLazyQuery>;
export type ViewInFiatQueryResult = Apollo.QueryResult<ViewInFiatQuery, ViewInFiatQueryVariables>;
export const StorageCurrencyDocument = gql`
    query StorageCurrency {
  storageCurrency @client {
    id
    shortName
    decimalDigits
    equivalentUsdRate
  }
}
    `;

/**
 * __useStorageCurrencyQuery__
 *
 * To run a query within a React component, call `useStorageCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageCurrencyQuery({
 *   variables: {
 *   },
 * });
 */
export function useStorageCurrencyQuery(baseOptions?: Apollo.QueryHookOptions<StorageCurrencyQuery, StorageCurrencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StorageCurrencyQuery, StorageCurrencyQueryVariables>(StorageCurrencyDocument, options);
      }
export function useStorageCurrencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StorageCurrencyQuery, StorageCurrencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StorageCurrencyQuery, StorageCurrencyQueryVariables>(StorageCurrencyDocument, options);
        }
export type StorageCurrencyQueryHookResult = ReturnType<typeof useStorageCurrencyQuery>;
export type StorageCurrencyLazyQueryHookResult = ReturnType<typeof useStorageCurrencyLazyQuery>;
export type StorageCurrencyQueryResult = Apollo.QueryResult<StorageCurrencyQuery, StorageCurrencyQueryVariables>;
export const HideSmallWalletAmountDocument = gql`
    query HideSmallWalletAmount {
  hideSmallWalletAmount @client
}
    `;

/**
 * __useHideSmallWalletAmountQuery__
 *
 * To run a query within a React component, call `useHideSmallWalletAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useHideSmallWalletAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHideSmallWalletAmountQuery({
 *   variables: {
 *   },
 * });
 */
export function useHideSmallWalletAmountQuery(baseOptions?: Apollo.QueryHookOptions<HideSmallWalletAmountQuery, HideSmallWalletAmountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HideSmallWalletAmountQuery, HideSmallWalletAmountQueryVariables>(HideSmallWalletAmountDocument, options);
      }
export function useHideSmallWalletAmountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HideSmallWalletAmountQuery, HideSmallWalletAmountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HideSmallWalletAmountQuery, HideSmallWalletAmountQueryVariables>(HideSmallWalletAmountDocument, options);
        }
export type HideSmallWalletAmountQueryHookResult = ReturnType<typeof useHideSmallWalletAmountQuery>;
export type HideSmallWalletAmountLazyQueryHookResult = ReturnType<typeof useHideSmallWalletAmountLazyQuery>;
export type HideSmallWalletAmountQueryResult = Apollo.QueryResult<HideSmallWalletAmountQuery, HideSmallWalletAmountQueryVariables>;