import { useAuthContext } from 'auth/useAuthContext';
import { SITE_HOUSE_DOMAINS } from 'graph/apollo';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { gamesLocalStateOperations } from 'store/operations';
import updateCacheOpts from 'store/operations/update-cache-opts';

// ----------------------------------------------------------------------

export function useMiniGame(slugs: string[]) {
  const { slug } = useParams();

  const { updateCacheUserWallet } = updateCacheOpts;
  const { showOverGameBankPopover } = gamesLocalStateOperations;
  const { openLoginModal, isAuthenticated } = useAuthContext();

  const [showPaytable, setShowPaytable] = useState({});
  const [showBetHistory, setShowBetHistory] = useState({});
  const [showWinnerHistory, setShowWinnerHistory] = useState({});
  const [isShowRoundDetailDialog, setIsShowRoundDetailDialog] = useState({});
  const [isShowEventDialog, setIsShowEventDialog] = useState({});
  const [isShowTopProfitDialog, setIsShowTopProfitDialog] = useState({});
  const [isShowTrendsDialog, setIsShowTrendsDialog] = useState({});
  const [isShowTouchFeatureDetail, setIsShowTouchFeatureDetail] = useState({});

  const minicrash_open = () => {
    const objStr = localStorage.getItem('minicrash-first-open');
    if (objStr) return false;
    return true;
  };
  const [showMiniCrash, setShowMiniCrash] = useState(minicrash_open());

  useEffect(() => {
    window.ccg_minicrash = {};
    window.ccg_minicrash.open = () => {
      setShowMiniCrash(true);
    };

    // window.ccg_threedice = {};
    // window.ccg_threedice.open = () => {};

    window.ccg_minigame = {
      urlDomain: process.env.REACT_APP_API_DOMAIN,
      houseDomain: SITE_HOUSE_DOMAINS[process.env.REACT_APP_SITE] || '',
    };
    window.ccg_minigame.onInsufficientAmount = () => {
      showOverGameBankPopover(true);
    };

    window.ccg_minigame.onUnauthenticated = () => {
      if (!isAuthenticated) openLoginModal();
    };

    window.ccg_minigame.onChangeBalance = ({ amount, currencyId }) => {
      updateCacheUserWallet({
        amount,
        currencyId,
      });
    };

    window?.ccg_button?.initButtonCCG!(slugs);

    slugs?.forEach((slug) => {
      const o = `ccg_${slug}`;

      const thisMiniGame: any = window[o as any];

      if (thisMiniGame) {
        thisMiniGame.onPaytable = () => {
          setShowPaytable((s: any) => {
            const cp = { ...s };
            cp[slug] = true;
            return cp;
          });
        };

        thisMiniGame.onHistory = (selectedTab: string) => {
          window.history.pushState(
            {
              dialogTab: selectedTab,
            },
            undefined,
          );
          setShowBetHistory((s: any) => {
            const cp = { ...s };
            cp[slug] = true;
            return cp;
          });
        };

        thisMiniGame.onWinner = () => {
          setShowWinnerHistory((s: any) => {
            const cp = { ...s };
            cp[slug] = true;
            return cp;
          });
        };

        thisMiniGame.onOpenGameEventDialog = (open = true) => {
          setIsShowEventDialog((prevState) => ({
            ...prevState,
            [slug]: open,
          }));
        };

        thisMiniGame.onOpenRoundDetailDialog = (
          roundId: string,
          open = true,
        ) => {
          setIsShowRoundDetailDialog((prevState) => ({
            ...prevState,
            [slug]: open,
          }));
          window.history.replaceState(
            {
              roundId,
            },
            undefined,
          );
        };

        thisMiniGame.onOpenTopProfitDialog = (open = true) => {
          setIsShowTopProfitDialog((prevState) => ({
            ...prevState,
            [slug]: open,
          }));
        };

        thisMiniGame.onOpenTrendsDialog = (open = true) => {
          setIsShowTrendsDialog((prevState) => ({
            ...prevState,
            [slug]: open,
          }));
        };

        thisMiniGame.onOpenTouchFeatureDialog = (open = true) => {
          setIsShowTouchFeatureDetail((prevState) => ({
            ...prevState,
            [slug]: open,
          }));
        };
      }
    });
  }, [slugs]);

  useEffect(() => {
    if (slug === 'crash') setShowMiniCrash(false);
  }, [slug]);

  useEffect(() => {
    setTimeout(() => {
      localStorage.setItem('minicrash-first-open', 'true');
    }, 1000);
  }, []);

  return {
    showMiniCrash,
    setShowMiniCrash,
    showPaytable,
    isShowEventDialog,
    isShowTrendsDialog,
    isShowTopProfitDialog,
    isShowRoundDetailDialog,
    isShowTouchFeatureDetail,
    hidePaytable: (slug: string) => {
      setShowPaytable((s: any) => {
        const cp = { ...s };
        cp[slug] = false;
        return cp;
      });
    },
    showBetHistory,
    hideHistory: (slug: string) => {
      setShowBetHistory((s: any) => {
        const cp = { ...s };
        cp[slug] = false;
        return cp;
      });
    },
    showWinnerHistory,
    hideWinner: (slug: string) => {
      setShowWinnerHistory((s: any) => {
        const cp = { ...s };
        cp[slug] = false;
        return cp;
      });
    },
  };
}
