import { LinearButton } from 'common/Button/style';
import { NAV } from 'config/config-global';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

// ------------------------------------------------------------

export const NavigationWrapper = styled(Box)(({ theme }) => ({
  width: NAV.W_BASE,
  height: '100%',
  backgroundColor: theme.palette.background.secondary,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  transition: 'width 0.3s ease',
  overflowX: 'hidden',
  padding: theme.spacing(1.5),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: theme.spacing(2, 1.5),
  },

  '.nav__box': {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2, 0),
  },

  '.logo': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.75rem 0 1rem 0',

    'svg:first-child': {
      width: '40%',
    },
  },
}));

export const LogoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '.logo': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    marginLeft: theme.spacing(3),

    svg: {
      width: '100%',
      height: 36,
      objectFit: 'cover',
      [theme.breakpoints.down('lg')]: {
        height: 30,
      },
    },
  },
}));

export const MenuIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(0),
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    color: theme.palette.text.contrast,
    backgroundColor: theme.palette.background.hovering,
  },
  transition: 'all ease 0.15s',
}));

// Language Mobile

export const LanguageMobile = styled(Box)(({ theme }) => ({
  display: 'flex',

  '.language_box, .fiat_box': {
    backgroundImage: 'linear-gradient(180deg, #313064 0%, #43416C 100%)',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',
    cursor: 'pointer',
    padding: '4px 8px',
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      color: theme.palette.text.contrast,
      path: {
        fill: theme.palette.text.contrast,
      },
    },
    img: {
      width: '24px',
      height: '24px',
    },
    span: {
      fontSize: '14px',
    },
  },
}));

// Gift code

export const GiftCode = styled(LinearButton)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  backgroundSize: '100% 100%',
  padding: theme.spacing('11px', 1.5),

  '.lottie-gift-code': {
    maxWidth: 30,
    marginRight: theme.spacing(1),
  },
  '.lottie-gift-code, svg': {
    height: 24,
    maxHeight: 24,
  },
}));

// NavItem

export const NavigationItemWrapper = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  marginBottom: theme.spacing(0.5),

  '.item__flex': {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    transition: 'all ease .2s',
    padding: theme.spacing(1, 1.5),

    'path, h6': {
      fill: theme.palette.text.primary,
      color: theme.palette.text.primary,
    },
  },

  '@media (hover: hover) and (pointer: fine)': {
    '.item__flex:hover': {
      '&:hover svg path': {
        fill: '#fff',
      },
      backgroundColor: theme.palette.background.main,
      transition: 'background ease 0.15s',
      borderRadius: theme.shape.borderRadius,
      'path, h6': {
        fill: theme.palette.text.contrast,
        color: theme.palette.text.contrast,
      },
    },
  },

  '.item__flex.active': {
    backgroundColor: theme.palette.background.hovering,
    borderRadius: theme.shape.borderRadius,
    transition: 'background ease 0.15s',
    'path, h6': {
      fill: theme.palette.text.contrast,
      color: theme.palette.text.contrast,
    },
  },

  '&& .vip_club': {
    'path, h6': {
      fill: theme.palette.support.orange,
      color: theme.palette.support.orange,
    },
  },

  '&&.live_support': {
    'path, h6': {
      fill: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },

  '&.grouped': {
    backgroundColor: theme.palette.background.overlay,
  },

  '&.collapse': {
    '.collapse_icon': {
      marginLeft: 'auto',
      transform: 'rotate(180deg)',
      '&.collapsed': {
        transform: 'rotate(0deg)',
      },
      transition: 'all ease-in-out .25s',
    },
  },

  '&.grouped:not(:first-of-type), &.sponsorships': {
    marginTop: theme.spacing(2),
  },

  '.icon__box': {
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1.5),
    '& svg.MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
}));
