import { bgVipPoint } from 'assets/images/vip_clubs';
import { textGradient } from 'utils/cssStyles';

import { alpha, Box, LinearProgress, styled, Typography } from '@mui/material';
import { Stack } from '@mui/system';

export const VipPointWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  display: 'flex',
  '.title-banner': {
    textTransform: 'uppercase',
    color: theme.palette.text.contrast,
    fontSize: 60,
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      fontSize: 32,
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  '&:before': {
    content: '" "',
    position: 'absolute',
    top: -70,
    left: 0,
    zIndex: -1,
    width: '100%',
    opacity: 1,
    aspectRatio: '2712/992',
    objectFit: 'cover',
    background: `url(${bgVipPoint}) no-repeat center/contain`,
    [theme.breakpoints.down('sm')]: {
      top: -20,
    },
  },
  '.vip_point_content': {
    flex: 4,
    justifyContent: 'center',
    'img[alt="vip"]': {
      aspectRatio: '239/175',
      objectFit: 'cover',
      [theme.breakpoints.down('md')]: {
        width: 120,
      },
      [theme.breakpoints.down('sm')]: {
        aspectRatio: '60/44',
        width: 60,
      },
    },
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },
  '.vip_rate_box': {
    flex: 2,
    position: 'relative',
    height: 'max-content',
    'img[alt="vip_point_gem"]': {
      aspectRatio: '390/503',
    },
    'img[alt="wager_rate"]': {
      position: 'absolute',
      top: '70%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 175,
      margin: 'auto',
      'img[alt="vip_point_gem"]': {
        aspectRatio: '175/213',
      },
      'img[alt="wager_rate"]': {
        top: '80%',
      },
    },
  },
}));

export const VipProgressWrapper = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(0deg, #313064 0%, rgba(67, 65, 108, 0) 58.57%)',
  width: ' 100%',
  borderRadius: '80px',
  padding: theme.spacing(0, 5, 3.75, 5),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 5, 3.75, 5),
  },
}));

export const ProgressInfo = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3, 0),
  },

  borderRadius: theme.shape.borderRadius,
  margin: '0 auto',
  position: 'relative',

  '.vip-progress-text': {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    color: '#FFE500',
    position: 'absolute',
    top: '-12px',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: theme.spacing(0, 1.5),
    background: theme.palette.background.secondary,
  },
}));

export const VipProgressBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  borderRadius: 12,
  position: 'relative',
  width: '300px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  '.exp': {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    '& p': {
      fontWeight: 700,
      fontSize: '10px',
      lineHeight: '18px',
    },
  },
}));

export const VipRankLinearTitle = styled(Typography)(() => ({
  '&.vip-rank-title': {
    fontSize: 22,
    fontWeight: 1000,
    textTransform: 'uppercase',
    width: 'fit-content',
    '&.bronze': {
      ...textGradient(
        '270deg, #FFF 29.42%, #FADED0 38.79%, #F5DACE 60.5%, #A15533 73.94%',
      ),
    },
    '&.silver': {
      ...textGradient(
        '270deg, #FFF 29.42%, #E0E4F4 38.79%, #DEE1F3 60.5%, #55639B 73.94%',
      ),
    },
    '&.gold': {
      ...textGradient(
        '270deg, #FFF 29.42%, #FBD278 38.79%, #FAD077 60.5%, #B65D1B 73.94%',
      ),
    },
    '&.platinum': {
      ...textGradient(
        '270deg, #FFCCE0 29.42%, #F577B6 38.79%, #F477B5 60.5%, #DA3296 73.94%',
      ),
    },
    '&.diamond': {
      ...textGradient(
        '270deg, #D3F6FF 29.42%, #71F5FD 38.79%, #18B6FE 60.5%, #685DE1 73.94%',
      ),
    },
  },
}));

export const VipBoxWrapper = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  position: 'absolute',
  right: 0,
  top: 0,
  transform: 'translate(160%, -30%)',
  aspectRatio: '1 / 1',
  width: '120px',
  '& img': {
    width: '100%',
    height: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    position: 'static',
    transform: 'unset',
    margin: 'auto',
    marginBottom: theme.spacing(2),
  },
}));

export const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: theme.shape.borderRadius,
  width: '300px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  background: theme.palette.background.main,
  '.MuiLinearProgress-bar': {
    width: '300px',
    background: 'linear-gradient(90deg, #63D8FE 2.23%, #8527FF 102.51%)',
    boxShadow: `2px 2px 4px 0px ${alpha(
      theme.palette.common.black,
      0.25,
    )} inset`,
    borderRadius: theme.shape.borderRadius,
  },
}));
