// routes
import {
  IconCasino,
  IconGameBaccarat,
  IconGameGemStone,
  IconGameMiniCrypto,
  IconGameMinipoker,
} from 'assets/icons';
import { ReactComponent as Affiliate } from 'assets/icons/Affiliate.svg';
import { ReactComponent as BitfarmIcon } from 'assets/icons/Bitfarm.svg';
import { ReactComponent as BlogIcon } from 'assets/icons/Blog.svg';
import { ReactComponent as CoinFlipIcon } from 'assets/icons/CoinFlip.svg';
import { ReactComponent as CrashIcon } from 'assets/icons/CrashIcon.svg';
import { ReactComponent as CryptoQuestIcon } from 'assets/icons/CryptoQuest.svg';
import { ReactComponent as DiceIcon } from 'assets/icons/dice.svg';
import { ReactComponent as LimboIcon } from 'assets/icons/LimboIcon.svg';
import { ReactComponent as MinesIcon } from 'assets/icons/Mines.svg';
import { ReactComponent as MochiMonsterIcon } from 'assets/icons/MochiMonster.svg';
import { ReactComponent as SupportIcon } from 'assets/icons/Support.svg';
import { ReactComponent as VideopokerIcon } from 'assets/icons/videopoker.svg';
import { ReactComponent as VipClub } from 'assets/icons/vip-club.svg';
import { HouseGame } from 'graph';
import { coinFlipSlugByEnv } from 'pages/Games/CoinFlip/config';
import { BLOG_PATHS } from 'routes/routesPage/blog';

import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { NavListProps } from '../../../components/nav-section/types';

// ----------------------------------------------------------------------

const navConfig = (): NavListProps[] => [
  {
    key: 'casino',
    type: 'collapse',
    path: PATH_DASHBOARD.casino.root,
    icon: <IconCasino />,
    items: [],
  },
  // {
  //   key: 'invest',
  //   icon: <IconProfit />,
  //   path: `coming-soon`,
  //   type: 'item',
  //   disable: false,
  // },
  // {
  //   key: 'promotions',
  //   icon: <Iconify icon="ion:gift" />,
  //   path: `coming-soon`,
  //   type: 'item',
  //   disable: false,
  // },
  {
    key: 'affiliate',
    icon: <Affiliate width="24px" height="24px" />,
    path: PATH_DASHBOARD.affiliate.root,
    type: 'item',
    disable: false,
  },
  {
    key: 'vip_club',
    type: 'item',
    icon: <VipClub width="24px" height="24px" />,
    path: PATH_DASHBOARD.vipClub.root,
    disable: false,
  },
  {
    key: 'blog',
    type: 'item',
    icon: <BlogIcon width="24px" height="24px" />,
    path: BLOG_PATHS.BLOG_HOME,
    link: `https://blog.${window.location.host}/`,
    disable: false,
  },
  {
    key: 'live_support',
    type: 'item',
    icon: <SupportIcon width="24px" height="24px" />,
    disable: false,
    click: () => {
      window.Intercom('show');
    },
  },
];

const listGamesHasIcon = [
  'crash',
  'minipoker',
  'bitfarm',
  'limbo',
  'baccarat',
  'cryptoquest',
  'dice',
  'mines',
  'mochimonster',
  'minicrypto',
  'gemstonewizardry',
  coinFlipSlugByEnv,
  'videopoker',
];

export const getGameIcon = (slug: string) => {
  switch (slug) {
    case listGamesHasIcon[0]:
      return <CrashIcon width="24px" height="24px" />;
    case listGamesHasIcon[1]:
      return <IconGameMinipoker />;
    case listGamesHasIcon[2]:
      return <BitfarmIcon width="24px" height="24px" />;
    case listGamesHasIcon[3]:
      return <LimboIcon width="24px" height="24px" />;
    case listGamesHasIcon[4]:
      return <IconGameBaccarat />;
    case listGamesHasIcon[5]:
      return <CryptoQuestIcon width="24px" height="24px" />;
    case listGamesHasIcon[6]:
      return <DiceIcon width="24px" height="24px" />;
    case listGamesHasIcon[7]:
      return <MinesIcon width="24px" height="24px" />;
    case listGamesHasIcon[8]:
      return <MochiMonsterIcon />;
    case listGamesHasIcon[9]:
      return <IconGameMiniCrypto />;
    case listGamesHasIcon[10]:
      return <IconGameGemStone />;
    case listGamesHasIcon[11]:
      return <CoinFlipIcon />;
    case listGamesHasIcon[12]:
      return <VideopokerIcon />;
    default:
      return null;
  }
};

export const getConfigNavWithGames = (games: HouseGame[]) => {
  const showedGamesOnNav = 12;

  return navConfig().map((item) => {
    if (!games || games.length === 0) return item;
    const gamesNavItems = [];

    if (item.key === 'casino') {
      for (const game of games) {
        if (listGamesHasIcon.includes(game.game?.slug)) {
          gamesNavItems.push({
            key: game.game.name,
            path: `casino/original/${game.game.slug}`,
            icon: getGameIcon(game?.game.slug),
            type: 'grouped_item',
            disable: false,
          });
        }
      }

      return {
        ...item,
        items: gamesNavItems.slice(0, showedGamesOnNav),
      };
    }

    return item;
  });
};

export const getFlatConfigNavWithGames = (games: HouseGame[]) => {
  if (!games || games.length === 0) return navConfig();

  const gamesNavItems = [];

  for (const game of games) {
    if (listGamesHasIcon.includes(game.game?.slug)) {
      gamesNavItems.push({
        key: game.game.name,
        path: `casino/original/${game.game.slug}`,
        icon: getGameIcon(game?.game.slug),
        type: 'grouped_item',
        disable: false,
      });
    }
  }

  const removedCasino = navConfig().slice(1);

  return gamesNavItems.concat(removedCasino);
};

export default navConfig;
